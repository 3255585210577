import { Enquiry } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name fetchEnquiry
		 * @param {Object} context
		 * @param {Uuid} id
		 */
		fetchEnquiry(context, id) {
			return Enquiry.get(id);
		},

		/**
		 * @async @name fetchEnquiryList
		 * @param {Object} context
		 * @param {Object} payload
		 */
		fetchEnquiryList(context, payload) {
			return Enquiry.list(payload);
		},

		/**
		 * @async @name reconnect
		 * @param {Object} context
		 * @param {Object} payload
		 */
		reconnect(context, payload) {
			return Enquiry.reconnect(payload);
		},

		/**
		 * @async @name createResponse
		 * @param {Object} context
		 * @param {Object} payload
		 */
		createResponse(context, payload) {
			return Enquiry.createResponse(payload);
		},

		/**
		 * @async @name response
		 * @param {Object} context
		 * @param {Object} payload
		 */
		response(context, payload) {
			return Enquiry.response(payload);
		},

		/**
		 * @async @name addProvider
		 * @param {function} commit
		 * @param {String} id
		 * @param {Object} payload
		 */
		addProvider(context, { id, payload }) {
			return Enquiry.addProvider(id, payload);
		},

		/**
		 * @async @name fetchFile
		 * @param {Object} context
		 * @param {Object} payload
		 */
		fetchFile(context, payload) {
			return Enquiry.getFile(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
