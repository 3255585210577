/**
 * @class @name App
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	state: {
		navigationItems: [
			{
				name: 'Dashboard',
				path: '/',
				icon: 'mdi-star',
				disabled: false
			},
			{
				name: 'Users',
				path: '/users',
				icon: 'mdi-star',
				groups: ['administrator.user'],
				disabled: false
			},
			{
				name: 'Registrations',
				path: '/registrations',
				icon: 'mdi-star',
				groups: ['administrator.user'],
				disabled: false
			},
			{
				name: 'Trading Styles',
				path: '/trading-styles',
				icon: 'mdi-star',
				groups: ['administrator.user'],
				disabled: false
			},
			{
				name: 'CMS',
				path: '/cms',
				icon: 'mdi-star',
				groups: ['administrator.cms'],
				disabled: false
			},
			{
				name: 'KFI Requests',
				path: '/requests',
				icon: 'mdi-star',
				groups: ['administrator.kfi'],
				disabled: false
			},
			{
				name: 'Cases',
				path: '/cases',
				icon: 'mdi-star',
				groups: ['administrator.cases'],
				disabled: false
			},
			{
				name: 'Enquiries',
				path: '/enquiries',
				icon: 'mdi-star',
				groups: ['administrator.enquiries'],
				disabled: false
			},
			{
				name: 'Rewards',
				path: '/rewards',
				icon: 'mdi-star',
				groups: ['administrator.rewards'],
				disabled: false
			},
			{
				name: 'Firm Rewards',
				path: '/firm-rewards',
				icon: 'mdi-star',
				groups: ['administrator.firmRewards'],
				disabled: false
			},
			{
				name: 'Admin',
				path: '/admin',
				icon: 'mdi-star',
				groups: ['administrator.system'],
				disabled: false
			}
		]
	},

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	getters: {
		/**
		 * @name navigationItems
		 * @description used to get navigation list
		 * @param {Object} state
		 */
		navigationItems: (state, getters, rootState) => {
			const userGroups = rootState.Account?.groups;
			return !userGroups ? [] : state.navigationItems.filter((item) => !item.groups || item.groups.some((group) => userGroups.includes(group)));
		}
	},

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	mutations: {},

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {},

	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
