import { RewardTransaction } from '@/service';

/**
 * @class @name
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	state: {},

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	getters: {},

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	mutations: {},

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getRewardTransactionList
		 * @param {function} commit
		 * @param {Object} payload
		 */
		getRewardTransactionList(context, payload) {
			return RewardTransaction.list(payload);
		},

		/**
		 * @async @name modifyRewardTransaction
		 * @param {function} commit
		 * @param {Object} payload
		 */
		modifyRewardTransaction(context, { id, payload }) {
			return RewardTransaction.modify(id, payload);
		},

		/**
		 * @async @name deleteRewardTransaction
		 * @param {function} commit
		 * @param {Object} payload
		 */
		deleteRewardTransaction(context, id) {
			return RewardTransaction.delete(id);
		},

		/**
		 * @async @name getUnclaimedRewards
		 * @param {function} commit
		 */
		getUnclaimedRewards(context) {
			return RewardTransaction.unclaimed();
		}
	}
};
