import { CmsEvent } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getEventList
		 * @param {String} id
		 */
		getEventList(context, payload) {
			return CmsEvent.list(payload);
		},

		/**
		 * @async @name getEvent
		 * @param {String} id
		 */
		getEvent(context, id) {
			return CmsEvent.get(id);
		},

		/**
		 * @async @name getEvent
		 * @param {String} id
		 */
		modifyEvent(context, { id, payload }) {
			return CmsEvent.modify(id, payload);
		},

		/**
		 * @async @name addEvent
		 */
		deleteEvent(context, id) {
			return CmsEvent.delete(id);
		},

		/**
		 * @async @name addEvent
		 */
		addEvent(context, payload) {
			return CmsEvent.add(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
