<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>Network #{{ networkId }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/admin/networks`"> Back to networks </v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="form('manager_network').value" :form-data="formData" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import DynamicForm from '@/components/common/base/dynamic-form';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';

	export default {
		name: 'app-products-details',
		components: {
			DynamicForm,
			CommonDialogConfirm
		},

		data: () => ({
			formData: {},
			formSchema: [],
			loading: false,
			submitting: false,
			organisation: {},
			valid: false
		}),

		computed: {
			...mapGetters('CmsForm', ['form']),

			networkId() {
				return this.$route.params.id || this.formData.id;
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('AccountOrganisation', [
				'getOrganisation',
				'addOrganisation',
				'modifyOrganisation',
				'addOrganisationAddress',
				'modifyOrganisationAddress',
				'addOrganisationCommunication',
				'modifyOrganisationCommunication',
				'addOrganisationDetail',
				'modifyOrganisationDetail',
				'addOrganisationProvider',
				'modifyOrganisationProvider'
			]),

			async init() {
				this.$route.meta.breadcrumb[3] = { text: `Network #${this.networkId}`, to: `/admin/network/${this.networkId}` };

				this.loading = true;
				await this.loadForm('manager_network');
				this.loading = false;

				if (this.networkId) {
					this.loading = true;

					this.getOrganisation(this.networkId).then(({ data }) => {
						this.loading = false;
						this.organisation = data;
						this.setFormData(data);
						this.$nextTick(async () => {
							this.valid = await this.$refs.dynamicForm.validate(false, true);
						});
					});
				}
			},

			setFormData(data) {
				this.formData = {
					id: data.id,
					name: data.name,
					address1: data.organisationAddress?.addressLine1,
					address2: data.organisationAddress?.addressLine2,
					town: data.organisationAddress?.townCity,
					postcode: data.organisationAddress?.postalCode
				};
			},

			/**
			 * @name submit
			 * @description submit user request to update firm details
			 */
			async submit(data) {
				this.valid = true;
				this.submitting = true;

				let requests = [
					this.modifyOrganisation({
						id: this.organisation.id,
						data: {
							name: data.name
						}
					})
				];

				if (this.organisation.organisationAddress) {
					requests.push(
						this.modifyOrganisationAddress({
							id: this.organisation.organisationAddress.id,
							data: {
								id: this.organisation.organisationAddress.id,
								addressLine1: data.address1,
								addressLine2: data.address2,
								townCity: data.town,
								postalCode: data.postcode
							}
						})
					);
				} else {
					requests.push(
						this.addOrganisationAddress({
							organisationId: this.organisation.id,
							addressLine1: data.address1,
							addressLine2: data.address2,
							townCity: data.town,
							postalCode: data.postcode
						})
					);
				}

				Promise.all([requests])
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Network details have been successfully updated');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update network details'))
					.finally(() => (this.submitting = false));
			}
		}
	};
</script>

<style lang="scss" scoped>
	.wrapper {
		background: #fff;
	}
</style>
