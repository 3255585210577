import { AccountOrganisation } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getProviderList
		 * @param {function} commit
		 */
		async getProviderList(context, payload) {
			return AccountOrganisation.list({ ...(payload || {}), where: { ...(payload.where || {}), 'organisation.type': 'provider' } });
		},

		/**
		 * @async @name getDownload
		 * @param {String} id
		 */
		getProvider(context, id) {
			return AccountOrganisation.get(id);
		},

		/**
		 * @async @name getProvider
		 * @param {String} id
		 */
		modifyProvider(context, { id, payload }) {
			return AccountOrganisation.modify(id, payload);
		},

		/**
		 * @async @name addProvider
		 */
		deleteProvider(context, id) {
			return AccountOrganisation.delete(id);
		},

		/**
		 * @async @name addProvider
		 */
		addProvider(context, payload) {
			return AccountOrganisation.add(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
