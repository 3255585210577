import { CmsBanner } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getBannerList
		 * @param {String} id
		 */
		getBannerList(context, payload) {
			return CmsBanner.list(payload);
		},

		/**
		 * @async @name getBanner
		 * @param {String} id
		 */
		getBanner(context, id) {
			return CmsBanner.get(id);
		},

		/**
		 * @async @name getBanner
		 * @param {String} id
		 */
		modifyBanner(context, { id, payload }) {
			return CmsBanner.modify(id, payload);
		},

		/**
		 * @async @name addBanner
		 */
		deleteBanner(context, id) {
			return CmsBanner.delete(id);
		},

		/**
		 * @async @name addBanner
		 */
		addBanner(context, payload) {
			return CmsBanner.add(payload);
		},

		/**
		 * @name getBannerTypeList
		 * @description Get all banner types as a list
		 * @returns {Object} Promise a http response
		 */
		getBannerTypeList(context, payload) {
			return CmsBanner.bannerTypeList(payload);
		},

		/**
		 * @async @name getBanner
		 * @param {String} id
		 */
		getBannerType(context, id) {
			return CmsBanner.getBannerType(id);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
