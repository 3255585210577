<!--
	@name common-base-breadcrumb
	@description breadcrumbs created from route meta
	@date 2022/12/07
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" style="background: #fff" class="elevation-1">
		<template #item="{ item }">
			<v-breadcrumbs-item :to="item.to" class="text-subtitle-2" exact>
				{{ item.text }}
			</v-breadcrumbs-item>
		</template>
	</v-breadcrumbs>
</template>

<script>
	export default {
		name: 'common-base-breadcrumb',

		computed: {
			breadcrumbs() {
				return this.$route.meta?.breadcrumb;
			}
		}
	};
</script>
