<template>
	<div class="pa-8">
		<div class="d-flex">
			<v-pagination v-model="options.page" :length="pages" total-visible="7" :disabled="loading" />
		</div>
		<v-data-table
			:headers="headers"
			:items="items"
			disable-pagination
			class="elevation-1 mt-3"
			:loading="loading"
			:options.sync="options"
			:server-items-length="total"
			hide-default-footer
			:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [5, 10, 15, 20, 25] }"
		>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn small color="success" :loading="redeeming[item.id]" @click="redeemItem(item.id)">
						redeem reward points
					</v-btn>
				</div>
			</template>
		</v-data-table>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';

	export default {
		name: 'app-admin-firm-rewards',

		components: {
			CommonDialogConfirm
		},

		data: () => ({
			items: [],
			loading: false,
			options: {},
			total: 0,
			pages: 0,
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Created', value: 'created' },
				{ text: 'Points Total', value: 'totalPoints' },
				{ text: '', value: 'actions' }
			],
			data: [],
			redeeming: {}
		}),

		watch: {
			options: {
				handler() {
					this.fetchFirmRewardsList();
				},
				deep: true
			}
		},

		changePage(evt) {
			if (evt) this.options.page++;
			else this.options.page--;
		},

		methods: {
			...mapActions('AccountOrganisation', ['getFirmRewardsList', 'redeemRewards']),

			async fetchFirmRewardsList() {
				if (this.loading) return;

				this.loading = true;

				const { page, itemsPerPage } = this.options;

				const payload = {
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					order: { property: 'organisation.name', direction: 'asc' }
				};

				let { data } = await this.getFirmRewardsList(payload);
				this.items = data.data;
				this.total = data.total;
				this.pages = Math.ceil(this.total / this.options.itemsPerPage);
				this.loading = false;
			},

			redeemItem(id) {
				this.$refs.confirm
					.open('Redeem firm rewards?', `Are you sure you wish to redeem the points for this firm?`)
					.then(() => {
						this.redeemRewards({ organisationId: id })
							.then(() => {
								ElementTools.fireNotification(this.$el, 'success', 'Reward points have been successfully redeemed');
								this.fetchFirmRewardsList();
							})
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to redeem points for firm'))
							.finally(() => (this.submitting = false));
					})
					.catch(() => {});
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep ul.v-pagination {
		justify-content: end !important;
	}
</style>
