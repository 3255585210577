<!--
	@name common-base-notification
	@description Global notification element
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-snackbar :value="notification.active" top :color="notification.type" :timeout="notification.timeout" app>
			<ul v-if="Array.isArray(notification.text)">
				<li v-for="(text, index) in notification.text" :key="index">
					{{ text }}
				</li>
			</ul>
			<div v-else>
				{{ notification.text }}
			</div>

			<template v-slot:action>
				<v-btn v-show="notification.close" icon @click="hide">
					<v-icon>mdi-close-circle</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
	export default {
		name: 'common-base-notification',

		data() {
			return { notification: {} };
		},

		computed: {
			default() {
				return {
					type: 'info',
					timeout: 3000,
					text: '',
					close: false
				};
			}
		},

		methods: {

			/**
			 * @static @name show
			 * @description Show a notification, uses a default detail set and splices in anything passed in
			 */
			show(detail) {
				this.notification = { ...this.default, ...detail, active: false };
				this.$nextTick(() => this.notification.active = true);
			},

			/**
			 * @static @name hide
			 * @description Hide the notifcation early, if timeout is long
			 */
			hide() {
				this.notification.active = false;
			}
		}
	};
</script>
