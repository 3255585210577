import { SystemVersion } from '@/service';
import { StringTools } from '@/utils';

/**
 * @class @name
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	state: {
		versionCurrent: process.env.VUE_APP_VERSION ?? '0.0.0',
		versionCheck: undefined
	},

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	getters: {
		versionUpgrade: (state) => StringTools.versionToNumber(state.versionCheck) > StringTools.versionToNumber(state.versionCurrent)
	},

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	mutations: {
		/**
		 * @name setVersion
		 * @description Set version number
		 * @param {Object} state
		 * @param {Object} data
		 */
		setVersionCheck(state, data) {
			state.versionCheck = data.version.ui.number;
		}
	},

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @name getVersion
		 * @description Get version from version log
		 * @param {function} commit
		 */
		async getVersionCheck({ commit }) {
			return SystemVersion.getVersion().then((request) => commit('setVersionCheck', request?.data));
		}
	}
};
