<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>User #{{ userId }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/user/${userId}`"> Back to user </v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<v-alert v-else-if="isProviderOrganisation" text outlined type="error" border="left" class="ma-0">
						<span class="text-body-2">Firm details cannot be updated for provider preview account</span>
					</v-alert>
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="formSchema.department" :form-data="formData.department" :submit-button-loading="submitting" @dynamic-form-submit="submitDepartment" />
						</v-sheet>
						<v-sheet v-if="isFirmOwner" class="pa-6 mt-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="formSchema.details" :form-data="formData.details" :submit-button-loading="submitting" @dynamic-form-submit="submitDetails" />
						</v-sheet>
						<v-sheet v-if="isFirmOwner" class="pa-6 mt-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="formSchema.address" :form-data="formData.address" :submit-button-loading="submitting" @dynamic-form-submit="submitAddress" />
						</v-sheet>
						<v-sheet v-if="isFirmOwner" class="pa-6 mt-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="formSchema.communication" :form-data="formData.communication" :submit-button-loading="submitting" @dynamic-form-submit="submitCommunication" />
						</v-sheet>
						<v-sheet v-if="isFirmOwner" class="pa-6 mt-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="formSchema.rewards" :form-data="formData.rewards" :submit-button-loading="submitting" @dynamic-form-submit="submitRewards" />
						</v-sheet>
						<v-sheet v-if="isFirmOwner" class="pa-6 mt-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="formSchema.writeroute" :form-data="formData.writeroute" :submit-button-loading="submitting" @dynamic-form-submit="submitWriteroute" />
						</v-sheet>
						<v-sheet class="pa-6 mt-6" elevation="4">
							<h2>Company Accounts</h2>
							<v-data-table :headers="headers" :items="users" class="elevation-1 mt-3" :loading="loading" />
						</v-sheet>
						<v-sheet v-if="isFirmOwner" class="pa-6 mt-6" elevation="4">
							<h2>Submission Routes</h2>
							<v-form ref="submissionRoutesForm" @submit.prevent="submitSubmissionRoutes">
								<v-list>
									<v-list-item v-for="p in providers" :key="p.id" class="my-2 grey lighten-4">
										<v-list-item-content class="py-0">
											<div class="d-flex align-center">
												<span class="font-weight-bold">{{ p.name }}</span>
												<v-switch v-model="p.value" class="ml-2" />
											</div>
											<div v-if="p.value">
												<v-text-field
													v-model="p.submissionRoute"
													label="Submission Route"
													outlined
													dense
													hide-details
													class="pb-6"
												/>
											</div>
										</v-list-item-content>
									</v-list-item>
								</v-list>

								<div class="text-center">
									<v-btn color="primary" type="submit">
										Submit
									</v-btn>
								</div>
							</v-form>
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>

		<dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import DynamicForm from '@/components/common/base/dynamic-form';
	import DialogConfirm from '@/components/common/dialog/confirm';

	export default {
		name: 'app-users-details-details',

		components: {
			DynamicForm,
			DialogConfirm
		},

		data() {
			return {
				formSchema: {
					department: [],
					details: [],
					address: [],
					communication: [],
					rewards: [],
					writeroute: []
				},
				formData: {
					department: {},
					details: {},
					address: {},
					communication: {},
					rewards: {},
					writeroute: {}
				},
				user: null,
				headers: [
					{ text: 'Name', value: 'name', sortable: false },
					{ text: 'Email', value: 'email', sortable: false },
					{ text: 'Role', value: 'role', sortable: false }
				],
				users: [],
				loading: false,
				submitting: false,
				firm: null,
				providers: null
			};
		},

		computed: {
			...mapGetters('CmsForm', ['formFromGroup']),

			userId() {
				return this.$route.params.id;
			},

			isFirmOwner() {
				return this.user?.department.nameUnique.includes('advisor.owner');
			},

			isProviderOrganisation() {
				return this.user?.organisation.type === 'provider';
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('AccountOrganisation', ['getOrganisation', 'modifyOrganisation', 'modifyOrganisationAddress', 'modifyOrganisationCommunication', 'getDepartmentList', 'getOrganisationUserList']),
			...mapActions('AccountUser', ['fetchUser', 'modifyUserDepartment']),
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsConfig', ['loadConfig']),

			async init() {
				this.$route.meta.breadcrumb[2] = {
					text: `User #${this.userId}`,
					to: `/user/${this.userId}`
				};

				this.loading = true;

				await this.loadForm('manager_user_firm');
				this.formSchema.department = this.formFromGroup('manager_user_firm', 'department');
				this.formSchema.details = this.formFromGroup('manager_user_firm', 'company_details');
				this.formSchema.address = this.formFromGroup('manager_user_firm', 'address');
				this.formSchema.communication = this.formFromGroup('manager_user_firm', 'contact');
				this.formSchema.rewards = this.formFromGroup('manager_user_firm', 'rewards');
				this.formSchema.writeroute = this.formFromGroup('manager_user_firm', 'writeroute');

				if (this.userId) {
					let { data } = await this.fetchUser(this.userId);
					this.user = data;

					const organisationPromise = this.getOrganisation(data.organisation.id);

					const departmentListPromise = this.getDepartmentList({
						where: { 'organisation.id': this.user.organisation.id }
					}).then(({ data }) => {
						this.formSchema.department.find((item) => item.name === 'memberType').options = data.data.map((item) => ({
							value: item.nameUnique.split('.').pop(),
							text: item.name
						}));
					});

					const organisationUserListPromise = this.getOrganisationUserList(data.organisation.id).then(({ data }) => {
						this.users = data.map((u) => ({
							name: u.name,
							email: u.userDetail.email,
							role: u.department ? (u.department.name.includes('owner') ? 'Account Owner' : u.department.name.includes('manager') ? 'Admin' : '') : ''
						}));
					});

					const providerPromise = this.loadConfig({ type: 'organisation', name: 'submission-routes-override' }).then((response) => {
						if (!response.error) this.providers = response.data.organisation || [];
					});

					Promise.all([organisationPromise, departmentListPromise, organisationUserListPromise, providerPromise])
						.then((response) => {
							this.organisation = response[0].data;
							this.setFormData(response[0].data);
							this.setSubmissionRoutesData(response[0].data);
						})
						.finally(() => {
							this.loading = false;
						});
				} else this.loading = false;
			},

			setFormData(org) {
				this.formData = {
					department: {
						memberType: this.user.department.nameUnique.split('.').pop()
					},
					details: {
						name: org.name,
						fca: org.data?.membership?.fca,
						fca_locked: org.data?.membership?.fca_locked
					},
					address: {
						addressLine1: org.organisationAddress.addressLine1,
						addressLine2: org.organisationAddress.addressLine2,
						townCity: org.organisationAddress.townCity,
						postalCode: org.organisationAddress.postalCode
					},
					communication: {
						landline: org.organisationCommunication.find((d) => d.type === 'phone').identifier
					},
					rewards: {
						rewards: org.data?.rewards
					},
					writeroute: {
						version: org.data?.writeRouteVersion,
						domain: org.data?.writeRouteDomain
					}
				};
			},

			setSubmissionRoutesData(org) {
				const providers = this.providers.map((p) => ({
					id: p.id,
					name: p.name,
					value: !!Object.prototype.hasOwnProperty.call(org.data?.submissionRoutesOverride ?? {}, p.id),
					submissionRoute: org.data?.submissionRoutesOverride?.[p.id] || ''
				}));

				this.providers = providers;
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submitDetails(data) {
				this.submitting = true;

				this.modifyOrganisation({
					id: this.organisation.id,
					data: {
						name: data.name,
						data: { membership: { fca: data.fca, fca_locked: data.fca_locked } }
					}
				})
					.then(() => ElementTools.fireNotification(this.$el, 'success', 'Firm details have been successfully updated'))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update Firm details'))
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submitAddress(data) {
				this.submitting = true;

				this.modifyOrganisationAddress({
					id: this.organisation.organisationAddress.id,
					data: {
						id: this.organisation.organisationAddress.id,
						addressLine1: data.addressLine1,
						addressLine2: data.addressLine2,
						townCity: data.townCity,
						postalCode: data.postalCode
					}
				})
					.then(() => ElementTools.fireNotification(this.$el, 'success', 'Firm details have been successfully updated'))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update Firm details'))
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submitCommunication(data) {
				this.submitting = true;

				this.modifyOrganisationCommunication({
					id: this.organisation.organisationCommunication.find((d) => d.type === 'phone').id,
					data: {
						organisationId: this.organisation.id,
						identifier: data.landline
					}
				})
					.then(() => ElementTools.fireNotification(this.$el, 'success', 'Firm details have been successfully updated'))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update Firm details'))
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submitDepartment(data) {
				this.$refs.confirm
					.open('Make Changes to Department', `Are you sure you wish to update ${this.user.name} member type?`)
					.then(() => {
						console.log('data :>> ', data);
						this.modifyUserDepartment({ id: this.user.department.userDepartmentId, data: { department: { name: data.memberType } } })
							.then(() => ElementTools.fireNotification(this.$el, 'success', 'Department details have been successfully updated'))
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update Department details'))
							.finally(() => (this.submitting = false));
					})
					.catch(() => ({}));
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submitRewards(data) {
				this.submitting = true;

				this.modifyOrganisation({
					id: this.organisation.id,
					data: {
						data: { rewards: data.rewards }
					}
				})
					.then(() => ElementTools.fireNotification(this.$el, 'success', 'Rewards details have been successfully updated'))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update Rewards details'))
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			 async submitWriteroute(data) {
				this.submitting = true;

				this.modifyOrganisation({
					id: this.organisation.id,
					data: {
						data: {
							writeRouteVersion: data.version,
							writeRouteDomain: data.domain
						}
					}
				})
					.then(() => ElementTools.fireNotification(this.$el, 'success', 'Writeroute details have been successfully updated'))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update Writeroute details'))
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			submitSubmissionRoutes() {
				let overrides = {};
				this.providers.forEach((p) => {
					if (p.value) overrides[p.id] = p.submissionRoute;
				});
				
				this.modifyOrganisation({
					id: this.organisation.id,
					data: {
						data: {
							submissionRoutesOverride: Object.keys(overrides).length ? overrides : null
						}
					}
				})
					.then(() => ElementTools.fireNotification(this.$el, 'success', 'Submission routes have been successfully updated'))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update submission routes'))
					.finally(() => (this.submitting = false));
			}
		}
	};
</script>

<style lang="scss" scoped></style>
