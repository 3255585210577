import { CmsProductUpdate } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getProductUpdateList
		 * @param {String} id
		 */
		getProductUpdateList(context, payload) {
			return CmsProductUpdate.list(payload);
		},

		/**
		 * @async @name getProductUpdate
		 * @param {String} id
		 */
		getProductUpdate(context, id) {
			return CmsProductUpdate.get(id);
		},

		/**
		 * @async @name getProductUpdate
		 * @param {String} id
		 */
		modifyProductUpdate(context, { id, payload }) {
			return CmsProductUpdate.modify(id, payload);
		},

		/**
		 * @async @name addProductUpdate
		 */
		deleteProductUpdate(context, id) {
			return CmsProductUpdate.delete(id);
		},

		/**
		 * @async @name addProductUpdate
		 */
		addProductUpdate(context, payload) {
			return CmsProductUpdate.add(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
