<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>{{ courseId ? `Course #${courseId}` : `New Course` }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/cms/courses`"> Back to courses </v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="form('manager_course').value" :form-data="formData" :submit-button="false" />
							<dynamic-form ref="videoForm" :form-schema="form('manager_course_video').value" :form-data="videoFormData" :submit-button="false" />
							<div class="text-center mt-4">
								<v-btn color="primary" @click="submit"> submit </v-btn>
							</div>
						</v-sheet>
					</div>

					<v-sheet class="pa-6 mt-4" elevation="4">
						<dynamic-form
							:form-schema="form('workflow_template_item').value"
							:form-data="workflowTemplateItemFormData"
							:submit-button-loading="submitting"
							:all-disabled="!formData.id"
							:submit-button="false"
						/>
					</v-sheet>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-course-details',
		components: {
			DynamicForm
		},

		data: () => ({
			formData: {},
			videoFormData: {},
			workflowTemplateItemFormData: {},
			loading: false,
			submitting: false
		}),

		computed: {
			...mapGetters('CmsForm', ['form']),

			courseId() {
				return this.$route.params.id || this.formData.id;
			},

			courseTypeId() {
				return this.$route.params.courseTypeId;
			},

			selectedWorkflowTemplateItems() {
				return [...(this.workflowTemplateItemFormData?.workflowTemplateItem || [])];
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsCourse', ['getCourse', 'addCourse', 'modifyCourse', 'addWorkflowTemplateItem', 'deleteWorkflowTemplateItem']),

			async init() {
				this.$route.meta.breadcrumb[3].text = this.courseId ? `Course #${this.courseId}` : 'New Course';

				this.loading = true;
				if (this.courseId) {
					await this.getCourse(this.courseId)
						.then(({ data }) => {
							this.workflowTemplateItemFormData = { workflowTemplateItem: data.workflowTemplateItem?.map((workflowTemplateItem) => workflowTemplateItem.id) };
							this.formData = data;
							this.videoFormData = data;
							this.initWatchers();
						});
				}

				await Promise.all([this.loadForm('manager_course'), this.loadForm('manager_course_video'), this.loadForm('workflow_template_item')]);
				this.loading = false;
			},

			initWatchers() {
				this.$watch(
					'selectedWorkflowTemplateItems',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));
						if (added) this.addWorkflowTemplateItemRequest(added);
						else if (deleted) this.deleteWorkflowTemplateItemRequest(deleted);
					},
					{ deep: true }
				);
			},

			addWorkflowTemplateItemRequest(workflowTemplateItemId) {
				let payload = { workflowTemplateItemIds: [workflowTemplateItemId] };

				this.addWorkflowTemplateItem({ id: this.courseId, payload }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to add workflowTemplateItem');
					this.getCourse(this.courseId).then(({ data }) => {
						this.workflowTemplateItemFormData = { workflowTemplateItem: data.workflowTemplateItem?.map((workflowTemplateItem) => workflowTemplateItem.id) };
					});
				});
			},

			deleteWorkflowTemplateItemRequest(workflowTemplateItemId) {
				this.deleteWorkflowTemplateItem({ id: this.courseId, workflowTemplateItemId }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to delete Workflow Template Item');
					this.getCourse(this.courseId).then(({ data }) => {
						this.workflowTemplateItemFormData = { workflowTemplateItem: data.workflowTemplateItem?.map((workflowTemplateItem) => workflowTemplateItem.id) };
					});
				});
			},

			submit() {
				this.submitting = true;

				const payload = {
					courseTypeId: this.formData.courseTypeId,
					name: this.formData.name,
					description: this.formData.description,
					url: this.formData.url,
					image: this.formData.files?.[0]?.s3Key || this.formData.image,
					video: this.videoFormData.video?.[0]?.s3Key || this.videoFormData.video,
					useAsIntro: this.videoFormData.useAsIntro,
					position: this.formData.position
				};

				const promise = this.courseId ? this.modifyCourse({ id: this.courseId, payload }) : this.addCourse(payload);
				promise
					.then(({ data }) => {
						ElementTools.fireNotification(this.$el, 'success', 'Course has been submitted successfully');

						if (data?.id) {
							this.$router.push(`/cms/course/${data.id}`);
							this.formData.id = this.formData.id || data.id;
						}
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit Course'))
					.finally(() => {
						this.submitting = false;
						this.initWatchers();
					});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.wrapper {
		background: #fff;
	}
</style>
