<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title class="font-weight-bold">
				{{ groupId ? `Group ${groupId}` : 'Add New Group' }}
			</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/admin/groups`">
				Back to groups
			</v-btn>
		</v-toolbar>

		<v-container v-if="!groupId" fluid>
			<v-row dense no-gutters class="justify-center">
				<v-col cols="6" md="8" lg="8" xl="6">
					<v-sheet class="pa-6" elevation="4">
						<dynamic-form ref="dynamicForm" :form-schema="form('manager_group').value" :form-data="formData" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
					</v-sheet>
				</v-col>
			</v-row>
		</v-container>
		<v-container fluid>
			<v-row dense no-gutters class="justify-center">
				<v-col cols="6" md="8" lg="8" xl="6">
					<v-sheet class="pa-6" elevation="4">
						<v-toolbar-title class="font-weight-bold">
							Roles
						</v-toolbar-title>

						<dynamic-form ref="dynamicFormRoles" :form-schema="mappedManagerGroupRoleForm" :form-data="formDataRole" :submit-button-loading="submitting" @dynamic-form-submit="addRole" />
						<v-data-table :headers="headers" :items="data" disable-pagination class="elevation-1 mt-3" :loading="loading" hide-default-footer>
							<template #item.actions="{ item }">
								<div class="d-flex flex-row justify-end align-center py-3">
									<v-btn color="error" dark @click="deleteRole(item)"> Delete </v-btn>
								</div>
							</template>
						</v-data-table>
					</v-sheet>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import DynamicForm from '@/components/common/base/dynamic-form';

	export default {
		name: 'app-admin-groups-details',

		components: {
			DynamicForm
		},

		data() {
			return {
				formData: {},
				formDataRole: {
					read: false,
					write: false,
					delete: false
				},
				loading: false,
				submitting: false,
				headers: [
					{ text: 'Name', value: 'name' },
					{ text: 'Role', value: 'role' },
					{ text: 'Read', value: 'read' },
					{ text: 'Write', value: 'write' },
					{ text: 'delete', value: 'delete' },
					{ text: '', value: 'actions', sortable: false }
				],
				data: []
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form']),

			mappedManagerGroupRoleForm() {
				const rolesSelect = this.form('manager_group_role').value;
				if (!rolesSelect) return [];
				const rolesSelectIndex = rolesSelect.findIndex(o => o.name === 'role');
				rolesSelect[rolesSelectIndex].options = rolesSelect[rolesSelectIndex].options.map(o => {
					o.text = `${o.text} (${o.nameUnique})`;
					return o;
				});
				return rolesSelect;
			},

			groupId() {
				return this.$route.params.id;
			}
		},

		created() {
			this.loadForm('manager_group');
			this.loadForm('manager_group_role');
			this.fetchRoleList();
		},

		methods: {
			...mapActions('AccountGroup', ['addGroup', 'addGroupRole', 'deleteGroupRole', 'getGroupRoleList']),
			...mapActions('CmsForm', ['loadForm']),

			fetchRoleList() {
				if (this.loading) return;

				this.loading = true;
				this.getGroupRoleList({ where: { group_id: this.groupId } })
					.then(({ data }) => (this.data = data.data))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to fetch group role list'))
					.finally(() => (this.loading = false));
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async addRole(data) {
				this.submitting = true;

				this.addGroupRole({
					roleId: data.role,
					read: data.read,
					write: data.write,
					delete: data.delete,
					groupId: this.groupId
				})
					.then(() => {
						ElementTools.fireNotification(this.$parent.$el, 'success', 'Role has been added to group successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$parent.$el, 'error', 'Failed to add group'))
					.finally(() => {
						this.submitting = false;
						this.fetchRoleList();
					});
			},

			/**
			 * @name deleteRole
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async deleteRole(data) {
				this.submitting = true;

				this.deleteGroupRole({
					roleId: data.id,
					groupId: this.groupId
				})
					.then(() => {
						ElementTools.fireNotification(this.$parent.$el, 'success', 'Role has been removed from the group successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$parent.$el, 'error', 'Failed to remove the role from group'))
					.finally(() => {
						this.submitting = false;
						this.fetchRoleList();
					});
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submit(data) {
				this.submitting = true;

				this.addGroup({
					name: data.name,
					nameUnique: data.nameUnique,
					scope: data.scope.split(',').map(s => s.trim()),
					description: data.description
				})
					.then(() => {
						this.$router.push(`/admin/groups`);
						ElementTools.fireNotification(this.$parent.$el, 'success', 'Group has been added successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$parent.$el, 'error', 'Failed to add group'))
					.finally(() => {
						this.submitting = false;
						this.fetchRoleList();
					});
			}
		}
	};
</script>
