import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Matter
 * @exports Service/App/Source/Matter/Kfi
 * @description API Matter Iteration Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name kfiList
	 * @description Get all KFIs
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`lifetime-mortgage/kfi/list`, payload),

	/**
	 * @name get
	 * @description get KFI
	 * @param {UUID} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`lifetime-mortgage/kfi/${id}`),

	/**
	 * @name logs
	 * @description fetch logs for a KFI
	 * @param {UUID} id
	 * @returns {Object} Promise a http response
	 */
	logs: (id) => $http.get(`lifetime-mortgage/kfi/${id}/logs`),

	/**
	 * @name log
	 * @description fetch logs for a KFI
	 * @param {UUID} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	log: (id, payload) => $http.post(`lifetime-mortgage/kfi/${id}/log`, payload),

	/**
	 * @name retry
	 * @description send a failed KFI back to the queue
	 * @param {UUID} id
	 * @returns {Object} Promise a http response
	 */
	retry: (id) => $http.post(`lifetime-mortgage/kfi/${id}/retry`, {}),

	/**
	 * @name getFile
	 * @description get KFI file
	 * @param {UUID} id
	 * @returns {Object} Promise a http response
	 */
	getFile: ({ id, payload }) => $http.get(`lifetime-mortgage/kfi/${id}/file`, payload)
};
