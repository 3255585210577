<template>
	<div class="pa-8">
		<div class="pb-4">
			<v-btn color="primary" dark :to="`/admin/user`">
				Add New User
			</v-btn>
		</div>
		<v-data-table :headers="headers" :items="data" disable-pagination class="elevation-1 mt-3" :loading="loading" hide-default-footer>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.updated="{ item }">
				{{ item.updated | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<!-- <v-btn small color="success" :to="`/cms/product-provider/${providerId}/user/${item.id}`">
						edit
					</v-btn> -->
					<v-btn v-if="item.twoFactor" small dark class="ml-2" color="amber" @click="clearTwoFactorAuth(item.account_id)"> Remove 2FA </v-btn>

					<v-btn v-if="!item.locked" small dark class="ml-2" color="amber" :loading="locking[item.id]" @click="lockUser(item.id)">
						Lock
					</v-btn>
					<v-btn v-if="item.locked" small dark class="ml-2" color="green" :loading="locking[item.id]" @click="unlockUser(item.id)">
						Unlock
					</v-btn>
					<v-btn small dark class="ml-2" color="error" :loading="deleting[item.id]" @click="deleteItem(item.id)">
						delete
					</v-btn>
				</div>
			</template>
		</v-data-table>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';

	export default {
		name: 'app-admin-users',

		components: {
			CommonDialogConfirm
		},

		data: () => ({
			loading: false,
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Company', value: 'organisation.name' },
				{ text: 'Created', value: 'created' },
				{ text: 'Last Updated', value: 'updated' },
				{ text: '', value: 'actions', sortable: false }
			],
			data: [],
			locking: {},
			deleting: {}
		}),

		computed: {
			providerId() {
				return this.$route.params.id;
			}
		},

		created() {
			this.getUserList();
		},

		methods: {
			...mapActions('AccountUser', ['fetchUserList', 'modifyUser','modifyUserAccount', 'deleteUser']),
			...mapActions('AppMembership', ['fetchApplicationList']),

			getUserList() {
				if (this.loading) return;

				this.loading = true;
				this.fetchUserList({ where: { type: 'admin' } })
					.then(({ data }) => (this.data = data.data))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to fetch user list'))
					.finally(() => (this.loading = false));
			},

			lockUser(id) {
				this.modifyUser({
					id,
					data: {
						locked: true
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'User has been locked successfully');
						this.getUserList();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to lock user'))
					.finally(() => (this.submitting = false));
			},

			unlockUser(id) {
				this.modifyUser({
					id,
					data: {
						locked: false
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'User has been unlocked successfully');
						this.getUserList();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to unlock user'))
					.finally(() => (this.submitting = false));
			},

			clearTwoFactorAuth(id) {
				this.$refs.confirm
					.open('Remove users two factor authentication', `Are you sure you wish to remove this users two factor authentication?`)
					.then(() => {
						this.modifyUserAccount({
							id,
							data: {
								twoFactor: false
							}
						})
							.then(() => {
								const index = this.data.findIndex((i) => i.account_id === id);
								this.$set(this.data, index, { ...this.data[index], twoFactor: false });
								ElementTools.fireNotification(this.$el, 'success', 'Two factor authentication has been removed successfully');
							})
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to delete two factor authentication'))
							.finally(() => (this.submitting = false));
					})
					.catch(() => {});
			},

			deleteItem(id) {
				this.$refs.confirm
					.open('Delete User', `Are you sure you wish to delete this user?`)
					.then(() => {
						this.deleteUser({ id })
							.then(() => {
								ElementTools.fireNotification(this.$el, 'success', 'User has been deleted successfully');
								this.getUserList();
							})
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to delete user'))
							.finally(() => (this.submitting = false));
					})
					.catch(() => {});
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep ul.v-pagination {
		justify-content: end !important;
	}
</style>
