import { $http } from '@/utils';

/**
 * @namespace Service
 * @class AirMail
 * @exports Service/Cms/AirMail
 * @description API AirMail Service
 * @date 2024/06/19
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default {
	/**
	 * @name list
	 * @description Get all airmails as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`cms/air-mail/list`, payload),

	/**
	 * @name get
	 * @description Get airmail
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`cms/air-mail/${id}`),

	/**
	 * @name modify
	 * @description Update some airmail details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`cms/air-mail/${id}`, payload),

	/**
	 * @name delete
	 * @description delete airmail for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	delete: (id) => $http.delete(`cms/air-mail/${id}`),

	/**
	 * @name add
	 * @description Add a new airmail resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`cms/air-mail`, payload)
};
