import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Factfind
 * @exports Service/Factfind
 * @description API Factfind Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @@name list
	 * @description Get all enquiries
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post('fact-find/admin-list', payload)
};
