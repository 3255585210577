import Vue from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import filters from '@/filters';
import vuetify from './plugins/vuetify';
import DatetimePicker from 'vuetify-datetime-picker';
import validator from '@/validator';
import JsonViewer from 'vue-json-viewer';
import CKEditor from '@ckeditor/ckeditor5-vue2';

// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

Vue.config.productionTip = false;

Vue.use(validator);
Vue.use(filters);
Vue.use(DatetimePicker);
Vue.use(JsonViewer);
Vue.use(CKEditor);

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App)
}).$mount('#app');
