<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>Notification #{{ formData.id }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/admin/notifications`">
				Back to notifications
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-btn-toggle v-model="formType" color="success" class="mb-8">
							<v-btn>
								Details
							</v-btn>
							<v-btn :disabled="!notificationId">
								Recipients
							</v-btn>
						</v-btn-toggle>
						<v-sheet v-if="formType === 0" class="pa-6" elevation="4">
							<p v-if="formData.avatar">
								Avatar: {{ formData.avatar }}
							</p>
							<dynamic-form ref="dynamicForm" :form-schema="form('manager_notification').value" :form-data="formData" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
						</v-sheet>
						<v-sheet v-if="formType === 1" class="pa-6" elevation="4">
							<p v-if="formData.recipientList">
								Recipient list: {{ formData.recipientList }}
							</p>
							<dynamic-form ref="dynamicForm2" :form-schema="form('manager_notification_recipient_list').value" :form-data="formData" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
						</v-sheet>

						<v-sheet class="pa-6 mt-4" elevation="4">
							<dynamic-form :form-schema="form('manager_organisation').value" :form-data="organisationFormData" :submit-button-loading="submitting" :all-disabled="!formData.id" :submit-button="false" />
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import { cloneDeep } from 'lodash';

    export default {
		name: 'app-admin-notification-details',

		components: { DynamicForm },

		data: () => ({
            formType: 0,
			formData: {},
			formSchema: [],
			organisationFormData: {},
			loading: false,
			submitting: false
		}),

        computed: {
			...mapGetters('CmsForm', ['form']),

            notificationId() {
                if(this.formData.id) return this.formData.id;
				return this.$route.params.id;
			},

			selectedExclusives() {
				return [...(this.organisationFormData?.exclusive || [])];
			},

			selectedRestricteds() {
				return [...(this.organisationFormData?.restricted || [])];
			}
		},

		watch: {
		},

        created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('AdminNotification', ['getNotification', 'addNotification', 'modifyNotification', 'addNotificationUser']),
			...mapActions('AccountOrganisation', ['addOrganisationEntity', 'deleteOrganisationEntity']),

            async init() {
				this.$route.meta.breadcrumb[3].text = this.notificationId ? `Notification #${this.notificationId}` : 'New Notification';

				await this.loadForm('manager_notification');
                await this.loadForm('manager_notification_recipient_list');
				await this.loadForm('manager_organisation');

				if (this.notificationId) {
					this.loading = true;

					this.getNotification(this.notificationId)
						.then(({ data }) => {
							this.formData = this.setFormData(data);
							this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
							this.initWatchers();
						})
						.finally(() => (this.loading = false));
				} else this.initWatchers();
			},

			initWatchers() {
				this.$watch(
					'selectedExclusives',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addOrganisationEntityRequest(added, 'exclusive');
						else if (deleted) this.deleteOrganisationEntityRequest(deleted);
					},
					{ deep: true }
				);

				this.$watch(
					'selectedRestricteds',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addOrganisationEntityRequest(added, 'restricted');
						else if (deleted) this.deleteOrganisationEntityRequest(deleted);
					},
					{ deep: true }
				);
			},

            setFormData(data) {
				return { ...data,
					expiryDate: data.expire ? this.$moment(data.expire).format('DD/MM/YYYY') : null,
					expiryTime: data.expire? this.$moment(data.expire).format('HH:mm') : null
				};
			},

			formatDate(date, time) {
				const [day, month, year] = date.split('/');
				return new Date([`${month}/${day}/${year}`, time]).toISOString();
			},

			submit() {
				this.submitting = true;

                let payload = cloneDeep(this.formData);
				payload.exclusive = payload.exclusive || [];
				payload.restricted = payload.restricted || [];
                if (payload.files) payload.avatar = payload.files[0].s3Key.split('/').pop();
                if (payload.recipient_list_files) payload.recipientList = payload.recipient_list_files[0].s3Key.split('/').pop();
				if (payload.expiryDate) payload.expire = this.formatDate(payload.expiryDate, payload.expiryTime);

				const promise = this.notificationId ? this.modifyNotification({ id: this.notificationId, payload }) : this.addNotification(payload);
				promise
					.then((data) => {
                        this.formData = this.setFormData(data.data);
						if (!payload.recipient_list_files) ElementTools.fireNotification(this.$el, 'success', 'Notification has been submitted successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit notification'));

				if (payload.recipient_list_files) {
					promise.then(() => {
						this.addNotificationUser(this.notificationId)
							.then(() => ElementTools.fireNotification(this.$el, 'success', 'Notification recipients has been submitted successfully'))
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit notification recipients'));
					});
				}

				promise.finally(() => (this.submitting = false));
			},

			addOrganisationEntityRequest(id, status) {
				let payload = {
					entity: 'notification',
					entityId: this.notificationId,
					status
				};

				this.addOrganisationEntity({ id, payload }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to add exclusive organisation');
					this.getNotification(this.notificationId).then(({ data }) => {
						this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
					});
				});
			},

			deleteOrganisationEntityRequest(id) {
				this.deleteOrganisationEntity({ id, entityId: this.notificationId, entity: 'notification' }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to delete exclusive organisation');
					this.getNotification(this.notificationId).then(({ data }) => {
						this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
					});
				});
			}
		}
	};
</script>
