import { $http } from '@/utils';

/**
 * @namespace Service
 * @class User
 * @exports Service/Account/Organisation/User
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get all users
	 * @param {Object} organisationId
	 * @returns {Object} Promise a http response
	 */
	list: (organisationId) => $http.get(`account/organisation/${organisationId}/user/list`)
};
