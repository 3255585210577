import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Matter
 * @exports Service/Matter
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @@name add
	 * @description Add matter entity
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (matterId, payload) => $http.post(`matter/${matterId}/entity`, payload)
};
