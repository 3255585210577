import { $http } from '@/utils';

/**
 * @namespace Service
 * @class MembershipOrganisation
 * @exports Service/MembershipOrganisation
 * @description API MembershipOrganisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
    /**
	 * @name list
	 * @description List all membership__organisation
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`membership/organisation/list`, payload),

	/**
	 * @name upsert
	 * @description Update some membership details for ID
	 * @param {Object} payload
	 */
	upsert: (payload) => $http.post(`membership/organisation`, payload)
};
