<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>Media #{{ formData.id }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/cms/media-list`">
				Back to media
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="form('manager_media').value" :form-data="formData" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
						</v-sheet>

						<v-sheet class="pa-6 mt-4" elevation="4">
							<dynamic-form :form-schema="form('tag').value" :form-data="tagFormData" :all-disabled="!formData.id" :submit-button="false" />
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import { cloneDeep } from 'lodash';

	export default {
		name: 'app-products-details',
		components: {
			DynamicForm
		},

		data: () => ({
			formData: {},
			tagFormData: {},
			loading: false,
			submitting: false
		}),

		computed: {
			...mapGetters('CmsForm', ['form']),

			mediaId() {
				return this.$route.params.id || this.formData.id;
			},

			selectedTags() {
				return [...(this.tagFormData?.tag || [])];
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsMedia', ['getMedia', 'addMedia', 'modifyMedia', 'addTag', 'deleteTag']),

			async init() {
				this.$route.meta.breadcrumb[3].text = this.mediaId ? `Media #${this.mediaId}` : 'New Media';

				this.loading = true;
				await Promise.all([this.loadForm('manager_media'), this.loadForm('tag')]);
				this.loading = false;

				if (this.mediaId) {
					this.loading = true;

					this.getMedia(this.mediaId)
						.then(({ data }) => {
							data.uploaded = this.$moment(data.uploaded).format('DD/MM/YYYY');
							this.formData = data;
							this.tagFormData = { tag: data.tag?.map((tag) => tag.id) };
							this.initWatchers();
						})
						.finally(() => (this.loading = false));
				} else this.initWatchers();
			},

			initWatchers() {
				this.$watch(
					'selectedTags',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addTagRequest(added);
						else if (deleted) this.deleteTagRequest(deleted);
					},
					{ deep: true }
				);
			},

			submit() {
				this.submitting = true;

				let payload = cloneDeep(this.formData);
				payload.uploaded = this.formatDate(this.formData.uploaded);
				payload.tag = payload.tag || [];

				const promise = this.mediaId ? this.modifyMedia({ id: this.mediaId, payload }) : this.addMedia(payload);
				promise
					.then(({ data }) => {
						ElementTools.fireNotification(this.$el, 'success', 'Media has been submitted successfully');
						this.$router.push(`/cms/media/${data.id}`);
						this.formData.id = this.formData.id || data.id;
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit media'))
					.finally(() => (this.submitting = false));
			},

			addTagRequest(tagId) {
				let payload = { tagIds: [tagId] };
				this.addTag({ id: this.mediaId, payload }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to add tag');
					this.getMedia(this.mediaId).then(({ data }) => {
						this.tagFormData = { tag: data.tag?.map((tag) => tag.id) };
					});
				});
			},

			deleteTagRequest(tagId) {
				this.deleteTag({ id: this.mediaId, tagId }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to delete tag');
					this.getMedia(this.mediaId).then(({ data }) => {
						this.tagFormData = { tag: data.tag?.map((tag) => tag.id) };
					});
				});
			},

			formatDate(date) {
				const [day, month, year] = date.split('/');
				return new Date(`${month}/${day}/${year}`).toISOString();
			}
		}
	};
</script>
