import { PartnerEnvironment } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getPartnerEnvironmentList
		 * @param {String} id
		 */
		getPartnerEnvironmentList(context, payload) {
			return PartnerEnvironment.list(payload);
		},

		/**
		 * @async @name modifyPartnerEnvironment
		 * @param {String} id
		 */
		modifyPartnerEnvironment(context, { nameUnique, data }) {
			return PartnerEnvironment.modify(nameUnique, data);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
