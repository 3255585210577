<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title class="font-weight-bold">
				{{ userId ? `Role ${userId}` : 'Add New Role' }}
			</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/admin/roles`">
				Back to roles
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters class="justify-center">
				<v-col cols="6" md="8" lg="8" xl="6">
					<v-sheet class="pa-6" elevation="4">
						<dynamic-form ref="dynamicForm" :form-schema="form('manager_role').value" :form-data="formData" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
					</v-sheet>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import DynamicForm from '@/components/common/base/dynamic-form';

	export default {
		name: 'app-admin-roles-details',

		components: {
			DynamicForm
		},

		data() {
			return {
				formData: {},
				formSchema: [],
				loading: false,
				submitting: false
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form']),

			userId() {
				return this.$route.params.id;
			}
		},

		created() {
			this.loadForm('manager_role');
		},

		methods: {
			...mapActions('AccountRole', ['addRole']),
			...mapActions('CmsForm', ['loadForm']),

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submit(data) {
				this.submitting = true;

				this.addRole({
					name: data.name,
					role: data.role,
					description: data.description
				})
					.then(() => {
						this.$router.push(`/admin/roles`);
						ElementTools.fireNotification(this.$parent.$el, 'success', 'Role has been added successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$parent.$el, 'error', 'Failed to add role'))
					.finally(() => (this.submitting = false));
			}
		}
	};
</script>
