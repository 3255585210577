<!--
	@name app-enquiry-details-response
	@description Wrapper response component for timeline item
	@date 2020/06/23
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<v-timeline-item :component="$options.name" :left="!isAdviser" :right="isAdviser" :color="color" :small="isAdviser">
			<template #opposite>
				<img v-if="response.author_logo" :src="response.author_logo" width="90" class="mb-2 elevation-2" />
				<p v-if="response.created" class="text-body-2 grey--text text--darken-1 mb-0">
					{{ response.created | moment('Do MMM YYYY, HH:mm') }}
				</p>
			</template>

			<v-card class="elevation-2">
				<v-toolbar :color="color" dark dense elevation="0">
					<v-toolbar-title dense class="font-weight-medium">
						{{ responseTitle }}
						<span v-if="isOrganisationWithdrawn(response?.data?.responder?.id || response?.data?.id)">(Withdrawn)</span>
					</v-toolbar-title>
				</v-toolbar>

				<v-card-text class="pa-4">
					<p v-if="isWithdraw" class="text-body-2 grey--text text--darken-3 mb-0">{{ getOrganisationName(response?.data?.id) }} withdrew from this enquiry.</p>
					<p v-else-if="isReconnect" class="text-body-2 grey--text text--darken-3 mb-0">{{ getOrganisationName(response?.data?.id) }} is readded to this enquiry.</p>
					<pre v-else class="text-body-2 grey--text text--darken-3 mb-0" style="white-space: break-spaces" v-html="response.data.message" />
					<v-sheet v-if="response.parentProcess && !response.parentProcess.initialMessage" shaped elevation="0" class="mt-5 ml-5 pa-4 yellow lighten-5">
						<p class="text-caption grey--text text--darken-2 font-weight-medium mb-0">
							<!-- {{ response.parentProcess.data.responder.id }} -->
							In response to {{ getResponderName(response?.parentProcess?.data?.responder) }}
						</p>
						<p class="text-caption mb-0 grey--text">
							{{ response.parentProcess.data.message }}
						</p>
					</v-sheet>

					<v-list v-if="anyRecipients && recipientList.length > 0" dense class="mt-5 pa-0">
						<p class="text-caption font-weight-bold black--text mb-1">Recipients</p>
						<div class="ma-n1">
							<v-tooltip v-for="(recipient, index) in recipientList" :key="index" bottom>
								<template #activator="{ on }">
									<v-chip
										class="ma-1"
										:class="[{ 'light-green': recipient.action?.length, 'green lighten-1': recipient.data.read, 'grey lighten-2': !recipient.action?.length && !recipient.data.read }]"
										small
										label
										v-on="on"
									>
										{{ getOrganisationName(recipient.data.recipient.id) }}

										<v-tooltip v-if="!recipient.action && !recipient.data.read" bottom>
											<template #activator="{ on }">
												<v-btn color="success" class="ml-1 mr-n2" icon v-on="on" @click="markAsRead(recipient)">
													<v-icon>mdi-eye-outline</v-icon>
												</v-btn>
											</template>
											<span>Mark as Read</span>
										</v-tooltip>
									</v-chip>
								</template>
								<span>{{ getStatus(recipient) }}</span>
							</v-tooltip>
						</div>
					</v-list>
					<v-list v-if="response.data.files" dense class="mt-5 pa-0">
						<p class="text-caption font-weight-bold black--text mb-1">Attachments</p>
						<v-list-item v-for="(file, index) in response.data.files" :key="index" class="pa-0 blue-grey lighten-5" :class="[{ 'mt-1': index !== 0 }]" @click="downloadFile(file)">
							<v-list-item-avatar height="30" width="30" class="mx-2">
								<v-progress-circular v-if="fileLoading[file.s3key]" indeterminate size="20" width="2" color="amber" />
								<v-icon v-else> mdi-download </v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>
									{{ file.filename }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card-text>

				<v-card-actions v-if="!isWithdraw || !isReconnect" class="pa-4 pt-0">
					<v-spacer />
					<v-btn v-if="isAdviser" :loading="loading[response.id]" :disabled="isRespondDisabled" small color="primary" @click="$emit('open-response-dialog', response)"> Send a Response </v-btn>
				</v-card-actions>
			</v-card>
		</v-timeline-item>
	</div>
</template>

<script>
	import { ElementTools } from '@/utils';
	import { mapActions, mapState } from 'vuex';
	export default {
		name: 'app-enquiry-details-response',

		components: {},

		props: {
			response: { type: Object, required: true },
			loading: { type: Object, required: true },
			enquiryClosed: { type: Boolean, required: false },
			organisations: { type: Array, required: true }
		},

		data() {
			return {
				adviserColor: 'purple darken-3',
				systemColor: 'green darken-2',
				fileLoading: {}
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapState('Account', ['organisation']),

			isAdviser() {
				return !this.response?.data?.responder && !this.isWithdraw && !this.isReconnect;
			},

			isProvider() {
				return this.response?.data?.responder?.type === 'organisation';
			},

			isSystem() {
				return this.response?.data?.responder?.type === 'system';
			},

			isWithdraw() {
				return this.response?.processTypeNameUnique === 'withdraw';
			},

			isReconnect() {
				return this.response?.processTypeNameUnique === 'reconnect';
			},

			color() {
				if (this.isAdviser) return this.adviserColor;
				else if (this.isSystem) return this.systemColor;
				else return 'blue darken-4';
			},

			anyRecipients() {
				return this.response.processTypeNameUnique === 'update';
			},

			recipientList() {
				return this.response.iteration.filter((iteration) => iteration.data.recipient.type !== 'system');
			},

			responseTitle() {
				return this.isProvider
					? this.getOrganisationName(this.response?.data?.responder?.id)
					: this.isWithdraw || this.isReconnect
					? this.getOrganisationName(this.response?.data?.id)
					: this.isSystem
					? 'Air Sourcing Support Team'
					: 'Adviser';
			},

			timeResponded() {
				return (this.response.iteration || []).find((iteration) => (iteration.action || []).find((action) => action.type === 'CreateResponse')).created;
			},

			isRespondDisabled() {
				return (this.response.processTypeNameUnique === 'update' && !this.response.iteration?.length) || this.enquiryClosed;
			}
		},

		methods: {
			...mapActions('Enquiry', ['fetchFile']),
			...mapActions('AppMatterIteration', ['modifyIteration']),

			/**
			 * @name getOrganisationName
			 * @description Get organisation name by id
			 * @param {String} id
			 */
			getOrganisationName(id) {
				return this.organisations.find((o) => o.id === id)?.name;
			},

			/**
			 * @name isOrganisationWithdrawn
			 * @description Get organisation withdrawn status
			 * @param {String} id
			 */
			isOrganisationWithdrawn(id) {
				return this.organisations.find((o) => o.id === id)?.withdrawn;
			},

			/**
			 * @name getResponderName
			 * @description Get responder name
			 * @param {Object} responder
			 */
			getResponderName(responder) {
				return responder?.type === 'organisation' ? this.getOrganisationName(responder.id) : responder?.type === 'system' ? 'Air Sourcing Support Team' : 'Adviser';
			},

			/**
			 * @name getStatus
			 * @description Get status
			 * @param {Object} recipient
			 */
			getStatus(recipient) {
				return recipient.action?.length ? 'Responded' : recipient.data.read ? 'Seen' : 'Awaiting Response';
			},

			/**
			 * @name downloadFile
			 * @description get signed url and download file requested
			 * @param {Object} file
			 */
			downloadFile(file) {
				this.$set(this.fileLoading, file.s3Key, true);

				this.fetchFile({ id: this.response.id, s3key: file.s3Key })
					.then((response) => {
						if (response.data?.preSignedUrl) window.open(response.data.preSignedUrl);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured. Please try again later'))
					.finally(() => this.$set(this.fileLoading, file.s3key, false));
			},

			/**
			 * @name markAsRead
			 * @description mark as read
			 * @param {Object} recipient
			 */
			markAsRead(iteration) {
				const date = new Date().toISOString();

				this.modifyIteration({ id: iteration.id, payload: { data: { read: date } } })
					.then(() => {
						this.$set(iteration, 'data', { ...iteration.data, read: date });
						ElementTools.fireNotification(this.$el, 'success', 'Marked as read successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured. Please try again later'));
			}
		}
	};
</script>
