import { CmsCourse } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getCourseList
		 * @param {String} id
		 */
		getCourseList(context, payload) {
			return CmsCourse.list(payload);
		},

		/**
		 * @async @name getCourse
		 * @param {String} id
		 */
		getCourse(context, id) {
			return CmsCourse.get(id);
		},

		/**
		 * @async @name getCourse
		 * @param {String} id
		 */
		modifyCourse(context, { id, payload }) {
			return CmsCourse.modify(id, payload);
		},

		/**
		 * @async @name addCourse
		 */
		deleteCourse(context, id) {
			return CmsCourse.delete(id);
		},

		/**
		 * @async @name addCourse
		 */
		addCourse(context, payload) {
			return CmsCourse.add(payload);
		},

		/**
		 * @name getCourseTypeList
		 * @description Get all course types as a list
		 * @returns {Object} Promise a http response
		 */
		getCourseTypeList(context, payload) {
			return CmsCourse.courseTypeList(payload);
		},

		/**
		 * @async @name getCourse
		 * @param {String} id
		 */
		getCourseType(context, id) {
			return CmsCourse.getCourseType(id);
		},

		/**
		 * @async @name addWorkflowTemplateItem
		 */
		addWorkflowTemplateItem(context, { id, payload }) {
			return CmsCourse.addWorkflowTemplateItem(id, payload);
		},

		/**
		 * @async @name deleteWorkflowTemplateItem
		 */
		deleteWorkflowTemplateItem(context, { id, workflowTemplateItemId }) {
			return CmsCourse.deleteWorkflowTemplateItem(id, workflowTemplateItemId);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
