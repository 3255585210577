<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>Provider #{{ providerId }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/cms/product-providers`">
				Back to providers
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<div class="d-flex justify-end mb-4">
							<v-btn v-if="!organisation.active" :disabled="!valid" color="success" @click="activateProvider">
								Activate Provider
							</v-btn>
							<v-btn v-else color="error" @click="deactivateProvider">
								Deactivate Provider
							</v-btn>
						</div>

						<v-sheet class="pa-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="form('manager_provider').value" :form-data="formData" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
						</v-sheet>

						<v-sheet class="pa-6 mt-4" elevation="4">
							<dynamic-form :form-schema="form('manager_provider_referral').value" :form-data="referralFormData" :submit-button-loading="submitting" :all-disabled="!formData.id" :submit-button="false" />
						</v-sheet>

						<v-sheet class="pa-6 mt-4" elevation="4">
							<dynamic-form :form-schema="form('manager_organisation').value" :form-data="organisationFormData" :submit-button-loading="submitting" :all-disabled="!formData.id" :submit-button="false" />
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import DynamicForm from '@/components/common/base/dynamic-form';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';

	export default {
		name: 'app-products-details',
		components: {
			DynamicForm,
			CommonDialogConfirm
		},

		data: () => ({
			formData: {},
			formSchema: [],
			loading: false,
			submitting: false,
			organisation: {},
			valid: false,
			organisationFormData: {},
			referralFormData: {}
		}),

		computed: {
			...mapGetters('CmsForm', ['form']),

			providerId() {
				return this.$route.params.id || this.formData.id;
			},

			selectedReferrals() {
				return [...(this.referralFormData?.matterType || [])];
			},

			selectedExclusives() {
				return [...(this.organisationFormData?.exclusive || [])];
			},

			selectedRestricteds() {
				return [...(this.organisationFormData?.restricted || [])];
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('AppMatter', ['addMatterTypeOrganisation', 'deleteMatterTypeOrganisation']),
			...mapActions('AccountOrganisation', [
				'getOrganisation',
				'addOrganisation',
				'modifyOrganisation',
				'addOrganisationAddress',
				'modifyOrganisationAddress',
				'addOrganisationCommunication',
				'modifyOrganisationCommunication',
				'addOrganisationDetail',
				'modifyOrganisationDetail',
				'addOrganisationProvider',
				'modifyOrganisationProvider',
				'addOrganisationEntity',
				'deleteOrganisationEntity'
			]),

			async init() {
				this.$route.meta.breadcrumb[3] = { text: `Provider #${this.providerId}`, to: `/cms/product-provider/${this.providerId}` };

				this.loading = true;
				await Promise.all([this.loadForm('manager_organisation'), this.loadForm('manager_provider'), this.loadForm('manager_provider_referral')]);
				this.loading = false;

				if (this.providerId) {
					this.loading = true;

					this.getOrganisation(this.providerId).then(({ data }) => {
						this.loading = false;
						this.organisation = data;
						this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
						this.referralFormData = { matterType: data.matterType };
						this.setFormData(data);
						this.initWatchers();
						this.$nextTick(async () => {
							this.valid = await this.$refs.dynamicForm.validate(false, true);
						});
					});
				} else this.initWatchers();
			},

			initWatchers() {
				this.$watch(
					'selectedExclusives',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addOrganisationEntityRequest(added, 'exclusive');
						else if (deleted) this.deleteOrganisationEntityRequest(deleted);
					},
					{ deep: true }
				);

				this.$watch(
					'selectedRestricteds',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addOrganisationEntityRequest(added, 'restricted');
						else if (deleted) this.deleteOrganisationEntityRequest(deleted);
					},
					{ deep: true }
				);

				this.$watch(
					'selectedReferrals',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addMatterTypeOrganisationRequest(added);
						else if (deleted) this.deleteMatterTypeOrganisationRequest(deleted);
					},
					{ deep: true }
				);
			},

			setFormData(data) {
				this.formData = {
					id: data.id,
					name: data.name,
					address1: data.organisationAddress?.addressLine1,
					address2: data.organisationAddress?.addressLine2,
					town: data.organisationAddress?.townCity,
					postcode: data.organisationAddress?.postalCode,
					email: data.organisationCommunication?.find((d) => d.type === 'email')?.identifier,
					phone: data.organisationCommunication?.find((d) => d.type === 'phone')?.identifier,
					enquiries_email: data.data?.enquiries_email,
					ambassador: data.ambassador,
					partner: data.partner,
					club: data.organisationProvider?.equityRelease,
					website: data.organisationDetail?.website,
					currentLogo: data.organisationDetail?.logo,
					bio: data.organisationProvider?.data.bio,
					fca: data.data?.membership?.fca,
					restricted: data.restricted || [],
					exclusive: data.exclusive || [],
					matterType: data.matterType
				};
			},

			activateProvider() {
				this.$refs.confirm
					.open('Activate Provider', `Are you sure you wish to activate this provider?`)
					.then(() => {
						this.modifyOrganisation({
							id: this.organisation.id,
							data: {
								active: true
							}
						})
							.then(() => {
								this.organisation.active = true;
								ElementTools.fireNotification(this.$el, 'success', 'Provider has been activated successfully');
							})
							.catch(() => {
								ElementTools.fireNotification(this.$el, 'error', 'Failed to activate provider');
							});
					})
					.catch(() => {});
			},

			deactivateProvider() {
				this.$refs.confirm
					.open('Deactivate Provider', `Are you sure you wish to deactivate this provider?`)
					.then(() => {
						this.modifyOrganisation({
							id: this.organisation.id,
							data: {
								active: false
							}
						})
							.then(() => {
								this.organisation.active = false;
								ElementTools.fireNotification(this.$el, 'success', 'Provider has been deactivated successfully');
							})
							.catch(() => {
								ElementTools.fireNotification(this.$el, 'error', 'Failed to deactivate provider');
							});
					})
					.catch(() => {});
			},

			addOrganisationEntityRequest(id, status) {
				let payload = {
					entity: 'organisation',
					entityId: this.providerId,
					status
				};

				this.addOrganisationEntity({ id, payload }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to add exclusive organisation');
					this.getOrganisation(this.providerId).then(({ data }) => {
						this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
					});
				});
			},

			deleteOrganisationEntityRequest(id) {
				this.deleteOrganisationEntity({ id, entityId: this.providerId, entity: 'organisation' }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to delete exclusive organisation');
					this.getOrganisation(this.providerId).then(({ data }) => {
						this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
					});
				});
			},

			addMatterTypeOrganisationRequest(id) {
				let payload = {
					organisationIds: [this.providerId]
				};

				this.addMatterTypeOrganisation({ id, payload }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to add referral product');
					this.getOrganisation(this.providerId).then(({ data }) => {
						this.referralFormData = { matterType: data.matterType };
					});
				});
			},

			deleteMatterTypeOrganisationRequest(id) {
				this.deleteMatterTypeOrganisation({ id, organisationId: this.providerId }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to delete referral product');
					this.getOrganisation(this.providerId).then(({ data }) => {
						this.referralFormData = { matterType: data.matterType };
					});
				});
			},

			/**
			 * @name submit
			 * @description submit user request to update firm details
			 */
			async submit(data) {
				this.valid = true;
				this.submitting = true;

				let requests = [
					this.modifyOrganisation({
						id: this.organisation.id,
						data: {
							exclusive: data.exclusive || [],
							restricted: data.restricted || [],
							ambassador: data.ambassador,
							partner: data.partner,
							matterType: data.matterType || [],
							data: { membership: { fca: data.fca } , enquiries_email: data.enquiries_email }
						}
					})
				];

				if (this.organisation.organisationAddress) {
					requests.push(
						this.modifyOrganisationAddress({
							id: this.organisation.organisationAddress.id,
							data: {
								id: this.organisation.organisationAddress.id,
								addressLine1: data.address1,
								addressLine2: data.address2,
								townCity: data.town,
								postalCode: data.postcode
							}
						})
					);
				} else {
					requests.push(
						this.addOrganisationAddress({
							organisationId: this.organisation.id,
							addressLine1: data.address1,
							addressLine2: data.address2,
							townCity: data.town,
							postalCode: data.postcode
						})
					);
				}

				if (this.organisation.organisationDetail) {
					requests.push(
						this.modifyOrganisationDetail({
							id: this.organisation.organisationDetail.id,
							data: {
								id: this.organisation.organisationDetail.id,
								website: data.website,
								logo:
									data.logo && data.logo.length
										? [
												{
													primary: true,
													mimetype: data.logo[0].type,
													name: data.logo[0].uploadedFilename
												}
										  ]
										: undefined
							}
						})
					);
				} else {
					requests.push(
						this.addOrganisationDetail({
							organisationId: this.organisation.id,
							website: data.website,
							logo:
								data.logo && data.logo.length
									? [
											{
												primary: true,
												mimetype: data.logo[0].type,
												name: data.logo[0].uploadedFilename
											}
									  ]
									: undefined
						})
					);
				}

				if (this.organisation.organisationProvider) {
					requests.push(
						this.modifyOrganisationProvider({
							id: this.organisation.organisationProvider.id,
							data: {
								equityRelease: data.club,
								data: {
									bio: data.bio
								}
							}
						})
					);
				} else {
					requests.push(
						this.addOrganisationProvider({
							organisationId: this.organisation.id,
							equityRelease: data.club,
							data: {
								bio: data.bio
							}
						})
					);
				}

				if (this.organisation.organisationCommunication?.find((d) => d.type === 'phone')?.id) {
					requests.push(
						this.modifyOrganisationCommunication({
							id: this.organisation.organisationCommunication.find((d) => d.type === 'phone').id,
							data: {
								organisationId: this.organisation.id,
								identifier: data.phone
							}
						})
					);
				} else {
					requests.push(
						this.addOrganisationCommunication({
							organisationId: this.organisation.id,
							type: 'phone',
							identifier: data.phone
						})
					);
				}

				if (this.organisation.organisationCommunication?.find((d) => d.type === 'email')?.id) {
					requests.push(
						this.modifyOrganisationCommunication({
							id: this.organisation.organisationCommunication.find((d) => d.type === 'email').id,
							data: {
								organisationId: this.organisation.id,
								identifier: data.email
							}
						})
					);
				} else {
					requests.push(
						this.addOrganisationCommunication({
							organisationId: this.organisation.id,
							type: 'email',
							identifier: data.email
						})
					);
				}

				Promise.all([requests])
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Provider details have been successfully updated');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update provider details'))
					.finally(() => (this.submitting = false));
			}
		}
	};
</script>

<style lang="scss" scoped>
	.wrapper {
		background: #fff;
	}
</style>
