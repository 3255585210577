import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Detail
 * @exports Service/Account/Organisation/Address
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @static @name add
	 * @description Add Organisation detail
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	add: (id, payload) => $http.post(`account/organisation/${id}/entity`, payload),

	/**
	 * @static @name add
	 * @description Add Organisation detail
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	delete: (id, entity, entityId) => $http.delete(`account/organisation/${id}/entity/${entity}/${entityId}`)
};
