import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Event
 * @exports Service/Notification
 * @description API Notification Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
    /**
	 * @name list
	 * @description Get all notifications as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`notification/list`, payload),

	/**
	 * @name get
	 * @description Get notification
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`notification/${id}`),

    /**
	 * @name modify
	 * @description Update some notification details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.put(`notification/${id}`, payload),

	/**
	 * @name add
	 * @description Add a new notification resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`notification`, payload),

	/**
	 * @name addUser
	 * @description Add notification users
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	addUser: (notificationId) => $http.post(`notification/add/${notificationId}`)
};
