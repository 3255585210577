<template>
	<div class="pa-8">
		<v-row>
			<v-col cols="4">
				<v-menu ref="startDateMenu" v-model="startDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
					<template #activator="{ on, attrs }">
						<v-text-field label="Start date" append-icon="mdi-calendar" readonly v-bind="attrs" :value="formattedStartDate" hide-details dense solo light v-on="on" />
					</template>
					<v-date-picker v-model="datePickerModel" no-title scrollable>
						<v-spacer />
						<v-btn text color="primary" @click="startDateMenu = false"> Cancel </v-btn>
						<v-btn text color="primary" @click="updateStartDate"> OK </v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
			<v-col cols="4">
				<validation-observer ref="observer" class="pa-0 col-6">
					<validation-provider v-slot="{ errors }" name="Search" rules="search">
						<v-text-field v-model="searchTerm" placeholder="Search" append-icon="mdi-magnify" dense solo light clear-icon="mdi-close-circle" clearable :error-messages="errors" @click:clear="searchTerm = ''" />
					</validation-provider>
				</validation-observer>
			</v-col>
			<v-col cols="4">
				<v-select
					hide-details
					dense
					solo
					light
					clearable
					:value="statusFilter"
					:items="statusItems.map(i => ({ text: i.text, value: i.value }))"
					@click:clear="updateStatusFilter(null)"
					@input="updateStatusFilter($event)"
				/>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" class="d-flex align-center">
				<v-btn :disabled="loading || currentPage == 1" @click="changePage(0)">
					<v-icon>mdi-arrow-left</v-icon>
				</v-btn>
				<v-text-field v-model="currentPage" type="number" solo dense hide-details class="ml-2 d-inline-flex flex-shrink-0 flex-grow-0" style="width: 100px" />
				<v-btn class="ml-2" :disabled="loading || count < 10" @click="changePage(1)">
					<v-icon>mdi-arrow-right</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table :headers="headers" :items="kfiRequests" sort-by="name" class="elevation-1" :loading="loading" item-key="iterationId" disable-sort hide-default-footer>
					<template #item.advisor="{ item }">
						<span style="white-space: nowrap">
							<a target="_blank" :href="airSourcingUrl + 'account?impersonate=' + item.advisorId">{{ item.advisor }}</a>
						</span>
					</template>
					<template #item.status="{ item }">
						<v-select :value="item.status" :items="statusItems" item-disabled="disabled" :append-outer-icon="item.application ? 'mdi-file-check' : 'mdi-progress-clock'" @input="updateStatus($event, item)" />
					</template>
					<template #item.submitted="{ item }">
						<span style="white-space: nowrap">{{ item.submitted | moment('DD-MM-YY, HH:mm') }}</span>
					</template>
					<template #item.document="{ item }">
						<template v-if="item.documents.length">
							<v-btn small color="primary" @click="openDocumentsList(item)"> Documents </v-btn>
						</template>
						<div v-else>
							<v-tooltip bottom>
								<template #activator="{ on }">
									<v-btn icon @click="openDocumentUploadDialog(item, 'DocumentUpload')" v-on="on">
										<v-icon color="blue"> mdi-cloud-upload </v-icon>
									</v-btn>
								</template>
								<span>Upload KFI Document</span>
							</v-tooltip>
						</div>
					</template>
					<template #item.api="{ item }">
						<div v-if="item.sqs" class="d-flex justify-center">
							<v-tooltip bottom>
								<template #activator="{ on }">
									<v-btn icon @click="openLogsDialog(item.id)" v-on="on">
										<v-icon color="secondary"> mdi-list-box </v-icon>
									</v-btn>
								</template>
								<span>View logs</span>
							</v-tooltip>
							<v-tooltip v-if="item.status === 'KFI Request Submitted'" bottom>
								<template #activator="{ on }">
									<v-btn icon v-on="on">
										<v-icon color="grey"> mdi-clock </v-icon>
									</v-btn>
								</template>
								<span>Processing</span>
							</v-tooltip>
							<v-tooltip v-if="['KFI Request Submitted', 'KFI Request Failed'].includes(item.status)" bottom>
								<template #activator="{ on }">
									<v-btn icon :disabled="item.disabled ?? false" @click="retryKfi(item)" v-on="on">
										<v-icon color="orange"> mdi-refresh-circle </v-icon>
									</v-btn>
								</template>
								<span>Retry</span>
							</v-tooltip>
						</div>
						<v-icon v-else color="red"> mdi-close </v-icon>
					</template>
					<template #item.actions="{ item }">
						<div class="d-flex">
							<v-tooltip bottom>
								<template #activator="{ on }">
									<v-btn icon @click="openMessagesDialog(item.id)" v-on="on">
										<v-badge v-if="item.messages.length" left :content="item.messages.length" overlap color="error">
											<v-icon color="primary"> mdi-chat </v-icon>
										</v-badge>
										<v-icon v-else color="primary"> mdi-chat </v-icon>
									</v-btn>
								</template>
								<span>Messages</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template #activator="{ on }">
									<v-btn icon @click="downloadResearchPdf(item)" v-on="on">
										<v-icon color="success"> mdi-file-download </v-icon>
									</v-btn>
								</template>
								<span>Download Research PDF</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template #activator="{ on }">
									<v-btn icon @click="downloadDataPdf(item)" v-on="on">
										<v-icon color="purple"> mdi-database-export </v-icon>
									</v-btn>
								</template>
								<span>Download Data PDF</span>
							</v-tooltip>
						</div>
					</template>
				</v-data-table>
			</v-col>
		</v-row>

		<common-dialog ref="documents" :max-width="800">
			<template #header> Documents </template>

			<template #body>
				<v-btn color="primary" small class="mt-5" @click="openDocumentUploadDialog(activeKfi, 'AdditionalDocumentUpload')"> Add supporting document </v-btn>
				<v-list elevation="2" class="mt-5">
					<template v-for="(document, index) in documents">
						<v-divider v-if="index" :key="index" />
						<v-list-item :key="document.id">
							<v-list-item-content>
								<v-list-item-title :class="{ 'font-weight-bold': document.type === 'DocumentUpload' }">
									{{ document.type === 'DocumentUpload' ? 'Key Facts Illustration' : document.data.title || '--' }}
								</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<div class="d-flex">
									<v-tooltip bottom>
										<template #activator="{ on }">
											<v-btn icon @click="downloadDocument(document)" v-on="on">
												<v-icon color="success"> mdi-file-download </v-icon>
											</v-btn>
										</template>
										<span>Download Document</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template #activator="{ on }">
											<v-btn icon @click="deleteDocument(document.id, activeKfi)" v-on="on">
												<v-icon color="error"> mdi-delete </v-icon>
											</v-btn>
										</template>
										<span>Delete Document</span>
									</v-tooltip>
								</div>
							</v-list-item-action>
						</v-list-item>
					</template>
				</v-list>
			</template>
		</common-dialog>

		<log-dialog ref="logDialog" />
		<data-dialog ref="dataDialog" />
		<common-dialog-confirm ref="confirm" />
		<message-dialog ref="messageDialog" @messages-updated="messagesUpdated" />
		<dynamic-form-dialog ref="dynamicFormDialog" @dynamic-form-submit="submitDocumentUpload" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { debounce, orderBy } from 'lodash';
	import { ElementTools } from '@/utils';
	import CommonDialog from '@/components/common/dialog';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';
	import DynamicFormDialog from '@/components/common/dialog/dynamic-form-dialog';
	import MessageDialog from './message-dialog';
	import LogDialog from './log-dialog';
	import DataDialog from './data-dialog';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';

	export default {
		name: 'app-requests',

		components: {
			CommonDialog,
			CommonDialogConfirm,
			DynamicFormDialog,
			MessageDialog,
			LogDialog,
			DataDialog,
			ValidationObserver,
			ValidationProvider
		},

		data: () => ({
			loading: false,
			kfiRequests: [],
			searchTerm: '',
			statusFilter: null,
			currentPage: 1,
			count: 10,
			headers: [
				{ text: 'Adviser', value: 'advisor' },
				{ text: 'Route', value: 'route' },
				{ text: 'Client', value: 'client' },
				{ text: 'Provider', value: 'provider' },
				{ text: 'Product', value: 'product' },
				{ text: 'Submitted', value: 'submitted' },
				{ text: 'Status', value: 'status' },
				{ text: 'KFI', value: 'document', align: 'center' },
				{ text: 'API', value: 'api', align: 'center' },
				{ text: '', value: 'actions', sortable: false }
			],
			statusItems: [
				{ text: 'KFI Request Pending Submission', value: 'KFI Request Pending Submission', disabled: true },
				{ text: 'KFI Request Submitted', value: 'KFI Request Submitted' },
				{ text: 'KFI Request Failed', value: 'KFI Request Failed', disabled: true },
				{ text: 'KFI Received', value: 'KFI Received', disabled: true }
			],
			datePickerModel: null,
			startDate: null,
			startDateMenu: false,
			documentFormData: {
				action: 'DocumentUpload'
			},
			documentFormSchema: [
				{
					type: 'file',
					name: 'files',
					display: 'Upload Document',
					s3Path: 'kfis',
					sm: 12,
					validations: [
						{
							type: 'required',
							property: 'name',
							value: true
						},
						{
							type: 'size',
							value: 2048,
							errorMessage: 'This file was too large. The maximum size permitted is 2MB.'
						},
						{
							type: 'count',
							value: 1,
							errorMessage: 'You can not upload more than 1 file.'
						},
						{
							type: 'type',
							value: ['application/msword', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
							errorMessage: 'This file was an invalid type. Permitted file types are doc, docx and pdf.'
						}
					]
				},
				{
					type: 'select',
					name: 'action',
					display: 'Type',
					rules: 'required',
					sm: 12,
					options: [
						{ text: 'Key Facts Illustration', value: 'DocumentUpload' },
						{ text: 'Supporting Document', value: 'AdditionalDocumentUpload' }
					]
				},
				{
					type: 'text',
					name: 'title',
					display: 'Supporting document title',
					sm: 12
				}
			],
			activeKfi: null,
			documents: []
		}),

		computed: {
			airSourcingUrl() {
				return process.env.VUE_APP_AIR_SOURCING_URL;
			},
			formattedStartDate() {
				return this.$moment(this.startDate).format('DD/MM/YYYY');
			}
		},

		watch: {
			async searchTerm() {
				const valid = await this.$refs.observer.validate();
				if(!valid) return;
				this.debouncedRequestList();
			},

			startDate() {
				console.log('this.startDate :>> ', this.startDate);
			}
		},

		created() {
			this.startDate = this.$moment().subtract(1, 'week').toISOString();
			this.datePickerModel = this.$moment(this.startDate).format('YYYY-MM-DD');
			this.getRequestList();
		},

		methods: {
			...mapActions('AppMatterSourcing', ['saveToPdf', 'fetchSourcingFile']),
			...mapActions('AppMatterKfi', ['fetchRequestList', 'fetchKfi', 'fetchKfiFile', 'sendKfiBackToQueue']),
			...mapActions('AppMatterIterationMessage', ['fetchMessageList']),
			...mapActions('AppMatterAction', ['fetchActionList', 'addAction', 'deleteAction']),

			changePage(evt) {
				if (evt) this.currentPage++;
				else this.currentPage--;
				this.getRequestList();
			},

			async getRequestList() {
				if (this.loading) return;

				this.loading = true;

				const payload = {
					where: ['(', { 'action.created': { c: '>=', v: this.startDate } }, '&&'],
					offset: (this.currentPage - 1) * 10,
					limit: 10
				};

				if (this.statusFilter) {
					payload.where.push({ "iteration.data->>'status'": { c: '=', v: this.statusFilter } });
				} else {
					payload.where.push({ "iteration.data->>'status'": { c: '!=', v: 'KFI Request Pending Submission' } });
				}
				payload.where.push(')');

				if (this.searchTerm)
					payload.where = [
						...payload.where,
						...[
							'&&',
							'(',
							{ 'client.name_given': { c: 'ILIKE', v: `%${this.searchTerm}%` } },
							'||',
							{ 'client.name_family': { c: 'ILIKE', v: `%${this.searchTerm}%` } },
							'||',
							{ 'advisor.name': { c: 'ILIKE', v: `%${this.searchTerm}%` } },
							'||',
							{ "iteration.data->'provider'->>'name'": { c: 'ILIKE', v: `%${this.searchTerm}%` } },
							')'
						]
					];

				let { data } = await this.fetchRequestList(payload);
				this.kfiRequests = data.data;
				this.count = data.count;
				this.loading = false;
			},

			/**
			 * @name debouncedRequestList
			 * @description Debounced fetch cases
			 */
			debouncedRequestList: debounce(function () {
				this.currentPage = 1;
				this.getRequestList();
			}, 1000),

			updateStatusFilter($event) {
				this.statusFilter = $event;
				this.currentPage = 1;
				this.getRequestList();
			},

			/**
			 * @name updateStartDate
			 * @description Update start date
			 */
			updateStartDate() {
				this.startDateMenu = false;
				this.startDate = this.$moment(this.datePickerModel).toISOString();
				this.currentPage = 1;
				this.getRequestList();
			},

			/**
			 * @name updateStatus
			 * @description Update status
			 */
			updateStatus(value, item) {
				this.addAction({
					iterationId: item.id,
					iterationCreatedDate: item.created,
					type: 'status',
					data: { status: value }
				});
			},

			openDocumentsList(item) {
				this.activeKfi = item;
				this.documents = orderBy(item.documents, [(document) => (document.type === 'DocumentUpload' ? 0 : 1)], 'asc');
				this.$refs.documents.open();
			},

			createFileName(document) {
				return `${document.id}.${document.data.s3Key.split('.').pop()}`;
			},

			/**
			 * @name downloadDocument
			 * @description Download document
			 */
			downloadDocument(file) {
				this.fetchKfiFile({ id: file.id })
					.then((response) => {
						if (response.data?.preSignedUrl) window.open(response.data.preSignedUrl);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured. Please try again later'));
			},

			/**
			 * @name deleteDocument
			 * @description Delete document
			 */
			deleteDocument(actionId, activeKfi) {
				this.$refs.confirm
					.open('Delete Document', `Are you sure you wish to delete this document?`)
					.then(() => {
						this.deleteAction(actionId)
							.then(() => {
								let index = this.kfiRequests.findIndex((k) => k.id === activeKfi.id);
								let dIndex = this.kfiRequests[index].documents.findIndex((d) => d.id == actionId);
								this.kfiRequests[index].documents.splice(dIndex, 1);
								this.documents = this.kfiRequests[index].documents;
								ElementTools.fireNotification(this.$el, 'success', 'KFI document deleted successfully');
							})
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured. Please try again later'));
					})
					.catch(() => {});
			},

			/**
			 * @name openDocumentUploadDialog
			 * @description Event for open dynamic form dialog
			 * @param {Number} responseId
			 */
			openDocumentUploadDialog(item, type) {
				this.documentFormData = { action: type };
				this.$refs.dynamicFormDialog.open({
					id: item.id,
					type: type,
					created: item.created,
					formSchema: this.documentFormSchema,
					formData: this.documentFormData,
					submitButtonText: 'Submit Document',
					title: 'Upload Document'
				});
			},

			/**
			 * @name submitDocumentUpload
			 * @description Event for submit dynamic form dialog
			 * @param {Object} data
			 */
			submitDocumentUpload({ data, config }) {
				const actionDataObj = {
					iterationId: config.id,
					iterationCreatedDate: config.created,
					type: config.type,
					data: {
						s3Key: data.files[0].s3Key
					}
				};
				// If supporting document title added, add here
				if (data.title) actionDataObj.data.title = data.title;

				this.addAction(actionDataObj)
					.then(({ data }) => {
						let index = this.kfiRequests.findIndex((k) => k.id === config.id);
						this.kfiRequests[index].documents.push({ ...data, data: actionDataObj.data });
						this.documents = this.kfiRequests[index].documents;
						ElementTools.fireNotification(this.$el, 'success', 'KFI document uploaded successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured. Please try again later'));
			},

			/**
			 * @name downloadResearchPdf
			 * @description Download document using s3 key
			 */
			async downloadResearchPdf(item) {
				ElementTools.fireNotification(this.$el, 'info', 'Your document is being prepared', { timeout: 0 });

				try {
					// Get the iteration id and process id of the sourcing session kfi belongs to
					const { data: kfi } = await this.fetchKfi(item.id);
					const processId = kfi.processId;
					const iterationId = kfi.iterationId;

					// Get the action list of the iteration to check if the research pdf has been generated already
					const { data: actions } = await this.fetchActionList({ where: { 'action.iteration_id': iterationId } });
					const saveToPdfAction = actions.data.find((action) => action.type === 'SaveToPdf' && action.data.s3Key);

					// If so, use the existing s3Key to download the pdf. Otherwise, create an action to generate a new s3Key
					if (!saveToPdfAction) await this.createSaveToPdfAction(processId, iterationId);

					// Download the pdf using the s3Key
					const {
						data: { preSignedUrl }
					} = await this.fetchSourcingFile({ id: iterationId });
					if (preSignedUrl) window.open(preSignedUrl);
					ElementTools.fireNotification(this.$el, 'info', 'Your document has been downloaded');
				} catch (e) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to download document');
				}
			},

			/**
			 * @name createSaveToPdfAction
			 * @description TODO
			 */
			createSaveToPdfAction(processId, iterationId) {
				return this.saveToPdf({
					processId,
					iterationId,
					data: { action: { key: 'SaveToPdf' } }
				}).then(({ data: action }) => action.data.s3Key);
			},

			/**
			 * @name downloadDataPdf
			 * @description Event for open data pdf dialog
			 */
			downloadDataPdf(iteration) {
				this.$refs.dataDialog.open(iteration);
			},

			/**
			 * @name openLogsDialog
			 * @description Event for open logs dialog
			 */
			openLogsDialog(iterationId) {
				this.$refs.logDialog.open(iterationId);
			},

			/**
			 * @name retryKfi
			 * @description Event for open logs dialog
			 */
			async retryKfi(item) {
				try {
					item.disabled = true;
					const iterationId = item.id;
					await this.sendKfiBackToQueue(iterationId);
					const idx = this.kfiRequests.findIndex((req) => req.id === iterationId);
					this.kfiRequests[idx].status = 'KFI Request Submitted';
					ElementTools.fireNotification(this.$el, 'success', 'KFI added to queue');
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to send KFI back to queue');
					item.disabled = false;
				}
			},

			/**
			 * @name openMessagesDialog
			 * @description Event for open messages dialog
			 */
			async openMessagesDialog(iterationId) {
				const payload = {
					order: { property: 'created', direction: 'desc' },
					where: { iterationId }
				};
				let { data } = await this.fetchMessageList(payload);
				const idx = this.kfiRequests.findIndex((req) => req.id === iterationId);
				this.kfiRequests[idx].messages = data.data;
				this.$refs.messageDialog.open(iterationId, data.data);
			},

			messagesUpdated(iterationId) {
				this.openMessagesDialog(iterationId);
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep ul.v-pagination {
		justify-content: end !important;
	}
</style>
