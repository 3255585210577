<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>{{ formData.id ? `Changelog #${formData.id}` : 'New Changelog' }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/cms/changelogs`"> Back to changelog </v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-btn-toggle v-model="formType" color="success" class="mb-8">
							<v-btn>
								Details
							</v-btn>
							<v-btn :disabled="!changelogId">
								Recipients
							</v-btn>
						</v-btn-toggle>

						<v-sheet v-if="formType === 0" class="pa-6" elevation="4">
							<dynamic-form ref="changelogForm" :form-schema="form('manager_changelog').value" :form-data="formData" :submit-button="false" />
							<dynamic-form ref="videoForm" :form-schema="form('manager_changelog_video').value" :form-data="videoFormData" :submit-button="false" />
							<div class="text-center mt-4">
								<v-btn color="primary" @click="submit"> submit </v-btn>
							</div>
						</v-sheet>

						<v-sheet v-show="formType === 1" class="pa-6" elevation="4">
							<p v-if="formData.recipientList">
								Recipient list: {{ formData.recipientList }}
							</p>
							<dynamic-form ref="recipientListForm" :form-schema="form('manager_changelog_recipient_list').value" :form-data="formData" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
						</v-sheet>

						<v-sheet class="pa-6 mt-4" elevation="4">
							<dynamic-form :form-schema="form('manager_organisation').value" :form-data="organisationFormData" :submit-button-loading="submitting" :all-disabled="!formData.id" :submit-button="false" />
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-changelog-details',
		components: {
			DynamicForm
		},

		data: () => ({
			formType: 0,
			formData: {},
			videoFormData: {},
			organisationFormData: {},
			loading: false,
			submitting: false
		}),

		computed: {
			...mapGetters('CmsForm', ['form']),

			changelogId() {
				if(this.formData.id) return this.formData.id;
				return this.$route.params.id;
			},

			selectedExclusives() {
				return [...(this.organisationFormData?.exclusive || [])];
			},

			selectedRestricteds() {
				return [...(this.organisationFormData?.restricted || [])];
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsChangelog', ['getChangelog', 'addChangelog', 'modifyChangelog', 'addChangelogUser']),
			...mapActions('AccountOrganisation', ['addOrganisationEntity', 'deleteOrganisationEntity']),

			async init() {
				console.log('this.changelogId :>> ', this.changelogId);
				this.$route.meta.breadcrumb[3].text = this.changelogId ? `Changelog #${this.changelogId}` : 'New Changelog';

				this.loading = true;
				await Promise.all([
					this.loadForm('manager_changelog'),
					this.loadForm('manager_changelog_recipient_list'),
					this.loadForm('manager_changelog_video'),
					this.loadForm('manager_organisation')
				]);
				this.loading = false;

				if (this.changelogId) {
					this.loading = true;

					this.getChangelog(this.changelogId)
						.then(({ data }) => {
							data.providers = data.data?.providers;
							this.setFormData(data);
							this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
							this.initWatchers();
						})
						.finally(() => (this.loading = false));
				} else this.initWatchers();
			},

			initWatchers() {
				this.$watch(
					'selectedExclusives',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addOrganisationEntityRequest(added, 'exclusive');
						else if (deleted) this.deleteOrganisationEntityRequest(deleted);
					},
					{ deep: true }
				);

				this.$watch(
					'selectedRestricteds',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addOrganisationEntityRequest(added, 'restricted');
						else if (deleted) this.deleteOrganisationEntityRequest(deleted);
					},
					{ deep: true }
				);
			},

			setFormData(data) {
				this.formData = {
					...data,
					posted: data.posted ? this.$moment(data.posted).format('DD/MM/YYYY') : null
				};
			},

			async submit() {
				const isValid = Boolean(this.formData?.recipient_list_files && this.changelogId) || ((await this.$refs.changelogForm.validate(false)) && (await this.$refs.videoForm.validate(false)));
				if (!isValid) return;

				this.submitting = true;

				const payload = {
					changelogCategoryId: this.formData.changelogCategoryId,
					title: this.formData.title,
					abstract: this.formData.abstract,
					description: this.formData.description,
					image: this.formData.files?.[0]?.s3Key || this.formData.image,
					video: this.videoFormData.video?.[0]?.s3Key || this.videoFormData.video,
					posted: this.formData.posted ? this.formatDate(this.formData.posted) : null
				};
				payload.exclusive = payload.exclusive || [];
				payload.restricted = payload.restricted || [];
                if (this.formData?.recipient_list_files) payload.recipientList = this.formData.recipient_list_files[0].s3Key.split('/').pop();

				const promise = this.changelogId ? this.modifyChangelog({ id: this.changelogId, payload: payload }) : this.addChangelog(payload);
				promise
					.then((data) => {
						if(data?.data?.id) {
							this.setFormData(data.data);
						}

						if (!payload.recipientList) ElementTools.fireNotification(this.$el, 'success', 'Changelog has been submitted successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit Changelog'));

				if (payload.recipientList) {
					promise.then(() => {
						this.addChangelogUser(this.changelogId)
							.then(() => ElementTools.fireNotification(this.$el, 'success', 'Changelog recipients has been submitted successfully'))
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit changelog recipients'));
					});
				}
				promise.finally(() => (this.submitting = false));
			},

			formatDate(date) {
				const [day, month, year] = date.split('/');
				return new Date([`${month}/${day}/${year}`]).toISOString();
			},

			addOrganisationEntityRequest(id, status) {
				let payload = {
					entity: 'changelog',
					entityId: this.changelogId,
					status
				};

				this.addOrganisationEntity({ id, payload }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to add exclusive organisation');
					this.getChangelog(this.changelogId).then(({ data }) => {
						this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
					});
				});
			},

			deleteOrganisationEntityRequest(id) {
				this.deleteOrganisationEntity({ id, entityId: this.changelogId, entity: 'changelog' }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to delete exclusive organisation');
					this.getChangelog(this.changelogId).then(({ data }) => {
						this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
					});
				});
			}
		}
	};
</script>
