import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Enquiry
 * @exports Service/Enquiry
 * @description API Enquiry Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @static @name get
	 * @description Get a enquiry
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	get: (id) => {
		return $http.get(`enquiry/${id}`);
	},

	/**
	 * @@name list
	 * @description Get all enquiries
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post('enquiry/list', payload),

	/**
	 * @@name addProvider
	 * @description Add provider to enquiry
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	addProvider: (id, payload) => $http.post(`enquiry/${id}/provider`, payload),

	/**
	 * @@name createResponse
	 * @description create a response to an enquiry
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	createResponse: (payload) => $http.post('enquiry/create-response', payload),

	/**
	 * @@name response
	 * @description create a response to an enquiry
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	response: (payload) => $http.post('enquiry/response', payload),

	/**
	 * @@name reconnect
	 * @description reconnect a provider to an enquiry
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	reconnect: (payload) => $http.post('enquiry/reconnect', payload),

	/**
	 * @static @name getFile
	 * @description Get file
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	getFile: ({ id, payload, s3key }) => $http.get(`enquiry/${id}/file?s3Key=${encodeURIComponent(s3key)}`, payload)
};
