<template>
	<v-navigation-drawer v-model="model" app clipped width="250" color="grey lighten-5" class="py-2">
		<v-list dense nav class="bg-grey-lighten-5">
			<v-list-item-group color="primary darken-2" class="mb-1">
				<v-list-item v-for="item in navigationItems" :key="item.path" :to="item.path" :disabled="item.disabled">
					<v-list-item-icon><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
					<v-list-item-content><v-list-item-title>{{ item.name }}</v-list-item-title></v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'app-layout-sidebar',

		data: () => ({
			model: false
		}),

		computed: {
			...mapGetters('App', ['navigationItems'])
		},

		methods: {
			switch() {
				this.model = !this.model;
			}
		}
	};
</script>

<style></style>
