import { render, staticRenderFns } from "./write-route.vue?vue&type=template&id=f85c75e6&scoped=true&"
import script from "./write-route.vue?vue&type=script&lang=js&"
export * from "./write-route.vue?vue&type=script&lang=js&"
import style0 from "./write-route.vue?vue&type=style&index=0&id=f85c75e6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f85c75e6",
  null
  
)

export default component.exports