import { AccountRegistration } from '@/service';

/**
 * @class @name
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	state: {},

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	getters: {},

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	mutations: {},

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name fetchRegistrationList
		 * @param {Object} context
		 * @param {Object} payload
		 */
		fetchRegistrationList(context, payload) {
			return AccountRegistration.list(payload);
		},

		/**
		 * @async @name acceptInvitation
		 * @param {Object} context
		 * @param {String} token
		 */
		acceptInvitation(context, token) {
			return AccountRegistration.accept(token);
		},

		/**
		 * @async @name verifyRegistration
		 * @param {Object} context
		 * @param {String} token
		 */
		verifyRegistration(context, token) {
			return AccountRegistration.verify({ token });
		},

		/**
		 * @async @name deleteRegistration
		 * @param {Object} context
		 * @param {String} id
		 */
		deleteRegistration(context, { type, id } ) {
			return AccountRegistration.delete(type, id);
		}
	}
};
