import { CmsCache } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @name invalidateCloudfrontCache
		 * @description Send a request to invalidate the cloudfront cache
		 * @returns {Object} Promise a http response
		 */
		invalidateCloudfrontCache(context, payload) {
			return CmsCache.invalidateCloudfrontCache(payload);
		},

		/**
		 * @name clearCache
		 * @description Send a request to invalidate the cloudfront cache
		 * @returns {Object} Promise a http response
		 */
		clearCache(context, operation) {
			return CmsCache.clearCache(operation);
		},

		/**
		 * @name updateOptions
		 * @description Send a request to update options in Redis
		 * @returns {Object} Promise a http response
		 */
		updateOptions(context, option) {
			return CmsCache.updateOptions(option);
		},

		/**
		 * @name updatePanel
		 * @description Send a request to update panel in Redis
		 * @returns {Object} Promise a http response
		 */
		updatePanel(context, panel) {
			return CmsCache.updatePanel(panel);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
