<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>Case #{{ caseId }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/cases`">
				Back to cases
			</v-btn>
		</v-toolbar>

		<v-card class="mx-auto" max-width="1100">
			<v-toolbar color="grey" dark>
				<v-toolbar-title>Events</v-toolbar-title>
			</v-toolbar>

			<v-list v-if="!loading" two-line>
				<template v-for="(item, index) in events">
					<v-list-item :key="item.title">
						<v-list-item-content>
							<v-list-item-title v-text="item.name" />
						</v-list-item-content>
						<v-list-item-action>
							<v-btn-toggle :value="item.complete" mandatory :color="item.complete ? 'success' : 'orange'">
								<v-btn v-for="option in statusOptions" :key="option.text" :value="option.value" :color="item.color" @click="updateStatus(option.value, item)">
									{{ option.text }}
								</v-btn>
							</v-btn-toggle>
						</v-list-item-action>
					</v-list-item>

					<v-divider v-if="index < events.length - 1" :key="index" />
				</template>
			</v-list>
			<v-skeleton-loader v-else type="list-item-three-line@3" class="pa-0 ma-0" />
		</v-card>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	export default {
		name: 'app-cases-details',

		data: () => ({
			loading: false,
			events: [],
			actions: [],
			statusOptions: [
				{ text: 'Pending', value: false },
				{ text: 'Complete', value: true }
			]
		}),

		computed: {
			caseId() {
				return this.$route.params.id;
			},

			iterationId() {
				return this.$route.query.iterationId;
			},

			matterTypeId() {
				return this.$route.query.matterTypeId;
			},

			created() {
				return this.$route.query.created;
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('AppMatterEvent', ['fetchEventList']),
			...mapActions('AppMatterAction', ['fetchActionList', 'addAction', 'deleteAction']),

			/**
			 * @name init
			 * @description Fetches the list of events and actions for the current case
			 */
			init() {
				this.loading = true;

				Promise.all([
					this.fetchEventList({ where: { matter_type_id: this.matterTypeId }, order: { property: 'position' } }),
					this.fetchActionList({ where: { 'action.iteration_id': this.iterationId, type: 'Event' } })
				]).then(([{ data: events }, { data: actions }]) => {
					this.loading = false;
					this.events = events.data;
					this.actions = actions.data;
					this.events.forEach((event) => {
						const action = this.actions.find((action) => action.data.matterEventId === event.id);
						if (action) {
							event.actionId = action.id;
							event.complete = action.data.complete;
						} else event.complete = false;
					});
				});
			},

			/**
			 * @name updateStatus
			 * @description Updates the status of an event
			 */
			updateStatus(status, event) {
				if (event.actionId) {
					this.deleteEvent(event);
				}
				this.addEvent(status, event);
			},

			/**
			 * @name addEvent
			 * @description Updates the status of an event by adding a new action
			 */
			addEvent(status, event) {
				this.addAction({
					iterationId: this.iterationId,
					iterationCreatedDate: this.created,
					type: 'Event',
					data: {
						complete: status,
						matterEventId: event.id,
						matterEventName: event.name
					}
				}).then(({ data }) => {
					event.actionId = data.id;
					event.complete = data.data.complete;
					this.$set(
						this.events,
						this.events.findIndex((item) => item.id === event.id),
						event
					);
				});
			},

			/**
			 * @name deleteEvent
			 * @description Updates the status of an event by modifying an existing action
			 */
			deleteEvent(event) {
				this.deleteAction(event.actionId).then(() => {
					this.$set(
						this.events,
						this.events.findIndex((item) => item.id === event.id),
						event
					);
				});
			}
		}
	};
</script>
