<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>{{ airMailId ? `AirMail #${airMailId}` : `New AirMail` }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/cms/air-mails`">
				Back to airmails
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="form('manager_air_mail').value" :form-data="formData" :submit-button="false" />
							<div class="text-center mt-4">
								<v-btn color="primary" @click="submit">
									submit
								</v-btn>
							</div>
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import DynamicForm from '@/components/common/base/dynamic-form';
import { ElementTools } from '@/utils';

	export default {
		name: 'app-air-mail-details',

        components: {
			DynamicForm
		},

		data: () => ({
			formData: {},
			loading: false,
			submitting: false
		}),

		computed: {
			...mapGetters('CmsForm', ['form']),

			airMailId() {
				return this.$route.params.id || this.formData.id;
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsAirMail', ['getAirMail', 'addAirMail', 'modifyAirMail']),

			/**
			 * Initializes the component and sets the breadcrumb text based on the airMailId.
			 * If airMailId is provided, it fetches the airMail data and sets the formData.
			 * Loads the form 'manager_air_mail' and sets the loading state to false.
			 * @returns {Promise<void>} A promise that resolves when the initialization is complete.
			 */
			async init() {
				this.$route.meta.breadcrumb[3].text = this.airMailId ? `AirMail #${this.courseId}` : 'New AirMail';

				this.loading = true;
				if (this.airMailId) {
					await this.getAirMail(this.airMailId)
						.then(({ data }) => {
							this.formData = data;
						});
				}

				await this.loadForm('manager_air_mail');
				this.loading = false;
			},

			/**
			 * Submits the form data to the server.
			 * If airMailId is provided, it modifies the airMail data.
			 * Otherwise, it adds a new airMail.
			 */
			submit() {
				this.submitting = true;

                const payload = {
					title: this.formData.title,
					hash: this.formData.hash,
					image: this.formData.files?.[0]?.s3Key || this.formData.image,
					description: this.formData.description,
					month: this.formData.month,
					year: this.formData.year
				};

				const promise = this.airMailId ? this.modifyAirMail({ id: this.airMailId, payload }) : this.addAirMail(payload);
				promise
					.then(({ data }) => {
						ElementTools.fireNotification(this.$el, 'success', 'AirMail has been submitted successfully');

						if (data?.id) {
							this.$router.push(`/cms/air-mail/${data.id}`);
							this.formData.id = this.formData.id || data.id;
						}
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit AirMail'))
					.finally(() => {
						this.submitting = false;
					});
			}
		}
	};
</script>
