import Account from './modules/Account';
import AccountDepartment from './modules/Account/Department';
import AccountGroup from './modules/Account/Group';
import AccountOrganisation from './modules/Account/Organisation';
import AccountRegistration from './modules/Account/Registration';
import AccountRole from './modules/Account/Role';
import AccountTradingStyle from './modules/Account/TradingStyle';
import AccountUser from './modules/Account/User';
import AdminCompletionUpload from './modules/Admin/CompletionUpload';
import AdminNotification from './modules/Admin/Notification';
import AdminPartnerEnvironment from './modules/Admin/PartnerEnvironment';
import App from './modules/App';
import AppClient from './modules/App/Client';
import AppClientAsset from './modules/App/Client/Asset';
import AppMatter from './modules/App/Matter';
import AppMatterAction from './modules/App/Matter/Action';
import AppMatterEvent from './modules/App/Matter/Event';
import AppMatterIteration from './modules/App/Matter/Iteration';
import AppMatterIterationMessage from './modules/App/Matter/Iteration/Message';
import AppMatterKfi from './modules/App/Matter/Kfi';
import AppMatterProcess from './modules/App/Matter/Process';
import AppMatterSourcing from './modules/App/Matter/Sourcing';
import AppMembership from './modules/App/Membership';
import AppProductLoanToValueUpdate from './modules/App/Product/LoanToValueUpdate';
import AppProductMandatoryPaymentsRateUpdate from './modules/App/Product/MandatoryPaymentsRateUpdate';
import AppProductRateUpdate from './modules/App/Product/RateUpdate';
import AppReward from './modules/App/Reward';
import AppRewardTransaction from './modules/App/Reward/Transaction';
import AppRewardUnclaimed from './modules/App/Reward/Unclaimed';
import CmsAirMail from './modules/Cms/AirMail';
import CmsAsset from './modules/Cms/Asset';
import CmsBanner from './modules/Cms/Banner';
import CmsCache from './modules/Cms/Cache';
import CmsChangelog from './modules/Cms/Changelog';
import CmsConfig from './modules/Cms/Config';
import CmsCourse from './modules/Cms/Course';
import CmsData from './modules/Cms/Data';
import CmsDownload from './modules/Cms/Download';
import CmsEvent from './modules/Cms/Event';
import CmsForm from './modules/Cms/Form';
import CmsInsight from './modules/Cms/Insight';
import CmsMedia from './modules/Cms/Media';
import CmsNews from './modules/Cms/News';
import CmsOnAirArticle from './modules/Cms/OnAirArticle';
import CmsProductUpdate from './modules/Cms/ProductUpdate';
import CmsProvider from './modules/Cms/Provider';
import CmsSite from './modules/Cms/Site';
import Enquiry from './modules/Enquiry';
import File from './modules/File';
import LogApi from './modules/Log/Api';
import SystemVersion from './modules/System/Version';
import Vue from 'vue';
import Vuex from 'vuex';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
	modules: {
		App,
		Account,
		AccountUser,
		AccountDepartment,
		AccountGroup,
		AccountRole,
		AccountRegistration,
		AccountOrganisation,
		AccountTradingStyle,
		AdminNotification,
		AdminCompletionUpload,
		AdminPartnerEnvironment,
		AppClient,
		AppClientAsset,
		AppMatter,
		AppMatterProcess,
		AppMatterAction,
		AppMatterEvent,
		AppMatterIteration,
		AppMatterKfi,
		AppMatterSourcing,
		AppMatterIterationMessage,
		AppProductMandatoryPaymentsRateUpdate,
		AppProductRateUpdate,
		AppProductLoanToValueUpdate,
		AppRewardTransaction,
		AppRewardUnclaimed,
		AppMembership,
		AppReward,
		CmsAsset,
		CmsConfig,
		CmsData,
		CmsForm,
		CmsSite,
		CmsEvent,
		CmsDownload,
		CmsInsight,
		CmsNews,
		CmsOnAirArticle,
		CmsMedia,
		CmsProvider,
		CmsProductUpdate,
		CmsBanner,
		CmsChangelog,
		CmsCourse,
		CmsCache,
		CmsAirMail,
		Enquiry,
		File,
		LogApi,
		SystemVersion
	}
});
