import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Sourcing
 * @exports Service/LifetimeMortgage/Sourcing
 * @description API Lifetime Mortgage Sourcing Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name saveToPdf
	 * @description generate sourcing pdf
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	saveToPdf: (payload) => $http.post('lifetime-mortgage/sourcing/save-to-pdf', payload),

	/**
	 * @static @name getFile
	 * @description get researchpdf
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	getFile: ({ id, payload }) => $http.get(`lifetime-mortgage/sourcing/${id}/file`, payload)
};
