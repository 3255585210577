<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title class="font-weight-bold">
				User {{ username || '--' }}
			</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/user/${userId}`">
				Back to user
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<v-row>
								<v-col cols="12">
									<h2>Security Questions</h2>
								</v-col>
								<v-col v-if="user && 'userSecurityQuestion' in user" cols="12">
									<v-list class="pa-0 ma-0" elevation="2">
										<v-list-item v-for="question in user.userSecurityQuestion" :key="question.id">
											<v-list-item-content>
												<v-list-item-title class="mb-2">
													{{ question.securityQuestion.name }}
												</v-list-item-title>
												<v-list-item-subtitle>
													{{ question.answer }}
												</v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-col>
							</v-row>
						</v-sheet>
						<v-sheet class="pa-6 mt-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="formSchema.user" :form-data="formData.user" :submit-button-loading="submitting" @dynamic-form-submit="submitUser" />
						</v-sheet>
						<v-sheet class="pa-6 mt-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="formSchema.address" :form-data="formData.address" :submit-button-loading="submitting" @dynamic-form-submit="submitAddress" />
						</v-sheet>
						<v-sheet class="pa-6 mt-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="formSchema.detail" :form-data="formData.detail" :custom-error="customError" :submit-button-loading="submitting" @dynamic-form-submit="submitDetail" @form-updated="customError = {}" />
						</v-sheet>
						<v-sheet class="pa-6 mt-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="formSchema.password" :form-data="formData.password" :submit-button-loading="submitting" @dynamic-form-submit="submitPassword" />
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import DynamicForm from '@/components/common/base/dynamic-form';

	export default {
		name: 'app-users-details-details',

		components: {
			DynamicForm
		},

		data() {
			return {
				formSchema: {
					user: [],
					address: [],
					detail: [],
					password: []
				},
				formData: {
					user: {},
					address: {},
					detail: {},
					password: {}
				},
				loading: false,
				submitting: false,
				user: null,
				username: null,
				customError: {}
			};
		},

		computed: {
			...mapGetters('CmsForm', ['formFromGroup']),

			userId() {
				return this.$route.params.id;
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('AccountUser', ['fetchUser', 'modifyUser', 'modifyUserAddress', 'modifyUserDetail', 'modifyUserAccount']),
			...mapActions('CmsForm', ['loadForm']),

			async init() {
				this.$route.meta.breadcrumb[2] = {
					text: `User #${this.userId}`,
					to: `/user/${this.userId}`
				};

				await this.loadForm('manager_user_details');
				this.formSchema.user = this.formFromGroup('manager_user_details', 'account_information');
				this.formSchema.address = this.formFromGroup('manager_user_details', 'address');
				this.formSchema.detail = this.formFromGroup('manager_user_details', 'contact');
				this.formSchema.password = this.formFromGroup('manager_user_details', 'password');

				if (this.userId) {
					this.loading = true;

					this.fetchUser(this.userId)
						.then(({ data }) => {
							this.user = data;
							this.username = this.user.userIdentity?.find((d) => d.type === 'username')?.identity;
							this.setFormData(data);
						})
						.finally(() => (this.loading = false));
				}
			},

			setFormData(user) {
				this.formData = {
					user: {
						name: user.name,
						accountType: user.type,
						accessToFactFind: user.data?.factFindAccess === null ? 'system' : user.data?.factFindAccess,
						isTestAccount: user.data?.testAccount,
						bankDetails: !!user.data?.bank?.details,
						memberOfANetwork: !!user.data?.member?.networkId,
						network: user.data?.member?.networkId,
						insurerName: user.data?.insurerName,
						policyNumber: user.data?.policyNumber,
						directlyAuthorised: user.data?.directlyAuthorised,
						serviceProviderId: user.data?.member?.serviceProviderId
					},
					address: {
						address1: user.userAddress?.addressLine1,
						address2: user.userAddress?.addressLine2,
						town: user.userAddress?.townCity,
						postcode: user.userAddress?.postalCode
					},
					detail: {
						mobile: user.userDetail?.mobile,
						email: user.userDetail?.email
					},
					password: {
						password: null
					}
				};
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submitUser(data) {
				this.submitting = true;

				this.modifyUser({
					id: this.userId,
					data: {
						name: data.name,
						data: {
							factFindAccess: data.accessToFactFind === 'system' ? null : data.accessToFactFind,
							testAccount: data.isTestAccount,
							insurerName: data.insurerName,
							policyNumber: data.policyNumber,
							directlyAuthorised: !data.memberOfANetwork ? data.directlyAuthorised : false,
							bank: {
								details: !!data.bankDetails
							},
							member: {
								...this.user.data?.member || {},
								networkId: data.network,
								serviceProviderId: data.serviceProviderId
							}
						}
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Account Information has been updated successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update Account Information'))
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submitAddress(data) {
				this.submitting = true;

				this.modifyUserAddress({
					id: this.user.userAddress.id,
					data: {
						addressLine1: data.address1,
						addressLine2: data.address2,
						townCity: data.town,
						postalCode: data.postcode
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Address details has been updated successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update Address details'))
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submitDetail(data) {
				this.submitting = true;

				this.modifyUserDetail({
					id: this.user.userDetail.id,
					data: {
						mobile: data.mobile,
						email: data.email
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Contact details has been updated successfully');
					})
					.catch((error) => {
						let uniqueValidationError;
						const { status, data } = error.response;

						if (status === 422) {
							uniqueValidationError =  data?.find((e) => e.label === 'email');
							if (uniqueValidationError) this.$set(this.customError, 'email', uniqueValidationError.message);
						}

						if (!uniqueValidationError) ElementTools.fireNotification(this.$el, 'error', 'Failed to update contact details');

					})
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
	 		*/
			async submitPassword(data) {
				this.submitting = true;

				this.modifyUserAccount({
					id: this.user.userAccount.id,
					data: {
						password: data.password
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Password has been updated successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update password'))
					.finally(() => (this.submitting = false));
			}
		}
	};
</script>

<style lang="scss" scoped></style>
