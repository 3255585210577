<template>
	<div class="pa-8">
		<v-data-table :headers="headers" :items="orderedItems" class="elevation-1 mt-3" :loading="loading" hide-default-footer disable-pagination>
			<template #item.urls="{ item }">
				<div v-for="(url, key) in item.urls" :key="key">
					<b>{{ key }}</b>: {{ url }}
				</div>
			</template>
			<template #item.actions="{ item }">
				<div class="d-flex flex-row justify-end align-center py-3">
					<div v-if="item.environment">
						<v-select v-model="item.environment" class="mt-0 pt-0" :value="item.id" :items="item.environments.map((e) => e.environment)" />
						<v-btn color="primary" dark @click="updateEnvironment(item)"> Swap environments </v-btn>
					</div>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { ElementTools } from '@/utils';
	import { mapActions } from 'vuex';
	import { orderBy } from 'lodash';

	export default {
		name: 'app-admin-partner-environment',

		data: () => ({
			items: [],
			loading: false,
			headers: [
				{ text: 'Partner', value: 'name', sortable: false },
				{ text: 'URLs', value: 'urls', sortable: false },
				{ text: '', value: 'actions', sortable: false }
			]
		}),

		computed: {
			orderedItems() {
				return orderBy(this.items, 'name', ['asc']);
			}
		},

		mounted() {
			this.fetchPartnerEnvironmentsList();
		},

		methods: {
			...mapActions('AdminPartnerEnvironment', ['getPartnerEnvironmentList', 'modifyPartnerEnvironment']),

			async fetchPartnerEnvironmentsList() {
				if (this.loading) return;
				this.loading = true;

				let { data: environments } = await this.getPartnerEnvironmentList();
				this.items = environments.data;
				this.loading = false;
			},

			updateEnvironment(data) {
				this.loading = true;
				this.modifyPartnerEnvironment({
					nameUnique: data.nameUnique,
					data: {environment: data.environment}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Environment changed successfully');
						this.loading = false;
					})
					.catch((e) => {
						ElementTools.fireNotification(this.$el, 'error', 'Failed to update environment: ' + e.message);
						this.loading = false;
					});
			}
		}
	};
</script>
