import { CmsOnAirArticle } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getArticles
		 * @param {String} id
		 */
		getArticles(context, payload) {
			return CmsOnAirArticle.list(payload);
		},

		/**
		 * @async @name getNews
		 * @param {String} id
		 */
		getArticle(context, id) {
			return CmsOnAirArticle.get(id);
		},

		/**
		 * @async @name getNews
		 * @param {String} id
		 */
		modifyArticle(context, { id, payload }) {
			return CmsOnAirArticle.modify(id, payload);
		},

		/**
		 * @async @name addNews
		 */
		deleteArticle(context, id) {
			return CmsOnAirArticle.delete(id);
		},

		/**
		 * @async @name addNews
		 */
		addArticle(context, payload) {
			return CmsOnAirArticle.add(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
