import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Sourcing
 * @exports Log/ApiLog
 * @description API Log
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name logs
	 * @description fetch logs
	 * @param {UUID} id
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`/log/api/list`, payload),

	/**
	 * @name log
	 * @description fetch log details
	 * @param {UUID} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`log/api/${id}`)
};
