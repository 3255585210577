<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>Completion Upload #{{ completionUploadId }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/admin/completion-uploads`">
				Back to Completion Uploads
			</v-btn>
		</v-toolbar>

		<v-container v-if="pending && pending.length" fluid>
			<div class="d-flex">
				<div class="text-h6">
					Pending
				</div>
				<v-spacer />
				<v-btn color="success" class="mr-2" :disabled="selectedApprovels.length === 0" :loading="approving" @click="approveSelected">
					<v-icon small class="mr-1">
						mdi-check
					</v-icon>
					<span> Approve selected </span>
				</v-btn>
			</div>

			<v-data-table v-model="selectedApprovels" class="elevation-1 mt-3" :headers="headers" :items="orderedPending" :loading="loading" disable-sort disable-pagination hide-default-footer show-select>
				<template #header.data-table-select="{ props, on }">
					<v-simple-checkbox v-if="showSelectAll" :value="props.value" :indeterminate="props.indeterminate" color="primary" class="ma-0" v-on="on" />
				</template>

				<template #item.data-table-select="{ isSelected, select, item }">
					<v-simple-checkbox v-if="item.user" :value="isSelected" color="primary" class="ma-0" @input="select($event)" />
				</template>

				<template #item.amount="{ item }">
					£{{ item.data.amount | numFormat }}
				</template>

				<template #item.fee="{ item }">
					£{{ item.data.commission | numFormat }}
				</template>

				<template #item.applied="{ item }">
					<div v-if="item.data.applied">
						{{ item.data.applied | moment('Do MMM YYYY') }}
					</div>
					<div v-else>
						--
					</div>
				</template>

				<template #item.completed="{ item }">
					{{ item.data.completed | moment('Do MMM YYYY') }}
				</template>

				<template #item.paid="{ item }">
					{{ item.data.paid | moment('Do MMM YYYY') }}
				</template>

				<template #item.user="{ item }">
					<div v-if="!item.user" class="d-flex justify-end">
						<v-btn small dark color="warning" @click="openUserSearchDialog(item)">
							<v-icon small class="mr-1">
								mdi-magnify
							</v-icon>
							<span> Find User </span>
						</v-btn>
					</div>
					<div v-else>
						<div class="py-1">
							<div class="font-weight-bold">
								{{ item.user.name }}
							</div>
							<div>{{ item.user.organisationName }}</div>
							<div>{{ item.user.fcaNumber }}</div>
						</div>
					</div>
				</template>
			</v-data-table>
		</v-container>

		<v-container fluid class="mt-4">
			<div class="text-h6">
				Approved
			</div>
			<v-data-table :headers="headers" :items="orderedApproved" class="elevation-1 mt-3" :loading="loading" disable-sort disable-pagination hide-default-footer>
				<template #item.amount="{ item }">
					£{{ item.data.amount | numFormat }}
				</template>

				<template #item.fee="{ item }">
					£{{ item.data.commission | numFormat }}
				</template>

				<template #item.applied="{ item }">
					<div v-if="item.data.applied">
						{{ item.data.applied | moment('Do MMM YYYY') }}
					</div>
					<div v-else>
						--
					</div>
				</template>

				<template #item.completed="{ item }">
					{{ item.data.completed | moment('Do MMM YYYY') }}
				</template>

				<template #item.paid="{ item }">
					{{ item.data.paid | moment('Do MMM YYYY') }}
				</template>

				<template #item.user="{ item }">
					<div class="py-1">
						<div class="font-weight-bold">
							{{ item.user.name }}
						</div>
						<div>{{ item.user.organisationName }}</div>
						<div>{{ item.user.fcaNumber }}</div>
					</div>
				</template>
			</v-data-table>
		</v-container>

		<common-dialog ref="findUser" :max-width="600" @closed="dialogClosed">
			<template #header>
				Find User
			</template>

			<template #body>
				<validation-observer ref="observer">
					<v-form ref="form" class="pt-8" @submit.prevent="addUser">
						<validation-provider v-slot="{ errors }" name="User Name" rules="required">
							<v-autocomplete
								v-model="selectedUser"
								:items="users"
								:loading="loadingUsers"
								:search-input.sync="search"
								:hide-no-data="false"
								:error-messages="errors"
								:no-data-text="noDataText"
								:hide-details="true"
								:hide-selected="false"
								item-text="name"
								item-value="id"
								label="User"
								placeholder="Search for a user"
								autocomplete="off"
								outlined
								return-object
							>
								<template #item="data">
									<v-list-item-content>
										<v-list-item-title>{{ data.item.name }}{{ data.item.deleted ? ' (Deleted)' : ''}}</v-list-item-title>
										<v-list-item-subtitle v-text="data.item.organisationName" />
										<v-list-item-subtitle v-text="data.item.fcaNumber" />
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</validation-provider>
						<div class="d-flex justify-center mt-6">
							<v-btn type="submit" color="success" :loading="submitting">
								Submit
							</v-btn>
						</div>
					</v-form>
				</validation-observer>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import { orderBy } from 'lodash';
	import CommonDialog from '@/components/common/dialog';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-admin-completion-upload-details',

		components: {
			CommonDialog,
			ValidationObserver,
			ValidationProvider
		},

		data: () => ({
			pending: [],
			approved: [],
			loading: false,
			loadingUsers: false,
			submitting: false,
			approving: false,
			selectedUser: null,
			selectedApprovels: [],
			search: null,
			users: [],
			itemToEdit: null,
			headers: [
				{ text: 'Provider', value: 'data.provider' },
				{ text: 'Funder', value: 'data.funder' },
				{ text: 'Client', value: 'data.client' },
				{ text: 'Firm', value: 'data.firm' },
				{ text: 'FCA Number	', value: 'data.fca_number' },
				{ text: 'Adviser', value: 'data.adviser' },
				{ text: 'Route', value: 'data.route' },
				{ text: 'Amount', value: 'amount' },
				{ text: 'Fee', value: 'fee' },
				{ text: 'Applied', value: 'applied' },
				{ text: 'Completed', value: 'completed' },
				{ text: 'Paid', value: 'paid' },
				{ text: 'User', value: 'user' }
			]
		}),

		computed: {
			completionUploadId() {
				return this.$route.params.id;
			},

			orderedPending() {
				return orderBy(this.pending, [(item) => item.data.adviser.toLowerCase()], 'asc');
			},

			orderedApproved() {
				return orderBy(this.approved, [(item) => item.data.adviser.toLowerCase()], 'asc');
			},

			noDataText() {
				return this.loadingUsers ? 'Searching for users...' : this.search && this.search.length > 1 ? 'No matching users found' : 'Start typing to search';
			},

			showSelectAll() {
				return this.pending.some((item) => item.user);
			}
		},

		watch: {
			search(val) {
				if (!val || val.length < 2) {
					this.users = [];
					return;
				}
				this.loadingUsers = true;

				this.findUser({ where: { '"user".name': { c: 'ILIKE', v: `%${val}%` } } })
					.then(({ data }) => (this.users = data))
					.catch((err) => ElementTools.fireNotification(this.$el, 'error', err?.data?.message || 'Could not find users'))
					.finally(() => (this.loadingUsers = false));
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('AdminCompletionUpload', ['getCompletionUpload', 'findUser', 'assignCompletion']),

			async init() {
				this.$route.meta.breadcrumb[3].text = `Completion Upload #${this.completionUploadId}`;

				if (this.completionUploadId) {
					this.loading = true;

					this.getCompletionUpload(this.completionUploadId)
						.then(({ data }) => {
							let { pending, approved } = data.completion;
							this.pending = pending;
							this.approved = approved;
						})
						.finally(() => (this.loading = false));
				}
			},

			addUser() {
				const user = {
					id: this.selectedUser.id,
					name: this.selectedUser.name,
					organisationName: this.selectedUser.organisationName,
					fcaNumber: this.selectedUser.fcaNumber
				};

				this.$set(this.itemToEdit, 'user', user);
				this.$set(this.itemToEdit, 'userId', user.id);

				this.$refs.findUser.close();
			},

			approveSelected() {
				this.approving = true;

				const payload = this.selectedApprovels.filter((item) => item.user).map((item) => ({ id: item.id, userId: item.user.id }));

				this.assignCompletion(payload)
					.then(() => this.init())
					.catch((err) => ElementTools.fireNotification(this.$el, 'error', err.response?.data?.message || 'Could not approve users'))
					.finally(() => {
						this.approving = false;
						this.selectedApprovels = [];
					});
			},

			dialogClosed() {
				this.selectedUser = null;
			},

			openUserSearchDialog(item) {
				this.$refs.findUser.open();
				this.itemToEdit = item;
			}
		}
	};
</script>
