<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>Update #{{ formData.id }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/cms/product-updates`">
				Back to updates
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="form('manager_product_update').value" :form-data="formData" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import { cloneDeep } from 'lodash';

	export default {
		name: 'app-products-details',
		components: {
			DynamicForm
		},

		data: () => ({
			formData: {},
			formSchema: [],
			loading: false,
			submitting: false
		}),

		computed: {
			...mapGetters('CmsForm', ['form']),

			productUpdateId() {
				return this.$route.params.id;
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsProductUpdate', ['getProductUpdate', 'addProductUpdate', 'modifyProductUpdate']),

			async init() {
				this.$route.meta.breadcrumb[3].text = this.productUpdateId ? `Product Update #${this.productUpdateId}` : 'New Product Update';

				await this.loadForm('manager_product_update');

				if (this.productUpdateId) {
					this.loading = true;

					this.getProductUpdate(this.productUpdateId)
						.then(({ data }) => {
							data.effective = this.$moment(data.effective).format('DD/MM/YYYY');
							data.lastApplication = this.$moment(data.lastApplication).format('DD/MM/YYYY');
							data.lastKfi = this.$moment(data.lastKfi).format('DD/MM/YYYY');
							this.formData = data;
						})
						.finally(() => (this.loading = false));
				}
			},

			submit() {
				this.submitting = true;

				let payload = cloneDeep(this.formData);
				payload.effective = this.formatDate(this.formData.effective);
				payload.lastApplication = this.formatDate(this.formData.lastApplication);
				payload.lastKfi = this.formatDate(this.formData.lastKfi);
				delete payload.created;
				delete payload.updated;

				const promise = this.productUpdateId ? this.modifyProductUpdate({ id: this.productUpdateId, payload }) : this.addProductUpdate(payload);
				promise
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Product Update has been submitted successfully');
						this.$router.push('/cms/product-updates');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit Product Update'))
					.finally(() => (this.submitting = false));
			},

			formatDate(date) {
				const [day, month, year] = date.split('/');
				return new Date(`${month}/${day}/${year}`).toISOString();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.wrapper {
		background: #fff;
	}
</style>
