<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>{{ assetId ? `Asset #${assetId}` : `New Asset` }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/cms/assets`"> Back to assets </v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="form('manager_asset').value" :form-data="formData" :submit-button="false" />
							<div class="text-center mt-4">
								<v-btn color="primary" @click="submit"> submit </v-btn>
							</div>
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-asset-details',
		components: {
			DynamicForm
		},

		data: () => ({
			formData: {},
			loading: false,
			submitting: false
		}),

		computed: {
			...mapGetters('CmsForm', ['form']),

			assetId() {
				return this.$route.params.id || this.formData.id;
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsAsset', ['getAsset', 'addAsset', 'modifyAsset']),

			async init() {
				this.$route.meta.breadcrumb[3].text = this.assetId ? `Asset #${this.assetId}` : 'New Asset';

				this.loading = true;
				if (this.assetId) {
					await this.getAsset(this.assetId).then(({ data }) => {
						this.formData = data;
					});
				}

				await this.loadForm('manager_asset');
				this.loading = false;
			},

			submit() {
				this.submitting = true;

				const payload = {
					name: this.formData.name,
					s3key: this.formData.files?.[0]?.s3Key || this.formData.image
				};

				const promise = this.assetId ? this.modifyAsset({ id: this.assetId, payload }) : this.addAsset(payload);
				promise
					.then(({ data }) => {
						ElementTools.fireNotification(this.$el, 'success', 'Asset has been submitted successfully');

						if (data?.id) {
							this.$router.push(`/cms/asset/${data.id}`);
							this.formData.id = this.formData.id || data.id;
						}
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit Asset'))
					.finally(() => {
						this.submitting = false;
					});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.wrapper {
		background: #fff;
	}
</style>
