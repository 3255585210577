import { AccountDepartment } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getDepartmentList
		 * @param {Object} context
		 * @param {Object} payload
		 */
		getDepartmentList(context, payload) {
			return AccountDepartment.list(payload);
		},

		/**
		 * @name addDepartment
		 * @param {Object} context
		 * @param {Object} payload
		 */
		addDepartment(context, payload) {
			return AccountDepartment.add(payload);
		},

		/**
		 * @name associateGroup
		 * @param {Object} context
		 * @param {String} id
		 * @param {Object} payload
		 */
		associateGroup(context, { id, payload }) {
			return AccountDepartment.associateGroup(id, payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
