/**
 * @namespace Router
 * @exports Router
 * @description Routes for system
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

import { ElementTools, EventBus } from '@/utils';
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import cms from '@/router/cms';
import admin from '@/router/admin';
import Dashboard from '@/components/app/dashboard';
import Users from '@/components/app/users';
import UsersDetails from '@/components/app/users/details';
import UsersDetailsDetails from '@/components/app/users/details/details';
import UsersDetailsFirm from '@/components/app/users/details/firm';
import UsersDetailsMembership from '@/components/app/users/details/membership';
import UsersDetailsRewards from '@/components/app/users/details/rewards';
import UsersDetailsWriteRoute from '@/components/app/users/details/write-route';
import Requests from '@/components/app/requests';
import Cases from '@/components/app/cases';
import CaseDetails from '@/components/app/cases/details';
import Enquiries from '@/components/app/enquiries';
import EnquiryDetails from '@/components/app/enquiries/enquiry-details';
import Rewards from '@/components/app/rewards';
import FirmRewards from '@/components/app/firm-rewards';
import Registrations from '@/components/app/registrations';
import TradingStyles from '@/components/app/trading-styles';
import Login from '@/components/app/login';

Vue.use(VueRouter);

const routes = [
	{ path: '/', name: 'dashboard', component: Dashboard },
	{ path: '/login', name: 'login', component: Login },
	{
		path: '/users',
		name: 'users',
		component: Users,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Users' }],
			groups: ['administrator.user']
		}
	},
	{
		path: '/user/:id',
		name: 'usersDetails',
		component: UsersDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Users', to: '/users' }, { text: 'User' }],
			groups: ['administrator.user']
		}
	},
	{
		path: '/user/:id/details',
		name: 'UsersDetailsDetails',
		component: UsersDetailsDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Users', to: '/users' }, { text: 'User' }, { text: 'User Details' }],
			groups: ['administrator.user']
		}
	},
	{
		path: '/user/:id/firm',
		name: 'UsersDetailsFirm',
		component: UsersDetailsFirm,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Users', to: '/users' }, { text: 'User' }, { text: 'Firm' }],
			groups: ['administrator.user']
		}
	},
	{
		path: '/user/:id/membership',
		name: 'UsersDetailsMembership',
		component: UsersDetailsMembership,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Users', to: '/users' }, { text: 'User' }, { text: 'Membership' }],
			groups: ['administrator.user']
		}
	},
	{
		path: '/user/:id/rewards',
		name: 'UsersDetailsRewards',
		component: UsersDetailsRewards,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Users', to: '/users' }, { text: 'User' }, { text: 'Rewards' }],
			groups: ['administrator.user']
		}
	},
	{
		path: '/user/:id/write-route',
		name: 'UsersDetailsWriteRoute',
		component: UsersDetailsWriteRoute,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Users', to: '/users' }, { text: 'User' }, { text: 'WriteRoute' }],
			groups: ['administrator.user']
		}
	},
	{
		path: '/users/:id/write-route',
		name: 'UsersDetailsWriteRoute',
		component: UsersDetailsWriteRoute,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Users', to: '/users' }, { text: 'User Details' }],
			groups: ['administrator.user']
		}
	},
	{
		path: '/requests',
		name: 'requests',
		component: Requests,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'KFI Requests' }],
			groups: ['administrator.kfi']
		}
	},
	{
		path: '/cases',
		name: 'cases',
		component: Cases,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Cases' }],
			groups: ['administrator.cases']
		}
	},
	{
		path: '/case/:id',
		name: 'caseDetails',
		component: CaseDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Cases', to: '/cases' }, { text: 'Case' }],
			groups: ['administrator.cases']
		}
	},
	{
		path: '/enquiries',
		name: 'enquiries',
		component: Enquiries,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Enquiries' }],
			groups: ['administrator.enquiries']
		}
	},
	{
		path: '/enquiry/:id',
		name: 'EnquiryDetails',
		component: EnquiryDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Enquiries', to: '/enquiries' }, { text: 'Enquiry Details' }],
			groups: ['administrator.enquiries']
		}
	},
	{
		path: '/rewards',
		name: 'rewards',
		component: Rewards,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Rewards' }],
			groups: ['administrator.rewards']
		}
	},
	{
		path: '/firm-rewards',
		name: 'firm-rewards',
		component: FirmRewards,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Firm Rewards' }],
			groups: ['administrator.firmRewards']
		}
	},
	{
		path: '/registrations',
		name: 'registrations',
		component: Registrations,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Registrations' }],
			groups: ['administrator.user']
		}
	},
	{
		path: '/trading-styles',
		name: 'trading-styles',
		component: TradingStyles,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'TradingStyles' }],
			groups: ['administrator.user']
		}
	},
	...cms,
	...admin
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach(async (to, from, next) => {
	window.scrollTo(0, 0);

	if (to.name !== 'login' && !store.getters['Account/authed']) {
		await store.dispatch('Account/verify').catch(() => router.push('/login'));
	}

	// check if the route is allowed by the user's groups
	const route = to.matched.find((route) => route.meta?.groups);

	if (route?.meta?.groups && !route.meta.groups.some((group) => store.state.Account.groups?.includes(group))) {
		console.log('No access to: ', to.path);
		ElementTools.fireNotification(document, 'error', 'You do not have access to this page');
		return next('/');
	}

	// check for version update
	if (location.hostname !== 'localhost') {
		store.dispatch('SystemVersion/getVersionCheck').then(() => {
			if (store.getters['SystemVersion/versionUpgrade']) EventBus.$emit('app-version-update', to);
		});
	}

	next();
});

export default router;
