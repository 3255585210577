import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Changelog
 * @exports Service/Cms/Changelog
 * @description API Changelog Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get all changelogs as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`cms/changelog/list`, payload),

	/**
	 * @name get
	 * @description Get changelog
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`cms/changelog/${id}`),

	/**
	 * @name update
	 * @description Update changelog details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	update: (id, payload) => $http.put(`cms/changelog/${id}`, payload),

	/**
	 * @name modify
	 * @description Update some changelog details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`cms/changelog/${id}`, payload),

	/**
	 * @name delete
	 * @description Hard delete changelog details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	delete: (id) => $http.delete(`cms/changelog/${id}`),

	/**
	 * @name add
	 * @description Add a new changelog resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`cms/changelog`, payload),

	/**
	 * @name addUser
	 * @description Add changelog users
	 * @param {Object} changelogId
	 * @returns {Object} Promise a http response
	 */
	addUser: (changelogId) => $http.post(`cms/changelog/add/${changelogId}`),

	/**
	 * @name changelogCategoryList
	 * @description Get all changelog categories as a list
	 * @returns {Object} Promise a http response
	 */
	changelogCategoryList: (payload) => $http.post(`cms/changelog-category/list`, payload),

	/**
	 * @name getChangelogCategory
	 * @description Get changelog category
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	getChangelogCategory: (id) => $http.get(`cms/changelog-category/${id}`),

	/**
	 * @name changelogCommentList
	 * @description Get all changelog comments as a list for a given changelog post
	 * @returns {Object} Promise a http response
	 */
	changelogCommentList: (payload) => $http.post(`cms/changelog-comment/list`, payload)
};
