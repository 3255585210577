<template>
	<div>
		<v-container class="pt-8">
			<v-row dense>
				<v-col cols="12">
					<p class="caption mb-2">
						Notes: Prestige, Quick Quotes, Reports are NOT being rebuilt as they are either redundant, or in DWH tableau
					</p>
					<ul class="caption mb-5">
						<li>
							What can we ignore from CMS?
						</li>
						<li>
							What can we ignore from admin?
						</li>
					</ul>
					<v-list dark class="ma-0 pa-0">
						<v-list-item v-for="item in navigationItems" :key="item.path" :to="item.path" :disabled="item.disabled" dense class="my-1 primary">
							<v-list-item-content>
								{{ item.name }}
							</v-list-item-content>
							<v-list-item-action class="my-0">
								<v-icon>
									{{ item.icon }}
								</v-icon>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'app-dashboard',

		data() {
			return {};
		},

		computed: {
			...mapGetters('App', ['navigationItems'])
		}
	};
</script>

<style></style>