import { $http } from '@/utils';

/**
 * @namespace Service
 * @class TradingStyle
 * @exports Service/Account/Organisation/TradingStyle
 * @description API TradingStyle Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get trading style list
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`account/organisation/trading-style/list`, payload),

	/**
	 * @static @name modify
	 * @description Update trading style
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`account/organisation/trading-style/${id}`, payload),

	/**
	 * @static @name delete
	 * @description Delete trading style
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	delete: (id) => $http.delete(`account/organisation/trading-style/${id}`)
};
