import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Course
 * @exports Service/Cms/Course
 * @description API Course Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get all courses as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`cms/course/list`, payload),

	/**
	 * @name get
	 * @description Get course
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`cms/course/${id}`),

	/**
	 * @name update
	 * @description Update course details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	update: (id, payload) => $http.put(`cms/course/${id}`, payload),

	/**
	 * @name modify
	 * @description Update some course details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`cms/course/${id}`, payload),

	/**
	 * @name delete
	 * @description Hard delete course details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	delete: (id) => $http.delete(`cms/course/${id}`),

	/**
	 * @name add
	 * @description Add a new course resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`cms/course`, payload),

	/**
	 * @name courseTypeList
	 * @description Get all course types as a list
	 * @returns {Object} Promise a http response
	 */
	courseTypeList: (payload) => $http.post(`cms/course-type/list`, payload),

	/**
	 * @name getCourseType
	 * @description Get course type
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	getCourseType: (id) => $http.get(`cms/course-type/${id}`),

	/**
	 * @name addWorkflowTemplateItem
	 * @description Associate a workflow template item with a course resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	addWorkflowTemplateItem: (id, payload) => $http.post(`cms/course/${id}/workflow-template-item`, payload),

	/**
	 * @name add
	 * @description Delete the association between a workflow template item and a course resource
	 * @param {String} id
	 * @param {String} tagId
	 * @returns {Object} Promise a http response
	 */
	deleteWorkflowTemplateItem: (id, tagId) => $http.delete(`cms/course/${id}/workflow-template-item/${tagId}`)
};
