import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Department
 * @exports Service/Account/User/Department
 * @description API Department Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name add
	 * @description Add user to a department
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`account/user/department`, payload),

	/**
	 * @static @name get
	 * @description Get department details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`account/user/department/${id}`, payload)
};
