<template>
	<v-app>
		<layout />

		<app-notification ref="notification" />
	</v-app>
</template>

<script>
	import Layout from '@/components/layout';
	import AppNotification from '@/components/common/base/notification';

	export default {
		name: 'app',

		components: {
			Layout,
			AppNotification
		},

		data: () => ({}),

		mounted() {
			document.onreadystatechange = () => {
				if (document.readyState == 'complete') {
					this.documentLoaded = true;
				}
			};

			document.addEventListener('notification', (ev) => {
				if (ev.detail.hide) this.$refs.notification.hide();
				else this.$refs.notification.show(ev.detail);
			});
		}
	};
</script>

<style>
	.theme--light.v-application {
		background: #e6e8e9 !important;
	}
</style>
