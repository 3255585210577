import { Process } from '@/service';

/**
 * @class @name
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name fetchProcess
		 * @param {function} commit
		 * @param {Object} conditions
		 */
		fetchProcessList(context, conditions) {
			return Process.list(conditions);
		}
	}
};
