<template>
	<div>
		<common-dialog ref="logs" :max-width="1200">
			<template #header>
				KFI Data
			</template>

			<template #body>
				<json-viewer v-if="!loading" :value="flattenObject(mergedData)" boxed copyable expanded class="mt-5" />
				<v-skeleton-loader v-else type="list-item-three-line" class="pa-0 mt-5 mb-0" />
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import CommonDialog from '@/components/common/dialog';
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import { merge } from 'lodash';

	export default {
		name: 'app-requests-data-dialog',

		components: {
			CommonDialog
		},

		data: () => ({
            loading: false,
            kfi: {},
            session: {}
		}),

        computed: {
            kfiData() {
                return this.kfi.data || {};
            },

            sessionData() {
                return this.session.data || {};
            },

            mergedData() {
                const sourcingProcessClients = this.sessionData?.clients;
                const kfiProcessClients = this.kfiData?.clients;

               // Merge kfi process client data into sourcing process client data using id incase if the client order was changed
                const clients = [];
                if (sourcingProcessClients?.length) {
                    for (const sourcingProcessClient of sourcingProcessClients) {
                        const kfiProcessClient = kfiProcessClients.find((kpc) => kpc.id === sourcingProcessClient.id);
                        if (!kfiProcessClient) clients.push(sourcingProcessClient);
                        else clients.push(merge({}, sourcingProcessClient, kfiProcessClient));
                    }
                }
                
                return { ...merge(this.sessionData, this.kfiData), clients };
            }
        },

		methods: {
            ...mapActions('AppMatterKfi', ['fetchKfi']),
			...mapActions('AppMatterProcess', ['fetchProcessList']),
			...mapActions('AppMatterAction', ['fetchActionList']),
			...mapActions('AppMatterIteration', ['fetchIteration', 'fetchIterationList']),

			async open(iteration) {
				this.$refs.logs.open();
                this.loading = true;
                let kfiSourcing = {};

                // Get Sourcing by KFI id
                try {
                    const { data } = await this.fetchKfi(iteration.id);
                    kfiSourcing = data;
				} catch (e) {
                    return this.error();
                }

                // Get Sourcing and Kfi process
                try {
                    const { data } = await this.fetchProcessList({ where: { 'process.id': { type: 'uuid', value: [kfiSourcing.processId, iteration.processId]} } });
                    this.kfi = data?.data.find((p) => p.id === iteration.processId) || {};
                    this.session = data?.data.find((p) => p.id === kfiSourcing.processId) || {};
				} catch (e) {
                    return this.error();
                }

                // Get Kfi Iteration
                try {
                    // eslint-disable-next-line no-redeclare
                    var { data } = await this.fetchIteration(iteration.id);
                    this.kfi.data = { ...this.kfi.data, ...data.data };
                } catch (e) {
                    return this.error();
                }

                this.loading = false;
            },

            error() {
                ElementTools.fireNotification(this.$el, 'error', 'Failed to retrieve data');
                this.$refs.logs.close();
                this.loading = false;
                return;
            },

            flattenObject(obj, prefix = '') {
                if(obj === null) return null;
                return Object.keys(obj).reduce((acc, k) => {
                    const pre = prefix.length ? prefix + '.' : '';
                    if (typeof obj[k] === 'object') Object.assign(acc, this.flattenObject(obj[k], pre + k));
                    else acc[pre + k] = obj[k];
                    return acc;
                }, {});
            }
		}
	};
</script>