<template>
	<v-app-bar v-if="$route" app clipped-left color="white" class="elevation-1">
		<v-app-bar-nav-icon @click.stop="$emit('menu-button-click')" />
		<v-toolbar-title class="pl-2">
			<span>
				Air Manager
				<span class="text-caption blue-grey--text d-inline-block ml-2">{{ version }}</span>
			</span>
		</v-toolbar-title>

		<v-spacer />

		<v-tooltip v-if="!authed" bottom>
			<template #activator="{ on }">
				<v-btn color="black" icon v-on="on">
					<v-icon>mdi-account</v-icon>
				</v-btn>
			</template>
			<span>Sign in</span>
		</v-tooltip>

		<v-tooltip v-if="authed" bottom>
			<template #activator="{ on }">
				<v-btn color="black" icon v-on="on" @click="logout">
					<v-icon>mdi-logout</v-icon>
				</v-btn>
			</template>
			<span>Sign out</span>
		</v-tooltip>
	</v-app-bar>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';

	export default {
		name: 'app-layout-header',

		data: () => ({}),

		computed: {
			...mapGetters('Account', ['authed']),

			version() {
				return process.env.VUE_APP_VERSION;
			}
		},

		methods: {
			...mapActions('Account', ['logout'])
		}
	};
</script>

<style></style>
