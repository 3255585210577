import { FileService } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getAssetFile
		 * @param {Object} context
		 * @param {Object} payload
		 */
		getAsset(context, payload) {
			let { s3Key, filename } = payload;
			return FileService.getAsset(s3Key, filename);
		},

		/**
		 * @async @name invalidateAssetCache
		 */
		invalidateAssetCache() {
			return FileService.invalidateAssetCache();
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
