<!--
	@name common-structure-section
	@description Display a section with header/footer
	@date 2020/03/04
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!--
	@example

	<common-structure-section thick-header thick-footer>
		<template v-slot:header>Your Header</template>
		<template v-slot:body>
			<p>Some content</p>
		</template>
		<template v-slot:footer>Your Footer</template>
	</common-structure-section>
-->

<template>
	<div :component="$options.name" :class="{ 'd-flex flex-column flexHeight': flexHeight }">
		<v-toolbar v-show="!!$slots['header']" flat :dense="!thickHeader" class="header text-subtitle-1 font-weight-medium" :class="mergedHeaderClass">
			<slot name="header" />
		</v-toolbar>
		<v-sheet class="body" :class="bodyClass" :with-header="!!$slots['header'] || noTopRadius" :with-footer="!!$slots['footer']">
			<slot name="body" />
		</v-sheet>
		<v-toolbar v-show="!!$slots['footer']" flat :dense="!thickHeader" class="footer" color="text-subtitle-1 font-weight-medium blue-grey lighten-5">
			<slot name="footer" />
		</v-toolbar>
	</div>
</template>

<script>
	export default {
		name: 'common-structure-section',

		props: {
			headerClass: { type: String, default: 'blue-grey lighten-4' },
			bodyClass: { type: String, default: 'flex-grow-1 pa-5' },
			thickHeader: { type: Boolean, default: false },
			thickFooter: { type: Boolean, default: false },
			flexHeight: { type: Boolean, default: false },
			noTopRadius: { type: Boolean, default: false }
		},

		computed: {
			mergedHeaderClass: function () {
				let ret = this.headerClass;
				if (this.flexHeight) ret += ' flex-grow-0';
				return ret;
			}
		}
	};
</script>

<style scoped>
	.flexHeight {
		height: 100%;
	}
</style>
