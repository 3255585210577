import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Data
 * @exports Service/Cms/Data
 * @description API Data Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @static @name usingName
	 * @description Get Data using name
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	usingName(id) {
		return $http.get(`cms/data/${id}`);
	}
};
