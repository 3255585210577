import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Department
 * @exports Service/Account/User/Department
 * @description API Department Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get department list
	 * @param {String} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`account/department/list`, payload),

	/**
	 * @name add
	 * @description Add a new department resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`account/department`, payload),

	/**
	 * @name associateGroup
	 * @description Associate a group to a department
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	associateGroup: (id, payload) => $http.post(`account/department/${id}/group`, payload)
};
