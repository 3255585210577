<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title class="font-weight-bold">
				Add New Loan To Value Update Schedule
			</v-toolbar-title>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters class="justify-center">
				<v-col cols="6" md="8" lg="8" xl="6">
					<v-sheet class="pa-6" elevation="4">
						<p><strong>Upload Loan To Value CSV:</strong></p>
						<dynamic-form ref="dynamicForm" :form-schema="form('manager_loan_to_value_upload').value" :form-data="formData" submit-button-text="Validate CSV & Dry Run" :submit-button-loading="submitting" @dynamic-form-submit="submitValidate" />
					</v-sheet>
				</v-col>
			</v-row>
		
			<v-row dense no-gutters class="justify-center mt-4">
				<v-col cols="6" md="8" lg="8" xl="6">
					<v-sheet class="pa-6" elevation="4">
						<p><strong>Update Loan To Value at:</strong></p>
						<dynamic-form
							ref="dynamicForm"
							:form-schema="form('manager_loan_to_value_update').value"
							:form-data="formData"
							:submit-button="true"
							:submit-button-loading="submitting"
							:submit-button-disabled="!validated"
							@dynamic-form-submit="submit"
						/>
					</v-sheet>
				</v-col>
			</v-row>

			<common-dialog ref="dryRun" :max-width="1000">
				<template #header>
					Dry Run Results (Document ID: {{ documentId }})
				</template>
				<template #header-action>
					<v-tooltip bottom>
						<template #activator="{ on }">
							<v-btn color="white" icon v-on="on" @click="downloadResults">
								<v-icon>mdi-download</v-icon>
							</v-btn>
						</template>
						<span>Download as JSON file</span>
					</v-tooltip>
				</template>

				<template #body>
					<v-expansion-panels v-if="dryRunResults" class="mt-5">
						<v-expansion-panel>
							<v-expansion-panel-header> Success ({{ dryRunResults.successes.length }}) </v-expansion-panel-header>
							<v-expansion-panel-content>
								<json-viewer :value="dryRunResults.successes" boxed copyable expanded />
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel>
							<v-expansion-panel-header> Fails ({{ dryRunResults.fails.length }})</v-expansion-panel-header>
							<v-expansion-panel-content>
								<json-viewer :value="dryRunResults.fails" boxed copyable expanded />
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
					<v-skeleton-loader v-else type="list-item-three-line" class="pa-0 mt-5 mb-0" />
				</template>
			</common-dialog>

		</v-container>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import { cloneDeep } from 'lodash';
	import DynamicForm from '@/components/common/base/dynamic-form';
	import CommonDialog from '@/components/common/dialog';

	export default {
		name: 'app-admin-loan-to-value-update',

		components: {
			DynamicForm,
			CommonDialog
		},

		data: () => ({
			fileUploadFormSchema: [],
			fileSubmitting: false,
			validated: false,
			formData: {},
			formSchema: [],
			submitting: false,
			documentId: null,
			dryRunResults: null
		}),

		computed: {
			...mapGetters('CmsForm', ['form'])
		},

		watch: {
			'formData.organisationId': {
				handler(orgId) {
					const fileField = this.fileUploadFormSchema.find((schema) => schema.name === 'files');
					fileField.s3Path = `loan-to-value-update/${orgId}`;
				},
				deep: true
			}
		},

		created() {
			this.loadForm('manager_loan_to_value_upload').then((res) => {
				this.fileUploadFormSchema = res.data.value;
			});
			this.loadForm('manager_loan_to_value_update');
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('AppProductLoanToValueUpdate', ['validate', 'addSchedule']),

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 */
			 async submitValidate() {
				this.fileSubmitting = true;

				const { organisationId, files } = cloneDeep(this.formData);
				this.documentId = files[0].s3Key.split('/').pop();
				const payload = { organisationId, documentId: this.documentId };
				this.$set(this.formData, 'documentId', this.documentId);

				this.validate(payload)
					.then((response) => {
						this.validated = true;
						this.dryRunResults = response.data;
						this.$refs.dryRun.open();
						ElementTools.fireNotification(this.$parent.$el, 'success', response.data.message);
					})
					.catch((error) => {
						this.documentId = null;
						ElementTools.fireNotification(this.$parent.$el, 'error', error.response.data.message || 'Failed to validate LTV csv');
					})
					.finally(() => (this.fileSubmitting = false));
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 */
			 async submit() {
				this.submitting = true;

				const { organisationId, mode, documentId, date, time } = cloneDeep(this.formData);
				const schedule = this.$moment(`${this.formatDate(date)} ${time}`).format('YYYY-MM-DDTHH:mm:ss');
				const payload = { mode, organisationId, documentId, schedule };

				this.addSchedule({ ...payload, schedule })
					.then(() => {
						this.$router.push(`/admin`);
						ElementTools.fireNotification(this.$parent.$el, 'success', 'LTV update schedule has been added successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$parent.$el, 'error', 'Failed to add LTV update schedule'))
					.finally(() => (this.submitting = false));
			},
			
			/**
			 * @name downloadResults
			 * @description Download dry run results
			 */
			 downloadResults() {
				const blob = new Blob([JSON.stringify(this.dryRunResults)], { type: 'application/json' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `dry-run-results-${this.documentId}.json`;
				a.click();
				window.URL.revokeObjectURL(url);
			},

			/**
			 * @name formatDate
			 * @description Format date to YYYY-MM-DD
			 */
			formatDate(date) {
				const [day, month, year] = date.split('/');
				return `${year}-${month}-${day}`;
			}
		}
	};
</script>
