import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Membership
 * @exports Service/Membership
 * @description API Membership Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description List all membership
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`membership/list`, payload),

	/**
	 * @name get
	 * @description Get membership details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`membership/${id}`),

	/**
	 * @name update
	 * @description Update all membership details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	update: (id, payload) => $http.put(`membership/${id}`, payload),

	/**
	 * @name modify
	 * @description Update some membership details for ID
	 * @param {String} id
	 * @param {Object} payload
	 */
	modify: (id, payload) => $http.patch(`membership/${id}`, payload),

	/**
	 * @name delete
	 * @description Hard delete membership details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	delete: (id) => $http.delete(`membership/${id}`),

	/**
	 * @name add
	 * @description Add a new membership resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`membership`, payload),

	/**
	 * @name add
	 * @description Add a new membership resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	applicationList: (payload) => $http.post(`membership/list-applications`, payload)
};
