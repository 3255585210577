import {
	AccountOrganisation,
	AccountOrganisationAddress,
	AccountOrganisationCommunication,
	AccountOrganisationDetail,
	AccountOrganisationProvider,
	AccountOrganisationEntity,
	AccountDepartment,
	AccountOrganisationUser
} from '@/service';
import { merge } from 'lodash';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @name getOrganisationList
		 * @param {Object} payload
		 */
		getOrganisationList(context, payload) {
			return AccountOrganisation.list(payload);
		},

		/**
		 * @name getOrganisationUserList
		 * @param {Object} payload
		 */
		getOrganisationUserList(context, id) {
			return AccountOrganisationUser.list(id);
		},

		/**
		 * @name getDownload
		 * @param {String} id
		 */
		getOrganisation(context, id) {
			return AccountOrganisation.get(id);
		},

		/**
		 * @name getOrganisation
		 * @param {String} id
		 * @param {Object} data
		 */
		modifyOrganisation(context, { id, data }) {
			return AccountOrganisation.modify(id, data);
		},

		/**
		 * @name addOrganisation
		 * @param {String} id
		 */
		deleteOrganisation(context, id) {
			return AccountOrganisation.delete(id);
		},

		/**
		 * @name addOrganisation
		 */
		addOrganisation(context, payload) {
			return AccountOrganisation.add(payload);
		},

		/**
		 * @name addOrganisationAddress
		 * @param {Object} payload
		 */
		addOrganisationAddress(context, payload) {
			return AccountOrganisationAddress.add(payload);
		},

		/**
		 * @name modifyOrganisationAddress
		 * @param {String} id
		 * @param {Object} data
		 */
		modifyOrganisationAddress(context, { id, data }) {
			return AccountOrganisationAddress.modify(id, data);
		},

		/**
		 * @name addOrganisationCommunication
		 * @param {Object} payload
		 */
		addOrganisationCommunication(context, payload) {
			return AccountOrganisationCommunication.add(payload);
		},

		/**
		 * @name modifyOrganisationCommunication
		 * @param {String} id
		 * @param {Object} data
		 */
		modifyOrganisationCommunication(context, { id, data }) {
			return AccountOrganisationCommunication.modify(id, data);
		},

		/**
		 * @name addOrganisationDetail
		 * @param {Object} payload
		 */
		addOrganisationDetail(context, payload) {
			return AccountOrganisationDetail.add(payload);
		},

		/**
		 * @name modifyOrganisationDetail
		 * @param {String} id
		 * @param {Object} data
		 */
		modifyOrganisationDetail(context, { id, data }) {
			return AccountOrganisationDetail.modify(id, data);
		},

		/**
		 * @name addOrganisationProvider
		 * @param {Object} payload
		 */
		addOrganisationProvider(context, payload) {
			return AccountOrganisationProvider.add(payload);
		},

		/**
		 * @name modifyOrganisationProvider
		 * @param {String} id
		 * @param {Object} data
		 */
		modifyOrganisationProvider(context, { id, data }) {
			return AccountOrganisationProvider.modify(id, data);
		},

		/**
		 * @async @name getDepartmentList
		 * @param {Object} context
		 * @param {Object} payload
		 */
		getDepartmentList(context, payload) {
			return AccountDepartment.list(payload);
		},

		/**
		 * @async @name loadProviderList
		 * @param {function} commit
		 */
		async loadProviderList(context, payload) {
			const { data } = await AccountOrganisation.list(merge(payload || {}, { where: { type: 'provider', 'organisation_provider.equity_release': true } }));
			return data;
		},

		/**
		 * @name addOrganisationEntity
		 * @param {Object} payload
		 */
		addOrganisationEntity(context, { id, payload }) {
			return AccountOrganisationEntity.add(id, {
				organisationEntities: [payload]
			});
		},

		/**
		 * @name deleteOrganisationEntity
		 * @param {Object} payload
		 */
		deleteOrganisationEntity(context, { id, entity, entityId }) {
			return AccountOrganisationEntity.delete(id, entity, entityId);
		},

		/**
		 * @name getFirmRewardsList
		 * @param {Object} payload
		 */
		getFirmRewardsList(context, payload) {
			return AccountOrganisation.firmRewardsList(payload);
		},

		/**
		 * @name redeemRewards
		 * @param {Object} payload
		 */
		redeemRewards(context, payload) {
			return AccountOrganisation.redeemRewards(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
