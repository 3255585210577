<template>
	<div class="pa-8">
		<div class="pb-4">
			<v-btn color="primary" dark to="/cms/insight">
				Add New Insight
			</v-btn>
		</div>
		<div class="d-flex">
			<validation-observer ref="observer" class="pa-0 col-6">
				<validation-provider v-slot="{ errors }" name="Search" rules="search">
					<v-text-field v-model="searchTerm" placeholder="Search" append-icon="mdi-magnify" dense solo light clear-icon="mdi-close-circle" clearable :error-messages="errors" @click:clear="searchTerm = ''" />
				</validation-provider>
			</validation-observer>
			<v-spacer />
			<v-pagination v-model="options.page" :length="pages" total-visible="7" :disabled="loading" />
		</div>
		<v-data-table
			:headers="headers"
			:items="items"
			class="elevation-1"
			:options.sync="options"
			:server-items-length="total"
			:loading="loading"
			:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [5, 10, 15, 20, 25] }"
		>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.url="{ item }">
				<a v-if="item.url" :href="item.url" target="_blank">External Link</a>
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn small color="success" :to="`/cms/insight/${item.id}`">
						edit
					</v-btn>
					<v-btn small dark class="ml-2" color="error" :loading="deleting[item.id]" @click="deleteItem(item.id)">
						delete
					</v-btn>
				</div>
			</template>
		</v-data-table>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import { debounce } from 'lodash';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';

	export default {
		name: 'app-cms-insights-hub',

		components: {
			CommonDialogConfirm,
			ValidationObserver,
			ValidationProvider
		},

		data: () => ({
			items: [],
			loading: false,
			deleting: {},
			options: {},
			total: 0,
			pages: 0,
			searchTerm: '',
			headers: [
				// { text: 'Type', value: 'type' },
				// { text: 'Provider', value: 'provider' },
				{ text: 'Title', value: 'name' },
				{ text: 'Abstract', value: 'headline' },
				{ text: 'Created', value: 'created' },
				{ text: 'External Link', value: 'url' },
				{ text: '', value: 'actions', sortable: false }
			]
		}),

		watch: {
			options: {
				handler() {
					this.fetchInsightList();
				},
				deep: true
			},

			async searchTerm() {
				const valid = await this.$refs.observer.validate();
				if(!valid) return;
				this.debouncedfetchInsightList();
			}
		},

		methods: {
			...mapActions('CmsInsight', ['getInsightList', 'deleteInsight']),

			async fetchInsightList() {
				if (this.loading) return;

				this.loading = true;
				const { sortBy, sortDesc, page, itemsPerPage } = this.options;

				const payload = {
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					order: sortBy.length > 0 ? { property: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc' } : { property: 'created', direction: 'desc' },
					where: this.searchTerm ? [{ 'insight.name': { c: 'ILIKE', v: `%${this.searchTerm}%` } }, '||', { 'insight.headline': { c: 'ILIKE', v: `%${this.searchTerm}%` } }] : undefined
				};

				let { data } = await this.getInsightList(payload);
				this.items = data.data;
				this.total = data.total;
				this.pages = Math.ceil(this.total / this.options.itemsPerPage);
				this.loading = false;
			},

			/**
			 * @name debouncedfetchInsightList
			 * @description Load news with debounce
			 */
			debouncedfetchInsightList: debounce(function () {
				this.options.page = 1;
				this.fetchInsightList();
			}, 1000),

			async deleteItem(id) {
				this.$refs.confirm
					.open('Delete Insight', `Are you sure you wish to delete this insight?`)
					.then(() => {
						this.deleting[id] = true;
						this.deleteInsight(id)
							.then(() => {
								ElementTools.fireNotification(this.$el, 'success', 'Insight has been deleted successfully');
								this.fetchInsightList();
							})
							.catch(() => {
								ElementTools.fireNotification(this.$el, 'error', 'Failed to delete Insight');
							})
							.finally(() => {
								this.deleting[id] = false;
							});
					})
					.catch(() => {});
			}
		}
	};
</script>
