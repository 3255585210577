import { Completion, CompletionUpload } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getCompletionUploadList
		 * @param {String} id
		 */
		getCompletionUploadList(context, payload) {
			return CompletionUpload.list(payload);
		},

		/**
		 * @async @name getCompletionUpload
		 * @param {String} id
		 */
		getCompletionUpload(context, id) {
			return CompletionUpload.get(id);
		},

		/**
		 * @async @name modifyCompletionUpload
		 * @param {String} id
		 */
		modifyCompletionUpload(context, { id, payload }) {
			return CompletionUpload.modify(id, payload);
		},

		/**
		 * @async @name addCompletionUpload
		 */
		addCompletionUpload(context, payload) {
			return CompletionUpload.add(payload);
		},

		/**
		 * @async @name deleteCompletionUpload
		 */
		deleteCompletionUpload(context, id) {
			return CompletionUpload.delete(id);
		},

		/**
		 * @async @name assignCompletion
		 * @param {String} id
		 */
		assignCompletion(context, payload) {
			return Completion.assignUser(payload);
		},

		/**
		 * @async @name findUser
		 */
		findUser(context, payload) {
			return Completion.findUser(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
