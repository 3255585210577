import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Media
 * @exports Service/Cms/Media
 * @description API Media Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get all events as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`cms/media/list`, payload),

	/**
	 * @name get
	 * @description Get event
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`cms/media/${id}`),

	/**
	 * @name update
	 * @description Update event details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	update: (id, payload) => $http.put(`cms/media/${id}`, payload),

	/**
	 * @name modify
	 * @description Update some event details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`cms/media/${id}`, payload),

	/**
	 * @name delete
	 * @description Hard delete event details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	delete: (id) => $http.delete(`cms/media/${id}`),

	/**
	 * @name add
	 * @description Add a new event resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`cms/media`, payload),

	/**
	 * @name addTag
	 * @description Associate a tag with a news resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	addTag: (id, payload) => $http.post(`cms/media/${id}/tag`, payload),

	/**
	 * @name add
	 * @description Delete the association between a tag and a news resource
	 * @param {String} id
	 * @param {String} tagId
	 * @returns {Object} Promise a http response
	 */
	deleteTag: (id, tagId) => $http.delete(`cms/media/${id}/tag/${tagId}`)
};
