import { ProductLoanToValueUpdate } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @name validate
		 * @description Send a request to process CSV file and validate
		 * @returns {Object} Promise a http response
		 */
		validate(context, payload) {
			return ProductLoanToValueUpdate.validate(payload);
		},

		/**
		 * @name addSchedule
		 * @description Add a schedule to process CSV file and store data in Redis
		 * @returns {Object} Promise a http response
		 */
		addSchedule(context, payload) {
			return ProductLoanToValueUpdate.addSchedule(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
