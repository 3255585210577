<template>
	<div class="pa-8">
		<div class="d-flex">
			<v-btn color="primary" dark to="/admin/notification">
				Add New Notification
			</v-btn>
			<v-spacer />
			<v-btn :disabled="loading || options.page === 1" @click="changePage(0)">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			<v-btn class="ml-2" :disabled="loading || count < 10" @click="changePage(1)">
				<v-icon>mdi-arrow-right</v-icon>
			</v-btn>
		</div>
		<v-data-table
			:headers="headers"
			:items="items"
			class="elevation-1 mt-3"
			:options.sync="options"
			:loading="loading"
			:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [10] }"
		>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn small color="success" :to="`/admin/notification/${item.id}`">
						view
					</v-btn>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';

    export default {
		name: 'app-admin-notifications',

		data: () => ({
			items: [],
			loading: false,
			deleting: {},
			options: {},
			count: 10,
			headers: [
				{ text: 'Title', value: 'title' },
				{ text: 'Created', value: 'created' },
				{ text: '', value: 'actions', sortable: false }
			]
		}),

		watch: {
			options: {
				handler() {
					this.fetchNotificationList();
				},
				deep: true
			}
		},

		methods: {
			...mapActions('AdminNotification', ['getNotificationList']),

			changePage(evt) {
				if(evt) this.options.page++;
				else this.options.page--;
			},

			async fetchNotificationList() {
				if (this.loading) return;

				this.loading = true;

				const { sortBy, sortDesc, page, itemsPerPage } = this.options;

				const payload = {
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					order: sortBy?.length > 0 ? { property: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc' } : { property: 'created', direction: 'desc' }
				};

				let { data } = await this.getNotificationList(payload);
				this.items = data.data;
				this.count = data.count;
				this.loading = false;
			}
		}
	};
</script>
