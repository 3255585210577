<template>
	<div class="pa-8">
		<v-data-table :headers="headers" :items="items" class="elevation-1 mt-3" :loading="loading" hide-default-footer>
			<template #item.name="{ item }">
				<v-icon left :color="item.apiHealth.status === 'Error' ? 'error' : item.apiHealth.status === 'Warning' ? 'warning' : 'success'" size="22"> mdi-circle-slice-8 </v-icon>{{ item.name }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex flex-row justify-end align-center py-3">
					<div class="pl-6">
						<div>
							<v-btn-toggle v-model="item.apiHealth.status" mandatory>
								<v-btn :value="'Good'" small color="success"> Good </v-btn>
								<v-btn :value="'Warning'" small color="warning"> Warning </v-btn>
								<v-btn :value="'Error'" small color="error"> Error </v-btn>
							</v-btn-toggle>
						</div>
						<v-textarea
							v-if="item.apiHealth.status !== 'Good'"
							v-model="item.apiHealth.message"
							solo
							name="input-7-4"
							label="This message will be shown to the advisors"
							hide-details
							rows="3"
							class="mt-3"
							style="width: 100%"
						/>
						<v-btn class="mt-3" color="primary" dark @click="updateHealth(item.apiHealth)"> Update health </v-btn>
					</div>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { ElementTools } from '@/utils';
	import { mapActions } from 'vuex';

	export default {
		name: 'app-admin-notifications',

		data: () => ({
			items: [],
			loading: false,
			headers: [
				{ text: 'Title', value: 'name', sortable: false },
				{ text: '', value: 'actions', sortable: false }
			]
		}),

		mounted() {
			this.fetchLifetimeMortgageSourcingList();
		},

		methods: {
			...mapActions('CmsConfig', ['loadLifetimeMortgageSourcing']),
			...mapActions('AccountOrganisation', ['modifyOrganisationProvider']),

			async fetchLifetimeMortgageSourcingList() {
				if (this.loading) return;
				this.loading = true;
				let { data } = await this.loadLifetimeMortgageSourcing();
				this.items = data.api.organisation;
				this.loading = false;
			},

			updateHealth(item) {
				this.loading = true;
				this.modifyOrganisationProvider({
					id: item.organisationProviderId,
					data: {
						apiHealth: {
							status: item.status,
							message: item.status !== 'Good' ? item.message : null
						}
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Health status changed successfully');
						this.loading = false;
					})
					.catch((e) => {
						ElementTools.fireNotification(this.$el, 'error', 'Failed to update health status: ' + e.message);
						this.loading = false;
					});
			}
		}
	};
</script>
