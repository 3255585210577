<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>Network #{{ networkId }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark to="/admin/networks"> Back to Networks </v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-list dark class="ma-0 pa-0">
						<v-list-item v-for="item in items" :key="item.path" :to="`${$route.path}/${item.path}`" :disabled="item.disabled" dense class="my-1 primary">
							<v-list-item-content>
								{{ item.name }}
							</v-list-item-content>
							<v-list-item-action class="my-0">
								<v-icon>
									{{ item.icon }}
								</v-icon>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	export default {
		name: 'app-users-details',

		data() {
			return {
				items: [
					{
						name: 'Details',
						path: 'details',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'Users',
						path: 'users',
						icon: 'mdi-star',
						disabled: false
					}
				]
			};
		},

		computed: {
			networkId() {
				return this.$route.params.id;
			}
		},

		created() {
			this.$route.meta.breadcrumb[2] = {
				text: `Network #${this.networkId}`,
				to: this.$route.path
			};
		}
	};
</script>
