<template>
	<div class="pa-8">
		<div class="pb-4">
			<v-btn color="primary" dark @click="openAddDialog">
				Add New Network
			</v-btn>
		</div>
		<div class="d-flex">
			<validation-observer ref="observer" class="pa-0 col-6">
				<validation-provider v-slot="{ errors }" name="Search" rules="search">
					<v-text-field
						v-model="searchTerm"
						placeholder="Search"
						append-icon="mdi-magnify"
						dense
						solo
						light
						clear-icon="mdi-close-circle"
						clearable
						:error-messages="errors"
						@click:clear="searchTerm = ''"
					/>
				</validation-provider>
			</validation-observer>
			<v-spacer />
			<v-pagination v-model="options.page" :length="pages" total-visible="7" :disabled="loading" />
		</div>
		<v-data-table
			:headers="headers"
			:items="items"
			class="elevation-1"
			:options.sync="options"
			:server-items-length="total"
			:loading="loading"
			:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [5, 10, 15, 20, 25] }"
		>
			<template #item.active="{ item }">
				<v-checkbox :input-value="!!item.active" disabled />
			</template>

			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn small color="success" :to="`/admin/network/${item.id}`">
						edit
					</v-btn>
					<v-btn small dark class="ml-2" color="error" :loading="deleting[item.id]" @click="deleteNetwork(item.id)">
						delete
					</v-btn>
				</div>
			</template>
		</v-data-table>

		<common-dialog ref="new-network" :max-width="600">
			<template #header>
				Add New Network
			</template>

			<template #body>
				<validation-observer ref="observer">
					<v-form ref="form" class="pt-8">
						<validation-provider v-slot="{ errors }" name="Network Name" rules="required">
							<v-text-field v-model="networkName" label="Network Name" :error-messages="errors" outlined required />
						</validation-provider>
						<div class="d-flex justify-center">
							<v-btn color="success" :loading="submitLoading" @click="addNetwork">
								Submit
							</v-btn>
						</div>
					</v-form>
				</validation-observer>
			</template>
		</common-dialog>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import { debounce } from 'lodash';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import CommonDialog from '@/components/common/dialog';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';

	export default {
		name: 'app-admin-networks',

		components: {
			CommonDialog,
			CommonDialogConfirm,
			ValidationObserver,
			ValidationProvider
		},

		data: () => ({
			items: [],
			loading: false,
			deleting: {},
			options: {},
			total: 0,
			pages: 0,
			searchTerm: '',
			networkName: '',
			submitLoading: false,
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: '', value: 'actions', sortable: false }
			]
		}),

		watch: {
			options: {
				handler() {
					this.fetchNetworkList();
				},
				deep: true
			},

			async searchTerm() {
				const valid = await this.$refs.observer.validate();
				if(!valid) return;
				this.debouncedFetchNetworkList();
			}
		},

		methods: {
			...mapActions('AccountOrganisation', ['getOrganisationList', 'addOrganisation', 'deleteOrganisation']),

			async fetchNetworkList() {
				if (this.loading) return;

				this.loading = true;

				const { sortBy, sortDesc, page, itemsPerPage } = this.options;

				const payload = {
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					order: {
						property: sortBy[0] || 'organisation.name',
						direction: sortDesc[0] ? 'desc' : 'asc'
					},
					where: { network: true, name: this.searchTerm ? { c: 'ILIKE', v: `%${this.searchTerm}%` } : undefined }
				};

				let { data } = await this.getOrganisationList(payload);
				({ data: this.items, total: this.total } = data);
				this.pages = Math.ceil(this.total / this.options.itemsPerPage);
				this.loading = false;
			},

			/**
			 * @name debouncedFetchNetworkList
			 * @description Load news with debounce
			 */
			debouncedFetchNetworkList: debounce(function () {
				this.options.page = 1;
				this.fetchNetworkList();
			}, 1000),

			openAddDialog() {
				this.$refs['new-network'].open();
			},

			async addNetwork() {
				const valid = await this.$refs.observer.validate();

				if (!valid) return;

				this.submitLoading = true;

				this.addOrganisation({
					type: 'default',
					network: true,
					name: this.networkName
				})
					.then(({ data }) => {
						this.$router.push(`/admin/network/${data.id}`);
						this.$refs['new-network'].close();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to create network'))
					.finally(() => (this.submitLoading = false));
			},

			async deleteNetwork(id) {
				this.$refs.confirm
					.open('Delete Network', `Are you sure you wish to delete this network?`)
					.then(() => {
						this.deleting[id] = true;
						this.deleteOrganisation(id)
							.then(() => {
								ElementTools.fireNotification(this.$el, 'success', 'Network has been deleted successfully');
								this.fetchNetworkList();
							})
							.catch(() => {
								ElementTools.fireNotification(this.$el, 'error', 'Failed to delete network');
							})
							.finally(() => {
								this.deleting[id] = false;
							});
					})
					.catch(() => {});
			}
		}
	};
</script>
