<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>Resource #{{ formData.id }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/cms/downloads`">
				Back to resources
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<dynamic-form :form-schema="form('manager_download').value" :form-data="formData" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
						</v-sheet>

						<v-sheet class="pa-6 mt-4" elevation="4">
							<dynamic-form :form-schema="form('tag').value" :form-data="tagFormData" :submit-button-loading="submitting" :all-disabled="!formData.id" :submit-button="false" />
						</v-sheet>

						<v-sheet class="pa-6 mt-4" elevation="4">
							<dynamic-form :form-schema="form('manager_organisation').value" :form-data="organisationFormData" :submit-button-loading="submitting" :all-disabled="!formData.id" :submit-button="false" />
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import { cloneDeep } from 'lodash';

	export default {
		name: 'app-products-details',
		components: {
			DynamicForm
		},

		data: () => ({
			formData: {},
			tagFormData: {},
			organisationFormData: {},
			loading: false,
			submitting: false
		}),

		computed: {
			...mapGetters('CmsForm', ['form']),

			downloadId() {
				return this.$route.params.id || this.formData.id;
			},

			selectedTags() {
				return [...(this.tagFormData?.tag || [])];
			},

			selectedExclusives() {
				return [...(this.organisationFormData?.exclusive || [])];
			},

			selectedRestricteds() {
				return [...(this.organisationFormData?.restricted || [])];
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsDownload', ['getDownload', 'addDownload', 'modifyDownload', 'addTag', 'deleteTag']),
			...mapActions('AccountOrganisation', ['addOrganisationEntity', 'deleteOrganisationEntity']),

			async init() {
				this.$route.meta.breadcrumb[3].text = this.downloadId ? `Resource #${this.downloadId}` : 'New Resource';

				this.loading = true;
				await Promise.all([this.loadForm('manager_download'), this.loadForm('manager_organisation'), this.loadForm('tag')]);
				this.loading = false;

				if (this.downloadId) {
					this.loading = true;

					this.getDownload(this.downloadId)
						.then(({ data }) => {
							this.tagFormData = { tag: data.tag?.map((tag) => tag.id) };
							this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
							this.formData = data;
							this.initWatchers();
						})
						.finally(() => (this.loading = false));
				} else this.initWatchers();
			},

			initWatchers() {
				this.$watch(
					'selectedTags',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addTagRequest(added);
						else if (deleted) this.deleteTagRequest(deleted);
					},
					{ deep: true }
				);

				this.$watch(
					'selectedExclusives',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addOrganisationEntityRequest(added, 'exclusive');
						else if (deleted) this.deleteOrganisationEntityRequest(deleted);
					},
					{ deep: true }
				);

				this.$watch(
					'selectedRestricteds',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addOrganisationEntityRequest(added, 'restricted');
						else if (deleted) this.deleteOrganisationEntityRequest(deleted);
					},
					{ deep: true }
				);
			},

			submit() {
				let payload = cloneDeep(this.formData);

				payload.exclusive = payload.exclusive || [];
				payload.restricted = payload.restricted || [];
				payload.tag = payload.tag || [];
				if (payload.files) payload.name = payload.files[0].s3Key.split('/').pop();

				this.submitting = true;

				const promise = this.downloadId ? this.modifyDownload({ id: this.downloadId, payload }) : this.addDownload(payload);
				promise
					.then(({ data }) => {
						if (data.id) this.$router.push(`/cms/download/${data.id}`);
						this.formData.id = this.formData.id || data.id;
						ElementTools.fireNotification(this.$el, 'success', 'Download has been submitted successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit download'))
					.finally(() => (this.submitting = false));
			},

			addTagRequest(tagId) {
				let payload = { tagIds: [tagId] };

				this.addTag({ id: this.downloadId, payload }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to add tag');
					this.getDownload(this.downloadId).then(({ data }) => {
						this.tagFormData = { tag: data.tag?.map((tag) => tag.id) };
					});
				});
			},

			deleteTagRequest(tagId) {
				this.deleteTag({ id: this.downloadId, tagId }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to delete tag');
					this.getDownload(this.downloadId).then(({ data }) => {
						this.tagFormData = { tag: data.tag?.map((tag) => tag.id) };
					});
				});
			},

			addOrganisationEntityRequest(id, status) {
				let payload = {
					entity: 'download',
					entityId: this.downloadId,
					status
				};

				this.addOrganisationEntity({ id, payload }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to add exclusive organisation');
					this.getDownload(this.downloadId).then(({ data }) => {
						this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
					});
				});
			},

			deleteOrganisationEntityRequest(id) {
				this.deleteOrganisationEntity({ id, entityId: this.downloadId, entity: 'download' }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to delete exclusive organisation');
					this.getDownload(this.downloadId).then(({ data }) => {
						this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
					});
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.wrapper {
		background: #fff;
	}
</style>
