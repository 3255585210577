<template>
	<div>
		<v-container class="pt-8">
			<v-row dense>
				<v-col cols="12">
					<!-- <common-base-tabs :tabs="routes" :loading="false" router-view dark /> -->

					<v-list dark class="ma-0 pa-0">
						<v-list-item v-for="item in routes" :key="item.path" :to="item.path" :disabled="item.disabled" dense class="my-1 primary">
							<v-list-item-content>
								{{ item.title }}
							</v-list-item-content>
							<v-list-item-action class="my-0">
								<v-icon>
									{{ item.icon }}
								</v-icon>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	export default {
		name: 'app-cms',

		data() {
			return {
				routes: [
					{
						name: 'cms.events',
						title: 'Events',
						alerts: 0,
						path: '/cms/events',
						icon: 'mdi-calendar',
						disabled: false
					},
					{
						name: 'cms.productProviders',
						title: 'Product Providers',
						alerts: 0,
						path: '/cms/product-providers',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'cms.downloads',
						title: 'Resources',
						alerts: 0,
						path: '/cms/downloads',
						icon: 'mdi-star',
						disabled: false
					},
					// {
					// 	name: 'cms.newsletters',
					// 	title: 'Newsletters',
					// 	alerts: 0,
					// 	path: '/cms/newsletters',
					// 	icon: 'mdi-star',
					// 	disabled: false
					// },
					{
						name: 'cms.news',
						title: 'News',
						alerts: 0,
						path: '/cms/news-list',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'cms.insightsHub',
						title: 'Insights Hub',
						alerts: 0,
						path: '/cms/insights-hub',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'cms.media',
						title: 'Media',
						alerts: 0,
						path: '/cms/media-list',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'cms.asset',
						title: 'Assets',
						alerts: 0,
						path: '/cms/assets',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'cms.deals',
						title: 'Deals',
						alerts: 0,
						path: '/cms/deals',
						icon: 'mdi-star',
						disabled: false
					},
					// {
					// 	name: 'cms.productIssueReports',
					// 	title: 'Product Issue Reports',
					// 	alerts: 0,
					// 	path: '/cms/product-issue-reports',
					// 	icon: 'mdi-star',
					// 	disabled: false
					// },
					// {
					// 	name: 'cms.onAirArticles',
					// 	title: 'On Air Articles',
					// 	alerts: 0,
					// 	path: '/cms/articles',
					// 	icon: 'mdi-star',
					// 	disabled: false
					// },
					// {
					// 	name: 'cms.campaigns',
					// 	title: 'Campaigns',
					// 	alerts: 0,
					// 	path: '/cms/campaigns',
					// 	icon: 'mdi-star',
					// 	disabled: false
					// },
					{
						name: 'cms.productUpdates',
						title: 'Product Updates',
						alerts: 0,
						path: '/cms/product-updates',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'cms.banners',
						title: 'Banners',
						alerts: 0,
						path: '/cms/banners',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'cms.changelogs',
						title: 'Changelogs',
						alerts: 0,
						path: '/cms/changelogs',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'cms.courses',
						title: 'Courses',
						alerts: 0,
						path: '/cms/courses',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'cms.cache',
						title: 'Cache',
						alerts: 0,
						path: '/cms/cache',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'cms.airMails',
						title: 'AirMails',
						alerts: 0,
						path: '/cms/air-mails',
						icon: 'mdi-mail',
						disabled: false
					}
				]
			};
		}
	};
</script>

<style></style>
