import { $http } from '@/utils';

/**
 * @namespace Service
 * @class CompletionUpload
 * @exports Service/Source/CompletionUpload
 * @description API CompletionUpload Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @@name get
	 * @description get a new completion upload resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`completion/upload/${id}`),

	/**
	 * @@name add
	 * @description Add completion upload
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`completion/upload`, payload),

	/**
	 * @@name list
	 * @description Get all completion uploads
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`completion/upload/list`, payload),

	/**
	 * @@name modify
	 * @description Update some details for ID
	 * @param {String} id
	 * @param {Object} payload
	 */
	modify: (id, payload) => $http.patch(`completion/upload/${id}`, payload),

	/**
	 * @@name delete
	 * @description Delete completion upload for ID
	 * @param {String} id
	 */
	delete: (id) => $http.delete(`completion/upload/${id}`)
};
