import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Download
 * @exports Service/Cms/Download
 * @description API Download Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get all downloads as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`cms/download/list`, payload),

	/**
	 * @name get
	 * @description Get download
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`cms/download/${id}`),

	/**
	 * @name update
	 * @description Update download details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	update: (id, payload) => $http.put(`cms/download/${id}`, payload),

	/**
	 * @name modify
	 * @description Update some download details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`cms/download/${id}`, payload),

	/**
	 * @name delete
	 * @description Hard delete download details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	delete: (id) => $http.delete(`cms/download/${id}`),

	/**
	 * @name add
	 * @description Add a new download resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`cms/download`, payload),

	/**
	 * @name addTag
	 * @description Associate a tag with a download resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	addTag: (id, payload) => $http.post(`cms/download/${id}/tag`, payload),

	/**
	 * @name add
	 * @description Delete the association between a tag and a download resource
	 * @param {String} id
	 * @param {String} tagId
	 * @returns {Object} Promise a http response
	 */
	deleteTag: (id, tagId) => $http.delete(`cms/download/${id}/tag/${tagId}`)
};
