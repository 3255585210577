import { CmsDownload } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getDownloadList
		 * @param {String} id
		 */
		getDownloadList(context, payload) {
			return CmsDownload.list(payload);
		},

		/**
		 * @async @name getDownload
		 * @param {String} id
		 */
		getDownload(context, id) {
			return CmsDownload.get(id);
		},

		/**
		 * @async @name getDownload
		 * @param {String} id
		 */
		modifyDownload(context, { id, payload }) {
			return CmsDownload.modify(id, payload);
		},

		/**
		 * @async @name addDownload
		 */
		deleteDownload(context, id) {
			return CmsDownload.delete(id);
		},

		/**
		 * @async @name addDownload
		 */
		addDownload(context, payload) {
			return CmsDownload.add(payload);
		},

		/**
		 * @async @name addTag
		 */
		addTag(context, { id, payload }) {
			return CmsDownload.addTag(id, payload);
		},

		/**
		 * @async @name deleteTag
		 */
		deleteTag(context, { id, tagId }) {
			return CmsDownload.deleteTag(id, tagId);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
