/**
 * @namespace Router
 * @exports Router
 * @description Routes for system
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

import Cms from '@/components/app/cms';
import Events from '@/components/app/cms/events';
import EventDetails from '@/components/app/cms/events/details';
import News from '@/components/app/cms/news';
import NewsDetails from '@/components/app/cms/news/details';
import Insight from '@/components/app/cms/insights-hub';
import InsightDetails from '@/components/app/cms/insights-hub/details';
import Media from '@/components/app/cms/media';
import MediaDetails from '@/components/app/cms/media/details';
import Downloads from '@/components/app/cms/downloads';
import DownloadDetails from '@/components/app/cms/downloads/details';
import Providers from '@/components/app/cms/providers';
import ProviderDetails from '@/components/app/cms/providers/details';
import ProviderDetailsDetails from '@/components/app/cms/providers/details/details';
import ProviderDetailsUsers from '@/components/app/cms/providers/details/users';
import ProviderDetailsUserDetails from '@/components/app/cms/providers/details/users/details';
import ProductUpdates from '@/components/app/cms/product-updates';
import ProductUpdateDetails from '@/components/app/cms/product-updates/details';
import Deals from '@/components/app/cms/deals';
import Assets from '@/components/app/cms/assets';
import AssetDetails from '@/components/app/cms/assets/details';
import Banners from '@/components/app/cms/banners';
import BannerList from '@/components/app/cms/banners/list';
import BannerDetails from '@/components/app/cms/banners/details';
import Changelog from '@/components/app/cms/changelog';
import ChangelogDetails from '@/components/app/cms/changelog/details';
import Course from '@/components/app/cms/courses';
import CourseDetails from '@/components/app/cms/courses/details';
import Cache from '@/components/app/cms/cache';
import AirMails from '@/components/app/cms/air-mail';
import AirMailDetails from '@/components/app/cms/air-mail/details';

export default [
	{
		name: 'cms',
		path: '/cms',
		component: Cms,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.events',
		path: '/cms/events',
		component: Events,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Events' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.events.detail',
		path: '/cms/event/:id?',
		component: EventDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Events', to: '/cms/events' }, { text: 'Event Details' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.downloads',
		path: '/cms/downloads',
		component: Downloads,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Resources' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.downloads.detail',
		path: '/cms/download/:id?',
		component: DownloadDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Resources', to: '/cms/downloads' }, { text: 'Download Details' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.news',
		path: '/cms/news-list',
		component: News,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'News' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.news.detail',
		path: '/cms/news/:id?',
		component: NewsDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'News', to: '/cms/news-list' }, { text: 'News Details' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.insight',
		path: '/cms/insights-hub',
		component: Insight,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Insights Hub' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.insight.detail',
		path: '/cms/insight/:id?',
		component: InsightDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Insights Hub', to: '/cms/insights-hub' }, { text: 'Insight Details' }],
			groups: ['administrator.cms']
		}
	},
	/*{
		name: 'cms.articles',
		path: '/cms/articles',
		component: Articles,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'On Air Articles' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.article.detail',
		path: '/cms/article/:id?',
		component: ArticleDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'On Air Articles', to: '/cms/articles' }, { text: 'Article Details' }],
			groups: ['administrator.cms']
		}
	},*/
	{
		name: 'cms.media',
		path: '/cms/media-list',
		component: Media,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Media' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.media.detail',
		path: '/cms/media/:id?',
		component: MediaDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Media', to: '/cms/media-list' }, { text: 'Media Details' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.asset',
		path: '/cms/assets',
		component: Assets,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Assets' }]
		}
	},
	{
		name: 'cms.asset.detail',
		path: '/cms/asset/:id?',
		component: AssetDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Assets', to: '/cms/assets' }, { text: 'Asset Details' }]
		}
	},
	{
		name: 'cms.providers',
		path: '/cms/product-providers',
		component: Providers,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Product Providers' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.providers',
		path: '/cms/product-provider/:id',
		component: ProviderDetails,
		meta: {
			breadcrumb: [
				{ text: 'Dashboard', to: '/' },
				{ text: 'CMS', to: '/cms' },
				{ text: 'Product Providers', to: '/cms/product-providers' }
			],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.providers',
		path: '/cms/product-provider/:id/details',
		component: ProviderDetailsDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Product Providers', to: '/cms/product-providers' }, { text: 'Product Provider Details' }, { text: 'Details' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.providers',
		path: '/cms/product-provider/:id/users',
		component: ProviderDetailsUsers,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Product Providers', to: '/cms/product-providers' }, { text: 'Product Provider Details' }, { text: 'Users' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.providers',
		path: '/cms/product-provider/:id/user/:userId?',
		component: ProviderDetailsUserDetails,
		meta: {
			breadcrumb: [
				{ text: 'Dashboard', to: '/' },
				{ text: 'CMS', to: '/cms' },
				{ text: 'Product Providers', to: '/cms/product-providers' },
				{ text: 'Product Provider Details' },
				{ text: 'Users' },
				{ text: 'User Details' }
			],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.productUpdates',
		path: '/cms/product-updates',
		component: ProductUpdates,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Product Updates' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.productUpdate',
		path: '/cms/product-update/:id?',
		component: ProductUpdateDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Product Updates', to: '/cms/product-updates' }, { text: 'Product Update Details' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.deals',
		path: '/cms/deals',
		component: Deals,
		meta: {
			breadcrumb: [
				{ text: 'Dashboard', to: '/' },
				{ text: 'CMS', to: '/cms' },
				{ text: 'Deals', to: '/cms/deals' }
			],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.banners',
		path: '/cms/banners',
		component: Banners,
		meta: {
			breadcrumb: [
				{ text: 'Dashboard', to: '/' },
				{ text: 'CMS', to: '/cms' },
				{ text: 'Banners', to: '/cms/banners' }
			],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.banners.list',
		path: '/cms/banners/:bannerTypeId',
		component: BannerList,
		meta: {
			breadcrumb: [
				{ text: 'Dashboard', to: '/' },
				{ text: 'CMS', to: '/cms' },
				{ text: 'Banners', to: '/cms/banners' }
			],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.banners.detail',
		path: '/cms/banner/:bannerTypeId/:id?',
		component: BannerDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Banners', to: '/cms/banners' }, { text: 'Banner Details' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.changelog',
		path: '/cms/changelogs',
		component: Changelog,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Changelogs' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.changelog.detail',
		path: '/cms/changelog/:id?',
		component: ChangelogDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Changelog', to: '/cms/changelogs' }, { text: 'Changelog Details' }],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.course',
		path: '/cms/courses',
		component: Course,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Courses' }]
		}
	},
	{
		name: 'cms.course.detail',
		path: '/cms/course/:id?',
		component: CourseDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'Courses', to: '/cms/courses' }, { text: 'Course Details' }]
		}
	},
	{
		name: 'cms.cache',
		path: '/cms/cache',
		component: Cache,
		meta: {
			breadcrumb: [
				{ text: 'Dashboard', to: '/' },
				{ text: 'CMS', to: '/cms' },
				{ text: 'Cache', to: '/cms/cache' }
			],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.airMails',
		path: '/cms/air-mails',
		component: AirMails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'AirMails'}],
			groups: ['administrator.cms']
		}
	},
	{
		name: 'cms.airMail.detail',
		path: '/cms/air-mail/:id?',
		component: AirMailDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'CMS', to: '/cms' }, { text: 'AirMails', to: '/cms/air-mails' }, { text: 'AirMail Details' }]
		}
	}
];
