<template>
	<div class="pa-8">
		<v-chip-group v-model="selectedFilter" mandatory active-class="success" dark>
			<v-chip v-for="filter in filters" :key="filter.value" label :value="filter.value">
				{{ filter.name }}
			</v-chip>
		</v-chip-group>

		<div class="d-flex">
			<validation-observer ref="observer" class="pa-0 col-12">
				<validation-provider v-slot="{ errors }" name="Search" rules="search">
					<v-text-field v-model="searchTerm" placeholder="Search" append-icon="mdi-magnify" dense solo light clear-icon="mdi-close-circle" clearable :error-messages="errors" @click:clear="searchTerm = ''" />
				</validation-provider>
			</validation-observer>
		</div>

		<v-data-table :headers="headers" :items="data" disable-pagination class="elevation-1 mt-3" :loading="loading" :no-results-text="'No results found'" :no-data-text="'Search for a user'" hide-default-footer>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.updated="{ item }">
				{{ item.updated | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn v-if="item.twoFactor" small dark class="ml-2" color="amber" @click="clearTwoFactorAuth(item.account_id)"> Remove 2FA </v-btn>
					<v-btn v-if="!item.locked" small dark class="ml-2" color="amber" :loading="locking[item.id]" @click="lockUser(item.id)"> Lock </v-btn>
					<v-btn v-if="item.locked" small dark class="ml-2" color="green" :loading="locking[item.id]" @click="unlockUser(item.id)"> Unlock </v-btn>
					<v-btn small class="ml-2" color="success" :to="`/user/${item.id}`"> edit </v-btn>
					<v-btn small dark class="ml-2" color="error" :loading="deleting[item.id]" @click="deleteItem(item.id)"> delete </v-btn>
				</div>
			</template>
		</v-data-table>

		<dynamic-form-dialog ref="dynamicFormDialog" @dynamic-form-submit="submitDelete" />
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import { debounce } from 'lodash';
	import DynamicFormDialog from '@/components/common/dialog/dynamic-form-dialog';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';

	export default {
		name: 'app-products',

		components: {
			DynamicFormDialog,
			CommonDialogConfirm,
			ValidationObserver,
			ValidationProvider
		},

		data: () => ({
			loading: false,
			selectedFilter: 'all',
			searchTerm: '',
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Company', value: 'organisation.name' },
				{ text: 'Created', value: 'created' },
				{ text: 'Last Updated', value: 'updated' },
				{ text: '', value: 'actions', sortable: false }
			],
			filters: [
				{ name: 'All', value: 'all' },
				{ name: 'Club Requests', value: 'club' },
				{ name: 'Academy Requests', value: 'academy' }
			],
			data: [],
			locking: {},
			deleting: {},
			submitting: false,
			itemToDelete: null
		}),

		computed: {
			...mapGetters('CmsForm', ['form'])
		},

		watch: {
			async searchTerm() {
				const valid = await this.$refs.observer.validate();
				if(!valid) return;
				if (this.selectedFilter === 'all') this.debouncedGetUserList();
				else this.debouncedGetApplicationList(this.selectedFilter);
			},

			selectedFilter(val) {
				if (val === 'all') this.getUserList();
				else this.getApplicationList(val);
			}
		},

		created() {
			this.loadForm('manager_user_delete');
		},

		methods: {
			...mapActions('AccountUser', ['fetchUserList', 'modifyUser', 'modifyUserAccount', 'deleteUser']),
			...mapActions('AppMembership', ['fetchApplicationList']),
			...mapActions('CmsForm', ['loadForm']),

			async getUserList() {
				if (this.loading) return;

				if (!this.searchTerm || this.searchTerm?.length < 3) {
					this.data = [];
					return;
				}

				this.loading = true;
				let payload = {
					where: [
						{ type: 'advisor' },
						'&&',
						'(',
						{ '"user".name': { c: 'ILIKE', v: `%${this.searchTerm}%` } },
						'||',
						{ '"user_detail".email': { c: 'ILIKE', v: `%${this.searchTerm}%` } },
						'||',
						{ '"organisation_communication".identifier': { c: 'ILIKE', v: `%${this.searchTerm}%` } },
						'||',
						{ '"organisation".name': { c: 'ILIKE', v: `%${this.searchTerm}%` } },
						'||',
						{ "\"organisation\".data->'membership'->>'fca'": { c: 'ILIKE', v: `%${this.searchTerm}%` } },
						'||',
						{ '"organisation_address".postal_code': { c: 'ILIKE', v: `%${this.searchTerm}%` } },
						')'
					]
				};
				let { data } = await this.fetchUserList(payload);
				this.data = data.data;
				this.loading = false;
			},

			async getApplicationList() {
				let type = this.selectedFilter;
				if (this.loading) return;

				this.loading = true;
				let payload = { where: { type, '"user".name': { c: 'ILIKE', v: `%${this.searchTerm}%` } } };
				let { data } = await this.fetchApplicationList(payload);
				this.data = data.data;
				this.loading = false;
			},

			/**
			 * @name debouncedfetchMediaList
			 * @description Load media with debounce
			 */
			debouncedGetUserList: debounce(function () {
				// this.options.page = 1;
				this.getUserList();
			}, 1000),

			/**
			 * @name debouncedfetchMediaList
			 * @description Load media with debounce
			 */
			debouncedGetApplicationList: debounce(function () {
				// this.options.page = 1;
				this.getApplicationList();
			}, 1000),

			lockUser(id) {
				this.modifyUser({
					id,
					data: {
						locked: true
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'User has been locked successfully');
						if (this.selectedFilter === 'all') this.getUserList();
						else this.getApplicationList(this.selectedFilter);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to lock user'))
					.finally(() => (this.submitting = false));
			},

			unlockUser(id) {
				this.modifyUser({
					id,
					data: {
						locked: false
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'User has been unlocked successfully');
						if (this.selectedFilter === 'all') this.getUserList();
						else this.getApplicationList(this.selectedFilter);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to unlock user'))
					.finally(() => (this.submitting = false));
			},

			clearTwoFactorAuth(id) {
				this.$refs.confirm
					.open('Remove users two factor authentication', `Are you sure you wish to remove this users two factor authentication?`)
					.then(() => {
						this.modifyUserAccount({
							id,
							data: {
								twoFactor: false
							}
						})
							.then(() => {
								const index = this.data.findIndex((i) => i.account_id === id);
								this.$set(this.data, index, { ...this.data[index], twoFactor: false });
								ElementTools.fireNotification(this.$el, 'success', 'Two factor authentication has been removed successfully');
							})
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to delete two factor authentication'))
							.finally(() => (this.submitting = false));
					})
					.catch(() => {});
			},

			deleteItem(id) {
				this.itemToDelete = id;
				this.$refs.dynamicFormDialog.open({
					formSchema: this.form('manager_user_delete').value,
					title: 'Delete User'
				});
			},

			async submitDelete({ data }) {
				const id = this.itemToDelete;

				if (!id && !data.note) {
					return;
				}
				await this.deleteUser({
					id,
					data: {
						data: {
							deleted: {
								note: data.note
							}
						}
					}
				}).then(() => {
					ElementTools.fireNotification(this.$el, 'success', 'User has been deleted successfully');
					if (this.selectedFilter === 'all') this.getUserList();
					else this.getApplicationList(this.selectedFilter);
				})
				.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to delete user'))
				.finally(() => {
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep ul.v-pagination {
		justify-content: end !important;
	}
</style>
