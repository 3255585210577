import { CmsAirMail } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getAirMailList
		 * @param {String} id
		 */
		getAirMailList(context, payload) {
			return CmsAirMail.list(payload);
		},

		/**
		 * @async @name getAirMail
		 * @param {String} id
		 */
		getAirMail(context, id) {
			return CmsAirMail.get(id);
		},

		/**
		 * @async @name getAirMail
		 * @param {String} id
		 */
		modifyAirMail(context, { id, payload }) {
			return CmsAirMail.modify(id, payload);
		},

		/**
		 * @async @name addAirMail
		 */
		deleteAirMail(context, id) {
			return CmsAirMail.delete(id);
		},

		/**
		 * @async @name addAirMail
		 */
		addAirMail(context, payload) {
			return CmsAirMail.add(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
