<template>
	<div>
		<v-container class="pt-8">
			<v-row dense>
				<v-col cols="12">
					<!-- <common-base-tabs :tabs="routes" :loading="false" router-view dark /> -->

					<v-list dark class="ma-0 pa-0">
						<v-list-item v-for="item in filteredRoutes" :key="item.path" :to="item.path" :disabled="item.disabled" dense class="my-1 primary">
							<v-list-item-content>
								{{ item.title }}
							</v-list-item-content>
							<v-list-item-action class="my-0">
								<v-icon>
									{{ item.icon }}
								</v-icon>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		name: 'app-admin',

		data() {
			return {
				routes: [
					{
						name: 'admin.api-health',
						title: 'API Health',
						alerts: 0,
						path: '/admin/api-health',
						icon: 'mdi-traffic-light',
						disabled: false
					},
					{
						name: 'admin.api-logs',
						title: 'Api Logs',
						alerts: 0,
						path: '/admin/api-logs',
						icon: 'mdi-list-box',
						disabled: false
					},
					{
						name: 'admin.api-users',
						title: 'API Users',
						alerts: 0,
						path: '/admin/api-users',
						icon: 'mdi-account-multiple',
						disabled: false
					},
					{
						name: 'admin.notifications',
						title: 'Notifications',
						alerts: 0,
						path: '/admin/notifications',
						icon: 'mdi-calendar',
						disabled: false
					},
					{
						name: 'admin.completion-uploads',
						title: 'Completion Uploads',
						alerts: 0,
						path: '/admin/completion-uploads',
						icon: 'mdi-upload',
						disabled: false
					},
					{
						name: 'admin.users',
						title: 'Users',
						alerts: 0,
						path: '/admin/users',
						icon: 'mdi-account-multiple',
						disabled: false
					},
					{
						name: 'admin.roles',
						title: 'Roles',
						alerts: 0,
						path: '/admin/roles',
						icon: 'mdi-account-lock',
						disabled: false
					},
					{
						name: 'admin.groups',
						title: 'Groups',
						alerts: 0,
						path: '/admin/groups',
						icon: 'mdi-format-list-group',
						disabled: false
					},
					{
						name: 'admin.rate-update',
						title: 'Rate Update',
						alerts: 0,
						path: '/admin/rate-update',
						icon: 'mdi-clock-check-outline',
						disabled: false
					},
					{
						name: 'admin.loan-to-value-update',
						title: 'Loan To Value Update',
						alerts: 0,
						path: '/admin/loan-to-value-update',
						icon: 'mdi-clock-check-outline',
						disabled: false
					},
					{
						name: 'admin.mandatory-payments-rate-update',
						title: 'Mandatory Payments Rate Update',
						alerts: 0,
						path: '/admin/mandatory-payments-rate-update',
						icon: 'mdi-clock-check-outline',
						disabled: false
					},
					{
						name: 'admin.partner-environment',
						title: 'Partner Environments',
						alerts: 0,
						path: '/admin/partner-environment',
						icon: 'mdi-server-network',
						disabled: false
					},
					{
						name: 'admin.unclaimed-rewards',
						title: 'Unclaimed Rewards',
						alerts: 0,
						path: '/admin/unclaimed-rewards',
						icon: 'mdi-gift',
						disabled: false	
					}
				]
			};
		},

		computed: {
			...mapState('Account', ['groups']),

			filteredRoutes() {
				return !this.groups ? [] : this.routes.filter((item) => !item.groups || item.groups.some((group) => this.groups.includes(group)));
			}
		}
	};
</script>

<style></style>
