<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>{{ bannerId ? `Banner #${bannerId}` : `New Banner` }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/cms/banners/${bannerTypeId}`">
				Back to banners
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="form('manager_banner').value" :form-data="formData" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-products-details',
		components: {
			DynamicForm
		},

		data: () => ({
			formData: {},
			loading: false,
			submitting: false
		}),

		computed: {
			...mapGetters('CmsForm', ['form']),

			bannerId() {
				return this.$route.params.id || this.formData.id;
			},

			bannerTypeId() {
				return this.$route.params.bannerTypeId;
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsBanner', ['getBanner', 'addBanner', 'modifyBanner']),

			async init() {
				this.$route.meta.breadcrumb[3].text = this.bannerId ? `Banner #${this.bannerId}` : 'New Banner';

				this.loading = true;
				await this.loadForm('manager_banner');
				this.loading = false;

				if (this.bannerId) {
					this.loading = true;

					this.getBanner(this.bannerId)
						.then(({ data }) => {
							this.setFormData(data);
						})
						.finally(() => (this.loading = false));
				}
			},

			setFormData(data) {
				this.formData = {
					...data,
					startDate: data.scheduled ? this.$moment(data.scheduled).format('DD/MM/YYYY') : null,
					endDate: data.ending ? this.$moment(data.ending).format('DD/MM/YYYY') : null
				};
			},

			submit() {
				this.submitting = true;

				const payload = {
					title: this.formData.title,
					scheduled: this.formData.startDate ? this.formatDate(this.formData.startDate) : null,
					ending: this.formData.endDate ? this.formatDate(this.formData.endDate) : null,
					bannerTypeId: this.bannerTypeId,
					image: this.formData.files?.[0]?.s3Key || this.formData.image,
					url: this.formData.url
				};

				const promise = this.bannerId ? this.modifyBanner({ id: this.bannerId, payload }) : this.addBanner(payload);
				promise
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Banner has been submitted successfully');
						this.$router.push(`/cms/banners/${this.bannerTypeId}`);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit banner'))
					.finally(() => (this.submitting = false));
			},

			formatDate(date) {
				const [day, month, year] = date.split('/');
				return new Date([`${month}/${day}/${year}`]).toISOString();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.wrapper {
		background: #fff;
	}
</style>
