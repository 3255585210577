import { $http } from '@/utils';

/**
 * @namespace Service
 * @class User
 * @exports Service/Account/User
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get all users
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`account/user/list`, payload),

	/**
	 * @name get
	 * @description Get user
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`account/user/${id}`),

	/**
	 * @static @name modify
	 * @description Update some User details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`account/user/${id}`, payload),

	/**
	 * @name delete
	 * @description Hard delete user details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	delete: (id, payload) => $http.post(`account/user/delete/${id}`, payload),

	/**
	 * @name add
	 * @description Add a new user
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`account/user`, payload),

	/**
	 * @name add
	 * @description Add a new api user
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	addApiUser: (payload) => $http.post(`account/user/api`, payload),

	/**
	 * @static @name addRole
	 * @description Add user to a role
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	addUserToRole: (id, payload) => $http.post(`account/user/${id}/role`, payload),

	/**
	 * @static @name addUserGroups
	 * @description Add user groups
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	addUserGroups: (id, payload) => $http.post(`account/user/${id}/group`, payload)
};
