import { AccountOrganisationTradingStyle } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name fetchTradingStyleList
		 * @param {Object} context
		 * @param {Object} payload
		 */
		fetchTradingStyleList(context, payload) {
			return AccountOrganisationTradingStyle.list(payload);
		},

		/**
		 * @name approveTradingStyle
		 * @param {String} id
		 * @param {Object} data
		 */
		approveTradingStyle(context, id) {
			return AccountOrganisationTradingStyle.modify(id, { approved: new Date() });
		},

		/**
		 * @name rejectTradingStyle
		 * @param {String} id
		 * @param {Object} data
		 */
		rejectTradingStyle(context, id) {
			return AccountOrganisationTradingStyle.delete(id);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
