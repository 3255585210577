<!--
	@name app-login
	@description Register page
	@date 2020/05/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-card v-if="!organisations.length" class="mx-auto mt-10" max-width="500">
			<h2 class="text-h5 text-center font-weight-bold pt-6 pb-5">
				Manager Log In
			</h2>

			<v-alert v-if="dispalyHint" class="mx-5 mt-2 mb-4 subtitle-2" type="info" dense outlined text>
				HINT - both username and password are case sensitive
			</v-alert>

			<v-sheet class="body pa-5 pt-0">
				<dynamic-form
					ref="loginForm"
					:form-schema="form('login').value"
					:form-data="formData"
					:submit-button-loading="loading"
					submit-button-text="Log in"
					:submit-button-block="true"
					:submit-button-large="true"
					:required-icon="false"
					@dynamic-form-submit="submit"
				/>
			</v-sheet>
		</v-card>
	</div>
</template>

<script>
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { mapGetters, mapState, mapActions } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-login',

		components: { DynamicForm },

		data() {
			return {
				dispalyHint: false,
				formData: {},
				twoFactorFormData: {},
				loading: false,
				organisations: []
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapGetters('CmsForm', ['form'])
		},

		created() {
			this.loadForm('manager-login');
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('Account', ['login']),

			async submit() {
				this.dispalyHint = false;
				this.loading = true;

				try {
					await this.login({ ...this.formData, ...this.twoFactorFormData });
					this.$router.push('/');
				} catch (e) {
					let error = e.response.data;
					if (error && error.organisations) return (this.organisations = error.organisations);
					ElementTools.fireNotification(this.$el, 'error', error.message || 'Login failed, please try again');
					this.dispalyHint = true;
					this.loading = false;
				}
			},

			async submitWithOrg(organisationID) {
				this.dispalyHint = false;
				this.loading = true;

				try {
					await this.login({ ...this.formData, organisationID });
					this.$router.push('/account');
				} catch (err) {
					if (err.data && err.data.organisations) return (this.organisations = err.data.organisations);
					ElementTools.fireNotification(this.$el, 'error', 'Login failed, please try again');
					this.dispalyHint = true;
					this.loading = false;
				}
			}
		}
	};
</script>
