import { IterationMessage } from '@/service';

/**
 * @class @name
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name fetchMessageList
		 * @param {function} commit
		 * @param {Object} payload
		 */
		fetchMessageList(context, payload) {
			return IterationMessage.list(payload);
		},

        /**
		 * @async @name createNewMessage
		 * @param {function} commit
		 * @param {Object} payload
		 */
		createNewMessage(context, payload) {
			return IterationMessage.add(payload);
		},

        /**
		 * @name deleteIterationMessage
		 * @param {function} commit
		 * @param {Number} messageId
		 */
		deleteIterationMessage(context, messageId) {
			return IterationMessage.delete(messageId);
		}
	}
};
