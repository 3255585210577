<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>User #{{ userId }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/user/${userId}`">
				Back to user
			</v-btn>
		</v-toolbar>

		<v-alert v-if="restricted" text outlined type="error" border="left" class="ma-0">
			<span class="text-body-2">Fact Find's not available for provider preview account</span>
		</v-alert>

		<div v-else>
			<v-pagination v-model="options.page" :length="pages" total-visible="7" :disabled="loading" />

			<v-data-table
				:headers="headers"
				:items="items"
				class="elevation-1 mt-3"
				:options.sync="options"
				:server-items-length="total"
				:loading="loading"
				:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [5, 10, 15, 20, 25] }"
			>
				<template #item.updated="{ item }">
					{{ item.updated | moment('Do MMM YYYY, HH:mm') }}
				</template>

				<template #item.created="{ item }">
					{{ item.created | moment('Do MMM YYYY, HH:mm') }}
				</template>

				<template #[`item.actions`]="{ item }">
					<v-btn small color="primary" class="d-block mt-1" :disabled="!item.deleted" :loading="restoring[item.id]" @click="restoreItem(item)">
						Restore
					</v-btn>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-users-details-write-route',

		data() {
			return {
				user: null,
				items: [],
				loading: false,
				restoring: {},
				options: {},
				total: 0,
				pages: 0,
				headers: [
					{ text: 'Reference', value: 'reference', sortable: false },
					{ text: 'Progress', value: 'progress', sortable: false },
					{ text: 'Version', value: 'version', sortable: false },
					{ text: 'Created', value: 'created' },
					{ text: 'Modified', value: 'updated', sortable: false },
					{ text: '', value: 'actions', sortable: false }
				]
			};
		},

		computed: {
			userId() {
				return this.$route.params.id;
			},

			restricted() {
				return this.user?.organisation.type === 'provider';
			}
		},

		watch: {
			options: {
				handler() {
					this.getFactFindList();
				},
				deep: true
			}
		},

		created() {
			this.$route.meta.breadcrumb[2] = {
				text: `User #${this.userId}`,
				to: `/user/${this.userId}`
			};

			this.getUser();
		},

		methods: {
			...mapActions('AccountUser', ['fetchUser']),
			...mapActions('AppMatter', ['fetchFactFindList', 'modifyMatter']),

			getUser() {
				this.fetchUser(this.userId).then(({ data }) => {
					this.user = data;
				});
			},

			async getFactFindList() {
				if (this.loading) return;

				this.loading = true;
				const { sortBy, sortDesc, page, itemsPerPage } = this.options;

				const payload = {
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					order: sortBy.length > 0 && {
						property: sortBy[0],
						direction: sortDesc[0] ? 'desc' : 'asc'
					},
					where: { 'matter.user_id': this.userId }
				};

				let { data } = await this.fetchFactFindList(payload);
				this.items = data.data;
				this.total = data.total;
				this.pages = Math.ceil(this.total / this.options.itemsPerPage);
				this.loading = false;
			},

			restoreItem(item) {
				this.restoring[item.id] = true;
				this.modifyMatter({ id: item.id, payload: { deleted: null } })
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Factfind has been restored successfully');
						this.getFactFindList();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to restore factfind'))
					.finally(() => (this.restoring[item.id] = false));
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep ul.v-pagination {
		justify-content: end !important;
	}
</style>
