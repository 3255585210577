<template>
	<common-dialog ref="messageDialog" :max-width="800">
		<template #header>
			Messages
		</template>

		<template #body>
			<div v-if="toggleNewMessage">
				<validation-observer ref="observer" v-slot="{ validate }">
					<v-form ref="form" class="pt-4" @submit.prevent="validate().then(addMessage)">
						<validation-provider v-slot="{ errors }" name="Message" rules="required">
							<v-textarea v-model="messageModel" label="Message" :error-messages="errors" outlined required />
						</validation-provider>
						<div class="d-flex justify-end">
							<v-btn color="success" type="submit" :loading="submittingMessage">
								Submit
							</v-btn>
							<v-btn color="error" class="ml-2" @click="toggleNewMessage = false">
								Cancel
							</v-btn>
						</div>
					</v-form>
				</validation-observer>
			</div>
			<div v-else>
				<v-list v-if="messages?.length" class="ma-0 pa-0">
					<v-sheet v-for="(item, index) in messages" :key="index" rounded elevation="0" class="yellow lighten-5 mt-4">
						<v-list-item>
							<v-list-item-content>
								<p class="text-caption grey--text text--darken-3 font-weight-medium mb-0">
									{{ item.created | moment('Do MMM YYYY, HH:mm') }}
								</p>
								<p class="text-caption mb-0 grey--text text--darken-1">
									{{ item.message }}
								</p>
							</v-list-item-content>
							<v-list-item-action class="my-0">
								<v-btn color="error" :loading="deleting[item.created]" icon @click="deleteMessage(item)">
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-sheet>
				</v-list>
				<div v-else>
					<v-sheet class="text-center pa-4" elevation="0">
						<v-icon class="display-4 grey--text">
							mdi-comment-text-outline
						</v-icon>
						<p class="text-caption grey--text text--darken-2 font-weight-medium mb-0">
							No messages has been created for this KFI Request
						</p>
					</v-sheet>
				</div>
				<v-btn color="success" small class="mt-4" @click="toggleNewMessage = true">
					+ Add New Message
				</v-btn>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import CommonDialog from '@/components/common/dialog';

	export default {
		name: 'app-requests-message-dialog',

		components: {
			CommonDialog,
			ValidationObserver,
			ValidationProvider
		},

		data: () => ({
			iterationId: null,
			messages: [],
			messageModel: '',
			submittingMessage: false,
			toggleNewMessage: false,
			deleting: {}
		}),

		methods: {
			...mapActions('AppMatterIterationMessage', ['createNewMessage', 'deleteIterationMessage']),

			open(iterationId, messages) {
				this.iterationId = iterationId;
				this.messages = messages;
				this.messageModel = '';
				this.toggleNewMessage = false;
				this.$refs.messageDialog.open();
			},

			addMessage() {
				this.submittingMessage = true;

				this.createNewMessage({
					iterationId: this.iterationId,
					message: this.messageModel
				})
					.then(() => {
						this.$emit('messages-updated', this.iterationId);
						ElementTools.fireNotification(this.$el, 'success', 'Message has been submitted successfully');
					})
					.finally(() => {
						this.submittingMessage = false;
					});
			},

			deleteMessage(message) {
				this.$set(this.deleting, message.created, true);

				this.deleteIterationMessage(message.id)
					.then(() => {
						const idx = this.messages.findIndex((msg) => msg.id === message.id);
						this.messages.splice(idx, 1);
						this.$emit('messages-updated', this.iterationId);
						ElementTools.fireNotification(this.$el, 'success', 'Message has been deleted successfully');
					})
					.finally(() => {
						this.$set(this.deleting, message.created, false);
					});
			}
		}
	};
</script>
