<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title class="font-weight-bold">
				{{ userId ? `User ${userId}` : 'Add New User' }}
			</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/admin/network/${networkId}/users`"> Back to users </v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters class="justify-center">
				<v-col cols="6" md="8" lg="8" xl="6">
					<v-stepper v-model="stepper" vertical>
						<v-stepper-step :complete="stepper > 1" step="1"> Select a Role </v-stepper-step>

						<v-stepper-content step="1">
							<v-sheet class="pa-6">
								<dynamic-form ref="dynamicForm" :form-schema="userRoleFormSchema" :form-data="userRoleFormData" :submit-button-loading="submitting" @dynamic-form-submit="submitUserRole" />
							</v-sheet>
						</v-stepper-content>

						<v-stepper-step :complete="stepper > 2" step="2"> Add User </v-stepper-step>

						<v-stepper-content step="2">
							<v-sheet class="pa-6">
								<dynamic-form
									ref="dynamicForm"
									:form-schema="form('manager_user').value"
									:form-data="formData"
									:submit-button-loading="submitting"
									:submit-button-disabled="!selectedDepartment"
									@dynamic-form-submit="submitAddUser"
								/>
							</v-sheet>
						</v-stepper-content>
					</v-stepper>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import DynamicForm from '@/components/common/base/dynamic-form';

	export default {
		name: 'app-users-details-details',

		components: {
			DynamicForm
		},

		data() {
			return {
				stepper: 1,
				formData: {},
				formSchema: [],
				userRoleFormData: {},
				userRoleFormSchema: [
					{
						type: 'select',
						name: 'userRole',
						rules: 'required',
						display: 'User Role',
						options: [{ text: 'Network', value: 'network.owner' }]
					}
				],
				departments: [
					{
						name: 'Network Owner (owner) Department',
						description: 'Network Department',
						value: 'network.owner'
					}
				],
				orgDepartments: [],
				loading: false,
				submitting: false,
				selectedDepartment: null
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form']),

			userId() {
				return this.$route.params.userId;
			},

			networkId() {
				return this.$route.params.id;
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('AccountUser', ['fetchUser', 'addUser', 'addUserDepartment', 'addUserToRole']),
			...mapActions('AccountDepartment', ['addDepartment', 'getDepartmentList', 'associateGroup']),
			...mapActions('AccountGroup', ['getGroupList']),
			...mapActions('AccountRole', ['getRoleList']),
			...mapActions('AccountOrganisation', ['getOrganisation']),
			...mapActions('CmsForm', ['loadForm']),

			async init() {
				this.$route.meta.breadcrumb[3] = {
					text: `Network #${this.networkId}`,
					to: `/admin/network/${this.networkId}`
				};

				this.$route.meta.breadcrumb[4] = {
					text: `Users`,
					to: `/admin/network/${this.networkId}/users`
				};

				this.$route.meta.breadcrumb[5] = {
					text: this.userId ? `User #${this.userId}` : 'Add New User'
				};

				this.loadForm('manager_user');
				this.getOrganisation(this.networkId).then(({ data }) => {
					this.organisation = data;
				});

				this.getDepartmentList({ where: { 'organisation.id': this.networkId } }).then(({ data }) => {
					this.orgDepartments = data.data;
				});

				if (this.userId) {
					this.loading = true;

					this.fetchUser(this.userId)
						.then(({ data }) => {
							this.formData = data;
						})
						.finally(() => (this.loading = false));
				}
			},

			submitUserRole() {
				let selectedUserRole = this.userRoleFormData.userRole;
				this.selectedDepartment = this.orgDepartments.find((department) => department.nameUnique.includes(selectedUserRole));

				if (!this.selectedDepartment) {
					this.addDepartment({
						organisationId: this.networkId,
						name: this.departments.find((department) => department.value === selectedUserRole).name,
						nameUnique: `${this.organisation.nameUnique}.${selectedUserRole}`,
						description: `${this.organisation.nameUnique} ${this.departments.find((department) => department.value === selectedUserRole).name} `,
						active: true
					}).then(({ data }) => {
						this.orgDepartments.push(data);
						this.selectedDepartment = data;
						this.getGroupList({ where: { name_unique: { type: 'text', value: [selectedUserRole] } } }).then(({ data: group }) => {
							this.associateGroup({
								id: data.id,
								payload: {
									departmentOrganisationId: this.networkId,
									groupId: group.data[0].id
								}
							});
							this.stepper = 2;
						});
					});
				} else this.stepper = 2;
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submitAddUser(data) {
				this.submitting = true;

				this.addUser({
					type: 'network',
					name: data.name,
					description: data.description,
					userIdentity: [{ identity: data.username }],
					userAccount: { password: data.password },
					userDetail: { email: data.email }
				})
					.then(({ data: user }) => {
						this.addUserDepartment({
							userId: user.id,
							departmentId: this.selectedDepartment.id,
							departmentOrganisationId: this.networkId
						}).then(() => {
							this.$router.push(`/admin/network/${this.networkId}/users`);
							ElementTools.fireNotification(this.$parent.$el, 'success', 'User has been added successfully');
						});
					})
					.catch(() => ElementTools.fireNotification(this.$parent.$el, 'error', 'Failed to add user'))
					.finally(() => (this.submitting = false));
			}
		}
	};
</script>

<style lang="scss" scoped></style>
