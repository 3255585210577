import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Configuration
 * @exports Service/Cms/Site
 * @description API Configuration Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name get
	 * @description Get Site
	 * @returns {Object} Promise a http response
	 */
	get: () => $http.get('cms/site'),

	/**
	 * @name getMembership
	 * @description Get membership site config
	 * @returns {Object} Promise a http response
	 */
	getMembership: (type) => $http.get(`cms/site/membership/${type}`)
};
