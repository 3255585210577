<template>
	<div class="pa-8">
		<v-chip-group v-model="selectedMatterType" mandatory active-class="success" dark>
			<v-chip v-for="option in matterTypeOptions" :key="option.value" label :value="option.value" :disabled="loading">
				{{ option.text }}
			</v-chip>
		</v-chip-group>

		<div class="d-flex mt-1">
			<validation-observer ref="observer" class="pa-0 col-6">
				<validation-provider v-slot="{ errors }" name="Search" rules="search">
					<v-text-field v-model="searchTerm" placeholder="Search" append-icon="mdi-magnify" dense solo light clear-icon="mdi-close-circle" clearable :error-messages="errors" @click:clear="searchTerm = ''" />
				</validation-provider>
			</validation-observer>
			<v-spacer />
			<v-pagination v-if="!!pageCount" v-model="options.page" :length="pageCount" total-visible="7" :disabled="loading" class="mt-n1" />
		</div>

		<v-data-table
			class="elevation-1"
			:headers="headers"
			:items="cases"
			:options.sync="options"
			:server-items-length="total"
			:loading="loading"
			no-data-text="No results found for your search"
			:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [5, 10, 15, 20, 25] }"
			disable-sort
			hide-default-footer
			item-key="iterationId"
		>
			<template #item.status="{ item }">
				<v-select :value="item.status" :items="statusOptions" @input="updateStatus($event, item)" />
			</template>

			<template #item.updated="{ item }">
				{{ item.updated | moment('Do MMM YYYY, HH:mm') }}
			</template>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>
			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn small color="primary" class="mr-2" :to="`/case/${item.id}?matterTypeId=${item.matterTypeId}&iterationId=${item.iterationId}&created=${item.created}`"> Access </v-btn>
					<!-- <v-btn small color="error">
						Delete
					</v-btn> -->
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { debounce } from 'lodash';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';

	export default {
		name: 'app-cases',

		components: {
			ValidationObserver,
			ValidationProvider
		},

		data: () => ({
			loading: false,
			searchTerm: '',
			options: {},
			total: 1,
			pageCount: 0,
			cases: [],
			selectedMatterType: 'conveyancing',
			headers: [
				{ text: 'Adviser', value: 'userName' },
				{ text: 'Client', value: 'clientName' },
				{ text: 'Type', value: 'matterTypeName' },
				{ text: 'Created', value: 'created' },
				{ text: 'Updated', value: 'updated' },
				{ text: 'Status', value: 'status', width: 200 },
				{ text: '', value: 'actions', sortable: false }
			],
			statusOptions: [
				{ text: 'Submitted', value: 'Submitted' },
				{ text: 'Declined', value: 'Declined' },
				{ text: 'Accepted', value: 'Accepted' },
				{ text: 'In Progress', value: 'In Progress' },
				{ text: 'Suspended', value: 'Suspended' },
				{ text: 'Completed', value: 'Completed' },
				{ text: 'Failed', value: 'Failed' }
			],
			matterTypeOptions: [
				{ text: 'Conveyancing', value: 'conveyancing' },
				{ text: 'Care Plans', value: 'care_plans' },
				{ text: 'Power of Attorney', value: 'power_of_attorney' },
				{ text: 'Wills', value: 'will' },
				{ text: 'Home Reversion Plan', value: 'home_reversion_plan' }
			]
		}),

		watch: {
			options: {
				handler() {
					this.fetchCases();
				},
				deep: true
			},

			async searchTerm() {
				const valid = await this.$refs.observer.validate();
				if(!valid) return;
				this.debouncedFetchCases(this.selectedFilter);
			},

			selectedMatterType() {
				this.options.page = 1;
				this.pageCount = 0;
				this.fetchCases();
			}
		},

		created() {},

		methods: {
			...mapActions('AppMatter', ['fetchCaseList']),
			...mapActions('AppMatterAction', ['addAction', 'fetchActionList']),

			/**
			 * @name fetchCases
			 * @description Load cases
			 */
			async fetchCases() {
				if (this.loading) return;

				this.cases = [];
				this.loading = true;
				const { page, itemsPerPage } = this.options;
				const payload = {
					limit: itemsPerPage,
					offset: (page - 1) * itemsPerPage,
					order: { property: 'action.updated', direction: 'DESC' },
					where: ['(', { 'matter_type.name_unique': this.selectedMatterType }, ')']
				};

				if (this.searchTerm)
					payload.where = [
						...payload.where,
						...[
							'&&',
							'(',
							{ 'client.name_given': { c: 'ILIKE', v: `%${this.searchTerm}%` } },
							'||',
							{ 'client.name_family': { c: 'ILIKE', v: `%${this.searchTerm}%` } },
							'||',
							{ 'user.name': { c: 'ILIKE', v: `%${this.searchTerm}%` } },
							')'
						]
					];

				const { data } = await this.fetchCaseList(payload);
				this.cases = data.data;
				this.pageCount = Math.ceil(data.total / itemsPerPage);
				this.total = data.total;
				this.loading = false;
			},

			/**
			 * @name debouncedFetchCases
			 * @description Debounced fetch cases
			 */
			debouncedFetchCases: debounce(function () {
				this.fetchCases();
			}, 1000),

			/**
			 * @name updateStatus
			 * @description Update status
			 */
			updateStatus(value, item) {
				this.addAction({
					iterationId: item.iterationId,
					iterationCreatedDate: item.created,
					type: 'status',
					data: { status: value }
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep ul.v-pagination {
		justify-content: end !important;
	}
</style>
