import { Membership, MembershipOrganisation } from '@/service';

/**
 * @class @name
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @name addMembership
		 * @param {Object} context
		 * @param {Object} payload
		 */
		addMembership(context, payload) {
			return Membership.add(payload);
		},

		/**
		 * @name fetchMembership
		 * @param {function} commit
		 * @param {String} id
		 */
		fetchMembership(context, id) {
			return Membership.get(id);
		},

		/**
		 * @name fetchMembershipList
		 * @param {function} commit
		 * @param {Object} payload
		 */
		fetchMembershipList(context, payload) {
			return Membership.list(payload);
		},

		/**
		 * @name modifyMembership
		 * @param {function} commit
		 * @param {Object} data
		 */
		modifyMembership(context, { id, data }) {
			return Membership.modify(id, data);
		},

		/**
		 * @name deleteMembership
		 * @param {function} commit
		 * @param {Object} data
		 */
		deleteMembership(context, id) {
			return Membership.delete(id);
		},

		/**
		 * @name fetchMembershipList
		 * @param {function} commit
		 * @param {Object} payload
		 */
		fetchApplicationList(context, payload) {
			return Membership.applicationList(payload);
		},

		/**
		 * @name findMembershipOrganisationList
		 * @param {function} commit
		 * @param {Object} payload
		 */
		findMembershipOrganisationList(context, payload) {
			return MembershipOrganisation.list(payload);
		},

		/**
		 * @name modifyMembershipOrganisation
		 * @param {function} commit
		 * @param {Object} payload
		 */
		modifyMembershipOrganisation(context, payload) {
			return MembershipOrganisation.upsert(payload);
		}
	}
};
