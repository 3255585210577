import { CmsChangelog } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getChangelogList
		 * @param {String} id
		 */
		getChangelogList(context, payload) {
			return CmsChangelog.list(payload);
		},

		/**
		 * @async @name getChangelog
		 * @param {String} id
		 */
		getChangelog(context, id) {
			return CmsChangelog.get(id);
		},

		/**
		 * @async @name modifyChangelog
		 * @param {String} id
		 */
		modifyChangelog(context, { id, payload }) {
			return CmsChangelog.modify(id, payload);
		},

		/**
		 * @async @name deleteChangelog
		 */
		deleteChangelog(context, id) {
			return CmsChangelog.delete(id);
		},

		/**
		 * @async @name addChangelog
		 */
		addChangelog(context, payload) {
			return CmsChangelog.add(payload);
		},

		/**
		 * @async @name addChangelogUser
		 */
		addChangelogUser(context, changelogId) {
			return CmsChangelog.addUser(changelogId);
		},

		/**
		 * @name getChangelogCategoryList
		 * @description Get all changelog categories as a list
		 * @returns {Object} Promise a http response
		 */
		getChangelogCategoryList(context, payload) {
			return CmsChangelog.changelogCategoryList(payload);
		},

		/**
		 * @async @name getChangelog
		 * @param {String} id
		 */
		getChangelogCategory(context, id) {
			return CmsChangelog.getChangelogCategory(id);
		},

		/**
		 * @name getChangelogCommentList
		 * @description Get all changelog comments as a list
		 * @returns {Object} Promise a http response
		 */
		getChangelogCommentList(context, payload) {
			return CmsChangelog.changelogCommentList(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
