import { $http } from '@/utils';

/**
 * @namespace Service
 * @class File
 * @exports Service/File
 * @description API File Service
 * @date 2023/01/19
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name getAsset
	 * @description Get presigned url for asset file
	 * @returns {Object} Promise a http response
	 */
	getAsset: (s3Key, filename) => $http.get(`asset?resource=${s3Key}&filename=${filename}`),

	/**
	 * @name invalidateCloudfrontCache
	 * @description Request invalidation for assets cache
	 * @returns {Object} Promise a http response
	 */
	invalidateAssetCache: () => $http.post(`cms/cache-rebuild/asset-invalidation`)
};
