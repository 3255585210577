import { Notification } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
        /**
		 * @async @name getNotificationList
		 * @param {String} id
		 */
		getNotificationList(context, payload) {
			return Notification.list(payload);
		},

		/**
		 * @async @name getNotification
		 * @param {String} id
		 */
		getNotification(context, id) {
			return Notification.get(id);
		},

        /**
		 * @async @name modifyNotification
		 * @param {String} id
		 */
		modifyNotification(context, { id, payload }) {
			return Notification.modify(id, payload);
		},

		/**
		 * @async @name addNotification
		 */
		addNotification(context, payload) {
			return Notification.add(payload);
		},

		/**
		 * @async @name addNotificationUser
		 */
		addNotificationUser(context, notificationId) {
			return Notification.addUser(notificationId);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
