import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Cache
 * @exports Service/Cms/Cache
 * @description API Cache Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name invalidateCloudfrontCache
	 * @description Send request to invalidate cloudfront cache
	 * @returns {Object} Promise a http response
	 */
	invalidateCloudfrontCache: (payload) => $http.post(`cms/cache-rebuild/invalidate`, payload),

	/**
	 * @name clearCache
	 * @description Send request to clear cache
	 * @returns {Object} Promise a http response
	 */
	clearCache: (operation) => $http.post(`cms/cache-rebuild/${operation}`),

	/**
	 * @name updateOptions
	 * @description Send request to update options
	 * @returns {Object} Promise a http response
	 */
	updateOptions: (option) => $http.post(`cms/cache-rebuild/update-options/${option}`),

	/**
	 * @name updatePanel
	 * @description Send request to update panel
	 * @returns {Object} Promise a http response
	 */
	updatePanel: (panel) => $http.post(`cms/cache-rebuild/update-panel/${panel}`)
};
