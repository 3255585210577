<template>
	<div class="pa-8">
		<div class="d-flex">
			<v-btn color="primary" dark @click="$refs['new-completion-upload-dialog']?.open()"> New Completion Upload </v-btn>
			<v-spacer />
			<v-pagination v-model="options.page" :length="pages" total-visible="7" :disabled="loading" />
		</div>
		<v-data-table
			:headers="headers"
			:items="items"
			class="elevation-1 mt-3"
			:options.sync="options"
			:server-items-length="total"
			:loading="loading"
			:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [5, 10, 15, 20, 25] }"
		>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn small color="success" :to="`/admin/completion-upload/${item.id}`"> view </v-btn>
					<v-btn small dark class="ml-2" color="error" :loading="deleting[item.id]" @click="deleteItem(item.id)"> delete </v-btn>
				</div>
			</template>
		</v-data-table>

		<common-dialog ref="new-completion-upload-dialog" :max-width="600" @closed="uploadDialogClosed">
			<template #header> New Completion Upload </template>

			<template #body>
				<dynamic-form ref="dynamic-form" :form-schema="form('manager_completion_upload').value" class="mt-4" :form-data="formData" @dynamic-form-submit="submitCompletionUpload" />
			</template>
		</common-dialog>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonDialog from '@/components/common/dialog';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';
	import DynamicForm from '@/components/common/base/dynamic-form';

	export default {
		name: 'app-admin-notifications',

		components: {
			CommonDialog,
			CommonDialogConfirm,
			DynamicForm
		},

		data: () => ({
			formData: {},
			items: [],
			loading: false,
			deleting: {},
			options: {},
			total: 0,
			pages: 0,
			headers: [
				{ text: 'Filename', value: 'data.title' },
				{ text: 'Completions', value: 'completion.count' },
				{ text: 'Unmatched', value: 'completion.unmatched' },
				{ text: 'Uploaded', value: 'created' },
				{ text: '', value: 'actions', sortable: false }
			]
		}),

		computed: {
			...mapGetters('CmsForm', ['form'])
		},

		watch: {
			options: {
				handler() {
					this.fetchCompletionUploads();
				},
				deep: true
			}
		},

		created() {
			this.loadForm('manager_completion_upload');
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('AdminCompletionUpload', ['getCompletionUploadList', 'addCompletionUpload', 'deleteCompletionUpload']),

			changePage(evt) {
				if (evt) this.options.page++;
				else this.options.page--;
			},

			async fetchCompletionUploads() {
				if (this.loading) return;

				this.loading = true;

				const { page, itemsPerPage } = this.options;

				const payload = {
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					order: { property: 'created', direction: 'desc' }
				};

				let { data } = await this.getCompletionUploadList(payload);
				this.items = data.data;
				this.total = data.total;
				this.pages = Math.ceil(this.total / this.options.itemsPerPage);
				this.loading = false;
			},

			submitCompletionUpload() {
				this.submitting = true;

				this.addCompletionUpload({ file: this.formData.file[0] })
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Completion upload has been submitted successfully');
						this.$refs['new-completion-upload-dialog'].close();

						this.fetchCompletionUploads();
					})
					.catch((e) => ElementTools.fireNotification(this.$el, 'error', e.response.data.message ?? 'Failed to submit completion upload'))
					.finally(() => (this.submitting = false));
			},

			async deleteItem(id) {
				this.$refs.confirm
					.open('Delete Completion Upload', `Are you sure you wish to delete this item?`)
					.then(() => {
						this.deleting[id] = true;
						this.deleteCompletionUpload(id)
							.then(() => {
								ElementTools.fireNotification(this.$el, 'success', 'Completion upload has been deleted successfully');
								this.fetchCompletionUploads();
							})
							.catch(() => {
								ElementTools.fireNotification(this.$el, 'error', 'Failed to delete completion upload');
							})
							.finally(() => {
								this.deleting[id] = false;
							});
					})
					.catch(() => {});
			},

			uploadDialogClosed() {
				this.formData = {};
			}
		}
	};
</script>
