import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Asset
 * @exports Service/Client/Asset
 * @description API Client Asset Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get all assets for a specific client (RBAC)
	 * @param {Object} clientId
	 * @returns {Object} Promise a http response
	 */
	list: (clientId, payload) => $http.post(`client/${clientId}/asset/list`, payload),

	/**
	 * @name get
	 * @description Get asset for specific client using CID and ID (RBAC)
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (clientId, id) => $http.get(`client/${clientId}/asset/${id}`),

	/**
	 * @name update
	 * @description Update client asset for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	update: (clientId, id, payload) => $http.put(`client/${clientId}/asset/${id}`, payload),

	/**
	 * @name modify
	 * @description Update client asset for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (clientId, id, payload) => $http.patch(`client/${clientId}/asset/${id}`, payload),

	/**
	 * @name delete
	 * @description Hard delete client asset for ID (RBAC)
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	delete: (clientId, id) => $http.delete(`client/${clientId}/asset/${id}`),

	/**
	 * @name add
	 * @description Add a new client asset
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (clientId, payload) => $http.post(`client/${clientId}/asset`, payload)
};
