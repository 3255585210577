import { CmsConfig } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name loadLifetimeMortgageSourcing
		 */
		loadLifetimeMortgageSourcing() {
			return CmsConfig.usingLifetimeMortgageSourcing();
		},

		/**
		 * @name loadConfig
		 * @param {Object} context
		 * @param {Object} payload
		 * @param {String} payload.type
		 * @param {String} payload.name
		 * @returns {Promise}
		 * @description Load config by type and name
		 */
		loadConfig(context, { type, name }) {
			return CmsConfig.get(type.replace(/_/g, '-'), name.replace(/_/g, '-'));
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
