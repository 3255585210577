import { ClientAsset } from '@/service';

/**
 * @class @name Content
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	state: {},

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	getters: {},

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	mutations: {},

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name fetchClients
		 * @param {function} commit
		 */
		async loadClientAssetList(context, { clientId, type, payload }) {
			let { data } = await ClientAsset.list(clientId, payload);
			return type ? data.data.filter((i) => i.type === type) || [] : data.data;
		},

		/**
		 * @name loadClientAsset
		 * @param {function} commit
		 */
		loadClientAsset(context, { clientId, id }) {
			return ClientAsset.get(clientId, id);
		},

		/**
		 * @name fetchClients
		 * @param {function} commit
		 */
		addClientAsset(context, { clientId, payload }) {
			return ClientAsset.add(clientId, payload);
		},

		/**
		 *  @name deleteClientAsset
		 * @param {function} commit
		 */
		deleteClientAsset(context, { clientId, id }) {
			return ClientAsset.delete(clientId, id);
		}
	},

	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
