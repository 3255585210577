import { AccountService } from '@/service';
import router from '@/router';

export default {
	state: {
		corporation: {},
		department: {},
		organisation: {},
		user: {},
		membership: [],
		permission: [],
		groups: [],
		impersonator: {}
	},

	getters: {
		/**
		 * @name authed
		 * @description Is the user authed
		 * @param {Object} state
		 * @return {Boolean} The auth status as a boolean
		 */
		authed: (state) => !!state.user.id,

		/**
		 * @name membership
		 * @description get membership of certain type
		 * @param {Object} state
		 * @return {Object} TODO
		 */
		membership: (state) => (type) => state.membership.find((m) => m.type === type) || {},

		/**
		 * @name permission
		 * @description get permission of certain type
		 * @param {Object} state
		 * @return {Object} TODO
		 */
		permission: (state) => (role) => state.permission.find((m) => m.role === role) || {}
	},

	mutations: {
		/**
		 * @name setDetails
		 * @param {Object} state
		 * @param {Object} data
		 */
		setDetails(state, data) {
			state.corporation = data.corporation || state.corporation;
			state.department = data.department || state.department;
			state.organisation = data.organisation || state.organisation;
			state.user = data.user || state.user;
			state.privacy = data.privacy || state.privacy;
			state.permission = data.permission || state.permission;
			state.membership = data.membership || state.membership;
			state.groups = data.groups || data.groups;
			state.impersonator = data.impersonator || state.impersonator;
		},

		/**
		 * @name setTwoFactor
		 * @param {Object} state
		 * @param {Object} data
		 */
		setTwoFactor(state, data) {
			state.user = { ...state.user, twoFactor: data };
		},

		/**
		 * @name clearDetails
		 * @param {Object} state
		 */
		clearDetails(state) {
			state.corporation = {};
			state.department = {};
			state.organisation = {};
			state.user = {};
			state.privacy = {};
			state.permission = [];
			state.membership = [];
			state.impersonator = {};
		},

		/**
		 * @name setMembership
		 * @param {Object} state
		 */
		setMembership(state, set) {
			state.membership.push(set);
		},

		/**
		 * @name setUserSecurityQuestion
		 * @param {Object} state
		 */
		setUserSecurityQuestion(state, data) {
			const idx = state.user.userSecurityQuestion.reduce((p, c, i) => (c.id === data.id ? i : p), -1);
			// if (data.deleted) state.user.userSecurityQuestion.splice(idx, 1);
			if (idx >= 0) state.user.userSecurityQuestion[idx] = { ...state.user.userSecurityQuestion[idx], ...data };
			else state.user.userSecurityQuestion.push(data);
		},

		/**
		 * @name setUserAccount
		 * @param {Object} state
		 */
		setUserAccount(state, data) {
			state.user.userAccount = { ...state.user.userAccount, ...data };
		},

		/**
		 * @name setUserAddress
		 * @param {Object} state
		 */
		setUserAddress(state, data) {
			state.user.userAddress = { ...state.user.userAddress, ...data };
		},

		/**
		 * @name setUserDetail
		 * @param {Object} state
		 */
		setUserDetail(state, data) {
			state.user.userDetail = { ...state.user.userDetail, ...data };
		},

		/**
		 * @name setUserIdentity
		 * @param {Object} state
		 */
		setUserIdentity(state, { identity, payload }) {
			state.user.userIdentity = [...state.user.userIdentity.filter((u) => u.id !== identity.id), { ...identity, ...payload }];
		}
	},

	actions: {
		/**
		 * @async @name login
		 * @param {function} commit
		 */
		async login({ commit }, creds) {
			await AccountService.login(creds);
			const { data } = await AccountService.verify();
			commit('setDetails', data);
		},

		/**
		 * @async @name logout
		 * @param {function} commit
		 */
		async logout({ commit }) {
			await AccountService.logout();
			commit('clearDetails');
			router.push('/login');
		},

		/**
		 * @async @name verify
		 * @param {function} commit
		 */
		async verify({ state, commit }) {
			try {
				let response = await AccountService.verify();
				const data = { ...state.user, ...response.data };
				commit('setDetails', data);
			} catch (err) {
				commit('clearDetails');
				throw err;
			}
		},

		/**
		 * @async @name impersonate
		 * @param {function} commit
		 */
		async impersonate({ commit }, creds) {
			let response = await AccountService.login(creds);
			commit('setDetails', response.data);
			if (response.data) return true;
		},

		/**
		 * @async @name reinstate
		 * @param {function} commit
		 */
		async reinstate({ state }) {
			await AccountService.setToken(state.impersonator.token);
			return true;
		},

		/**
		 * @async @name generateTwoFactor
		 * @param {function} commit
		 */
		async generateTwoFactor() {
			let response = await AccountService.getTwoFactor();
			if (response.data) return response.data;
		},

		/**
		 * @async @name deactivateTwoFactor
		 * @param {function} commit
		 */
		async deactivateTwoFactor({ state, commit }) {
			let response = await AccountService.deleteTwoFactor(state.user.id);
			if (response.data) {
				commit('setTwoFactor', false);
				return response.data;
			}
		},

		/**
		 * @async @name modifyTwoFactor
		 * @param {function} commit
		 */
		async modifyTwoFactor({ commit }, data) {
			let response = await AccountService.setTwoFactor(data);
			if (response.data) {
				commit('setTwoFactor', true);
				return true;
			}
		}
	},

	namespaced: true
};
