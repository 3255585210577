import { CmsNews } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getNewsList
		 * @param {String} id
		 */
		getNewsList(context, payload) {
			return CmsNews.list(payload);
		},

		/**
		 * @async @name getNews
		 * @param {String} id
		 */
		getNews(context, id) {
			return CmsNews.get(id);
		},

		/**
		 * @async @name getNews
		 * @param {String} id
		 */
		modifyNews(context, { id, payload }) {
			return CmsNews.modify(id, payload);
		},

		/**
		 * @async @name addNews
		 */
		deleteNews(context, id) {
			return CmsNews.delete(id);
		},

		/**
		 * @async @name addNews
		 */
		addNews(context, payload) {
			return CmsNews.add(payload);
		},

		/**
		 * @async @name addTag
		 */
		addTag(context, { id, payload }) {
			return CmsNews.addTag(id, payload);
		},

		/**
		 * @async @name deleteTag
		 */
		deleteTag(context, { id, tagId }) {
			return CmsNews.deleteTag(id, tagId);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
