import { $http } from '@/utils';

/**
 * @namespace Service
 * @class RateUpdate
 * @exports Service/Product
 * @description API Notification Service
 * @date 2023/10/11
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name validate
	 * @description Send a request to process CSV file and validate
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	validate: (payload) => $http.post(`product/rate-update/validate`, payload),

	/**
	 * @name addSchedule
	 * @description Add a new schedule resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	addSchedule: (payload) => $http.post(`product/rate-update/schedule`, payload)
};
