<template>
	<div class="pa-8">
		<div class="pb-4">
			<v-btn color="primary" dark :to="`/admin/network/${networkId}/user`"> Add New User </v-btn>
		</div>
		<v-data-table :headers="headers" :items="data" disable-pagination class="elevation-1 mt-3" :loading="loading" hide-default-footer>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.updated="{ item }">
				{{ item.updated | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn small dark class="ml-2" color="error" :loading="deleting[item.id]" @click="deleteItem(item.id)"> delete </v-btn>
				</div>
			</template>
		</v-data-table>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import { debounce } from 'lodash';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';

	export default {
		name: 'app-products',

		components: {
			CommonDialogConfirm
		},

		data: () => ({
			loading: false,
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'User Type', value: 'type' },
				{ text: 'Created', value: 'created' },
				{ text: 'Last Updated', value: 'updated' },
				{ text: '', value: 'actions', sortable: false }
			],
			data: [],
			deleting: {}
		}),

		computed: {
			networkId() {
				return this.$route.params.id;
			}
		},

		created() {
			this.$route.meta.breadcrumb[3] = { text: `Network #${this.networkId}`, to: `/admin/network/${this.networkId}` };

			this.getUserList();
		},

		methods: {
			...mapActions('AccountUser', ['fetchUserList', 'modifyUser', 'deleteUser']),

			async getUserList() {
				if (this.loading) return;

				this.loading = true;
				let payload = {
					where: { 'organisation.id': this.networkId }
				};
				let { data } = await this.fetchUserList(payload);
				this.data = data.data;
				this.loading = false;
			},

			/**
			 * @name debouncedGetUserList
			 * @description Load media with debounce
			 */
			debouncedGetUserList: debounce(function () {
				this.getUserList();
			}, 1000),

			deleteItem(id) {
				this.$refs.confirm
					.open('Delete User', `Are you sure you wish to delete this user?`)
					.then(() => {
						this.deleteUser({ id })
							.then(() => {
								ElementTools.fireNotification(this.$el, 'success', 'User has been deleted successfully');
								this.getUserList();
							})
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to delete user'))
							.finally(() => (this.submitting = false));
					})
					.catch(() => {});
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep ul.v-pagination {
		justify-content: end !important;
	}
</style>
