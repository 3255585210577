<template>
	<div>
		<v-container class="pt-8">
			<v-row dense>
				<v-col cols="12">
					<!-- <common-base-tabs :tabs="routes" :loading="false" router-view dark /> -->

					<v-list dark class="ma-0 pa-0">
						<v-list-item v-for="item in bannerTypes" :key="item.id" :to="`banners/${item.id}`" dense class="my-1 primary">
							<v-list-item-content>
								{{ item.name }}
							</v-list-item-content>
							<v-list-item-action class="my-0">
								<v-icon> mdi-star </v-icon>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	export default {
		name: 'app-cms',

		data() {
			return {
				bannerTypes: []
			};
		},

		created() {
			this.getBannerTypeList({ limit: 10, offset: 0 }).then(({ data }) => {
				this.bannerTypes = data.data;
			});
		},

		methods: {
			...mapActions('CmsBanner', ['getBannerTypeList'])
		}
	};
</script>

<style></style>
