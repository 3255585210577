/**
 * @namespace Utils
 * @class StringTools
 * @exports Utils/StringTools
 * @description Util for manipulating strings
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default class StringTools {
	/**
	 * @static @name filepathToComponentName
	 * @description TODO
	 * @return {TODO} TODO
	 */
	static filepathToComponentName(filepath, startString, endString) {
		return filepath
			.replace(/\\|\//g, '/')
			.substring(0, filepath.length - endString.length)
			.split(startString.replace(/\\|\//g, '/'))
			.pop()
			.replace(/\/index/, '')
			.replace(/\//g, '-');
	}

	/**
	 * @static @name camelize
	 * @description Converts string to camelCase
	 * @return {String}
	 */
	static camelize(str) {
		return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, '');
	}

	/**
	 * @static @name versionToNumber
	 * @description Converts a version as a string '1.0.0' to number 10000000000 for comparisons
	 * @return {TODO} TODO
	 */
	static versionToNumber(version) {
		return !version || typeof version !== 'string'
			? 0
			: Number(
					version
						.split('.')
						.reverse()
						.reduce((a, c) => String('00000' + c).slice(-5) + a, '')
			);
	}
}
