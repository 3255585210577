import { $http } from '@/utils';

/**
 * @namespace Service
 * @class IterationMessage
 * @exports Service/Iteration/Message
 * @description API Iteration Message Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get all iteration messages
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`iteration/message/list`, payload),

	/**
	 * @name add
	 * @description Add new iteration messages
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`iteration/message`, payload),

    /**
	 * @@name delete
	 * @description Selete Iteration Message
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	delete: (id) => $http.delete(`iteration/message/${id}`)
};
