<template>
	<div class="pa-8">
		<div class="pb-4">
			<v-btn color="primary" dark to="/cms/changelog"> Add New Changelog </v-btn>
		</div>
		<div class="d-flex">
			<validation-observer ref="observer" class="pa-0 col-6">
				<validation-provider v-slot="{ errors }" name="Search" rules="search">
					<v-text-field v-model="searchTerm" placeholder="Search" append-icon="mdi-magnify" dense solo light clear-icon="mdi-close-circle" clearable :error-messages="errors" @click:clear="searchTerm = ''" />
				</validation-provider>
			</validation-observer>
			<v-spacer />
			<v-pagination v-model="options.page" :length="pages" total-visible="7" :disabled="loading" />
		</div>
		<v-data-table
			:headers="headers"
			:items="items"
			class="elevation-1"
			:options.sync="options"
			:server-items-length="total"
			:loading="loading"
			:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [5, 10, 15, 20, 25] }"
		>
			<template #item.posted="{ item }">
				{{ item.posted | moment('Do MMM YYYY') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn icon @click="openCommentsDialog(item.id)" v-on="on">
						<v-badge v-if="item.changelogCommentCount" left :content="item.changelogCommentCount" overlap color="error">
							<v-icon color="primary"> mdi-chat </v-icon>
						</v-badge>
						<v-icon v-else color="primary"> mdi-chat </v-icon>
					</v-btn>

					<v-btn small color="success" :to="`/cms/changelog/${item.id}`"> edit </v-btn>
					<v-btn small dark class="ml-2" color="error" :loading="deleting[item.id]" @click="deleteItem(item.id)"> delete </v-btn>
				</div>
			</template>
		</v-data-table>

		<common-dialog-confirm ref="confirm" />
		<comment-dialog ref="commentDialog" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import { debounce } from 'lodash';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';
	import CommentDialog from './comment-dialog';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';

	export default {
		name: 'app-cms-changelog',

		components: {
			CommonDialogConfirm,
			CommentDialog,
			ValidationObserver,
			ValidationProvider
		},

		data: () => ({
			items: [],
			loading: false,
			deleting: {},
			options: {},
			total: 0,
			pages: 0,
			searchTerm: '',
			headers: [
				{ text: 'Title', value: 'title' },
				{ text: 'Article Date', value: 'posted' },
				{ text: '', value: 'actions', sortable: false }
			]
		}),

		watch: {
			options: {
				handler() {
					this.fetchChangelogList();
				},
				deep: true
			},

			async searchTerm() {
				const valid = await this.$refs.observer.validate();
				if(!valid) return;
				this.debouncedfetchChangelogList();
			}
		},

		methods: {
			...mapActions('CmsChangelog', ['getChangelogList', 'deleteChangelog', 'getChangelogCommentList']),

			async fetchChangelogList() {
				if (this.loading) return;

				this.loading = true;
				const { sortBy, sortDesc, page, itemsPerPage } = this.options;

				const payload = {
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					order: sortBy.length > 0 ? { property: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc' } : { property: 'created', direction: 'desc' },
					where: this.searchTerm ? [{ 'changelog.title': { c: 'ILIKE', v: `%${this.searchTerm}%` } }, '||', { 'changelog.description': { c: 'ILIKE', v: `%${this.searchTerm}%` } }] : undefined
				};

				let { data } = await this.getChangelogList(payload);
				this.items = data.data;
				this.total = data.total;
				this.pages = Math.ceil(this.total / this.options.itemsPerPage);
				this.loading = false;
			},

			/**
			 * @name debouncedfetchChangelogList
			 * @description Load Changelog with debounce
			 */
			debouncedfetchChangelogList: debounce(function () {
				this.options.page = 1;
				this.fetchChangelogList();
			}, 1000),

			async deleteItem(id) {
				this.$refs.confirm
					.open('Delete Changelog article', `Are you sure you wish to delete this changelog article?`)
					.then(() => {
						this.deleting[id] = true;
						this.deleteChangelog(id)
							.then(() => {
								ElementTools.fireNotification(this.$el, 'success', 'Changelog article has been deleted successfully');
								this.fetchChangelogList();
							})
							.catch(() => {
								ElementTools.fireNotification(this.$el, 'error', 'Failed to delete Changelog article');
							})
							.finally(() => {
								this.deleting[id] = false;
							});
					})
					.catch(() => {});
			},

			/**
			 * @name openCommentsDialog
			 * @description Event for open messages dialog
			 */
			async openCommentsDialog(changelogId) {
				const payload = {
					order: { property: 'created', direction: 'desc' },
					where: { changelogId }
				};
				let { data } = await this.getChangelogCommentList(payload);
				this.$refs.commentDialog.open(data.data);
			}
		}
	};
</script>
