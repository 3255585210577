import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Data
 * @exports Service/Cms/Config
 * @description API Data Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @static @name usingLifetimeMortgageSourcing
	 * @description
	 * @returns {Object} Promise a http response
	 */
	usingLifetimeMortgageSourcing() {
		return $http.get(`cms/config/using/lifetime-mortgage/sourcing`);
	},

	/**
	 * @static @name get
	 * @param {String} type
	 * @param {String} name
	 * @returns {Object} Promise a http response
	 */
	get(type, name) {
		return $http.get(`cms/config/using/${type}/${name}`);
	}
};
