import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Transaction
 * @exports Service/Reward/Transaction
 * @description API Reward Transaction Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @static @name list
	 * @description Get all reward transactions
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post('reward/transaction/list', payload),

	/**
	 * @static @name add
	 * @description Add a new reward trandaction resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`reward/transaction`, payload),

	/**
	 * @static @name modify
	 * @description modify a new reward trandaction resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`reward/transaction/${id}`, payload),

	/**
	 * @name delete
	 * @description Delete reward transaction by ID
	 * @returns {Object} Promise a http response
	 */
	delete: (id) => $http.delete(`reward/transaction/${id}`)
};
