<!--
	@name app-enquiry-details
	@description Responses timeline for enquiry
	@date 2020/06/23
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<v-container :component="$options.name" class="py-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>Enquiry #{{ matter?.matterReference }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark to="/enquiries"> Back to enquiries </v-btn>
		</v-toolbar>

		<v-fade-transition>
			<v-container>
				<v-row>
					<v-col class="py-0" cols="12" md="8">
						<common-structure-section>
							<template #body>
								<p v-if="matter && clients && property" class="text-body-1 mb-0">
									{{ summary }}
								</p>

								<v-skeleton-loader v-else type="list-item-three-line" class="ma-n4" />
							</template>
						</common-structure-section>

						<v-alert v-if="isEnquiryClosed" type="success" text outlined dense class="mt-6"> This enquiry was closed on {{ matter.data.closed | moment('MMM Do YYYY, h:mma') }} </v-alert>

						<common-structure-section class="mt-6">
							<template #header> Providers </template>
							<template #body>
								<div v-if="organisations" class="mx-n2 mt-n4">
									<v-chip v-for="id in selectedProviders" :key="id" :disabled="loading" class="ml-2 mt-4">
										{{ getOrganisationName(id) }}
									</v-chip>

									<v-btn v-if="organisations.length !== selectedProviders.length" color="primary" class="d-block mt-6 ml-2" @click="openProvidersDialog"> Add Provider </v-btn>
								</div>
								<v-skeleton-loader v-else type="list-item-three-line" class="ma-n4" />
							</template>
						</common-structure-section>
						<common-structure-section class="mt-6">
							<template #header>
								Activity
								<v-spacer />
								<v-btn v-if="matter" color="primary" small class="mb-0" :loading="processing[0]" :disabled="isEnquiryClosed" @click="openResponseDialog(0)">
									<v-icon left> mdi-comment-text </v-icon>
									Send a Response
								</v-btn>
							</template>

							<template #body>
								<div v-if="matter && matter.process.length && allOrganisations">
									<v-timeline>
										<app-enquiry-enquiry-details-response
											v-for="(response, index) in responses"
											:key="index"
											:response="response"
											:organisations="allOrganisations"
											:loading="processing"
											:enquiry-closed="isEnquiryClosed"
											@open-response-dialog="openResponseDialog"
										/>
									</v-timeline>
								</div>

								<v-card v-else class="flex-grow-1 mb-0 pa-6">
									<v-skeleton-loader type="list-item-avatar" class="mb-6" />
									<v-skeleton-loader type="divider, card" />
								</v-card>
							</template>
						</common-structure-section>
					</v-col>

					<v-col class="py-0" cols="12" md="4">
						<app-enquiry-enquiry-details-documents :responses="responses" @show-all-documents="openDocumentsDialog" />
					</v-col>
				</v-row>
			</v-container>
		</v-fade-transition>

		<dynamic-form-dialog ref="dynamicFormDialog" @dynamic-form-submit="submitResponse" />
		<app-enquiry-enquiry-details-documents-dialog ref="documentsDialog" :responses="responses" :organisations="organisations" />
		<app-enquiry-enquiry-details-providers-dialog ref="providersDialog" :organisations="organisations" :matter-entity="matterEntity" :active-providers="selectedProviders" @submitted="loadMatter" />
	</v-container>
</template>

<script>
	import { mapGetters, mapActions, mapState } from 'vuex';
	import { ElementTools } from '@/utils';
	import { orderBy, findIndex } from 'lodash';
	import CommonStructureSection from '@/components/common/structure/section';
	import DynamicFormDialog from '@/components/common/dialog/dynamic-form-dialog';
	import AppEnquiryEnquiryDetailsResponse from './response';
	import AppEnquiryEnquiryDetailsDocuments from './documents';
	import AppEnquiryEnquiryDetailsDocumentsDialog from './documents-dialog';
	import AppEnquiryEnquiryDetailsProvidersDialog from './providers-dialog';

	export default {
		name: 'app-enquiry-details',

		components: {
			CommonStructureSection,
			DynamicFormDialog,
			AppEnquiryEnquiryDetailsResponse,
			AppEnquiryEnquiryDetailsDocuments,
			AppEnquiryEnquiryDetailsDocumentsDialog,
			AppEnquiryEnquiryDetailsProvidersDialog
		},

		data() {
			return {
				formData: {},
				loading: true,
				processing: {},
				matter: null,
				matterEntity: null,
				clients: null,
				property: null,
				organisations: null,
				allOrganisations: null,
				processList: null,
				selectedProviders: []
			};
		},

		computed: {
			...mapState('Account', ['organisation']),
			...mapGetters('CmsForm', ['form']),
			...mapGetters('CmsData', ['data']),

			enquiryId() {
				return this.$route.params.id;
			},

			isEnquiryClosed() {
				return !!this.matter?.data?.closed;
			},

			responses() {
				return orderBy(this.processList, 'created', ['desc']);
			},

			summary() {
				let clientSummary = '';
				this.clients.map((client, index) => (clientSummary += `${index > 0 ? ' and ' : ''}${client.nameGiven} ${client.nameFamily}`));

				let { data: property } = this.property;
				let propertySummary = `${property.location.address1},${property.location.address2 ? ` ${property.location.address2},` : ''} ${property.location.townCity}, ${property.location.postcode}`;

				return `Enquiry #${this.matter?.matterReference}, created ${this.$moment(this.matter.created).format('DD MMM YYYY HH:mm')} on behalf of ${clientSummary} at ${propertySummary}`;
			},

			providerOptions() {
				return this.organisations
					?.filter((o) => !this.selectedProviders.includes(o.id))
					?.map((o) => ({
						text: o.name,
						value: o.id
					}));
			},

			respondAsOptions() {
				return this.organisations
					?.filter((o) => this.selectedProviders.includes(o.id))
					.map((o) => ({
						text: o.name,
						value: o.id
					}));
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('AppMatter', ['modifyMatter', 'addMatterEntity']),
			...mapActions('AppClient', ['loadClientList']),
			...mapActions('AppClientAsset', ['loadClientAsset']),
			...mapActions('AccountOrganisation', ['loadProviderList']),
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('Enquiry', ['fetchEnquiry', 'response', 'createResponse']),

			init() {
				this.loadMatter();
				this.loadForm('enquiryResponse');
			},

			/**
			 * @name loadMatter
			 * @description fetch matter
			 */
			async loadMatter() {
				this.loading = true;
				let { data: matter } = await this.fetchEnquiry(this.enquiryId);

				this.matter = matter;
				this.matterEntity = matter.matterEntity;

				let orderedProcessList = orderBy(matter.process, ['created'], ['asc']);

				this.processList = orderedProcessList.map((process, index) => {
					let parentProcess = orderedProcessList.find((p) => p.id === process.action?.iteration?.processId);
					if (parentProcess) parentProcess.initialMessage = findIndex(orderedProcessList, parentProcess) === 0;
					return {
						...process,
						initialMessage: index === 0,
						parentProcess
					};
				});

				const clientIds = (matter?.matterEntity || []).filter((me) => me.entity === 'client').map((me) => me.entityId);
				const assetId = (matter?.matterEntity || []).find((me) => me.entity === 'asset')?.entityId;
				const organisationIds = (matter?.matterEntity || []).filter((me) => me.entity === 'organisation' && !me.data?.withdrawn).map((me) => me.entityId);
				this.selectedProviders = organisationIds || [];

				const requests = [
					this.loadClientList({ where: { id: { value: clientIds, type: 'uuid' } } }),
					this.loadClientAsset({ clientId: clientIds[0], id: assetId }),
					this.loadProviderList({ order: { property: 'organisation.name' } })
				];

				Promise.all(requests).then((responses) => {
					this.clients = responses[0];
					this.property = responses[1].data;
					this.organisations = (responses[2]?.data || []).map((o) => ({
						...o,
						withdrawn: this.isOrganisationWithdrawn(o)
					}));
					this.allOrganisations = this.organisations; // Get all organisations before filtering, so that existing selected providers can still be shown
					this.organisations = this.organisations.filter((o) => o.active);
				});

				this.loading = false;
			},

			/**
			 * @name submitResponse
			 * @description submit response
			 */
			async submitResponse({ config, data }) {
				let payload;
				let request;

				if (config.response) {
					request = this.createResponse;

					let process = {
						message: data.message,
						files: data.files,
						responder: data.respondAs === 'air-sourcing-support-team' ? { name: 'Air Sourcing Support Team', type: 'system' } : { id: data.respondAs, type: 'organisation' }
					};

					let iteration = config.response.processTypeNameUnique === 'update' ? config.response.iteration.find((i) => i.data.recipient.id === data.respondAs) : config.response.iteration[0];

					payload = {
						processId: config.response.id,
						iterationId: iteration.id,
						data: {
							action: { key: 'CreateResponse' },
							process
						}
					};
				} else {
					request = this.response;

					payload = {
						matterId: this.matter.id,
						processType: 'response',
						process: { ...data, responder: data.respondAs === 'air-sourcing-support-team' ? { name: 'Air Sourcing Support Team', type: 'system' } : { id: data.respondAs, type: 'organisation' } }
					};
				}

				this.$set(this.processing, config.response?.id?.toString() || 0, true);
				request(payload)
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Response has been sent successfully');
						this.matter = null;
						this.loadMatter();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured'))
					.finally(() => this.$set(this.processing, config.response?.id?.toString() || 0, false));
			},

			/**
			 * @name openResponseDialog
			 * @description Event for open dynamic form dialog
			 * @param {Number} responseId
			 */
			openResponseDialog(response) {
				this.$refs.dynamicFormDialog.open({
					response,
					formSchema: this.formSchema(response.processTypeNameUnique === 'update', response),
					formData: this.formData,
					submitButtonText: 'Submit Response',
					title: 'Send a Response'
				});
			},

			/**
			 * @name openDocumentsDialog
			 * @description Event for open documents dialog
			 */
			openDocumentsDialog() {
				this.$refs.documentsDialog.open();
			},

			/**
			 * @name openProvidersDialog
			 * @description Event for open documents dialog
			 */
			openProvidersDialog() {
				this.$refs.providersDialog.open();
			},

			/**
			 * @name getOrganisationName
			 * @description get organisation name by id
			 */
			getOrganisationName(id) {
				return this.allOrganisations?.find((o) => o.id === id)?.name;
			},

			/**
			 * @name isOrganisationWithdrawn
			 * @description go through process list and check if organisation is withdrawn by checking if there is a withdraw process and any reconnect process shouldn't be after that if it's withdrawn
			 */
			isOrganisationWithdrawn(organisation) {
				const withdrawReconnectProcesses = this.processList?.filter((p) => (p.processTypeNameUnique === 'withdraw' || p.processTypeNameUnique === 'reconnect') && p.data.id === organisation.id);

				if (withdrawReconnectProcesses?.length > 0) {
					const latestWithdrawReconnectProcess = withdrawReconnectProcesses.reduce((prev, current) => (this.$moment(prev.created).isAfter(this.$moment(current.created)) ? prev : current));

					return latestWithdrawReconnectProcess.processTypeNameUnique === 'withdraw';
				} else return false;
			},

			formSchema(isUpdate, response) {
				let respondAsOptions = response?.parentProcess?.data?.responder?.id ? this.respondAsOptions.filter((o) => o.value === response.parentProcess.data.responder.id) : this.respondAsOptions;
				if (!isUpdate) respondAsOptions = [{ text: 'Air Sourcing Support Team', value: 'air-sourcing-support-team' }, ...respondAsOptions];

				return [
					{
						type: 'select',
						name: 'respondAs',
						display: 'Respond as',
						rules: 'required',
						options: respondAsOptions,
						sm: 12
					},
					{
						type: 'textarea',
						name: 'message',
						display: 'Response',
						rules: 'required',
						sm: 12
					},
					{
						type: 'file',
						name: 'files',
						display: 'Attach Files',
						multiple: true,
						s3Path: 'upload/enquiry',
						sm: 12,
						validations: [
							{
								type: 'size',
								value: 2048,
								errorMessage: 'This file was too large. The maximum size permitted is 2MB.'
							},
							{
								type: 'count',
								value: 3,
								errorMessage: 'You can not upload more than 3 files.'
							},
							{
								type: 'type',
								value: ['application/msword', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
								errorMessage: 'This file was an invalid type. Permitted file types are doc, docx and pdf.'
							}
						]
					}
				];
			}
		}
	};
</script>
