import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#1eb0e1',
				secondary: '#1b78d8',
				accent: colors.yellow.darken2,
				info: '#670056',
				success: colors.green.darken3,
				warning: '#F18805',
				error: colors.red.darken2
			}
		}
	}
});
