<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>User #{{ userId }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/user/${userId}`"> Back to user </v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<p class="caption mb-5">Notes: Academy and Club content tab now found here, along with Platinum, Elite and Select</p>
					<v-expansion-panels v-model="panel">
						<v-expansion-panel v-for="(membership, i) in memberships" :key="i">
							<v-expansion-panel-header class="text-capitalize">
								{{ membership }}
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-btn v-if="!getMembership(membership)" color="success" @click="addMembershipRequest(membership)" :disabled="isNotAllowed(membership)"> Add membership </v-btn>
								<template v-else>
									<v-btn color="error" @click="deleteMembershipRequest(membership)"> Remove membership </v-btn>
									<v-btn v-if="!getMembership(membership)?.approve" color="success" class="ml-2" @click="approveMembershipRequest(membership)"> Approve membership </v-btn>
								</template>
								<v-sheet v-if="membership.toLowerCase() === 'select' && getMembership(membership)" class="mt-6">
									<v-data-table :headers="headers" :items="mappedSelectTiers" :items-per-page="5" hide-default-footer class="elevation-1">
										<template #item.id="{ item }">
											<v-btn-toggle :value="item.tier - 1" color="success">
												<v-btn v-for="tier in tiers" :key="tier.value" small @click="updateSelectTier(tier.value, item)">
													{{ tier.text }}
												</v-btn>
											</v-btn-toggle>
										</template>
									</v-data-table>
								</v-sheet>
								<v-sheet v-else-if="formSchema[membership] && getMembership(membership)" class="mt-6">
									<dynamic-form
										ref="dynamicForm"
										:form-schema="formSchema[membership]"
										:form-data="formData[membership]"
										:submit-button-loading="submitting"
										@dynamic-form-submit="modifyMembershipRequest(membership)"
									/>
								</v-sheet>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
			</v-row>
		</v-container>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';
	import DynamicForm from '@/components/common/base/dynamic-form';

	export default {
		name: 'app-users-details-membership',

		components: {
			CommonDialogConfirm,
			DynamicForm
		},

		data() {
			return {
				panel: [],
				memberships: ['club', 'platinum', 'academy', 'elite', 'select', 'comentis'],
				selectTiers: [],
				membership: null,
				formSchema: {
					academy: [],
					comentis: []
				},
				formData: {
					academy: {},
					comentis: {}
				},
				submitting: false,
				loading: false,
				headers: [
					{ text: 'Provider', align: 'start', sortable: false, value: 'name' },
					{ text: 'Tier', align: 'right', sortable: false, value: 'id', width: '35%' }
				],
				providers: [],
				tiers: [
					{ value: 1, text: 'Tier 1' },
					{ value: 2, text: 'Tier 2' },
					{ value: 3, text: 'Tier 3' }
				]
			};
		},

		computed: {
			...mapGetters('CmsForm', ['formFromGroup']),

			userId() {
				return this.$route.params.id;
			},

			mappedSelectTiers() {
				return this.providers.map((provider) => {
					const match = this.selectTiers.find((tier) => tier.organisationId === provider.id);
					provider.tier = match?.data?.tier || null;
					return provider;
				});
			}
		},

		watch: {
			formData: {
				handler() {
					console.log('this.formData :>> ', this.formData);
				},
				deep: true
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('AccountUser', ['fetchUser']),
			...mapActions('AccountOrganisation', ['getOrganisationList']),
			...mapActions('AppMembership', ['addMembership', 'deleteMembership', 'modifyMembership', 'fetchMembership', 'fetchMembershipList', 'findMembershipOrganisationList', 'modifyMembershipOrganisation']),
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsSite', ['getMembershipSite']),

			/**
			 * @name init
			 * @description init component
			 */
			async init() {
				this.$route.meta.breadcrumb[2] = {
					text: `User #${this.userId}`,
					to: `/user/${this.userId}`
				};
				await this.loadForm('manager_user_membership');
				this.formSchema.academy = this.formFromGroup('manager_user_membership', 'academy');
				this.formSchema.comentis = this.formFromGroup('manager_user_membership', 'comentis');

				this.loadMembershipData();
			},

			/**
			 * @name loadMembershipData
			 * @description load user membership data
			 */
			async loadMembershipData() {
				if (this.userId) {
					this.loading = true;

					this.fetchMembershipList({ where: { identity_id: this.userId } })
						.then(({ data }) => {
							this.membership = data.data;

							const academy = this.getMembership('academy');
							if (academy)
								this.formData.academy = {
									procFees: !!academy.accessToFee,
									graduated: academy.data?.graduated,
									premium: academy.data?.premium,
									induction: academy.data?.induction
								};

							const select = this.getMembership('select');
							if (select) {
								this.getMembershipSite('select').then(({ data }) => {
									this.getOrganisationList({
										order: { property: 'organisation.name', direction: 'asc' },
										where: { type: 'provider', id: { v: data.matter?.lifetimeMortgage?.panel?.sourcing || [], t: 'uuid' } }
									}).then(({ data }) => (this.providers = data.data.map((o) => ({ id: o.id, name: o.name }))));
								});
								this.findMembershipOrganisationList({ where: { membership_id: select.id } }).then(({ data }) => (this.selectTiers = data.data));
							}

							const comentis = this.getMembership('comentis');
							if (comentis)
								this.formData.comentis = {
									username: comentis.data?.username,
									secret: comentis.data?.secret
								};
						})
						.finally(() => (this.loading = false));
				}
			},

			/**
			 * @name getMembership
			 * @description get membership data by type
			 * @param {string} type
			 */
			getMembership(type) {
				return this.membership?.find((m) => m.type === type);
			},

			/**
			 * @name isNotAllowed
			 * @description check if user has a select or elite membership, then they cannot have another select or elite membership
			 * @param {string} type
			 */
			isNotAllowed(type) {
				if (type === 'select' || type === 'elite') return this.membership?.some((m) => m.type === 'elite' || m.type === 'select') || false;
				return false;
			},

			/**
			 * @name addMembershipHandler
			 * @description add membership handler
			 * @param {string} type
			 */
			addMembershipRequest(type) {
				const payload = { type, identityId: this.userId, identityType: 'user', data: this.formData[type], statusRequest: new Date() };

				this.addMembership(payload)
					.then(() => {
						this.loadMembershipData();
						ElementTools.fireNotification(this.$el, 'success', 'Membership has been added successfully');
					})
					.catch(() => {
						ElementTools.fireNotification(this.$el, 'error', 'Failed to add membership');
					});
			},

			/**
			 * @name deleteMembershipHandler
			 * @description delete membership handler
			 * @param {string} type
			 */
			deleteMembershipRequest(type) {
				this.$refs.confirm
					.open('Remove Membership', `Are you sure you wish to remove ${type} membership from this user?`)
					.then(() => {
						const membership = this.getMembership(type);

						this.deleteMembership(membership.id)
							.then(() => {
								this.loadMembershipData();
								ElementTools.fireNotification(this.$el, 'success', 'Membership has been removed successfully');
							})
							.catch(() => {
								ElementTools.fireNotification(this.$el, 'error', 'Failed to remove membership');
							});
					})
					.catch(() => {});
			},

			modifyMembershipRequest(type) {
				this.submitting = true;
				const membership = this.getMembership(type);
				let payload = null;

				// payload = { id: membership.id, data: { ...membership.data, ...this.formData[type] } };

				if (type === 'comentis') {
					payload = {
						data: {
							...membership.data,
							username: this.formData.comentis.username,
							secret: this.formData.comentis.secret
						}
					};
				}

				if (type === 'academy') {
					payload = {
						accessToFee: this.formData.academy.procFees ? new Date() : null,
						data: {
							...membership.data,
							graduated: this.formData.academy.graduated,
							premium: this.formData.academy.premium,
							induction: this.formData.academy.induction
						}
					};
				}

				this.modifyMembership({ id: membership.id, data: payload })
					.then(() => {
						this.loadMembershipData();
						ElementTools.fireNotification(this.$el, 'success', 'Membership has been updated successfully');
					})
					.catch(() => {
						ElementTools.fireNotification(this.$el, 'error', 'Failed to update membership');
					})
					.finally(() => (this.submitting = false));
			},

			approveMembershipRequest(type) {
				const membership = this.getMembership(type);

				this.submitting = true;
				this.modifyMembership({ id: membership.id, data: { statusApprove: new Date() } })
					.then(() => {
						this.loadMembershipData();
						ElementTools.fireNotification(this.$el, 'success', 'Membership has been approved successfully');
					})
					.catch(() => {
						ElementTools.fireNotification(this.$el, 'error', 'Failed to approve membership');
					})
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name updateSelectTier
			 * @description TODO
			 */
			updateSelectTier(tier, provider) {
				const membership = this.getMembership('select');

				this.modifyMembershipOrganisation({ membershipId: membership.id, organisationId: provider.id, data: { ...membership.data, tier } })
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Tier has been set successfully');
					})
					.catch(() => {
						ElementTools.fireNotification(this.$el, 'error', 'Failed to set tier');
					});
			}
		}
	};
</script>

<style></style>
