<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title class="font-weight-bold">
				{{ userId ? `User ${userId}` : 'Add New User' }}
			</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/admin/users`">
				Back to users
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters class="justify-center">
				<v-col cols="6" md="8" lg="8" xl="6">
					<v-stepper v-model="stepper" vertical>
						<v-stepper-step :complete="stepper > 1" step="1">
							Select Access
						</v-stepper-step>

						<v-stepper-content step="1">
							<v-sheet class="pa-6">
								<dynamic-form ref="dynamicForm" :form-schema="accessFormSchema" :form-data="accessFormData" :submit-button-loading="submitting" @dynamic-form-submit="stepper = 2" />
							</v-sheet>
						</v-stepper-content>

						<v-stepper-step :complete="stepper > 2" step="2">
							Add User
						</v-stepper-step>

						<v-stepper-content step="2">
							<v-sheet class="pa-6">
								<dynamic-form
									ref="dynamicForm"
									:form-schema="form('manager_user').value"
									:form-data="formData"
									:custom-error="customError" 
									:submit-button-loading="submitting"
									:submit-button-disabled="!accessFormData?.userGroups?.length"
									@dynamic-form-submit="submit"
									@form-updated="customError = {}"
								/>
							</v-sheet>
						</v-stepper-content>
					</v-stepper>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import DynamicForm from '@/components/common/base/dynamic-form';

	export default {
		name: 'app-admin-users-details',

		components: {
			DynamicForm
		},

		data() {
			return {
				stepper: 1,
				formData: {},
				formSchema: [],
				accessFormData: {},
				accessFormSchema: [
					{
						type: 'select',
						name: 'userGroups',
						multiple: true,
						rules: 'required',
						display: 'Access',
						options: [
							{ text: 'Admin', value: 'administrator.system' },
							{ text: 'Cases', value: 'administrator.cases' },
							{ text: 'CMS', value: 'administrator.cms' },
							{ text: 'Enquiries', value: 'administrator.enquiries' },
							{ text: 'Firm Rewards', value: 'administrator.firmRewards' },
							{ text: 'KFI Requests', value: 'administrator.kfi' },
							{ text: 'Rewards', value: 'administrator.rewards' },
							{ text: 'User', value: 'administrator.user' }
						]
					}
				],
				loading: false,
				submitting: false,
				customError: {}
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form']),

			userId() {
				return this.$route.params.id;
			}
		},

		created() {
			this.loadForm('manager_user');
		},

		methods: {
			...mapActions('AccountUser', ['addUser', 'addUserDepartment', 'addUserGroups']),
			...mapActions('AccountDepartment', ['getDepartmentList']),
			...mapActions('AccountGroup', ['getGroupList']),
			...mapActions('CmsForm', ['loadForm']),

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submit(data) {
				this.submitting = true;

				this.addUser({
					type: 'admin',
					name: data.name,
					description: data.description,
					userIdentity: [{ identity: data.username }],
					userAccount: { password: data.password },
					userDetail: { email: data.email }
				})
					.then(({ data: user }) => {
						this.getDepartmentList({
							where: {
								'organisation.name_unique': 'air_sourcing',
								'organisation.type': 'system',
								'department.name_unique': 'air_sourcing.administrator'
							}
						}).then(({ data: departments }) => {
							this.addUserDepartment({
								userId: user.id,
								departmentId: departments.data[0].id,
								departmentOrganisationId: departments.data[0].organisationId
							})
						}).then(() => {
							this.getGroupList({ 
								where: { name_unique: { type: 'text', value: this.accessFormData.userGroups } } 
							}).then(({ data: groups }) => {
								this.addUserGroups({
									id: user.id,
									payload: { groups: groups.data.map((g) => ({ id: g.id })) }
								}).then(() => {
									this.$router.push(`/admin/users`);
									ElementTools.fireNotification(this.$parent.$el, 'success', 'User has been added successfully');
								});
							});
						});
					})
					.catch((error) => {
						let uniqueValidationError;
						const { status, data } = error.response;

						if (status === 422) {
							data.forEach((i) => {
								const label = i.label.substring(i.label.indexOf('.') + 1);
								if (['email', 'identity'].includes(label)) {
									uniqueValidationError = true;
									this.$set(this.customError, label === 'identity' ? 'username' : label, i.message);
								}
							});
						}
						
						if (!uniqueValidationError) ElementTools.fireNotification(this.$parent.$el, 'error', 'Failed to add user')
					})
					.finally(() => (this.submitting = false));
			}
		}
	};
</script>