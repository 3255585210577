import { RewardTransaction } from '@/service';

/**
 * @class @name
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @name addRewardTransaction
		 * @param {Object} context
		 */
		addRewardTransaction(context, payload) {
			return RewardTransaction.add(payload);
		}
	}
};
