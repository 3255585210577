<template>
	<div class="pa-8">
		<v-chip-group v-model="selectedDisplayOption" mandatory active-class="success" dark>
			<v-chip v-for="option in displayOptions" :key="option.value" label :value="option.value">
				{{ option.text }}
			</v-chip>
		</v-chip-group>

		<div class="d-flex mt-1">
			<validation-observer ref="observer" class="pa-0 col-6">
				<validation-provider v-slot="{ errors }" name="Search" rules="search">
					<v-text-field v-model="searchTerm" placeholder="Search" append-icon="mdi-magnify" dense solo light clear-icon="mdi-close-circle" clearable :error-messages="errors" @click:clear="searchTerm = ''" />
				</validation-provider>
			</validation-observer>
			<v-spacer />
			<v-pagination v-if="!!pageCount" v-model="options.page" :length="pageCount" total-visible="7" :disabled="loading.enquiries" class="mt-n1" />
		</div>

		<v-data-table
			:headers="headers"
			:footer-props="{ 'disable-items-per-page': true, 'disable-pagination': false }"
			:items="enquiries"
			:loading="loading.enquiries"
			:server-items-length="total"
			:item-class="itemClass"
			class="elevation-1"
			:options.sync="options"
		>
			<template #item.name="{ item }">
				<p class="font-weight-bold mt-4 mb-0">
					{{ item.userName }}
				</p>
				<p>{{ item.organisationName }}</p>
			</template>
			<template #item.enquiry="{ item }">
				<p class="font-weight-bold mt-4 mb-0">
					{{ getClientName(item) }}
				</p>
				<p>{{ getPropertyAddress(item) }}</p>
			</template>
			<template #item.responses="{ item }">
				<div v-if="item.unansweredResponse">{{ item.unansweredResponse }} providers yet to respond</div>
				<div v-if="item.outstandingResponse">{{ item.outstandingResponse }} outstanding responses</div>
				<div v-if="item.numberOfResponse === 0">Enquiry requires a response</div>
			</template>
			<template #item.status="{ item }">
				<p class="text-capitalize">
					{{ item.status }}
				</p>
			</template>
			<template #item.lastResponse="{ item }"> {{ item.lastResponse.by }} @ {{ item.lastResponse.at | moment('Do MMM YYYY, HH:mm') }} </template>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>
			<template #item.updated="{ item }">
				{{ item.updated | moment('Do MMM YYYY, HH:mm') }}
			</template>
			<template #item.deleted="{ item }">
				{{ item.deleted | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn v-if="isClosed(item)" color="green" class="ml-2 mr-2" small dark :disabled="!!item.deleted" :loading="loading.status[item.id]" @click="openEnquiry(item)"> Open Enquiry </v-btn>
					<v-btn v-else color="orange" class="ml-2 mr-2" small dark :disabled="!!item.deleted" :loading="loading.status[item.id]" @click="closeEnquiry(item)"> Close Enquiry </v-btn>
					<v-btn small color="success" :disabled="!!item.deleted" :to="`/enquiry/${item.id}`"> view </v-btn>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { ElementTools } from '@/utils';
	import { mapActions } from 'vuex';
	import { debounce } from 'lodash';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';

	export default {
		name: 'app-enquiries',

		components: {
			ValidationObserver,
			ValidationProvider
		},

		data: () => ({
			enquiries: [],
			total: 0,
			options: {
				page: 1,
				itemsPerPage: 10
			},
			pageCount: 0,
			loading: {
				enquiries: false,
				withdraw: {},
				status: {}
			},
			headers: [
				{ text: 'Ref', value: 'matterReference', sortable: false },
				{ text: 'Name', value: 'name', sortable: false },
				{ text: 'Enquiry', value: 'enquiry', sortable: false },
				{ text: 'Providers', value: 'providers', sortable: false },
				{ text: 'Responses', value: 'responses', sortable: false },
				{ text: 'Last Response', value: 'lastResponse', sortable: false },
				{ text: 'Status', value: 'status', sortable: false },
				{ text: 'Submitted', value: 'created' },
				{ text: 'Updated', value: 'updated', sortable: false },
				{ text: 'Deleted', value: 'deleted', sortable: false },
				{ text: '', value: 'actions', sortable: false }
			],
			selectedDisplayOption: 'open',
			searchTerm: '',
			displayOptions: [
				{ text: 'All', value: null },
				{ text: 'Open', value: 'open' },
				{ text: 'Closed', value: 'closed' }
			]
		}),

		watch: {
			options: {
				handler() {
					this.fetchEnquiries();
				},
				deep: true
			},

			async searchTerm() {
				const valid = await this.$refs.observer.validate();
				if(!valid) return;
				this.debouncedFetchEnquiries();
			},

			selectedDisplayOption() {
				this.fetchEnquiries();
			}
		},

		methods: {
			...mapActions('AppMatter', ['modifyMatter']),
			...mapActions('Enquiry', ['fetchEnquiryList']),

			/**
			 * @name fetchEnquiries
			 * @description Fetch enquiries
			 */
			fetchEnquiries() {
				const { page, itemsPerPage, sortBy, sortDesc } = this.options;
				const payload = {
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					order: [
						{ property: "matter.data->>'closed' IS NULL", direction: 'DESC' },
						{ property: "matter.data->>'closed'", direction: 'DESC' },
						{ property: 'matter.updated', direction: 'ASC' }
					],
					where: undefined,
					search: this.searchTerm ? `%${this.searchTerm.trim()}%` : undefined,
				};

				// If sort by has 'created', remove matter.updated from ordering and add matter.created as first order
				if (sortBy?.length && sortBy.includes('created')) {
					payload.order = payload.order.filter((o) => o.property !== 'matter.updated');
					payload.order.unshift({ property: 'matter.created', direction: sortDesc[0] ? 'DESC' : 'ASC' });
				}

				if (this.selectedDisplayOption) {
					payload.where = ['('];
					if (this.selectedDisplayOption === 'open') {
						payload.where.push({ "matter.data->>'closed'": null });
						payload.where.push('&&');
						payload.where.push({ 'matter.deleted': null });
					} else if (this.selectedDisplayOption === 'closed') payload.where.push({ "matter.data->>'closed'": { c: 'IS NOT', v: null } });
					payload.where.push(')');
				}

				this.loading.enquiries = true;
				this.fetchEnquiryList(payload)
					.then(({ data }) => {
						this.enquiries = data.data;
						this.total = data.total;
						this.pageCount = Math.ceil(data.total / itemsPerPage);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to get enquiries'))
					.finally(() => (this.loading.enquiries = false));
			},

			/**
			 * @name debouncedFetchEnquiries
			 * @description Load enquiries with debounce
			 */
			debouncedFetchEnquiries: debounce(function () {
				this.options.page = 1;
				this.fetchEnquiries();
			}, 1000),

			/**
			 * @name isClosed
			 * @description check if the enquiry is closed
			 * @param {Object} enquiry
			 */
			isClosed(enquiry) {
				return enquiry.data?.closed;
			},

			/**
			 * @name openEnquiry
			 * @description Open enquiry
			 * @param {Object} enquiry
			 */
			openEnquiry(enquiry) {
				this.$set(this.loading.status, enquiry.id.toString(), true);
				const payload = { data: { closed: null } };

				this.modifyMatter({ id: enquiry.id, payload })
					.then(() => {
						this.fetchEnquiries();
						ElementTools.fireNotification(this.$el, 'success', 'Enquiry has been successfully opened');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured'))
					.finally(() => this.$set(this.loading.status, enquiry.id.toString(), false));
			},

			/**
			 * @name closeEnquiry
			 * @description Close enquiry
			 * @param {Object} enquiry
			 */
			closeEnquiry(enquiry) {
				this.$set(this.loading.status, enquiry.id.toString(), true);
				const payload = { data: { closed: new Date() } };

				this.modifyMatter({ id: enquiry.id, payload })
					.then(() => {
						this.fetchEnquiries();
						ElementTools.fireNotification(this.$el, 'success', 'Enquiry has been successfully closed');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured'))
					.finally(() => this.$set(this.loading.status, enquiry.id.toString(), false));
			},

			/**
			 * @name getClientName
			 * @description Get client name
			 * @param {Object} item
			 */
			getClientName(item) {
				let clientName = '';
				item.client.map((client, index) => {
					clientName += `${index > 0 ? ' and ' : ''}${client.nameTitle || '--'} ${client.nameGiven || '--'} ${client.nameFamily || '--'}`;
				});
				return clientName;
			},

			/**
			 * @name getPropertyAddress
			 * @description Get property address
			 * @param {Object} item
			 */
			getPropertyAddress(item) {
				const { location: property } = item.asset;
				return [property.address1, property.address2, property.townCity, property.postcode].filter((p) => p).join(', ');
			},

			/**
			 * @name withdrawItem
			 * @description Withdraw enquiry
			 * @param {String} id
			 */
			withdrawItem(id) {
				this.loading.withdraw[id] = true;
				this.modifyMatter({ id, payload: { status: 'withdrawn' } })
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Enquiry withdrawn');
						this.fetchEnquiries();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to withdraw enquiry'))
					.finally(() => (this.loading.withdraw[id] = false));
			},

			/**
			 * @name itemClass
			 * @description Get item class
			 * @param {Object} item
			 */
			itemClass(item) {
				return {
					'red lighten-4': item.status === 'open',
					'grey lighten-4': item.status === 'withdrawn',
					'green lighten-4': item.status === 'closed'
				};
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep ul.v-pagination {
		justify-content: end !important;
	}

	.green {
		background-color: #4caf50;
	}
</style>
