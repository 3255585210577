<!--
	@name common-base-dynamic-form
	@description Dynamically created form component with a schema
	@date 2020/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<validation-provider v-slot="{ errors }" :name="field.display" :rules="rules" :vid="field.ref">
		<v-text-field
			:ref="field.ref"
			v-model="inputModel"
			:error-messages="errors"
			:label="field.display"
			:placeholder="field.placeholder"
			type="password"
			:hide-details="!errors.length && !field.hint"
			:disabled="disabled"
			:prefix="field.prefix"
			:readonly="field.readonly"
			:prepend-icon="field.prependIcon"
			:prepend-inner-icon="field.prependInnerIcon"
			:suffix="field.suffix"
			:counter="field.counter"
			:hint="field.hint"
			persistent-hint
			outlined
			:dense="field.dense === null || field.dense !== false"
			loading
			class="v-password"
			@input="onInput($event)"
		>
			<template #progress>
				<v-row class="v-progress-bars">
					<v-col cols="12" sm="3">
						<v-progress-linear :value="scoreStep >= 25 ? 100 : 0" :color="scoreColor" height="4" />
					</v-col>
					<v-col cols="12" sm="3">
						<v-progress-linear :value="scoreStep >= 50 ? 100 : 0" :color="scoreColor" height="4" />
					</v-col>
					<v-col cols="12" sm="3">
						<v-progress-linear :value="scoreStep >= 75 ? 100 : 0" :color="scoreColor" height="4" />
					</v-col>
					<v-col cols="12" sm="3">
						<v-progress-linear :value="scoreStep == 100 ? 100 : 0" :color="scoreColor" height="4" />
					</v-col>
				</v-row>
			</template>
			<template #append>
				<span :class="`${scoreColor}--text text--darken-2`">
					{{ scoreText }}
				</span>
			</template>
		</v-text-field>
	</validation-provider>
</template>

<script>
	import { passwordStrength } from 'check-password-strength';
	import { ValidationProvider } from 'vee-validate';

	export default {
		name: 'common-base-password',

		components: {
			ValidationProvider
		},

		props: {
			model: {
				type: String,
				default: null
			},

			field: {
				type: Object,
				required: true
			},

			disabled: {
				type: Boolean,
				default: false
			},

			error: {
				type: Array,
				default: () => []
			},

			rules: {
				type: String,
				default: ''
			}
		},

		data() {
			return {
				score: null,
				inputModel: null
			};
		},

		computed: {
			scoreText() {
				return this.score?.value;
			},

			scoreColor() {
				let score = this.score?.id;
				if (score == 3) return 'green';
				if (score == 2) return 'light-green';
				if (score == 1) return 'amber';
				if (score == 0) return 'red';
				return 'grey';
			},

			scoreStep() {
				let score = this.score?.id;
				if (score == 3) return 100;
				if (score == 2) return 75;
				if (score == 1) return 50;
				if (score == 0) return 25;
				return 0;
			}
		},

		methods: {
			/**
			 * @name onInput
			 * @description on text field input
			 */
			onInput(inputValue) {
				this.score = passwordStrength(inputValue, [
					{ id: 0, value: 'Woeful', minDiversity: 0, minLength: 0 },
					{ id: 1, value: 'Weak', minDiversity: 2, minLength: 6 },
					{ id: 2, value: 'Good', minDiversity: 4, minLength: 8 },
					{ id: 3, value: 'Strong', minDiversity: 4, minLength: 10 }
				]);

				this.$emit('update:model', inputValue);
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '~vuetify/src/styles/styles.sass';

	.v-password {
		::v-deep .v-input__slot {
			margin-bottom: 16px !important;
		}

		::v-deep .v-progress-linear__background {
			display: block !important;
		}

		.v-progress-linear {
			border-radius: 5px;
		}

		.v-progress-bars {
			width: 100%;
			position: absolute;
			bottom: 0;

			.col-12 {
				padding: 0 2px;
			}
		}
	}

	::v-deep .v-input__append-inner {
		margin: 0 !important;
		align-self: center !important;
		font-size: 0.8rem;
		font-weight: 500;
		text-transform: uppercase;
	}

	::v-deep .v-text-field__details {
		margin-top: 6px;
		//margin-bottom: 0px !important;
	}
</style>
