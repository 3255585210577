<template>
	<div class="pa-8">
		<div class="pb-4">
			<v-btn color="primary" dark @click="openAddDialog"> Add New Asset </v-btn>
		</div>
		<div class="d-flex">
			<validation-observer ref="observer" class="pa-0 col-6">
				<validation-provider v-slot="{ errors }" name="Search" rules="search">
					<v-text-field v-model="searchTerm" placeholder="Search" append-icon="mdi-magnify" dense solo light clear-icon="mdi-close-circle" clearable :error-messages="errors" @click:clear="searchTerm = ''" />
				</validation-provider>
			</validation-observer>
			<v-spacer />
			<v-pagination v-model="options.page" :length="pages" total-visible="7" :disabled="loading" />
		</div>
		<v-data-table
			:headers="headers"
			:items="items"
			class="elevation-1"
			:options.sync="options"
			:server-items-length="total"
			:loading="loading"
			:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [5, 10, 15, 20, 25] }"
		>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.updated="{ item }">
				{{ item.updated | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn small color="success" :to="`/cms/asset/${item.id}`"> edit </v-btn>
					<v-btn small dark class="ml-2" color="error" :loading="deleting[item.id]" @click="deleteItem(item.id)"> delete </v-btn>
				</div>
			</template>
		</v-data-table>

		<common-dialog ref="new-asset" :max-width="600">
			<template #header> Add New Asset </template>

			<template #body>
				<validation-observer ref="observer">
					<v-form ref="form" class="pt-8">
						<validation-provider v-slot="{ errors }" name="Asset Name" rules="required">
							<v-text-field v-model="assetName" label="Asset Name" :error-messages="errors" outlined required />
						</validation-provider>
						<div class="d-flex justify-center">
							<v-btn color="success" :loading="submitLoading" @click="createAsset"> Submit </v-btn>
						</div>
					</v-form>
				</validation-observer>
			</template>
		</common-dialog>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import { debounce } from 'lodash';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import CommonDialog from '@/components/common/dialog';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';

	export default {
		name: 'app-cms-asset',

		components: {
			CommonDialog,
			CommonDialogConfirm,
			ValidationObserver,
			ValidationProvider
		},

		data: () => ({
			items: [],
			loading: false,
			deleting: {},
			options: {},
			total: 0,
			pages: 0,
			assetName: '',
			submitLoading: false,
			searchTerm: '',
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Direct Link', value: 'directlink' },
				{ text: 'Download Link', value: 'downloadlink' },
				{ text: 'Created', value: 'created' },
				{ text: 'Updated', value: 'updated' },
				{ text: '', value: 'actions', sortable: false }
			]
		}),

		watch: {
			options: {
				handler() {
					this.fetchAssetList();
				},
				deep: true
			},

			async searchTerm() {
				const valid = await this.$refs.observer.validate();
				if (!valid) return;
				this.debouncedfetchAssetList();
			}
		},

		methods: {
			...mapActions('CmsAsset', ['addAsset', 'getAssetList', 'deleteAsset']),

			openAddDialog() {
				this.$refs['new-asset'].open();
			},

			async createAsset() {
				const valid = await this.$refs.observer.validate();

				if (!valid) return;

				this.submitLoading = true;

				this.addAsset({
					name: this.assetName
				})
					.then(({ data }) => {
						this.$router.push(`/cms/asset/${data.id}`);
						this.$refs['new-asset'].close();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to create asset record'))
					.finally(() => (this.submitLoading = false));
			},

			async fetchAssetList() {
				if (this.loading) return;

				this.loading = true;
				const { sortBy, sortDesc, page, itemsPerPage } = this.options;

				const payload = {
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					order: sortBy.length > 0 ? { property: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc' } : { property: 'created', direction: 'desc' },
					where: this.searchTerm ? [{ 'asset.name': { c: 'ILIKE', v: `%${this.searchTerm}%` } }] : undefined
				};

				let { data } = await this.getAssetList(payload);
				this.items = data.data;
				this.total = data.total;
				this.pages = Math.ceil(this.total / this.options.itemsPerPage);
				this.loading = false;
			},

			/**
			 * @name debouncedfetchAssetList
			 * @description Load Asset with debounce
			 */
			debouncedfetchAssetList: debounce(function () {
				this.options.page = 1;
				this.fetchAssetList();
			}, 1000),

			async deleteItem(id) {
				this.$refs.confirm
					.open('Delete Asset item', `Are you sure you wish to delete this asset item?`)
					.then(() => {
						this.deleting[id] = true;
						this.deleteAsset(id)
							.then(() => {
								ElementTools.fireNotification(this.$el, 'success', 'Asset item has been deleted successfully');
								this.fetchAssetList();
							})
							.catch(() => {
								ElementTools.fireNotification(this.$el, 'error', 'Failed to delete Asset item');
							})
							.finally(() => {
								this.deleting[id] = false;
							});
					})
					.catch(() => {});
			}
		}
	};
</script>
