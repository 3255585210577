import { $http } from '@/utils';

/**
 * @namespace Service
 * @class MandatoryPaymentsRateUpdate
 * @exports Service/Product
 * @description API Mandatory Payments Rate Update Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name validate
	 * @description Send a request to process CSV file and validate
	 * @returns {Object} Promise a http response
	 */
	validate: (payload) => $http.post(`product/mandatory-payments-rate-update/validate`, payload),

	/**
	 * @name addSchedule
	 * @description Add a schedule to process CSV file and store data in Redis
	 * @returns {Object} Promise a http response
	 */
	addSchedule: (payload) => $http.post(`product/mandatory-payments-rate-update/schedule`, payload)
};
