import { Client } from '@/service';

/**
 * @class @name Client
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	state: {
		clientList: []
	},

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	getters: {
		/**
		 * @name client
		 * @description used to get filtered clients from store
		 * @param {Object} state
		 */
		client: (state) => (id) => {
			return state.clientList.find((i) => i.id === id) || {};
		}
	},

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	mutations: {
		/**
		 * @name setClients
		 * @param {Object} state
		 * @param {Object} data
		 */
		setClientList(state, data) {
			state.clientList = data;
		},

		deleteClient(state, clientId) {
			state.clientList = state.clientList.filter((client) => client.id !== clientId);
		}
	},

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name loadClientList
		 * @param {Object} store
		 * @param {Object} options
		 */
		async loadClientList(context, payload) {
			const response = await Client.list({
				order: {
					property: 'updated',
					direction: 'desc'
				},
				...payload
			});

			let clientList = response.data.data.map((i) => ({ ...i, nameFull: `${i.nameTitle} ${i.nameGiven}${i.nameMiddle ? i.nameMiddle : ' '}${i.nameFamily}` }));
			return clientList;
		},

		/**
		 * @name loadAggregatedClientList
		 * @param {function} commit
		 * @param {Number} payload
		 */
		loadAggregatedClientList(context, payload) {
			return Client.aggregatedList(payload);
		},

		/**
		 * @name loadClient
		 * @param {function} commit
		 * @param {Number} id
		 */
		loadClient(context, id) {
			return Client.get(id);
		},

		/**
		 * @name addClient
		 * @param {function} commit
		 * @param {Object} client
		 */
		addClient(context, client) {
			return Client.add(client);
		},

		/**
		 * @name deleteClient
		 * @param {function} commit
		 * @param {Number} id
		 */
		deleteClient(context, clientId) {
			return Client.delete(clientId);
		}
	},

	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
