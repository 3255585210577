<template>
	<div class="pa-8">
		<div class="d-flex mt-1">
			<v-text-field v-model="reference" placeholder="Reference" append-icon="mdi-magnify" hide-details dense solo light clear-icon="mdi-close-circle" clearable @click:clear="clearReference()" @input="debouncedSearch"/>
		</div>

		<v-data-table
			
			:headers="headers"
			:items="filteredLogs"
			class="elevation-1 mt-3"
			:loading="loading"
			:no-results-text="'No results found'"
			:no-data-text="'Enter reference to fetch logs'"
			disable-pagination
			hide-default-footer
			:options.sync="options"
		>

		<template #top>
				<v-toolbar flat class="pt-1">
					<v-spacer />
					<validation-observer ref="observer">
						<validation-provider v-slot="{ errors }" name="Search" rules="search">
							<v-text-field
								v-model="searchTerm"
								placeholder="Search"
								append-icon="mdi-magnify"
								dense
								solo
								light
								clear-icon="mdi-close-circle"
								clearable
								class="mt-6"
								:class="{ 'flex-shrink-0': '20rem' }"
								:style="{ width: '20rem', 'max-width': '20rem' }"
								:error-messages="errors"
								@click:clear="searchTerm = ''"
							/>
						</validation-provider>
					</validation-observer>
				</v-toolbar>
			</template>

			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm:ss') }}
			</template>
			<template #item.actions="{ item }">
				<v-btn icon @click="openLog(item)">
					<v-icon :color="item.loaded ? 'success' : 'primary'">mdi-eye</v-icon>
				</v-btn>
			</template>
		</v-data-table>
		<common-dialog ref="log" :max-width="1000">
			<template #header> Logs </template>

			<template #body>
				<v-expansion-panels v-if="log" v-model="panel" class="mt-5">
					<v-expansion-panel>
						<v-expansion-panel-header> Details </v-expansion-panel-header>
						<v-expansion-panel-content>
							<p v-if="log?.details?.reference" class="text-caption mt-1 mb-0"><span class="font-weight-bold"> Reference: </span>{{ log.details.reference }}</p>
							<p v-if="log?.details?.type" class="text-caption mt-1 mb-0"><span class="font-weight-bold"> Type: </span>{{ log.details.type }}</p>
							<p v-if="log?.details?.url" class="text-caption mt-1 mb-0"><span class="font-weight-bold"> Method: </span> {{ log.details.url }}</p>
							<p v-if="log?.details?.created" class="text-caption mt-1 mb-0"><span class="font-weight-bold"> Created: </span>{{ log.details.created | moment('Do MMM YYYY, HH:mm:ss') }}</p>
							<p v-if="log?.response?.status" class="text-caption mt-1 mb-0">
								<span class="font-weight-bold">Status: </span>
								<span v-if="[200, 201].includes(log.response.status)" :style="{ color: 'green' }">{{ log.response.status }}</span>
								<span v-else :style="{ color: 'red' }">{{ `${log.response.status} - "${log.response?.body?.error || log.response?.body?.message}"` }}</span>
							</p>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header> Request </v-expansion-panel-header>
						<v-expansion-panel-content>
							<json-viewer :value="log.request" boxed copyable expanded />
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header> Response </v-expansion-panel-header>
						<v-expansion-panel-content>
							<json-viewer :value="log.response" boxed copyable expanded />
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel v-if="log.error">
						<v-expansion-panel-header> Error </v-expansion-panel-header>
						<v-expansion-panel-content>
							<json-viewer :value="log.error" boxed copyable expanded />
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<v-skeleton-loader v-else type="list-item-three-line" class="pa-0 mt-5 mb-0" />
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import CommonDialog from '@/components/common/dialog';
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import { debounce } from 'lodash';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';

	export default {
		name: 'app-admin-api-logs',

		components: {
			CommonDialog,
			ValidationObserver,
			ValidationProvider
		},

		data: () => ({
			reference: '',
			searchTerm: '',
			loading: false,
			options: {
				page: 1,
				sortBy: ['created'],
				sortDesc: [false],
				multiSort: false,
				mustSort: true
			},
			headers: [
				{ text: 'Reference', align: 'start', value: 'reference', sortable: false },
				{ text: 'Type', align: 'start', value: 'type', sortable: false },
				{ text: 'Method', align: 'start', value: 'url', sortable: true },
				{ text: 'Created', align: 'center', value: 'created', sortable: true },
				{ text: 'Actions', align: 'center', value: 'actions', sortable: false }
			],
			logs: [],
			filteredLogs: [],
			log: null
		}),

		watch: {
			options: {
				handler() {
					this.search();
				},
				deep: true
			},

			reference() {
				if (!this.reference) {
					this.clearReference();
				}
			},

			async searchTerm() {
				const valid = await this.$refs.observer.validate();
				if (!valid) return;
				this.filter();
			}
		},

		methods: {
			...mapActions('LogApi', ['fetchLogs', 'fetchLog']),

			/**
			 * @name debouncedfetchMediaList
			 * @description Load media with debounce
			 */
			debouncedSearch: debounce(function () {
				this.search();
			}, 500),

			clearReference() {
				this.loading = false;
				this.reference = '';
				this.searchTerm = '';
				this.logs = [];
				this.filteredLogs = [];
				this.log = null;
			},

			search() {
				if (this.loading) return;

				if (!this.reference || this.reference?.length < 3) {
					this.data = [];
					return;
				}

				this.logs = [];
				this.log = null;
				this.loading = true;

				const { sortBy, sortDesc } = this.options;
				const payload = {
					order: sortBy.length > 0 && {
						property: sortBy[0],
						direction: sortDesc[0] ? 'desc' : 'asc'
					},
					where: {
						reference: this.reference
					}
				};

				this.fetchLogs(payload)
					.then((resp) => {
						resp.data?.count === 0 ? ElementTools.fireNotification(this.$el, 'error', 'There are no logs available for this request') : null;
						this.logs = resp.data?.data;
						this.filteredLogs = resp.data?.data;
						this.loading = false;
					})
					.catch(() => {
						this.loading = false;
						ElementTools.fireNotification(this.$el, 'error', 'There are no logs available for this request');
					});
			},

			filter() {
				if (this.searchTerm) {
					this.filteredLogs = this.logs.filter((log) => {
						return log.url && log.url.toLowerCase().includes(this.searchTerm.toLowerCase());
					});
				} else {
					this.filteredLogs = this.logs;
				}
			},

			async openLog(item) {
				this.panel = 0;
				this.log = null;
				this.$refs.log.open();
				const { data } = await this.fetchLog(item.id);
				this.log = { ...data, details: item };
				if (!item.loaded) {
					const index = this.logs.findIndex((i) => i.id === item.id);
					this.$set(this.logs, index, { ...this.logs[index], loaded: true });
					this.filter();
				}
			}
		}
	};
</script>

<style scoped></style>
