import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Matter
 * @exports Service/Process
 * @description API Matter Process Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description list process
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`process/list`, payload)
};
