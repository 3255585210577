import { MatterEvent } from '@/service';

/**
 * @class @name
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name addEvent
		 * @param {Object} payload
		 */
		addEvent(context, payload) {
			return MatterEvent.add(payload);
		},

		/**
		 * @async @name fetchEventList
		 * @param {Object} payload
		 */
		fetchEventList(context, payload) {
			return MatterEvent.list(payload);
		}
	}
};
