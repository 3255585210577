<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>User #{{ userId }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/user/${userId}`">
				Back to user
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<p class="caption mb-5">
						Notes: History and deduct removed. Moved current balance here from details form
					</p>
					<v-alert v-if="restricted" text outlined type="error" border="left" class="ma-0">
						<span class="text-body-2">Rewards cannot be updated for provider preview account</span>
					</v-alert>
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<dynamic-form :form-schema="formSchema.balance" :form-data="formData.balance" :submit-button-loading="submitting" @dynamic-form-submit="updateBalance" />
						</v-sheet>
						<v-sheet class="pa-6 mt-6" elevation="4">
							<dynamic-form :form-schema="formSchema.add" :form-data="formData.add" :submit-button-loading="submitting" @dynamic-form-submit="addPoints" />
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import DynamicForm from '@/components/common/base/dynamic-form';

	export default {
		name: 'app-users-details-rewards',

		components: {
			DynamicForm
		},

		data() {
			return {
				formSchema: {
					balance: [],
					add: []
				},
				formData: {
					balance: {},
					add: {}
				},
				user: null,
				loading: {
					user: false,
					balance: false,
					add: false
				},
				submitting: false
			};
		},

		computed: {
			...mapGetters('CmsForm', ['formFromGroup']),

			userId() {
				return this.$route.params.id;
			},

			restricted() {
				return this.user?.organisation.type === 'provider';
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('AccountUser', ['fetchUser', 'modifyUser']),
			...mapActions('AppReward', ['addRewardTransaction']),
			...mapActions('CmsForm', ['loadForm']),

			async init() {
				this.$route.meta.breadcrumb[2] = {
					text: `User #${this.userId}`,
					to: `/user/${this.userId}`
				};

				await this.loadForm('manager_user_rewards');
				this.formSchema.balance = this.formFromGroup('manager_user_rewards', 'balance');
				this.formSchema.add = this.formFromGroup('manager_user_rewards', 'add');

				// await this.loadForm('user-details');

				if (this.userId) {
					this.getUser();
				}
			},

			getUser() {
				this.loading.user = true;

				this.fetchUser(this.userId)
					.then(({ data }) => {
						this.user = data;
						this.formData.balance = { rewardsBalance: data.data.rewardsBalance };
					})
					.finally(() => (this.loading.user = false));
			},

			updateBalance(data) {
				this.loading.balance = true;
				this.modifyUser({
					id: this.userId,
					data: {
						data: {
							rewardsBalance: parseInt(data.rewardsBalance)
						}
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Rewards balance has been updated successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to update Rewards balance'))
					.finally(() => (this.loading.balance = false));
			},

			addPoints(data) {
				let payload = {
					userId: this.userId,
					creditId: data.type,
					data: { type: 'credit', client: data.reference, provider: data.provider }
				};

				// if other selected, add amount and reason
				if (data.type === this.formSchema.add?.find((item) => item.name === 'type')?.options?.find((item) => item.nameUnique === 'other')?.value) {
					payload.amount = data.amount;
					payload.data.reason = data.reason;
				}

				this.addRewardTransaction(payload)
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'Reward points has been added successfully');
						this.formData.add = {};
						this.getUser();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to add Reward points'))
					.finally(() => (this.loading.balance = false));
			}
		}
	};
</script>

<style></style>
