import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Case
 * @exports Service/Case
 * @description API Case Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @@name list
	 * @description Get all cases
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post('case/list', payload)
};
