import { AccountGroup } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getGroupList
		 * @param {Object} context
		 * @param {Object} payload
		 */
		getGroupList(context, payload) {
			return AccountGroup.list(payload);
		},

		/**
		 * @async @name getGroupList
		 * @param {Object} context
		 * @param {Object} payload
		 */
		getGroupRoleList(context, payload) {
			return AccountGroup.roles(payload);
		},

		/**
		 * @async @name addGroup
		 * @param {Object} context
		 * @param {Object} payload
		 */
		addGroup(context, payload) {
			return AccountGroup.add(payload);
		},

		/**
		 * @async @name addGroup
		 * @param {Object} context
		 * @param {Object} payload
		 */
		addGroupRole(context, payload) {
			return AccountGroup.addRole(payload);
		},

		/**
		 * @async @name deleteGroupRole
		 * @param {Object} context
		 * @param {Object} payload
		 */
		deleteGroupRole(context, payload) {
			return AccountGroup.deleteRole(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
