import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Group
 * @exports Service/Account/User/Group
 * @description API Group Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get Group list
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`account/group/list`, payload),

	/**
	 * @name roles
	 * @description Get Group Roles list
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	roles: (payload) => $http.post(`account/group/${payload.where.group_id}/role/list`, payload),

	/**
	 * @name add
	 * @description Add a new group
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`account/group`, payload),

	/**
	 * @name addRole
	 * @description Add a new group role
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	addRole: (payload) => $http.post(`account/group/${payload.groupId}/role`, payload),

	/**
	 * @name addRole
	 * @description Add a new group role
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	deleteRole: (payload) => $http.delete(`account/group/${payload.groupId}/role/${payload.roleId}`, payload)
};
