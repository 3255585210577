import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Organisation
 * @exports Service/Matter/Type/[mtid]/Organisation
 * @description API Matter Type Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @@name add
	 * @description Add matter type
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (matterTypeId, payload) => $http.post(`matter/type/${matterTypeId}/organisation`, payload),

	/**
	 * @@name delete
	 * @description Delete matter type
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	delete: (matterTypeId, organisationId) => $http.delete(`matter/type/${matterTypeId}/organisation/${organisationId}`)
};
