<!--
	@name app-enquiry-details-documents
	@description Documents section
	@date 2020/07/08
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-structure-section :component="$options.name" class="mb-6">
		<template #header>
			Documents
		</template>

		<template #body>
			<div v-if="responses && responses.length">
				<div v-if="files.length">
					<v-list two-line class="pa-0">
						<v-list-item v-for="(file, index) in files" :key="index" two-line class="elevation-2 blue-grey lighten-5" :class="[{ 'mt-3': index !== 0 }]">
							<v-list-item-avatar class="py-3 pr-3 ma-0 align-self-start" height="auto" width="auto">
								<v-icon large color="primary">
									mdi-file-download
								</v-icon>
							</v-list-item-avatar>

							<v-list-item-content class="py-3 align-self-start">
								<v-list-item-title class="text-subtitle-1 font-weight-bold ma-0 mb-1">
									{{ file.filename }}
								</v-list-item-title>

								<v-list-item-subtitle class="text-body-2 ma-0 mt-1">
									Download document
								</v-list-item-subtitle>
							</v-list-item-content>

							<v-list-item-action>
								<v-btn icon color="primary" :loading="fileLoading[file.s3key]" class="mb-1" @click="downloadFile(file)">
									<v-icon large>
										mdi-download
									</v-icon>
								</v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-list>

					<div class="mt-5 text-center">
						<v-btn color="primary" small outlined @click="$emit('show-all-documents')">
							Show All Documents
						</v-btn>
					</div>
				</div>

				<div v-else>
					No document has been uploaded regarding this Enquiry
				</div>
			</div>

			<v-skeleton-loader v-else type="list-item-three-line@2" class="pa-0 ma-0" />
		</template>
	</common-structure-section>
</template>

<script>
	import { ElementTools } from '@/utils';
	import CommonStructureSection from '@/components/common/structure/section';
	import { mapActions } from 'vuex';

	export default {
		name: 'app-enquiry-details-documents',

		components: {
			CommonStructureSection
		},

		props: {
			responses: {
				type: Array,
				default: () => {}
			}
		},

		data() {
			return {
				fileLoading: {}
			};
		},

		computed: {
			files() {
				let files = [];
				this.responses.map((response) => (response.data?.files || []).map((file) => files.push({ ...file, processId: response.id })));
				return files.slice(0, 3);
			}
		},

		methods: {
			...mapActions('Enquiry', ['fetchFile']),

			async downloadFile(file) {
				this.$set(this.fileLoading, file.s3Key, true);

				this.fetchFile({ id: file.processId, s3key: file.s3Key })
					.then((response) => {
						if (response.data?.preSignedUrl) window.open(response.data.preSignedUrl);
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured. Please try again later'))
					.finally(() => this.$set(this.fileLoading, file.s3Key, false));
			}
		}
	};
</script>
