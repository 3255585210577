import { CmsData } from '@/service';

export default {
	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	state: {
		dataList: []
	},

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	getters: {
		/**
		 * @name data
		 * @description used to get filtered data from store
		 * @param {Object} state
		 */
		data: (state) => (nameUnique) => {
			return state.dataList.find((i) => i.nameUnique === nameUnique) || {};
		}
	},

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	mutations: {
		/**
		 * @name setData
		 * @description used to set data on store
		 * @param {Object} state
		 * @param {Object} data
		 */
		setData: (state, data) => {
			let idx = state.dataList.reduce((p, c, i) => (c.nameUnique === data.nameUnique ? i : p), -1);
			if (idx >= 0) state.dataList[idx] = data;
			else state.dataList.push(data);
		}
	},

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getData
		 * @param {function} commit
		 */
		async loadData({ commit }, name) {
			let response = await CmsData.usingName(name);
			if (!response.error) commit('setData', response.data);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
