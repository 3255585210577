import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Event
 * @exports Service/Matter/Event
 * @description API Matter Event Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get all events
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`matter/event/list`, payload),

	/**
	 * @name add
	 * @description Add a new event
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`matter/event`, payload)
};
