<!--
	@name common-base-dynamic-form
	@description Dynamically created form component with a schema
	@date 2020/03/19
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<validation-observer v-if="formSchema.length" ref="observer">
			<v-form @submit.prevent="submit">
				<v-row>
					<v-col v-for="(field, index) in formSchema" v-show="getDisplayCondition(field)" :key="index" :class="field.colClass" :cols="field.sm" :sm="field.sm">
						<validation-provider v-slot="{ errors }" :name="field.display" :rules="getRules(field, true)" :vid="field.ref" :debounce="getDebounceValue(field.rules)">
							<!-- <address-search
								v-if="field.name === 'propertySearch'"
								:model="formData"
								:value="formData[field.name]"
								:label="getLabel(field)"
								:disabled="field.disabled || getDisabledCondition(field)"
								:hide-details="!errors.length"
								:error-messages="errors"
								:country-options="countryOptions"
							/> -->

							<h1 v-if="field.type === 'title'" :class="[field.class, 'mb-0']">
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-chip v-if="field.new" label small class="mr-1 pink--text yellow px-2" v-on="on">
											NEW
										</v-chip>
									</template>
									<span>{{ field.new }}</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-chip v-if="field.updated" label small class="mr-1 blue--text teal accent-2" v-on="on">
											UPDATED
										</v-chip>
									</template>
									<span>{{ field.updated }}</span>
								</v-tooltip>
								{{ getLabel(field) }}
							</h1>

							<h2 v-else-if="field.type === 'subtitle'" :class="field.class">
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-chip v-if="field.new" label small class="mr-1 pink--text yellow px-2" v-on="on">
											NEW
										</v-chip>
									</template>
									<span>{{ field.new }}</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template #activator="{ on }">
										<v-chip v-if="field.updated" label small class="mr-1 blue--text teal accent-2" v-on="on">
											UPDATED
										</v-chip>
									</template>
									<span>{{ field.updated }}</span>
								</v-tooltip>
								{{ getLabel(field) }}
							</h2>

							<p v-else-if="field.type === 'paragraph'" :class="field.class">
								<v-tooltip v-if="field.tooltip" :max-width="700" :color="field.tooltip.color" top>
									<template #activator="{ on }">
										<span v-on="on">{{ getLabel(field) }}</span>
									</template>
									<div :class="field.tooltip.textClass" v-html="field.tooltip.text" />
								</v-tooltip>
								<span v-else>{{ getLabel(field) }}</span>
							</p>

							<v-alert
								v-else-if="field.type === 'alert'"
								class="mb-0"
								:class="field.class"
								:text="field.alertText || false"
								:prominent="field.alertProminent || false"
								:dense="field.alertDense || true"
								:type="field.alertType || 'info'"
								v-html="getLabel(field)"
							/>

							<!-- eslint-disable vue/no-v-html -->
							<div v-else-if="field.type === 'html'" :class="field.class" v-html="getLabel(field)" />

							<v-divider v-else-if="field.type === 'divider'" :class="field.class" />

							<!-- Password Strength Component -->
							<common-base-password
								v-else-if="['passwordStrength'].includes(field.type)"
								:ref="field.ref"
								:rules="getRules(field, true)"
								:model.sync="formData[field.name]"
								:field="field"
								:disabled="field.disabled || getDisabledCondition(field)"
								:error="errors"
							/>

							<!-- Text Component -->
							<v-text-field
								v-else-if="['text', 'password'].includes(field.type) && field.name !== 'property_search'"
								:ref="field.ref"
								v-model="formData[field.name]"
								:error-messages="customError[field.name] || errors"
								:label="getLabel(field)"
								:placeholder="field.placeholder"
								:type="field.type"
								:hide-details="!errors.length && !field.hint && !customError[field.name]"
								:disabled="field.disabled || getDisabledCondition(field)"
								:prefix="field.prefix"
								:readonly="field.readonly"
								:prepend-icon="field.prependIcon"
								:prepend-inner-icon="field.prependInnerIcon"
								:suffix="field.suffix"
								:counter="field.counter"
								:hint="field.hint"
								persistent-hint
								outlined
								:dense="field.dense === null || field.dense !== false"
								@input="field.onInput ? onInput($event, field.onInput) : false"
							/>

							<!-- Numeric text Component -->
							<v-text-field
								v-else-if="field.type === 'number'"
								:ref="field.ref"
								v-model.number="formData[field.name]"
								:error-messages="errors"
								:label="getLabel(field)"
								:placeholder="field.placeholder"
								:type="field.type"
								:hide-details="!errors.length && !field.hint"
								:disabled="field.disabled || getDisabledCondition(field)"
								:prefix="field.prefix"
								:readonly="field.readonly"
								:prepend-icon="field.prependIcon"
								:prepend-inner-icon="field.prependInnerIcon"
								:suffix="field.suffix"
								:counter="field.counter"
								:hint="field.hint"
								persistent-hint
								outlined
								step="any"
								:dense="field.dense === null || field.dense !== false"
								@input="field.onInput ? onInput($event, field.onInput) : false"
							/>

							<!-- Select Component -->
							<v-select
								v-else-if="field.type === 'select'"
								v-model="formData[field.name]"
								:error-messages="errors"
								:disabled="field.disabled || getDisabledCondition(field)"
								:label="getLabel(field)"
								:items="field.options"
								:multiple="field.multiple"
								:hide-details="!errors.length"
								outlined
								clearable
								dense
							>
								<template v-if="field.selectAll && formData[field.name]" #prepend-item>
									<v-list-item ripple @click="selectAll(field)">
										<v-list-item-action>
											<v-icon v-if="formData[field.name].length == 0" color="green darken-2">
												mdi-checkbox-blank-outline
											</v-icon>
											<v-icon v-else-if="formData[field.name].length > 0 && formData[field.name].length !== field.options.length" color="green darken-2">
												mdi-minus-box
											</v-icon>
											<v-icon v-else color="red lighten-1">
												mdi-close-box
											</v-icon>
										</v-list-item-action>
										<v-list-item-content>
											<v-list-item-title v-if="formData[field.name].length !== field.options.length">
												Select All
											</v-list-item-title>
											<v-list-item-title v-else>
												Clear All
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-divider class="mt-2" />
								</template>
							</v-select>

							<!-- DatePicker Component -->
							<template v-else-if="field.type === 'date-picker'">
								<v-menu v-model="datePickerMenu[field.name]" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
									<template #activator="{ on }">
										<validation-provider v-slot="{ errors }" :name="getLabel(field)" :rules="getRules(field)">
											<v-text-field
												v-model="formData[field.name]"
												:disabled="field.disabled || getDisabledCondition(field)"
												:label="getLabel(field)"
												:hide-details="!errors.length"
												:error-messages="errors"
												outlined
												dense
												autocomplete="off"
												@input="parseDate(field.name)"
												@focus="dateFieldFocus(field)"
												v-on="on"
											/>
										</validation-provider>
									</template>
									<v-date-picker
										v-model="datePickerModel[field.name]"
										:disabled="field.disabled || getDisabledCondition(field)"
										:label="getLabel(field)"
										outlined
										dense
										@input="parseDateToText(field.name)"
									/>
								</v-menu>
							</template>

							<!-- TimePicker Component -->
							<template v-else-if="field.type === 'time-picker'">
								<v-menu v-model="datePickerMenu[field.name]" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
									<template #activator="{ on }">
										<validation-provider v-slot="{ errors }" :name="getLabel(field)" :rules="getRules(field)">
											<v-text-field
												v-model="formData[field.name]"
												:disabled="field.disabled || getDisabledCondition(field)"
												:label="getLabel(field)"
												:hide-details="!errors.length"
												:error-messages="errors"
												outlined
												dense
												readonly
												autocomplete="off"
												v-on="on"
											/>
										</validation-provider>
									</template>
									<v-time-picker v-model="formData[field.name]" :disabled="field.disabled || getDisabledCondition(field)" :label="getLabel(field)" outlined dense />
								</v-menu>
							</template>

							<!-- Switch Components -->
							<v-switch
								v-else-if="field.type === 'switch'"
								v-model="formData[field.name]"
								:disabled="field.disabled || getDisabledCondition(field)"
								:label="getLabel(field)"
								:hide-details="!errors.length"
								color="primary"
								class="ma-0 pt-0"
							/>

							<!-- Checkbox Component -->
							<v-checkbox
								v-else-if="field.type === 'checkbox'"
								v-model="formData[field.name]"
								:disabled="field.disabled || getDisabledCondition(field)"
								:label="getLabel(field)"
								:hide-details="!errors.length"
								:error-messages="errors"
								color="primary"
								class="ma-0 pt-0"
							>
								<template #append>
									<v-tooltip v-if="field.tooltip" :max-width="700" :color="field.tooltip.color" top>
										<template #activator="{ on, attrs }">
											<v-btn icon v-bind="attrs" style="margin-top: -7px" v-on="on">
												<v-icon :color="field.tooltip.iconColor">
													{{ field.tooltip.icon }}
												</v-icon>
											</v-btn>
										</template>
										<div :class="field.tooltip.textClass" v-html="field.tooltip.text" />
									</v-tooltip>
								</template>

								<template v-if="field.labelSlot" #label>
									<div v-html="field.labelSlot" />
								</template>
							</v-checkbox>

							<!-- Button Group -->
							<div v-else-if="field.type === 'button-group'">
								<p class="mb-2 font-weight-medium">
									<v-tooltip bottom>
										<template #activator="{ on }">
											<v-chip v-if="field.new" label small class="mr-1 pink--text yellow px-2" v-on="on">
												NEW
											</v-chip>
										</template>
										<span>{{ field.new }}</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template #activator="{ on }">
											<v-chip v-if="field.updated" label small class="mr-1 blue--text teal accent-2" v-on="on">
												UPDATED
											</v-chip>
										</template>
										<span>{{ field.updated }}</span>
									</v-tooltip>
									{{ getLabel(field) }}
								</p>

								<v-btn-toggle v-model="formData[field.name]" :multiple="field.multiple" :label="getLabel(field)" dense :color="color">
									<v-btn v-for="(option, index) in field.options" :key="index" :value="option.value" :small="field.small" :disabled="field.disabled || getDisabledCondition(field)">
										{{ option.text }}
									</v-btn>
								</v-btn-toggle>
							</div>

							<!-- Chip Group -->
							<div v-else-if="field.type === 'chip-group'">
								<h2 :class="field.titleClass">
									{{ getLabel(field) }}
								</h2>

								<v-chip-group
									v-model="formData[field.name]"
									:active-class="field.activeClass"
									:max="field.max"
									:multiple="field.multiple"
									column
									@change="$emit('field-updated', { name: field.name, data: formData[field.name] })"
								>
									<v-chip v-for="option in field.options" :key="option.id" :value="option.value">
										{{ option.text }}
									</v-chip>
								</v-chip-group>

								<div v-if="errors.length" class="v-messages theme--light error--text ml-3">
									<div class="v-messages__message">
										{{ errors[0] }}
									</div>
								</div>
							</div>

							<!-- Chip Group with Question Answers -->
							<div v-else-if="field.type === 'chip-group-question-answer'">
								<h2 :class="field.titleClass">
									{{ getLabel(field) }}
								</h2>

								<v-chip-group
									:ref="'chip-group-question-' + field.name"
									v-model="formData.selected"
									:active-class="field.activeClass"
									:max="field.max"
									:multiple="field.multiple"
									column
									@change="$emit('field-updated', { name: field.name, data: formData[field.name] })"
								>
									<v-chip v-for="option in field.options" :key="option.value" :value="option.value">
										{{ option.text }}
									</v-chip>
								</v-chip-group>

								<div v-if="errors.length" class="v-messages theme--light error--text ml-3">
									<div class="v-messages__message">
										{{ errors[0] }}
									</div>
								</div>

								<div v-for="option in field.options" :key="option.value">
									<div v-if="formData.selected.includes && formData.selected.includes(option.value)">
										<validation-provider v-slot="{ errors }" :name="getLabel(field)" rules="required">
											<v-text-field
												:ref="'chip-group-answer-' + field.name"
												v-model="formData[option.value].answer"
												:label="option.text"
												:error-messages="errors"
												outlined
												dense
												autocomplete="off"
											/>
										</validation-provider>
									</div>
								</div>
							</div>

							<!-- TextArea Component -->
							<v-textarea
								v-else-if="field.type === 'textarea'"
								v-model="formData[field.name]"
								:rows="field.rows || 5"
								:error-messages="errors"
								:label="getLabel(field)"
								:hide-details="!errors.length"
								:disabled="field.disabled || getDisabledCondition(field)"
								:readonly="field.readonly"
								outlined
								dense
							/>

							<!-- WYSIWYG Component -->
							<div v-else-if="field.type === 'wysiwyg'">
								<p>{{ getLabel(field) }}</p>
								<ckeditor
									v-model="formData[field.name]"
									:editor="editor"
									:config="editorConfig"
									:rows="field.rows || 5"
									:error-messages="errors"
									:label="getLabel(field)"
									:hide-details="!errors.length"
									:disabled="field.disabled || getDisabledCondition(field)"
									:readonly="field.readonly"
									outlined
									dense
								/>
							</div>

							<!-- Table Component -->
							<div v-else-if="field.type === 'table'">
								<v-btn color="success" @click="openTable(field)">
									Update {{ field.display }}
								</v-btn>

								<v-alert v-if="tableWarning[field.name]" dense text border="left" type="warning" class="mt-4 text-body-2 font-weight-bold">
									<span> None of the {{ field.display }} have been updated</span>
								</v-alert>

								<common-dialog :ref="`table-${[field.ref]}`">
									<template #header>
										{{ field.display }}
									</template>
									<template #body>
										<validation-observer :ref="`validation-observer-${[field.ref]}`">
											<v-simple-table class="mt-2">
												<template #default>
													<thead>
														<tr>
															<th v-for="column in field.config.columns" :key="column.value" class="text-left">
																{{ column.display }}
															</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="row in field.config.rows" :key="row.name">
															<td :width="`${100 / field.config.columns.length}%`">
																{{ row.display }}
															</td>
															<template v-for="(input, index) in field.config.columns">
																<td v-if="index !== 0" :key="index" :width="`${100 / field.config.columns.length}%`">
																	<validation-provider v-slot="{ errors }" :name="row.display" :rules="field.config.rules">
																		<v-text-field
																			v-model.number="tableData[field.name][row.value][input.value]"
																			:error-messages="errors"
																			:prefix="field.config.prefix"
																			name="name"
																			label=""
																			@input="inputTest(field.name)"
																		/>
																	</validation-provider>
																</td>
															</template>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</validation-observer>
									</template>
									<template #footer>
										<div class="d-flex py-4">
											<v-spacer />
											<v-btn dark color="grey" @click="closeTable(field.ref)">
												close
											</v-btn>
											<v-btn class="mx-4" color="success" @click="saveTableData(field)">
												save
											</v-btn>
										</div>
									</template>
								</common-dialog>
							</div>

							<!-- File Upload -->
							<!-- Important Note: Multiple file upload fields in a single form is NOT supported at the moment. -->
							<div v-else-if="field.type === 'file'" class="d-flex align-center flex-wrap">
								<div class="d-block" style="width: 100%">
									<v-card
										:class="[{ dragover: dragging }, 'pt-2 pb-4 elevation-0 file-upload-container v-input d-block']"
										@drop.prevent="dropFile(field, $event)"
										@dragover.prevent="dragging = true"
										@dragenter="dragging = true"
										@dragend="dragging = false"
										@dragleave="dragging = false"
									>
										<v-card-text class="text-center pa-2 pb-0">
											<div class="text-body-2 pb-2">
												{{ getLabel(field) }}
											</div>
											<v-icon :size="65">
												mdi-cloud-upload
											</v-icon>
											<p class="text-body-2 grey--text text--darken-2 mt-1 mb-0">
												Drag your documents here to start uploading
											</p>
										</v-card-text>

										<v-card-text class="text-body-2 grey--text text--darken-2 text-center pa-2 pt-0">
											or <a class="grey--text text--darken-4 text-decoration-underline" @click="browse()">click to browse files</a>
										</v-card-text>

										<v-file-input
											v-show="false"
											id="fileUpload"
											v-model="fileUploadModel"
											:label="getLabel(field)"
											prepend-icon="mdi-attachment"
											:accept="getValidFileTypes(field)"
											:multiple="field.multiple"
											class="pt-0 mt-0 file-upload-input"
											hide-details
											outlined
											dense
											@change="confirmUploadFiles(field)"
										/>
									</v-card>
								</div>

								<!-- Required error message -->
								<div v-if="!fileUploadValid" class="v-messages theme--light error--text mt-2 pl-2">
									<div class="v-messages__message">
										{{ getLabel(field) }} field is required
									</div>
								</div>

								<!-- File upload error messages -->
								<div v-if="fileUploadErrors.length > 0" class="mt-4 py-2" style="width: 100%">
									<div class="error--text text-body-2 mb-0">
										<span class="font-weight-bold d-block pb-1">
											{{ fileUploadErrors.length }} issue{{ fileUploadErrors.length > 1 ? 's' : '' }} was encountered whilst attempting to upload your files:
										</span>

										<ul>
											<li v-for="(error, index) in fileUploadErrors" :key="index">
												<span class="font-weight-medium">{{ error.type === 'count' ? error.message : `${error.fileName} - ${error.message}` }}</span>
											</li>
										</ul>
									</div>
								</div>

								<!-- Successfully uploaded Files -->
								<div v-show="uploadedFiles.length > 0" class="mt-4">
									<div class="mx-n2 mb-n2">
										<v-chip v-for="(item, index) in uploadedFiles" :key="index" class="ma-2 mt-0" :color="item.progress === null ? 'green' : 'orange'" outlined dark label>
											{{ item.file.name }}
											<v-progress-circular v-show="item.progress !== null" :value="item.progress" :size="20" :width="3" color="orange" class="ml-2" />

											<v-btn v-show="item.progress == null" light icon right x-small color="black" class="ml-2" @click="confirmRemoveFile(item, field.name)">
												<v-icon dense>
													mdi-close
												</v-icon>
											</v-btn>
										</v-chip>
									</div>
								</div>
							</div>

							<!-- Hidden Field -->
							<input v-else-if="field.type === 'hidden'" v-model="formData[field.name]" type="hidden">
						</validation-provider>

						<div v-if="field.alert">
							<v-fade-transition hide-on-leave>
								<v-alert
									v-show="showAlert(field)"
									class="mt-4"
									border="left"
									:color="field.alert.color"
									:dense="field.alert.dense ? field.alert.dense : false"
									:dark="field.alert.dark"
									v-html="field.alert.text"
								/>
							</v-fade-transition>
						</div>
					</v-col>
				</v-row>

				<div v-if="cancelButton || submitButton" class="text-center mt-6">
					<v-btn v-if="cancelButton" color="grey lighten-3 red--text mr-3" @click="cancel">
						{{ cancelButtonText }}
					</v-btn>

					<v-btn v-if="submitButton" color="primary" type="submit" :loading="submitButtonLoading" :large="submitButtonLarge" :block="submitButtonBlock" :disabled="fileUploadInProgress || submitButtonDisabled">
						{{ submitButtonText }}
					</v-btn>
				</div>
			</v-form>
		</validation-observer>

		<div v-else>
			<v-skeleton-loader type="list-item-three-line" />
		</div>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import axios from 'axios';
	import { $http } from '@/utils';
	import { mapActions } from 'vuex';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import { isEmpty, debounce, cloneDeep } from 'lodash';
	import { ElementTools } from '@/utils';
	import CommonBasePassword from '@/components/common/base/password';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';
	// import AddressSearch from '@/components/common/base/address-search';
	import CommonDialog from '@/components/common/dialog';
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
	import UploadAdapter from '@/utils/UploadAdapter';
	import * as DOMPurify from 'dompurify';

	export default {
		name: 'common-base-dynamic-form',

		components: {
			ValidationObserver,
			ValidationProvider,
			CommonBasePassword,
			CommonDialog,
			CommonDialogConfirm
			// AddressSearch,
		},

		props: {
			formSchema: { type: Array, default: () => [] },
			formData: { type: Object, required: true },
			submitButton: { type: Boolean, default: true },
			submitButtonText: { type: String, default: 'Submit' },
			submitButtonLoading: { type: Boolean, default: false },
			submitButtonLarge: { type: Boolean, default: false },
			submitButtonBlock: { type: Boolean, default: false },
			submitButtonDisabled: { type: Boolean, default: false },
			cancelButton: { type: Boolean, default: false },
			cancelButtonText: { type: String, default: 'Cancel' },
			confirmFileUpload: { type: Boolean, default: false },
			requiredIcon: { type: Boolean, default: true },
			allDisabled: { type: Boolean, default: false },
			customError: { type: Object, default: () => ({}) }
		},

		data() {
			return {
				datePickerModel: {},
				datePickerMenu: {},
				color: 'primary',
				uploadedFiles: [],
				fileUploadModel: [],
				fileUploadErrors: [],
				fileUploadInProgress: false,
				fileUploadValid: true,
				dragging: false,
				tableData: {},
				valid: false,
				submitted: false,
				tableWarning: {},
				editor: ClassicEditor,
				editorConfig: {
					extraPlugins: [uploader],
					htmlEmbed: {
						showPreviews: true,
						sanitizeHtml(inputHtml) {
							// Strip unsafe elements and attributes, e.g.:
							// the `<script>` elements and `on*` attributes.
							const outputHtml = DOMPurify.sanitize(inputHtml);

							return {
								html: outputHtml,
								// true or false depending on whether the sanitizer stripped anything.
								hasChanged: true
							};
						}
					},
					mediaEmbed: {
						previewsInData: true,
						removeProviders: ['dailymotion', 'spotify', 'youtube', 'vimeo', 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook'],
						extraProviders: [
							{
								// https://s3.eu-west-2.amazonaws.com/air.assets/webinar/ComprehensiveConversationsPaulQ1.mp4
								name: 'custom',
								url: /https:\/\/s3\.eu-west-2\.amazonaws\.com\/air\.assets\/(.*)/,
								html: (match) => `<video controls src="${match[0]}" width="100%" height="auto" />`
							}
						]
					}
				}
			};
		},

		computed: {
			countryOptions() {
				return this.formSchema.find((item) => item.name === 'countryId')?.options;
			}
		},

		watch: {
			formData() {
				this.submitted = false;
			},

			uploadedFiles(val) {
				if (val.length > 0) this.fileUploadValid = true;
			}
		},

		created() {
			this.formSchema.forEach((field) => {
				if (field.type === 'table') this.setTableWarning(field);
			});
		},

		methods: {
			...mapActions('app', ['openChat']),
			...mapActions('File', ['invalidateAssetCache']),

			/**
			 * @name showAlert
			 * @description Show alert based on a condition
			 * @param {Object} field
			 * @returns {Boolean} Is visible
			 */
			showAlert(field) {
				return (
					(field.alert.condition.type === 'equals' && this.formData[field.name] == field.alert.condition.value) ||
					(field.alert.condition.type === 'not_equals' && this.formData[field.name] != field.alert.condition.value) ||
					(field.alert.condition.type === 'greater_than' && this.formData[field.name] > field.alert.condition.value) ||
					(field.alert.condition.type === 'less_than' && this.formData[field.name] < field.alert.condition.value)
				);
			},

			/**
			 * @name dropFile
			 * @description File drag & drop handler for file input field
			 * @param {Object} field
			 * @param {Object} event
			 */
			dropFile(field, event) {
				this.dragging = false;
				this.confirmUploadFiles(field, event);
				// this.fileUploadModel = Array.from(event.dataTransfer.files);
			},

			confirmUploadFiles(field, event) {
				if (!this.confirmFileUpload) {
					if (event) this.fileUploadModel = Array.from(event.dataTransfer.files);
					this.uploadFiles(field);
					return;
				}

				this.$refs.confirm
					.open('Upload File', 'Once you upload a file, existing file will be overwritten. Do you wish to continue?')
					.then(() => {
						if (event) this.fileUploadModel = Array.from(event.dataTransfer.files);
						this.uploadFiles(field);
					})
					.catch(() => {
						this.fileUploadModel = [];
					});
			},

			/**
			 * @name uploadFiles
			 * @description File upload handler for file input field
			 * @param {Object} field
			 */
			uploadFiles(field) {
				let fileUploadRequests = [];
				this.fileUploadErrors = [];
				this.formData[field.name] = this.formData[field.name] || [];

				this.fileUploadModel = this.fileUploadModel.length ? this.fileUploadModel : [this.fileUploadModel];
				this.fileUploadModel.map((file) => {
					let valid = true;

					(field.validations || []).map((validation) => {
						if (
							(validation.type === 'size' && file.size / 1024 > validation.value) ||
							(validation.type === 'count' && this.uploadedFiles.length >= validation.value) ||
							(validation.type === 'type' && !validation.value.includes(file.type))
						) {
							let fileCountError = this.fileUploadErrors.filter((error) => error.type === 'count')[0];

							if (!validation.type === 'count' || !fileCountError)
								this.fileUploadErrors.push({
									fileName: file.name,
									type: validation.type,
									message: validation.errorMessage
								});

							valid = false;
						}
					});

					if (valid) {
						this.uploadedFiles.push({
							id: Math.random().toString(36).substr(2, 9),
							file,
							progress: null,
							requested: false
						});
					}
				});

				const getS3Key = (field, fileName) => {
					return field.s3Key?.includes('{{') ? `${this.formData[field.s3Key.match(/\{{(.*?)\}}/)[1]]}.${fileName.split('.').pop()}` : field.s3Key;
				};

				this.uploadedFiles.map((item, index) => {
					if (item.requested) return;

					item.requested = true;
					item.progress = 0;

					const payload = {
						name: item.file.name,
						size: item.file.size,
						type: item.file.type,
						s3Bucket: field.s3Bucket,
						s3Path: field.s3Path,
						s3Key: getS3Key(field, item.file.name)
					};

					const axiosConfig = {
						headers: { 'Content-Type': item.file.type },
						onUploadProgress(progressEvent) {
							item.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
						}
					};

					this.fileUploadInProgress = true;
					this.$emit('file-upload-in-progress', true);

					$http
						.post(`file`, payload)
						.then((response) => {
							fileUploadRequests.push(
								axios
									.put(response.data.preSignedUrl, item.file, axiosConfig)
									.then(() => {
										this.formData[field.name].push({
											s3Key: field.s3Path + response.data.preSignedUrl.split(field.s3Path)[1].split('?')[0],
											filename: item.file.name,
											type: item.file.type,
											uploadedFilename: payload.s3Key
										});
									})
									.catch(() => {
										ElementTools.fireNotification(this.$el, 'error', 'An error occured whilst attempting to upload your file');
										this.removeFile(item, field.name);
									})
									.finally(() => {
										if (index === this.uploadedFiles.length - 1) {
											this.fileUploadInProgress = false;
											this.$emit('file-upload-in-progress', false);
										}
										item.progress = null;
										if (field.s3Bucket === 'air.assets') this.invalidateAssetCache();
									})
							);
						})
						.catch(() => {
							ElementTools.fireNotification(this.$el, 'error', 'An error occured whilst attempting to upload your file');
							this.removeFile(item, field.name);
						});
				});

				this.fileUploadModel = [];
			},

			/**
			 * @name removeFile
			 * @description Remove file failed to upload successfully
			 * @param {Object} item
			 * @param {String} fieldName
			 */
			confirmRemoveFile: debounce(function (item, fieldName) {
				this.$refs.confirm
					.open('Remove File', 'Are you sure you wish to remove this file?')
					.then(() => this.removeFile(item, fieldName))
					.catch(() => {});
			}, 200),

			/**
			 * @name removeFile
			 * @description Remove file failed to upload successfully
			 * @param {Object} item
			 * @param {String} fieldName
			 */
			removeFile(item, fieldName) {
				this.uploadedFiles = this.uploadedFiles.filter((file) => file.id !== item.id);
				this.formData[fieldName] = this.formData[fieldName].filter((file) => file.filename !== item.filename);
			},

			/**
			 * @name getValidFileTypes
			 * @description Get accepted file mimetypes for file upload
			 * @param {Object} field
			 */
			getValidFileTypes(field) {
				let validation = (field.validations || []).filter((validation) => validation.type === 'type');

				if (validation.length > 0) return validation[0].value.join(', ');
			},

			/**
			 * @name getDisabledCondition
			 * @description Get if the field is disabled
			 * @param {Object} field
			 * @returns {Boolean} Is disabled
			 */
			getDisabledCondition(field) {
				if (this.allDisabled) return true;
				if (!field.conditions || !field.conditions.disable) return false;

				let isDisabled = false;

				field.conditions.disable.forEach((condition) => {
					if (
						(condition.type === 'equals' && this.formData[condition.name] == condition.value) ||
						(condition.type === 'not_equals' && this.formData[condition.name] != condition.value) ||
						(condition.type === 'includes' && this.formData[condition.name] && this.formData[condition.name].includes(condition.value)) ||
						(condition.type === 'in_array' && condition.value.includes(this.formData[condition.name])) ||
						(condition.type === 'is_empty' && isEmpty(this.formData[condition.name])) ||
						(condition.type === 'not_empty' && !isEmpty(this.formData[condition.name]))
					) {
						isDisabled = true;
						return;
					}
				});

				return isDisabled;
			},

			/**
			 * @name getDisplayCondition
			 * @description Get if the field is visible
			 * @param {Object} field
			 * @returns {Boolean} Is visible
			 */
			getDisplayCondition(field) {
				if (field.type === 'hidden') return false;
				if (!field.conditions || !field.conditions.show) return true;

				let isVisible = true;

				field.conditions.show.forEach((condition) => {
					if (
						(condition.type === 'equals' && this.formData[condition.name] != condition.value) ||
						(condition.type === 'not_equals' && this.formData[condition.name] == condition.value) ||
						(condition.type === 'includes' && (!this.formData[condition.name] || !this.formData[condition.name].includes(condition.value))) ||
						(condition.type === 'in_array' && !condition.value.includes(this.formData[condition.name])) ||
						(condition.type === 'is_empty' && !isEmpty(this.formData[condition.name])) ||
						(condition.type === 'not_empty' && isEmpty(this.formData[condition.name]))
					) {
						isVisible = false;
						return;
					}
				});

				return isVisible;
			},

			/**
			 * @name getDisplayCondition
			 * @description Get if the field is visible
			 * @param {Object} field
			 * @returns {Boolean} Is visible
			 */
			getRulesCondition(field) {
				if (!field.conditions || !field.conditions.rules) return true;

				let applyRules = false;

				field.conditions.rules.forEach((condition) => {
					if (
						(condition.type === 'equals' && this.formData[condition.name] == condition.value) ||
						(condition.type === 'not_equals' && this.formData[condition.name] != condition.value) ||
						(condition.type === 'includes' && this.formData[condition.name] && this.formData[condition.name].includes(condition.value)) ||
						(condition.type === 'in_array' && condition.value.includes(this.formData[condition.name])) ||
						(condition.type === 'is_empty' && isEmpty(this.formData[condition.name])) ||
						(condition.type === 'not_empty' && !isEmpty(this.formData[condition.name]))
					) {
						applyRules = true;
						return;
					}
				});

				return applyRules;
			},

			/**
			 * @name getRules
			 * @description Get validation rules for a form field
			 * @param {Object} field
			 * @returns {String} Rules
			 */
			getRules(field, validationWrapper) {
				if ((field.type === 'date-picker' || field.type === 'time-picker' || field.type === 'button-group' || field.type === 'table') && validationWrapper) return;

				let rules = field.rules;

				if (field.conditions) {
					if (!this.getDisplayCondition(field) || this.getDisabledCondition(field) || !this.getRulesCondition(field)) {
						rules = null;
					}
				}

				return rules;
			},

			/**
			 * @name isRequired
			 * @description Get if the field is required
			 * @param {Object} field
			 * @returns {Boolean} Is required
			 */
			isRequired(field) {
				let isRequired = (field.rules || '').includes('required');

				if (field.conditions && field.conditions.show) return this.getDisplayCondition(field) ? isRequired : null;

				return isRequired;
			},

			/**
			 * @name validate
			 * @description validate form
			 * @param {Boolean} reset reset fields after validation is completed
			 * @param {Boolean} silent silent validation
			 */
			async validate(reset = true, silent = false) {
				return this.$refs.observer.validate({ silent }).then((isValid) => {
					if (reset)
						requestAnimationFrame(() => {
							this.resetValidation();
						});

					let fileUploadField = this.formSchema.find((field) => field.type === 'file');

					if (
						fileUploadField &&
						(fileUploadField.validations || []).find((validation) => validation.type === 'required' && validation.value) &&
						!this.uploadedFiles.length > 0 &&
						!this.formData[fileUploadField.validations.find((validation) => validation.type === 'required' && validation.value).property]
					) {
						this.fileUploadValid = isValid = false;
					}

					return isValid;
				});
			},

			/**
			 * @name resetValidation
			 * @description Resets the validation observer and clears the validation errors
			 */
			resetValidation() {
				this.$refs.observer.reset();
			},

			/**
			 * @name selectAll
			 * @description Select all
			 * @param {Object} field the input
			 */
			selectAll(field) {
				if (this.formData[field.name].length === field.options.length) {
					this.formData[field.name] = [];
				} else {
					this.formData[field.name] = (field.options || []).map((option) => option.value);
				}
			},

			/**
			 * @name parseDateToText
			 * @description Parse datepicker value into text field
			 * @param {String} fieldName field name
			 */
			parseDateToText(fieldName) {
				let date = this.datePickerModel[fieldName];

				if (!date) return null;

				const [year, month, day] = date.split('-');
				this.formData[fieldName] = `${day}/${month}/${year}`;
				this.$set(this.datePickerMenu, fieldName, false);
			},

			/**
			 * @name parseDate
			 * @description Parse text field date value into datepicker
			 * @param {String} fieldName field name
			 */
			parseDate(fieldName) {
				let regex =
					/(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;
				let date = this.formData[fieldName];
				let isValid = regex.test(date);
				let pickerValue = null;

				if (isValid) {
					const [day, month, year] = date.split('/');
					try {
						pickerValue = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
					} catch {
						pickerValue = null;
					}
				}

				this.$set(this.datePickerModel, fieldName, pickerValue);
			},

			/**
			 * @name dateFieldFocus
			 * @description Event handler for date field focus
			 * @param {Object} field
			 */
			dateFieldFocus(field) {
				if (!this.formData[field.name] && (field.valueDefault || field.ageRelativeDob)) {
					this.$set(this.formData, field.name, field.valueDefault || field.ageRelativeDob);
				}

				this.$nextTick(() => {
					this.parseDate(field.name);
				});
			},

			/**
			 * @name getDebounceValue
			 * @description returns a debounce value if rules contains a server-side validation
			 * @param {String} rules validation rules
			 * @returns {Number} debounce value
			 */
			getDebounceValue(rules) {
				if (rules && (rules.includes('is_unique_email') || rules.includes('is_unique_username'))) {
					return 500;
				}
			},

			clearCustomError() {
				this.$emit('form-updated', this.formData);
			},

			/**
			 * @name submit
			 * @description dispatch a form submit event with form data
			 */
			submit() {
				this.validate(false).then((isValid) => {
					this.valid = isValid;
					this.submitted = true;
					if (!isValid) {
						this.$emit('validation-failed');
						return;
					}
					this.$emit('dynamic-form-submit', this.formData);
					this.clear();
				});
			},

			/**
			 * @name clear
			 * @description clears validations and local state of the form
			 */
			clear() {
				this.$refs.observer.reset();
				this.datePickerModel = {};
				this.datePickerMenu = {};
				this.fileUploadModel = [];
				this.uploadedFiles = [];
			},

			/**
			 * @name cancel
			 * @description dispatch a cancel event
			 */
			cancel() {
				this.$emit('dynamic-form-cancel');
			},

			/**
			 * @name emit
			 * @description Eventbus
			 */
			emit(name, data) {
				this.$emit('field-updated', { name, data });
			},

			/**
			 * @name onInput
			 * @description on text field input
			 */
			onInput: debounce(function (inputValue, options) {
				const res = this[options.fnctn](inputValue, options.params);
				if (options.populate && res) this.$set(this.formData, options.populate, res);
			}, 1000),

			/**
			 * @name browse
			 * @description open select file dialog
			 */
			browse() {
				const fileUpload = this.$el.querySelector('#fileUpload');
				fileUpload.click();
			},

			/**
			 * @name openTable
			 * @description open table dialog
			 */
			openTable(field) {
				let data = cloneDeep(this.formData[field.name]);

				data = data || {};

				field.config.rows.forEach((row) => {
					if (!data[row.value]) {
						data[row.value] = {};
					}

					for (let index = 1; index < field.config.columns.length; index++) {
						if (typeof data[row.value][field.config.columns[index].value] === 'undefined') data[row.value][field.config.columns[index].value] = field.config.valueDefault;
					}
				});

				this.tableData[field.name] = data;
				this.$refs[`table-${field.ref}`][0].open();
			},

			/**
			 * @name closeTable
			 * @description close table dialog
			 */
			closeTable(ref) {
				this.$refs[`table-${ref}`][0].close();
			},

			/**
			 * @name saveTableData
			 * @description save table data into form data
			 */
			async saveTableData(field) {
				const valid = await this.$refs[`validation-observer-${field.ref}`][0].validate();

				if (valid) {
					this.formData[field.name] = cloneDeep(this.tableData[field.name]);
					this.closeTable(field.ref);
					this.setTableWarning(field);
				}
			},

			setTableWarning(field) {
				let value = true;

				Object.keys(this.tableData?.[field.name] || {}).forEach((key) => {
					Object.keys(this.tableData[field.name][key]).forEach((subKey) => {
						if (this.tableData[field.name][key][subKey] !== field.config.valueDefault) {
							value = false;
							return;
						}
					});
				});

				this.$set(this.tableWarning, field.name, value);
			},

			inputTest(name) {
				console.log(`${name} :>>`, this.tableData[name]);
			},

			getErrors(errors) {
				return Object.keys(errors)
					.filter((key) => errors[key].length)
					.map((key) => {
						return errors[key][0];
					});
			},

			getLabel(field) {
				return this.requiredIcon && field.rules && field.rules.includes('required') ? `${field.display} *` : field.display;
			}
		}
	};

	export const uploader = function (editor) {
		editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
			return new UploadAdapter(loader);
		};
	};
</script>

<style lang="scss" scoped>
	@import '~vuetify/src/styles/styles.sass';

	.v-input--checkbox {
		::v-deep .v-input__control {
			width: auto;
			flex-grow: 0;
		}
	}

	.file-upload-container {
		border: 2px dashed rgba(0, 0, 0, 0.25);
		opacity: 0.75;
		transition: all 0.05s ease-in-out;
		::v-deep .v-icon {
			opacity: 0.75;
		}
		&.dragover {
			border: 2px solid rgba(0, 0, 0, 0.3) !important;
			border-style: solid !important;
			transform: scale(1.01);
			background-color: rgba(0, 0, 0, 0.05);

			::v-deep .v-icon {
				transform: scale(1.1);
			}
		}
	}
</style>
