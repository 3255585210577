import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Matter
 * @exports Service/App/Source/Matter/Iteration
 * @description API Matter Iteration Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name get
	 * @description get iteration
	 * @param {UUID} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`iteration/${id}`),

	/**
	 * @name modify
	 * @description modify iteration
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`iteration/${id}`, payload),

	/**
	 * @name list
	 * @description Get all iterations
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`iteration/list`, payload)
};
