import { $http } from '@/utils';
import axios from 'axios';
import { v4 } from 'uuid';
export default class UploadAdapter {
	constructor(loader) {
		// The file loader instance to use during the upload.
		this.loader = loader;
	}

	// Starts the upload process.
	upload() {
		return this.loader.file.then((uploadedFile) => {
			return new Promise((resolve, reject) => {
				// Validate file type of image upload
				if (!['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(uploadedFile.type)) {
					reject('Please upload images with either jpeg, gif or png file types');
				} else {
					const uuid = v4();
					const filename = `${uuid}_${uploadedFile.name}`;

					const payload = {
						name: uploadedFile.name,
						size: uploadedFile.size,
						type: uploadedFile.type,
						s3Bucket: 'air.assets',
						s3Path: 'cms',
						s3Key: encodeURIComponent(filename)
					};

					const axiosConfig = {
						headers: { 'Content-Type': uploadedFile.type }
					};

					// Send file to S3, then pass url back to WYSIWYG
					$http
						.post(`file`, payload)
						.then((response) => {
							axios
								.put(response.data.preSignedUrl, uploadedFile, axiosConfig)
								.then((response) => {
									console.log(response);
									const url = `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/cms/${payload.s3Key}`;
									if (response.status === 200) {
										resolve({
											default: url
										});
									} else {
										reject(response.data.message);
									}
								})
								.catch((e) => {
									console.log('Error uploading file:', e);
									reject(`An error occured whilst attempting to upload your file: ${e.message}`);
								});
						})
						.catch((e) => {
							console.log('Error uploading file:', e);
							reject(`An error occured whilst attempting to upload your file: ${e.message}`);
						});
				}
			});
		});
	}

	// Aborts the upload process.
	abort() {}
}
