import { $http } from '@/utils';

/**
 * @namespace Service
 * @exports Service/Account/Organisation
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get all organisations as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post('account/organisation/list', payload),

	/**
	 * @name get
	 * @description Get organisation
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`account/organisation/${id}`),

	/**
	 * @name update
	 * @description Update organisation details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	update: (id, payload) => $http.put(`account/organisation/${id}`, payload),

	/**
	 * @name modify
	 * @description Update some organisation details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`account/organisation/${id}`, payload),

	/**
	 * @name delete
	 * @description Hard delete organisation details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	delete: (id) => $http.delete(`account/organisation/${id}`),

	/**
	 * @name add
	 * @description Add a new organisation resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`account/organisation`, payload),

	/**
	 * @name firmRewardsList
	 * @description Get all organisations with firm rewards as a list that can be manipulated through post body properties
	 * @returns {Object} Promise a http response
	 */
	firmRewardsList: (payload) => $http.post('account/organisation/list-firm-rewards', payload),

	/**
	 * @name redeemRewards
	 * @description For a given organisation ID, this will redeem reward points for all users under the organisation
	 * @returns {Object} Promise a http response
	 */
	redeemRewards: (payload) => $http.post('account/organisation/redeem-rewards', payload),

	/**
	 * @name fcaCheck
	 * @description Find if FCA number is available
	 * @returns {Object} Promise a http response
	 */
	fcaCheck: (payload) => $http.post(`account/organisation/fca-check`, payload)
};
