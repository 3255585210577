<template>
	<div class="pa-8">
		<p class="text-h6 mt-n2">
			{{ bannerType.name }}
		</p>
		<div class="d-flex">
			<v-btn color="primary" dark :to="`/cms/banner/${bannerTypeId}`">
				Add New Banner
			</v-btn>
			<v-spacer />
			<v-pagination v-model="options.page" :length="pages" total-visible="7" :disabled="loading" />
		</div>
		<v-data-table
			:headers="headers"
			:items="items"
			class="elevation-1 mt-3"
			:options.sync="options"
			:server-items-length="total"
			:loading="loading"
			:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [5, 10, 15, 20, 25] }"
		>
			<template #item.scheduled="{ item }">
				{{ item.scheduled | moment('Do MMM YYYY') }}
			</template>

			<template #item.ending="{ item }">
				{{ item.ending | moment('Do MMM YYYY') }}
			</template>

			<template #item.active="{ item }">
				<v-checkbox :input-value="!!item.active" disabled />
			</template>

			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.url="{ item }">
				<a :href="item.url" target="_blank">Banner URL</a>
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn small color="success" :to="`/cms/banner/${bannerTypeId}/${item.id}`">
						edit
					</v-btn>
					<v-btn small dark class="ml-2" color="error" :loading="deleting[item.id]" @click="deleteItem(item.id)">
						delete
					</v-btn>
				</div>
			</template>
		</v-data-table>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import Vue from 'vue';
	import { ElementTools } from '@/utils';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';

	export default {
		name: 'app-cms-events',

		components: {
			CommonDialogConfirm
		},

		data: () => ({
			items: [],
			bannerType: {},
			loading: false,
			deleting: {},
			options: {},
			total: 0,
			pages: 0,
			headers: [
				{ text: 'Title', value: 'title' },
				{ text: 'Url', value: 'url' },
				{ text: 'Start', value: 'scheduled' },
				{ text: 'End', value: 'ending' },
				{ text: 'Created', value: 'created' },
				{ text: '', value: 'actions', sortable: false }
			]
		}),

		computed: {
			bannerTypeId() {
				return this.$route.params.bannerTypeId;
			}
		},

		watch: {
			options: {
				handler() {
					this.fetchBannerList();
				},
				deep: true
			}
		},

		created() {
			this.getBannerType(this.bannerTypeId).then(({ data }) => {
				this.bannerType = data;
				// Vue.set(this.$route.meta.breadcrumb, 3, { text: data.name });
			});
		},

		methods: {
			...mapActions('CmsBanner', ['getBannerList', 'deleteBanner', 'getBannerType']),

			async fetchBannerList() {
				if (this.loading) return;

				this.loading = true;

				const { page, itemsPerPage } = this.options;

				const payload = {
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					where: [{ 'banner.banner_type_id': this.bannerTypeId }],
					order: { property: 'scheduled', direction: 'desc' }
				};

				let { data } = await this.getBannerList(payload);
				this.items = data.data;
				this.total = data.total;
				this.pages = Math.ceil(this.total / this.options.itemsPerPage);
				this.loading = false;
			},

			async deleteItem(id) {
				this.$refs.confirm
					.open('Delete Banner', `Are you sure you wish to delete this Banner?`)
					.then(() => {
						this.deleting[id] = true;
						this.deleteBanner(id)
							.then(() => {
								ElementTools.fireNotification(this.$el, 'success', 'Banner has been deleted successfully');
								this.fetchBannerList();
							})
							.catch(() => {
								ElementTools.fireNotification(this.$el, 'error', 'Failed to delete banner');
							})
							.finally(() => {
								this.deleting[id] = false;
							});
					})
					.catch(() => {});
			}
		}
	};
</script>
