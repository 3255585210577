import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Registration
 * @exports Service/Account/User/Registration
 * @description API Registration Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get Registration list
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`account/registration/list`, payload),

	/**
	 * @name verify
	 * @description Verify Registration
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	verify: (payload) => $http.post(`account/registration/verify`, payload),

	/**
	 * @name accept
	 * @description Accept Invitation
	 * @param {String} token
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	accept: (token, payload) => $http.post(`account/registration/accept-invitation/${token}`, payload),

	/**
	 * @name delete
	 * @description Delete a registration
	 * @param {String} id
	 * @param {String} type
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	delete: (type, id) => $http.delete(`account/registration/${type}/${id}`)
};
