<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>Event #{{ formData.id }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/cms/events`">
				Back to events
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="form('manager_event').value" :form-data="formData" :submit-button-loading="submitting" :submit-button="false" />
							<dynamic-form ref="dynamicForm2" :form-schema="form('manager_event_thumbnail').value" :form-data="formData" :submit-button-loading="submitting" :submit-button="false" />
							<div class="text-center mt-4">
								<v-btn color="primary" @click="submit"> submit </v-btn>
							</div>
						</v-sheet>

						<v-sheet class="pa-6 mt-4" elevation="4">
							<dynamic-form :form-schema="form('manager_organisation').value" :form-data="organisationFormData" :submit-button-loading="submitting" :all-disabled="!formData.id" :submit-button="false" />
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import { cloneDeep } from 'lodash';

	export default {
		name: 'app-products-details',
		components: {
			DynamicForm
		},

		data: () => ({
			organisationFormData: {},
			formData: {},
			formSchema: [],
			loading: false,
			submitting: false
		}),

		computed: {
			...mapGetters('CmsForm', ['form']),

			eventId() {
				return this.$route.params.id || this.formData.id;
			},

			selectedExclusives() {
				return [...(this.organisationFormData?.exclusive || [])];
			},

			selectedRestricteds() {
				return [...(this.organisationFormData?.restricted || [])];
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsEvent', ['getEvent', 'addEvent', 'modifyEvent']),
			...mapActions('AccountOrganisation', ['addOrganisationEntity', 'deleteOrganisationEntity']),

			async init() {
				this.$route.meta.breadcrumb[3].text = this.eventId ? `Event #${this.eventId}` : 'New Event';

				this.loading = true;
				await Promise.all([this.loadForm('manager_organisation'), this.loadForm('manager_event'), this.loadForm('manager_event_thumbnail')]);
				this.loading = false;

				if (this.eventId) {
					this.loading = true;

					this.getEvent(this.eventId)
						.then(({ data }) => {
							this.setFormData(data);
							this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
							this.initWatchers();
						})
						.finally(() => (this.loading = false));
				} else this.initWatchers();
			},

			initWatchers() {
				this.$watch(
					'selectedExclusives',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addOrganisationEntityRequest(added, 'exclusive');
						else if (deleted) this.deleteOrganisationEntityRequest(deleted);
					},
					{ deep: true }
				);

				this.$watch(
					'selectedRestricteds',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addOrganisationEntityRequest(added, 'restricted');
						else if (deleted) this.deleteOrganisationEntityRequest(deleted);
					},
					{ deep: true }
				);
			},

			setFormData(data) {
				this.formData = {
					...data,
					tag: data.tag?.map((tag) => tag.id) || [],
					exclusive: data.exclusive || [],
					restricted: data.restricted || [],
					startDate: this.$moment(data.start).format('DD/MM/YYYY'),
					startTime: this.$moment(data.start).format('HH:mm'),
					endDate: this.$moment(data.end).format('DD/MM/YYYY'),
					endTime: this.$moment(data.end).format('HH:mm')
				};
			},

			async submit() {
				const validated = await Promise.all([
					this.$refs.dynamicForm.validate(false, false),
					this.$refs.dynamicForm2.validate(false, false)
				]);
				if (!validated.every(v => v === true)) return;
				this.submitting = true;

				let payload = cloneDeep(this.formData);
				payload.image = this.formData.files?.[0]?.s3Key || this.formData.image;
				payload.thumbnail = this.formData.thumbnails?.[0]?.s3Key || this.formData.thumbnail;
				payload.start = this.formatDate(this.formData.startDate, this.formData.startTime);
				payload.end = this.formatDate(this.formData.endDate, this.formData.endTime);
				payload.tag = payload.tag || [];
				payload.exclusive = payload.exclusive || [];
				payload.restricted = payload.restricted || [];

				const promise = this.eventId ? this.modifyEvent({ id: this.eventId, payload }) : this.addEvent(payload);
				promise
					.then(({ data }) => {
						ElementTools.fireNotification(this.$el, 'success', 'Event has been submitted successfully');
						this.$router.push(`/cms/event/${this.formData.id || data.id}`);
						this.formData.id = this.formData.id || data.id;
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit event'))
					.finally(() => (this.submitting = false));
			},

			formatDate(date, time) {
				const [day, month, year] = date.split('/');
				return new Date([`${month}/${day}/${year}`, time]).toISOString();
			},

			addOrganisationEntityRequest(id, status) {
				let payload = {
					entity: 'event',
					entityId: this.eventId,
					status
				};

				this.addOrganisationEntity({ id, payload }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to add exclusive organisation');
					this.getEvent(this.eventId).then(({ data }) => {
						this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
					});
				});
			},

			deleteOrganisationEntityRequest(id) {
				this.deleteOrganisationEntity({ id, entityId: this.eventId, entity: 'event' }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to delete exclusive organisation');
					this.getEvent(this.eventId).then(({ data }) => {
						this.organisationFormData = { exclusive: data.exclusive, restricted: data.restricted };
					});
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.wrapper {
		background: #fff;
	}
</style>
