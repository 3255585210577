import { $http } from '@/utils';

/**
 * @namespace Service
 * @class User
 * @exports Service/Account/User/Identity
 * @description API User Identity Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @static @name get
	 * @description Get User Identity for ID (RBAC)
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`account/user/identity/${id}`),

	/**
	 * @static @name update
	 * @description Update all User Identity for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	update: (id, payload) => $http.put(`account/user/identity/${id}`, payload),

	/**
	 * @static @name modify
	 * @description Update some User Identity for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`account/user/identity/${id}`, payload),

	/**
	 * @static @name validate
	 * @description Validate user identity for uniqueness
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	validate: (payload) => $http.post(`account/user/identity/validate`, payload)
};
