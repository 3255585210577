<template>
	<div class="pa-8">
		<div class="pb-4">
			<v-btn color="primary" dark :to="`/admin/group`">
				Add New Group
			</v-btn>
		</div>
		<v-data-table :headers="headers" :items="data" disable-pagination class="elevation-1 mt-3" :loading="loading" hide-default-footer>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn small color="success" :to="`/admin/group/${item.id}`">
						view
					</v-btn>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-admin-groups',

		data: () => ({
			loading: false,
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Group', value: 'nameUnique' },
				{ text: 'Description', value: 'description' },
				{ text: 'Scope', value: 'scope' },
				{ text: 'Created', value: 'created' },
				{ text: '', value: 'actions' }
			],
			data: []
		}),

		created() {
			this.fetchGroupList();
		},

		methods: {
			...mapActions('AccountGroup', ['getGroupList']),

			fetchGroupList() {
				if (this.loading) return;

				this.loading = true;
				this.getGroupList()
					.then(({ data }) => (this.data = data.data))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to fetch group list'))
					.finally(() => (this.loading = false));
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep ul.v-pagination {
		justify-content: end !important;
	}
</style>
