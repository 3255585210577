import router from '@/router';
import axios from 'axios';

/**
 * @namespace Utils
 * @method $http
 * @exports Utils/$http
 * @description Util giving http access using axios
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

const config = {
	baseURL: process.env.VUE_APP_API_URL,
	withCredentials: false,
	headers: { Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' }
};

const $http = axios.create(config);

const tabID = window.sessionStorage.getItem('air.manager.tabID') || Math.random();
window.sessionStorage.setItem('air.manager.tabID', tabID);
window.addEventListener('storage', (event) => {
	if (event.key === 'air.manager.authorization') {
		if (tabID !== window.localStorage.getItem('air.manager.authorizationTabID')) location.reload();
	}
});

$http.setToken = (accessToken, refreshToken) => {
	window.localStorage.setItem('air.manager.authorizationTabID', tabID);
	window.localStorage.setItem('air.manager.authorization', accessToken);
	window.localStorage.setItem('air.manager.refreshToken', refreshToken);
};

$http.clearToken = () => {
	window.localStorage.removeItem('air.manager.authorization');
	window.localStorage.removeItem('air.manager.refreshToken');
};

$http.forceLogin = () => {
	$http.clearToken();
	if (router.history.current?.path !== '/login' && router.history.pending?.path !== '/login') window.location.href = '/login';
};

const authInterceptor = (config) => {
	// get auth token from local storage
	let token = window.localStorage.getItem('air.manager.authorization');
	if (token) config.headers['Authorization'] = 'Bearer ' + token;

	return config;
};

// Request Interceptors
$http.interceptors.request.use(authInterceptor);

// Response Interceptors
let pendingRefresh = false;
let pendingRequests = [];

$http.interceptors.response.use(
	(response) => {
		// auto cache auth token to local storage
		if (response.config.url.includes('account/authenticate') && response.data.accessToken) $http.setToken(response.data.accessToken, response.data.refreshToken);

		return response;
	},
	(error) => {
		if (error.response?.status === 401) {
			// if refresh token request is pending, add request to queue
			if (pendingRefresh) {
				let resolve;
				let reject;

				// need to create and return a promise to keep the chain going
				const promise = new Promise((res, rej) => {
					resolve = res;
					reject = rej;
				});

				pendingRequests.push({ config: error.config, resolve, reject });

				return promise;
			}

			pendingRefresh = true;

			return axios
				.post(`${config.baseURL}/account/refresh`, {
					token: window.localStorage.getItem('air.manager.refreshToken')
				})
				.then((response) => {
					// update the token on successful refresh
					$http.setToken(response.data.accessToken, response.data.refreshToken);

					// send all requests waiting on queue after the token has been updated
					(pendingRequests || []).forEach((request) =>
						$http(request.config)
							.then((response) => request.resolve(response))
							.catch((error) => request.reject(error))
					);

					// send the original request with a valid token
					let originalRequest = error.config;
					originalRequest.headers['Authorization'] = 'Bearer ' + response.data.accessToken;
					return $http(originalRequest);
				})
				.catch((e) => {
					// Retry failed, clean up and reject the promise
					if (e.response?.status === 403 || e.response?.config?.url.includes('account/refresh')) $http.forceLogin();
					return Promise.reject(e.response || e);
				})
				.finally(() => {
					pendingRefresh = false;
					pendingRequests = [];
				});
		} else if (error.response?.status === 403 && (error.response?.config?.url.includes('account/authenticate') || error.response?.data?.message?.includes('Missing Authorization Token'))) $http.forceLogin();

		return Promise.reject(error);
	}
);

export default $http;
