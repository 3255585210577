<template>
	<div class="pa-8">
		<v-data-table :headers="headers" :items="items" class="elevation-1 mt-3" :loading="loading" hide-default-footer>
            <template #item.vouchers="{ item }">
                {{ formatNumber(item.vouchers) }}
            </template>
            <template #item.nonVouchers="{ item }">
                {{ formatNumber(item.nonVouchers) }}
            </template>
        </v-data-table>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';

	export default {
		name: 'app-admin-unclaimed-rewards',

		data: () => ({
			items: [],
			loading: false,
			headers: [
				{ text: 'Vouchers', value: 'vouchers', sortable: false },
				{ text: 'Non-Vouchers', value: 'nonVouchers', sortable: false }
			]
		}),

		mounted() {
			this.init();
		},

		methods: {
			...mapActions('AppRewardUnclaimed', ['getRewardUnclaimed']),

			async init() {
				this.loading = true;
				const { data } = await this.getRewardUnclaimed();
				this.items = data;
				this.loading = false;
			},

            formatNumber(value) {
                return new Intl.NumberFormat().format(value);
            }
		}
	};
</script>
