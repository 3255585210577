import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Matter
 * @exports Service/Action
 * @description API Action Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description list all actions
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`action/list`, payload),

	/**
	 * @name add
	 * @description create a new action
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`action`, payload),

	/**
	 * @name delete
	 * @description delete action
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	delete: (id) => $http.delete(`action/${id}`)
};
