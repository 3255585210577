<template>
	<div class="pa-8">
		<v-row class="pb-4">
			<v-col cols="4">
				<v-btn color="primary" dark :to="`/admin/api-user`"> Add New API User </v-btn>
			</v-col>
			<v-spacer />
			<v-col cols="4">
				<v-select
					hide-details
					dense
					solo
					light
					clearable
					placeholder="Filter API Users"
					:value="filterItem"
					:items="filterItems"
					@input="updateFilter($event)"
				/>
			</v-col>
		</v-row>
		<v-data-table :headers="headers" :items="filteredItems" disable-pagination class="elevation-1 mt-3" :loading="loading" hide-default-footer>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>
			<template #item.updated="{ item }">
				{{ item.updated | moment('Do MMM YYYY, HH:mm') }}
			</template>
			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn v-if="!item.locked" small dark class="ml-2" color="amber" :loading="locking[item.id]" @click="lockUser(item.id)">
						Lock
					</v-btn>
					<v-btn v-if="item.locked" small dark class="ml-2" color="green" :loading="locking[item.id]" @click="unlockUser(item.id)">
						Unlock
					</v-btn>
					<v-btn v-if="!item.suspended" small dark class="ml-2" color="red" :loading="suspending[item.id]" @click="suspendUser(item.id)">
						Suspend
					</v-btn>
					<v-btn v-if="item.suspended" small dark class="ml-2" color="green" :loading="suspending[item.id]" @click="unsuspendUser(item.id)">
						Unsuspend
					</v-btn>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-admin-users',

		data: () => ({
			loading: false,
			headers: [
				{ text: 'Name', value: 'organisation.name' },
				{ text: 'Advisor Organisation', value: 'organisation.data.advisorOrganisationName' },
				{ text: 'Created', value: 'created' },
				{ text: 'Last Updated', value: 'updated' },
				{ text: '', value: 'actions', sortable: false }
			],
			filterItem: null,
			filterItems: [
				{ text: 'Locked', value: 'locked' },
				{ text: 'Suspended', value: 'suspended' },
				{ text: 'Advisor Organisation', value: 'advisorOrganisationId' }
			],
			data: [],
			locking: {},
			suspending: {},
			deleting: {}
		}),

		computed: {
			filteredItems() {
				if (this.filterItem === null) return this.data;
				return this.data.filter(item => {
					if (this.filterItem === 'locked' && item.locked) return true;
					if (this.filterItem === 'suspended' && item.suspended) return true;
					if (this.filterItem === 'advisorOrganisationId' && item.organisation?.data?.advisorOrganisationId) return true;
					return false;
				});
			}
		},

		created() {
			this.getUserList();
		},

		methods: {
			...mapActions('AccountUser', ['fetchUserList', 'modifyUser']),

			getUserList() {
				if (this.loading) return;

				this.loading = true;
				this.fetchUserList({ where: { type: 'api' } })
					.then(({ data }) => (this.data = data.data))
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to fetch user list'))
					.finally(() => (this.loading = false));
			},

			updateFilter($event) {
				this.filterItem = $event;
			},

			lockUser(id) {
				this.modifyUser({
					id,
					data: {
						locked: true
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'User has been locked successfully');
						this.getUserList();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to lock user'))
					.finally(() => (this.submitting = false));
			},

			unlockUser(id) {
				this.modifyUser({
					id,
					data: {
						locked: false
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'User has been unlocked successfully');
						this.getUserList();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to unlock user'))
					.finally(() => (this.submitting = false));
			},

			suspendUser(id) {
				this.modifyUser({
					id,
					data: {
						suspended: true
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'User has been suspended successfully');
						this.getUserList();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to suspend user'))
					.finally(() => (this.submitting = false));
			},

			unsuspendUser(id) {
				this.modifyUser({
					id,
					data: {
						suspended: false
					}
				})
					.then(() => {
						ElementTools.fireNotification(this.$el, 'success', 'User has been unsuspend successfully');
						this.getUserList();
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to unsuspend user'))
					.finally(() => (this.submitting = false));
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep ul.v-pagination {
		justify-content: end !important;
	}
</style>
