<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>Insight #{{ formData.id }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/cms/insights-hub`">
				Back to Insights Hub
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="form('manager_insight').value" :form-data="formData" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-products-details',
		components: {
			DynamicForm
		},

		data: () => ({
			formData: {},
			loading: false,
			submitting: false
		}),

		computed: {
			...mapGetters('CmsForm', ['form']),

			insightId() {
				return this.$route.params.id;
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsInsight', ['getInsight', 'addInsight', 'modifyInsight']),

			async init() {
				this.$route.meta.breadcrumb[3].text = this.insightId ? `Insight #${this.insightId}` : 'New Insight';

				this.loading = true;
				await this.loadForm('manager_insight');
				this.loading = false;

				if (this.insightId) {
					this.loading = true;

					this.getInsight(this.insightId)
						.then(({ data }) => {
							data.providers = data.data?.providers;
							this.formData = data;
						})
						.finally(() => (this.loading = false));
				}
			},

			submit() {
				this.submitting = true;

				let { ...payload } = this.formData;

				// map files
				payload.image = this.formData.files?.[0]?.s3Key || this.formData.image;
				delete payload.files;
				const promise = this.insightId ? this.modifyInsight({ id: this.insightId, payload }) : this.addInsight(payload);
				promise
					.then(({ data }) => {
						ElementTools.fireNotification(this.$el, 'success', 'Insight has been submitted successfully');

						if (data?.data) {
							this.$router.push(`/cms/insight/${data.data.id}`);
							this.formData.id = this.formData.id || data.data?.id;
						}
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit Insight'))
					.finally(() => (this.submitting = false));
			}
		}
	};
</script>
