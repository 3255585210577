import { CmsInsight } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getInsightList
		 * @param {String} id
		 */
		getInsightList(context, payload) {
			return CmsInsight.list(payload);
		},

		/**
		 * @async @name getInsight
		 * @param {String} id
		 */
		getInsight(context, id) {
			return CmsInsight.get(id);
		},

		/**
		 * @async @name getInsight
		 * @param {String} id
		 */
		modifyInsight(context, { id, payload }) {
			return CmsInsight.modify(id, payload);
		},

		/**
		 * @async @name addInsight
		 */
		deleteInsight(context, id) {
			return CmsInsight.delete(id);
		},

		/**
		 * @async @name addInsight
		 */
		addInsight(context, payload) {
			return CmsInsight.add(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
