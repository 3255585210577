<template>
	<common-dialog ref="commentDialog" :max-width="800">
		<template #header> Comments </template>

		<template #body>
			<v-list v-if="comments?.length" class="ma-0 pa-0">
				<v-sheet v-for="(item, index) in comments" :key="index" rounded elevation="0" class="yellow lighten-5 mt-4">
					<v-list-item>
						<v-list-item-content>
							<p class="text-caption grey--text text--darken-3 font-weight-medium mb-0">
								{{ item.created | moment('Do MMM YYYY, HH:mm') }}
							</p>
							<p class="text-caption mb-0 grey--text text--darken-2">
								{{ item.comment }}
							</p>
							<p class="text-caption grey--text font-weight-medium mb-0">
								{{ item.userName }}
							</p>
						</v-list-item-content>
					</v-list-item>
				</v-sheet>
			</v-list>
			<div v-else>
				<v-sheet class="text-center pa-4" elevation="0">
					<v-icon class="display-4 grey--text"> mdi-comment-text-outline </v-icon>
					<p class="text-caption grey--text text--darken-2 font-weight-medium mb-0">No comments have been made on this changelog item</p>
				</v-sheet>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/components/common/dialog';

	export default {
		name: 'app-changelog-comment-dialog',

		components: {
			CommonDialog
		},

		data: () => ({
			comments: []
		}),

		methods: {
			open(comments) {
				this.comments = comments;
				this.$refs.commentDialog.open();
			}
		}
	};
</script>
