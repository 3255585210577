<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>Article #{{ formData.id }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/cms/news-list`">
				Back to news
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<v-skeleton-loader v-if="loading" type="article, list-item-three-line, list-item-three-line" />
					<div v-else>
						<v-sheet class="pa-6" elevation="4">
							<dynamic-form ref="dynamicForm" :form-schema="form('manager_news').value" :form-data="formData" :submit-button-loading="submitting" @dynamic-form-submit="submit" />
						</v-sheet>

						<v-sheet class="pa-6 mt-4" elevation="4">
							<dynamic-form :form-schema="form('tag').value" :form-data="tagFormData" :all-disabled="!formData.id" :submit-button="false" />
						</v-sheet>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-products-details',
		components: {
			DynamicForm
		},

		data: () => ({
			formData: {},
			tagFormData: {},
			loading: false,
			submitting: false
		}),

		computed: {
			...mapGetters('CmsForm', ['form']),

			newsId() {
				return this.$route.params.id;
			},

			selectedTags() {
				return [...(this.tagFormData?.tag || [])];
			}
		},

		created() {
			this.init();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('CmsNews', ['getNews', 'addNews', 'modifyNews', 'addTag', 'deleteTag']),

			async init() {
				this.$route.meta.breadcrumb[3].text = this.newsId ? `News #${this.newsId}` : 'New News';

				this.loading = true;
				await Promise.all([this.loadForm('manager_news'), this.loadForm('tag')]);
				this.loading = false;

				if (this.newsId) {
					this.loading = true;

					this.getNews(this.newsId)
						.then(({ data }) => {
							data.providers = data.data?.providers;
							this.formData = data;
							this.tagFormData = { tag: data.tag?.map((tag) => tag.id) };
							this.initWatchers();
						})
						.finally(() => (this.loading = false));
				} else this.initWatchers();

				await Promise.all([this.loadForm('manager_download'), this.loadForm('manager_organisation'), this.loadForm('tag')]);
			},

			initWatchers() {
				this.$watch(
					'selectedTags',
					function (val, oldVal) {
						let added = val.find((x) => !oldVal.includes(x));
						let deleted = oldVal.find((x) => !val.includes(x));

						if (added) this.addTagRequest(added);
						else if (deleted) this.deleteTagRequest(deleted);
					},
					{ deep: true }
				);
			},

			submit() {
				this.submitting = true;

				let { providers, ...payload } = this.formData;
				payload.data = { providers };

				// map files
				payload.image = this.formData.files?.[0]?.s3Key || this.formData.image;
				delete payload.files;
				const promise = this.newsId ? this.modifyNews({ id: this.newsId, payload }) : this.addNews(payload);
				promise
					.then(({ data }) => {
						ElementTools.fireNotification(this.$el, 'success', 'News has been submitted successfully');

						if (data?.data) {
							this.$router.push(`/cms/news/${data.data.id}`);
							this.formData.id = this.formData.id || data.data?.id;
						}
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'Failed to submit News'))
					.finally(() => (this.submitting = false));
			},

			addTagRequest(tagId) {
				let payload = { tagIds: [tagId] };
				this.addTag({ id: this.newsId, payload }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to add tag');
					this.getNews(this.newsId).then(({ data }) => {
						this.tagFormData = { tag: data.tag?.map((tag) => tag.id) };
					});
				});
			},

			deleteTagRequest(tagId) {
				this.deleteTag({ id: this.newsId, tagId }).catch(() => {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to delete tag');
					this.getNews(this.newsId).then(({ data }) => {
						this.tagFormData = { tag: data.tag?.map((tag) => tag.id) };
					});
				});
			}
		}
	};
</script>
