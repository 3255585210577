<!--
	@name common-dialog
	@description Display a dialog with headers and or footers
	@date 2020/03/04
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!--
	@example

	<common-dialog ref="dialog" max-width="600" max-height="500" color="red">
		<template v-slot:header>Your Header</template>
		<template v-slot:body>
			<p>Some content</p>
		</template>
		<template v-slot:footer>Your Footer</template>
	</common-dialog>

	this.$refs.dialog.open();
-->

<template>
	<v-dialog v-model="active" scrollable :max-width="maxWidth" :max-height="maxHeight" :eager="eager" :component="$options.name" @input="close">
		<v-card :key="contentKey">
			<v-app-bar :dense="dense" :dark="dark" :color="color">
				<v-toolbar-title>
					<slot name="header" />
				</v-toolbar-title>
				<v-spacer />
				<slot v-show="!!$slots['header-action']" name="header-action" />
				<v-btn v-show="showClose" icon @click="close">
					<v-icon>mdi-close-box</v-icon>
				</v-btn>
			</v-app-bar>
			<v-divider />
			<slot name="fixed" />
			<v-card-text>
				<slot name="body" />
			</v-card-text>
			<v-divider />
			<div v-show="!!$slots['footer']" class="footer">
				<slot name="footer" />
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
	import { EventBus } from '@/utils';

	export default {
		name: 'common-dialog',

		props: {
			color: { type: String, default: 'blue-grey' },
			dark: { type: Boolean, default: true },
			dense: { type: Boolean, default: false },
			eager: { type: Boolean, default: false },
			maxWidth: { type: Number, default: 900 },
			maxHeight: { type: Number, default: 500 },
			showClose: { type: Boolean, default: true },
			destroyOnClose: { type: Boolean, default: true }
		},

		data: () => ({
			active: false,
			contentKey: 0
		}),

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.active = true;
			},

			/**
			 * @name close
			 * @description Close dialog and set properties
			 */
			close() {
				this.active = false;
				this.$emit('closed', true);
				EventBus.$emit('common-dialog-closed');

				// Reset the content key to force a re-render
				if (this.destroyOnClose)
					setTimeout(() => {
						this.contentKey = Math.random();
					}, 100);
			}
		}
	};
</script>

<style scoped>
	.header {
		font-size: 1.1rem;
		line-height: 1.5;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: normal;
	}

	.footer {
		background-color: #f4f4f4;
	}
</style>
