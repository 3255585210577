import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Address
 * @exports Service/Account/Organisation/Address
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @static @name add
	 * @description Add Organisation address
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`account/organisation/address`, payload),

	/**
	 * @static @name get
	 * @description Get Organisation address for ID (RBAC)
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`account/organisation/address/${id}`),

	/**
	 * @static @name update
	 * @description Update all Organisation address for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	update: (id, payload) => $http.put(`account/organisation/address/${id}`, payload),

	/**
	 * @static @name modify
	 * @description Update some Organisation address for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`account/organisation/address/${id}`, payload)
};
