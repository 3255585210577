<template>
	<div class="pa-8">
		<div class="pb-4">
			<v-btn color="primary" dark to="/cms/course"> Add New Course </v-btn>
		</div>
		<div class="d-flex">
			<validation-observer ref="observer" class="pa-0 col-6">
				<validation-provider v-slot="{ errors }" name="Search" rules="search">
					<v-text-field v-model="searchTerm" placeholder="Search" append-icon="mdi-magnify" dense solo light clear-icon="mdi-close-circle" clearable :error-messages="errors" @click:clear="searchTerm = ''" />
				</validation-provider>
			</validation-observer>
			<v-spacer />
			<v-pagination v-model="options.page" :length="pages" total-visible="7" :disabled="loading" />
		</div>
		<v-data-table
			:headers="headers"
			:items="items"
			class="elevation-1"
			:options.sync="options"
			:server-items-length="total"
			:loading="loading"
			:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [5, 10, 15, 20, 25] }"
		>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.updated="{ item }">
				{{ item.updated | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn small color="success" :to="`/cms/course/${item.id}`"> edit </v-btn>
					<v-btn small dark class="ml-2" color="error" :loading="deleting[item.id]" @click="deleteItem(item.id)"> delete </v-btn>
				</div>
			</template>
		</v-data-table>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import { debounce } from 'lodash';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';

	export default {
		name: 'app-cms-course',

		components: {
			CommonDialogConfirm,
			ValidationObserver,
			ValidationProvider
		},

		data: () => ({
			items: [],
			loading: false,
			deleting: {},
			options: {},
			total: 0,
			pages: 0,
			searchTerm: '',
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Description', value: 'description' },
				{ text: 'Created', value: 'created' },
				{ text: 'Updated', value: 'updated' },
				{ text: '', value: 'actions', sortable: false }
			]
		}),

		watch: {
			options: {
				handler() {
					this.fetchCourseList();
				},
				deep: true
			},

			async searchTerm() {
				const valid = await this.$refs.observer.validate();
				if(!valid) return;
				this.debouncedfetchCourseList();
			}
		},

		methods: {
			...mapActions('CmsCourse', ['getCourseList', 'deleteCourse', 'getCourseCommentList']),

			async fetchCourseList() {
				if (this.loading) return;

				this.loading = true;
				const { sortBy, sortDesc, page, itemsPerPage } = this.options;

				const payload = {
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					order: sortBy.length > 0 ? { property: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc' } : { property: 'created', direction: 'desc' },
					where: this.searchTerm ? [{ 'course.name': { c: 'ILIKE', v: `%${this.searchTerm}%` } }, '||', { 'course.description': { c: 'ILIKE', v: `%${this.searchTerm}%` } }] : undefined
				};

				let { data } = await this.getCourseList(payload);
				this.items = data.data;
				this.total = data.total;
				this.pages = Math.ceil(this.total / this.options.itemsPerPage);
				this.loading = false;
			},

			/**
			 * @name debouncedfetchCourseList
			 * @description Load Course with debounce
			 */
			debouncedfetchCourseList: debounce(function () {
				this.options.page = 1;
				this.fetchCourseList();
			}, 1000),

			async deleteItem(id) {
				this.$refs.confirm
					.open('Delete Course item', `Are you sure you wish to delete this course item?`)
					.then(() => {
						this.deleting[id] = true;
						this.deleteCourse(id)
							.then(() => {
								ElementTools.fireNotification(this.$el, 'success', 'Course item has been deleted successfully');
								this.fetchCourseList();
							})
							.catch(() => {
								ElementTools.fireNotification(this.$el, 'error', 'Failed to delete Course item');
							})
							.finally(() => {
								this.deleting[id] = false;
							});
					})
					.catch(() => {});
			},

			/**
			 * @name openCommentsDialog
			 * @description Event for open messages dialog
			 */
			async openCommentsDialog(courseId) {
				const payload = {
					order: { property: 'created', direction: 'desc' },
					where: { courseId }
				};
				let { data } = await this.getCourseCommentList(payload);
				this.$refs.commentDialog.open(data.data);
			}
		}
	};
</script>
