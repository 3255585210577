<!--
	@name app-enquiry-details-providers-dialog
	@description enquiry details add providers dialog component
	@date 2020/06/23
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<common-dialog ref="addProviderDialog" :max-width="800">
			<template #header>
				Add New Provider
			</template>

			<template #fixed>
				<v-container>
					<v-row v-if="selectedProviders.length" align="center" justify="start" class="px-4 pb-6 pt-2">
						<v-chip v-for="(selection, i) in selectedProviders" :key="selection.text" :disabled="loading" close class="mx-2 mt-4" @click:close="selectedProviders.splice(i, 1)">
							{{ selection.text }}
						</v-chip>
					</v-row>
				</v-container>

				<v-divider v-if="selectedProviders.length" />
			</template>

			<template #body>
				<div>
					<v-list :max-height="400">
						<template v-for="item in providerOptions">
							<v-list-item v-if="!selectedProviders.includes(item)" :key="item.text" :disabled="loading" @click="selectedProviders.push(item)">
								<v-list-item-title v-text="item.text" />
							</v-list-item>
						</template>
					</v-list>
				</div>
			</template>

			<template #footer>
				<div class="d-flex justify-center my-4">
					<v-btn color="success" :loading="loading" :disabled="!selectedProviders.length" @click="submit">
						Submit
					</v-btn>
				</div>
			</template>
		</common-dialog>
		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import CommonDialog from '@/components/common/dialog';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-enquiry-details-providers-dialog',

		components: {
			CommonDialog,
			CommonDialogConfirm
		},

		props: {
			organisations: { type: Array, default: () => [] },
			activeProviders: { type: Array, default: () => [] },
			matterEntity: { type: Array, default: () => [] }
		},

		data() {
			return {
				selectedProviders: [],
				loading: false
			};
		},

		computed: {
			providerOptions() {
				return this.organisations
					?.filter((o) => !this.activeProviders.includes(o.id))
					?.map((o) => ({
						text: o.name,
						value: o.id
					}));
			}
		},

		methods: {
			...mapActions('Enquiry', ['addProvider', 'reconnect']),

			/**
			 * @name open
			 * @description open providers dialog
			 */
			open() {
				this.selectedProviders = [];
				this.loading = false;
				this.$refs.addProviderDialog.open();
			},

			/**
			 * @name submit
			 * @description submit new providers
			 */
			submit() {
				this.$refs.confirm
					.open('Not so fast!!', `Are you sure you want to add these providers? Please note that this operation cannot be undone.`)
					.then(() => {
						this.loading = true;

						let reconnectedProviders = this.selectedProviders.filter((p) => this.matterEntity.find((e) => e.entityId === p.value && !!e.data?.withdrawn));
						let newProviders = this.selectedProviders.filter((p) => !this.matterEntity.find((e) => e.entityId === p.value));

						let requests = [];

						if (newProviders.length) {
							requests.push(
								this.addProvider({
									id: this.$route.params.id,
									payload: this.selectedProviders.map((p) => ({ entity: 'organisation', entityId: p.value }))
								})
							);
						}

						reconnectedProviders.map((p) => {
							requests.push(
								this.reconnect({
									matterId: this.$route.params.id,
									processType: 'reconnect',
									process: { id: p.value, type: 'organisation' }
								})
							);
						});
						Promise.all(requests)
							.then(() => ElementTools.fireNotification(this.$el, 'success', 'Providers have been added successfully'))
							.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured whilst adding Providers to this enquiry'))
							.finally(() => {
								this.$refs.addProviderDialog.close();
								this.$emit('submitted');
							});
					})
					.catch(() => {});
			}
		}
	};
</script>
