<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title class="font-weight-bold">
				{{ 'Add New API User' }}
			</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark :to="`/admin/api-users`"> Back to API users </v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters class="justify-center">
				<v-col cols="6" md="8" lg="8" xl="6">
					<v-sheet class="pa-6" elevation="4">
						<dynamic-form ref="dynamicForm" :form-schema="form('manager_api_user').value" :form-data="formData" :custom-error="customError" :submit-button-loading="submitting" @dynamic-form-submit="submit" @form-updated="customError = {}" />
					</v-sheet>
				</v-col>
			</v-row>
			<common-dialog ref="new-api-user-dialog" :max-width="600" @closed="createApiUserDialogClosed">
				<template #header> New API User Credentials </template>

				<template #body>
					<p class="mt-5">Client ID: {{ apiCredentials.clientId }}</p>
					<p>Client Secret: {{ apiCredentials.clientSecret }}</p>
				</template>
			</common-dialog>
		</v-container>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import DynamicForm from '@/components/common/base/dynamic-form';
	import CommonDialog from '@/components/common/dialog';

	export default {
		name: 'app-admin-users-details',

		components: {
			DynamicForm,
			CommonDialog
		},

		data() {
			return {
				formData: {},
				formSchema: [],
				loading: false,
				submitting: false,
				apiCredentials: null,
				customError: {}
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form'])
		},

		created() {
			this.loadForm('manager_api_user');
		},

		methods: {
			...mapActions('AccountUser', ['addApiUser']),
			...mapActions('CmsForm', ['loadForm']),

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 * @param {Object} data
			 */
			async submit(data) {
				this.submitting = true;

				this.addApiUser({
					username: data.username,
					apikey: data.apikey,
					groups: data.groups
				})
					.then((resp) => {
						this.apiCredentials = resp.data;
						this.$refs['new-api-user-dialog'].open();
					})
					.catch((error) => {
						let uniqueValidationError;
						const { status, data } = error.response;

						if (status === 422) {
							const uniqueValidationError =  data?.find((e) => e.label === 'username');
							if (uniqueValidationError) this.$set(this.customError, 'username', uniqueValidationError.message);
						}
						
						if (!uniqueValidationError) ElementTools.fireNotification(this.$parent.$el, 'error', 'Failed to add api user')
					})
					.finally(() => (this.submitting = false));
			},

			createApiUserDialogClosed() {
				this.$router.push(`/admin/api-users`);
				ElementTools.fireNotification(this.$parent.$el, 'success', 'API User has been added successfully.');
			}
		}
	};
</script>
