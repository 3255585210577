// import Request from 'air-components/src/components/air/resource/Request';
import { $http } from '@/utils';
import CryptoJS  from 'crypto-js';

/**
 * @namespace Service
 * @class Account
 * @exports Service/Account
 * @description API Account Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name verify
	 * @description Performs API verify
	 * @returns {Object} Promise a http response
	 */
	verify: () => $http.get('account/authenticate'),

	/**
	 * @name login
	 * @description Performs a login action to get a JWT auth token
	 * @param {Object} payload - The object contains the payload such as { email: ..., password: ...}
	 * @returns {Object} Promise a http response
	 */
	login: (payload) =>
		$http.post('account/authenticate', {
			identity: payload.username,
			identityType: 'username',
			password: payload.password,
			code: payload.code,
			organisationID: payload.organisationID,
			impersonate: payload.impersonate,
			scope: 'manager',
			token: CryptoJS.MD5(process.env.VUE_APP_AUTH_TOKEN).toString()
		}),

	/**
	 * @static @name logout
	 * @description Removes JWT auth token from local storage
	 * @returns {Object} Promise a http response
	 */
	logout: () => $http.clearToken()
};
