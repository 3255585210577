import { CmsAsset } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getAssetList
		 * @param {String} id
		 */
		getAssetList(context, payload) {
			return CmsAsset.list(payload);
		},

		/**
		 * @async @name getAsset
		 * @param {String} id
		 */
		getAsset(context, id) {
			return CmsAsset.get(id);
		},

		/**
		 * @async @name modifyAsset
		 * @param {String} id
		 */
		modifyAsset(context, { id, payload }) {
			return CmsAsset.modify(id, payload);
		},

		/**
		 * @async @name deleteAsset
		 */
		deleteAsset(context, id) {
			return CmsAsset.delete(id);
		},

		/**
		 * @async @name addAsset
		 */
		addAsset(context, payload) {
			return CmsAsset.add(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
