import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Unclaimed
 * @exports Service/Reward/Unclaimed
 * @description API Reward Unclaimed Service
 * @date 2024/11/08
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default {
	/**
	 * @name unclaimed
	 * @description Get all unclaimed rewards
	 * @returns {Object} Promise a http response
	 */
	get: () => $http.get('reward/unclaimed')
};
