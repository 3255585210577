import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Completion
 * @exports Service/Completion
 * @description API Completion Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @@name findUser
	 * @description find user
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	findUser: (payload) => $http.post(`completion/find-user`, payload),

	/**
	 * @@name modify
	 * @description Update some details for ID
	 * @param {String} id
	 * @param {Object} payload
	 */
	assignUser: (payload) => $http.patch(`completion/assign-user`, payload)
};
