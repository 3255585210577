import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Matter
 * @exports Service/Matter
 * @description API Organisation Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @@name add
	 * @description Add matter
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`matter`, payload),

	/**
	 * @@name modify
	 * @description Update some source details for ID
	 * @param {String} id
	 * @param {Object} payload
	 */
	modify: (id, payload) => $http.patch(`matter/${id}`, payload),

	/**
	 * @@name addMatterEntity
	 * @description Add matter entity
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	addMatterEntity: (matterId, payload) => $http.post(`matter/${matterId}/entity`, payload),

	/**
	 * @@name addMatterTypeOrganisation
	 * @description Add matter type
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	addMatterTypeOrganisation: (matterTypeId, payload) => $http.post(`matter/type/${matterTypeId}/organisation`, payload),

	/**
	 * @@name deleteMatterTypeOrganisation
	 * @description Delete matter type
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	deleteMatterTypeOrganisation: (matterTypeId, organisationId) => $http.delete(`matter/type/${matterTypeId}/organisation/${organisationId}`)
};
