<template>
	<div class="pa-8">
		<div class="pb-4">
			<v-btn color="primary" dark @click="invalidateCache()">
				Refresh Cache files in Cloudfront
			</v-btn>
		</div>

		<div class="pb-4">
			<v-btn color="primary" dark @click="invalidateAssets()">
				Refresh Asset files in Cloudfront
			</v-btn>
		</div>

		<div class="pb-4">
			<v-btn color="primary" dark @click="clearCacheOperation('all')">
				Clear and refresh All Cache
			</v-btn>
		</div>

		<div class="pb-4">
			<v-btn color="primary" dark @click="clearCacheOperation('form')">
				Clear and refresh Form Cache
			</v-btn>
		</div>

		<div class="pb-4">
			<v-btn color="primary" dark @click="clearCacheOperation('content')">
				Clear and refresh Content Cache
			</v-btn>
		</div>

		<div class="pb-4">
			<v-btn color="primary" dark @click="clearCacheOperation('data')">
				Clear and refresh Data Cache
			</v-btn>
		</div>

		<div class="pb-4">
			<v-btn color="primary" dark @click="updateOptionsRedis()">
				Update Options in Redis
			</v-btn>
		</div>

		<div class="pb-4">
			<v-btn color="primary" dark @click="updatePanelRedis('select')"> Update Select Panel in Redis </v-btn>
		</div>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';

	export default {
		name: 'app-cms-cache',

		components: {
			CommonDialogConfirm
		},

		data: () => ({}),

		watch: {},

		methods: {
			...mapActions('CmsCache', ['clearCache', 'invalidateCloudfrontCache', 'updateOptions', 'updatePanel']),

			/**
			 * @name invalidateCache
			 * @description Invalidate Cloudfront Cache click handler
			 */
			async invalidateCache() {
				this.$refs.confirm
					.open('Invalidate Cloudfront Cache', `Are you sure you wish to invalidate the Cloudfront Cache?`)
					.then(() => this.submitInvalidateCloudfrontCache({ paths: ['/*'] }))
					.catch(() => {});
			},

			/**
			 * @name invalidateAssets
			 * @description Invalidate Cloudfront Asset click handler
			 */
			async invalidateAssets() {
				this.$refs.confirm
					.open('Invalidate Cloudfront Assets', `Are you sure you wish to invalidate the Cloudfront Assets?`)
					.then(() => this.submitClearCache('asset-invalidation'))
					.catch(() => {});
			},

			/**
			 * @name clearCache
			 * @description Clear cache files and invalidate
			 */
			async clearCacheOperation(operation) {
				if (!['all', 'form', 'content', 'data'].includes(operation)) {
					throw 'Unrecognised operation';
				}

				this.$refs.confirm
					.open('Clear Cache', `Are you sure you wish to clear cache for "${operation}"?`)
					.then(() => this.submitClearCache(operation))
					.catch(() => {});
			},

			/**
			 * @name updatePanel
			 * @description Update panel in Redis
			 */
			async updatePanelRedis(panel) {
				this.$refs.confirm
					.open(`Update ${panel} panel`, `Are you sure you wish to update the ${panel} panel`)
					.then(() => this.submitUpdatePanel(panel))
					.catch(() => {});
			},

			/**
			 * @name submitInvalidateCloudfrontCache
			 * @description Invalidate Cloudfront Cache request handler
			 * @param {Object} account Account to revoke access
			 */
			async submitInvalidateCloudfrontCache(payload) {
				try {
					await this.invalidateCloudfrontCache(payload);

					ElementTools.fireNotification(this.$el, 'success', `You have successfully requested a cache invalidation, please wait a few minutes for this action to complete`);
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to request invalidation');
				}
			},

			/**
			 * @name submitClearCache
			 * @description Clear Cache request handler
			 * @param {string} operation Operation type
			 */
			async submitClearCache(operation) {
				try {
					await this.clearCache(operation);

					ElementTools.fireNotification(this.$el, 'success', `You have successfully requested to clear the cache (${operation})), please wait a few minutes for this action to complete`);
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to request cache clear');
				}
			},

			/**
			 * @name updateOptionsRedis
			 * @description Update options in Redis
			 */
			async updateOptionsRedis() {
				this.$refs.confirm
					.open(`Update all options`, `Are you sure you wish to update all options in Redis?`)
					.then(() => this.submitUpdateOptions('all'))
					.catch(() => {});
			},

			/**
			 * @name submitUpdateOptions
			 * @description Reload options into Redis request handler
			 * @param {string} option Option name
			 */
			async submitUpdateOptions(option) {
				try {
					await this.updateOptions(option);
					ElementTools.fireNotification(this.$el, 'success', `You have successfully requested update options (${option}))`);
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to update options');
				}
			},
			
			/**
			 * @name submitUpdatePanel
			 * @description Reload panel into Redis request handler
			 * @param {string} panel Panel name
			 */
			async submitUpdatePanel(panel) {
				try {
					await this.updatePanel(panel);

					ElementTools.fireNotification(this.$el, 'success', `You have successfully requested update panel (${panel}))`);
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to update panel');
				}
			}
		}
	};
</script>
