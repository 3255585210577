import { CmsSite } from '@/service';

/**
 * @class @name Site
 * @description Exposes Site store module
 */
export default {
	/**
	 * @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	state: {
		site: undefined
	},

	/**
	 * @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	mutations: {
		/**
		 * @name setSite
		 * @description used to set app config
		 * @param {Object} state
		 * @param {Object} data
		 */
		setSite(state, data) {
			const { view, ...rest } = data;
			state.site = { ...view, ...rest };
		}
	},

	/**
	 * @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name loadSite
		 * @description used to fetch app config
		 * @param {function} commit
		 */
		async loadSite({ commit }) {
			let { data } = await CmsSite.get();
			commit('setSite', data);
		},

		/**
		 * @async @name getMembership
		 * @param {String} type
		 */
		async getMembershipSite(context, type) {
			return await CmsSite.getMembership(type);
		}
	},

	namespaced: true
};
