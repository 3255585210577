<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title class="font-weight-bold">
				Mandatory Payments Rate Update
			</v-toolbar-title>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters class="justify-center">
				<v-col cols="6" md="8" lg="8" xl="6">
					<v-sheet class="pa-6" elevation="4">
						<p><strong>Upload Rates CSV:</strong></p>
						<dynamic-form ref="dynamicForm" :form-schema="form('manager_mandatory_payments_rate_upload').value" :form-data="formData" submit-button-text="Validate CSV" :submit-button-loading="submitting" @dynamic-form-submit="submitValidate" />
					</v-sheet>
				</v-col>
			</v-row>
			<v-row dense no-gutters class="justify-center mt-4">
				<v-col cols="6" md="8" lg="8" xl="6">
					<v-sheet class="pa-6" elevation="4">
						<p><strong>Update Rates at:</strong></p>
						<dynamic-form
							ref="dynamicForm"
							:form-schema="form('manager_mandatory_payments_rate_update').value"
							:form-data="formData"
							:submit-button="true"
							:submit-button-loading="submitting"
							:submit-button-disabled="!validated"
							@dynamic-form-submit="submit"
						/>
					</v-sheet>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { cloneDeep } from 'lodash';

	export default {
		name: 'app-mandatory-payments-rate-update',

		components: {
			DynamicForm
		},

		data: () => ({
			formData: {},
			formSchema: [],
			submitting: false,
			validated: false
		}),

		computed: {
			...mapGetters('CmsForm', ['form'])
		},

		created() {
			this.loadForm('manager_mandatory_payments_rate_upload');
			this.loadForm('manager_mandatory_payments_rate_update');
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('AppProductMandatoryPaymentsRateUpdate', ['validate', 'addSchedule']),

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 */
			async submitValidate() {
				this.submitting = true;
			
				const { organisationId, files } = cloneDeep(this.formData);
				const payload = { organisationId: organisationId, s3Key: files[0].s3Key };

				this.validate(payload)
					.then((response) => {
						this.validated = true;
						ElementTools.fireNotification(this.$parent.$el, 'success', response.data.message);
					})
					.catch((error) => {
						ElementTools.fireNotification(this.$parent.$el, 'error', error.response.data.message || 'Failed to validate mandatory payments rate csv');
					})
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name submit
			 * @description Event listener for form submissions
			 */
			async submit() {
				this.submitting = true;

				const { organisationId, files, date, time } = cloneDeep(this.formData);
				const payload = { organisationId: organisationId, s3Key: files[0].s3Key };
				payload.schedule = this.$moment(`${this.formatDate(date)} ${time}`).format('YYYY-MM-DDTHH:mm:ss');

				this.addSchedule(payload)
					.then((response) => {
						ElementTools.fireNotification(this.$parent.$el, 'success', response.data.message);
					})
					.catch((error) => {
						ElementTools.fireNotification(this.$parent.$el, 'error', error.response.data.message || 'Failed to add mandatory payments rate update schedule');
					})
					.finally(() => (this.submitting = false));
			},

			/**
			 * @name formatDate
			 * @description Format date to YYYY-MM-DD
			 */
			formatDate(date) {
				const [day, month, year] = date.split('/');
				return `${year}-${month}-${day}`;
			}
		}
	};
</script>
