import { AccountRole } from '@/service';

export default {
	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name getRoleList
		 * @param {Object} context
		 * @param {Object} payload
		 */
		getRoleList(context, payload) {
			return AccountRole.list(payload);
		},

		/**
		 * @async @name addRole
		 * @param {Object} context
		 * @param {Object} payload
		 */
		addRole(context, payload) {
			return AccountRole.add(payload);
		}
	},

	/**
	 * @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true
};
