import { Action } from '@/service';

/**
 * @class @name
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name fetchActionList
		 * @param {Object} payload
		 */
		fetchActionList(context, payload) {
			return Action.list(payload);
		},

		/**
		 * @async @name addAction
		 * @param {Object} payload
		 */
		addAction(context, payload) {
			return Action.add(payload);
		},

		/**
		 * @async @name modifyAction
		 * @param {Object} payload
		 */
		modifyAction(context, { id, payload }) {
			return Action.modify(id, payload);
		},

		/**
		 * @async @name deleteAction
		 * @param {String} id
		 */
		deleteAction(context, id) {
			return Action.delete(id);
		}
	}
};
