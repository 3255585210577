import { ProductRateUpdate } from '@/service';

/**
 * @class @name
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name validate
		 * @param {Object} payload
		 */
		validate(context, payload) {
			return ProductRateUpdate.validate(payload);
		},
		/**
		 * @async @name addSchedule
		 * @param {Object} payload
		 */
		addSchedule(context, payload) {
			return ProductRateUpdate.addSchedule(payload);
		}
	}
};
