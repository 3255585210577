<template>
	<div class="pa-8">
		<v-data-table
			:headers="headers"
			:items="items"
			class="elevation-1 mt-3"
			:options.sync="options"
			:server-items-length="total"
			:loading="loading"
			:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [5, 10, 15, 20, 25] }"
		>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn v-if="item.invitationId && item.userId" small color="success" :loading="verifying[item.invitationId]" @click="acceptItem(item)"> Accept </v-btn>
					<v-btn v-else-if="item.invitationId && !item.userId" small color="success" :href="airSourcingUrl + 'complete-registration/' + item.token" target="_blank"> Complete </v-btn>
					<v-btn v-else small color="success" :loading="verifying[item.registrationId]" @click="verifyItem(item)"> Verify </v-btn>
					<v-btn small color="error" class="ml-2" :loading="deleting[(item.type === 'invitation' && item.invitationId) || (item.type === 'registration' && item.registrationId)]" @click="deleteItem(item)"> Delete </v-btn>
				</div>
			</template>
		</v-data-table>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';

	export default {
		name: 'app-registrations',

		components: {
			CommonDialogConfirm
		},

		data: () => ({
			items: [],
			loading: false,
			deleting: {},
			verifying: {},
			options: {},
			total: 0,
			pages: 0,
			headers: [
				{ text: 'Name', value: 'data.name', sortable: false },
				{ text: 'Identity', value: 'identity', sortable: false },
				{ text: 'Identity Type', value: 'identityType', sortable: false },
				{ text: 'Created', value: 'created', sortable: false },
				{ text: '', value: 'actions', sortable: false }
			]
		}),

		computed: {
			airSourcingUrl() {
				return process.env.VUE_APP_AIR_SOURCING_URL;
			}
		},

		watch: {
			options: {
				handler() {
					this.fetchRegistrations();
				},
				deep: true
			}
		},

		methods: {
			...mapActions('AccountRegistration', ['fetchRegistrationList', 'acceptInvitation', 'verifyRegistration', 'deleteRegistration']),

			async fetchRegistrations() {
				if (this.loading) return;

				this.loading = true;
				const { sortBy, sortDesc, page, itemsPerPage } = this.options;

				const payload = {
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					order:
						sortBy.length > 0
							? {
									property: sortBy[0],
									direction: sortDesc[0] ? 'desc' : 'asc'
							  }
							: undefined
				};

				const { data } = await this.fetchRegistrationList(payload);

				this.items = data.data;
				this.total = data.total;
				this.pages = Math.ceil(this.total / this.options.itemsPerPage);
				this.loading = false;
			},

			acceptItem(item) {
				this.$refs.confirm
					.open('Accept Invitation', 'Are you sure you wish to accept this invitation?')
					.then(async () => {
						this.$set(this.verifying, item.invitationId, true);
						await this.acceptInvitation(item.token);
						this.$set(this.verifying, item.invitationId, false);
						await this.fetchRegistrations();
					})
					.catch(() => {
						ElementTools.fireNotification(this.$el, 'error', 'Failed to accept invitation');
					});
			},

			verifyItem(item) {
				this.$refs.confirm
					.open('Verify Registration', 'Are you sure you wish to verify this registration?')
					.then(async () => {
						this.$set(this.verifying, item.registrationId, true);
						await this.verifyRegistration(item.token);
						this.$set(this.verifying, item.registrationId, false);
						await this.fetchRegistrations();
					})
					.catch(() => {
						ElementTools.fireNotification(this.$el, 'error', 'Failed to verify user registration');
					});
			},

			async deleteItem(item) {
				const id = (item.type === 'invitation' && item.invitationId) || (item.type === 'registration' && item.registrationId);

				try {
					await this.$refs.confirm.open('Delete Registration', 'Are you sure you wish to delete this registration?');
					this.$set(this.deleting, id, true);
					await this.deleteRegistration({ type: item.type, id });
					this.$set(this.deleting, id, false);
					await this.fetchRegistrations();
				} catch (error) {
					ElementTools.fireNotification(this.$el, 'error', 'Failed to delete user registration');
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep ul.v-pagination {
		justify-content: end !important;
	}
</style>
