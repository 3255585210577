import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Account
 * @exports Service/Account/User/Account
 * @description API User Account Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @static @name get
	 * @description Get User account for ID (RBAC)
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`account/user/account/${id}`),

	/**
	 *: @@name upda =>t
	  @description Update all User account for ID,
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	update: (id, payload) => $http.put(`account/user/account/${id}`, payload),

	/**
	 *: @@name modi =>f
	  @description Update some User account for ID,
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	modify: (id, payload) => $http.patch(`account/user/account/${id}`, payload)
};
