<template>
	<div class="pa-8">
		<v-data-table
			:headers="headers"
			:items="items"
			class="elevation-1 mt-3"
			:options.sync="options"
			:server-items-length="total"
			:loading="loading"
			:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [5, 10, 15, 20, 25] }"
		>
			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn small color="success" :loading="approving[item.id]" @click="approveItem(item.id)"> Approve </v-btn>
					<v-btn small color="error" class="ml-2" :loading="deleting[item.id]" @click="rejectItem(item.id)"> Reject </v-btn>
				</div>
			</template>
		</v-data-table>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';

	export default {
		name: 'app-trading-styles',

		components: {
			CommonDialogConfirm
		},

		data: () => ({
			items: [],
			loading: false,
			deleting: {},
			approving: {},
			options: {},
			total: 0,
			pages: 0,
			headers: [
				{ text: 'Organisation', value: 'organisationName', sortable: false },
				{ text: 'Trading Style', value: 'name', sortable: false },
				{ text: 'FCA', value: 'data.fca', sortable: false },
				{ text: 'Created', value: 'created', sortable: true },
				{ text: '', value: 'actions', sortable: false }
			]
		}),

		computed: {
			airSourcingUrl() {
				return process.env.VUE_APP_AIR_SOURCING_URL;
			}
		},

		watch: {
			options: {
				handler() {
					this.fetchTradingStyles();
				},
				deep: true
			}
		},

		methods: {
			...mapActions('AccountTradingStyle', ['fetchTradingStyleList', 'approveTradingStyle', 'rejectTradingStyle']),

			async fetchTradingStyles() {
				if (this.loading) return;

				this.loading = true;
				const { sortBy, sortDesc, page, itemsPerPage } = this.options;

				const payload = {
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					where: [{ 'organisation_trading_style.approved': null }, '&&', { "organisation_trading_style.data->>'fca'": { c: 'IS NOT', v: null } }],
					order: sortBy.length > 0 ? { property: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc' } : undefined
				};

				const { data } = await this.fetchTradingStyleList(payload);

				this.items = data.data;
				this.total = data.total;
				this.pages = Math.ceil(this.total / this.options.itemsPerPage);
				this.loading = false;
			},

			approveItem(id) {
				this.$refs.confirm
					.open('Approve Trading Style', 'Are you sure you wish to approve this trading style?')
					.then(async () => {
						this.$set(this.approving, id, true);
						await this.approveTradingStyle(id);
						await this.fetchTradingStyles();
						this.$set(this.approving, id, false);
					})
					.catch(() => {
						ElementTools.fireNotification(this.$el, 'error', 'Failed to approve trading style');
					});
			},

			async rejectItem(id) {
				this.$refs.confirm
					.open('Reject Trading Style', 'Are you sure you wish to reject this trading style?')
					.then(async () => {
						this.$set(this.deleting, id, true);
						await this.rejectTradingStyle(id);
						await this.fetchTradingStyles();
						this.$set(this.deleting, id, false);
					})
					.catch(() => {
						ElementTools.fireNotification(this.$el, 'error', 'Failed to reject trading style');
					});
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep ul.v-pagination {
		justify-content: end !important;
	}
</style>
