import { LogApi } from '@/service';

/**
 * @class @name
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {

		/**
		 * @async @name fetchLogs
		 * @param {function} commit
		 * @param {UUID} id
		 */
		fetchLogs(context, payload) {
			return LogApi.list(payload);
		},

		/**
		 * @async @name fetchLog
		 * @param {function} commit
		 * @param {Object} data
		 */
		fetchLog(context, id) {
			return LogApi.get(id);
		}
	}
};
