import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Role
 * @exports Service/Account/User/Role
 * @description API Role Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name list
	 * @description Get Role list
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post(`account/role/list`, payload),

	/**
	 * @name add
	 * @description Add a new role
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`account/role`, payload)
};
