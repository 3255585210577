<!--
	@name app-layout
	@description Front page layout
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<!-- header -->
		<layout-header @menu-button-click="$refs.sidebar.switch()" />

		<!-- sidebar -->
		<layout-sidebar ref="sidebar" />

		<!-- content -->
		<v-main>
			<common-base-breadcrumb />

			<router-view />
		</v-main>

		<!-- footer -->
		<!-- <layout-footer /> -->
		<common-dialog-version-update ref="versionUpdate" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import { EventBus } from '@/utils';
	import CommonBaseBreadcrumb from '@/components/common/base/breadcrumb';
	import CommonDialogVersionUpdate from '@/components/common/dialog/version-update';
	import LayoutHeader from './header';
	import LayoutSidebar from './sidebar';
	// import LayoutFooter from './footer';

	export default {
		name: 'app-layout',

		components: {
			CommonBaseBreadcrumb,
			CommonDialogVersionUpdate,
			LayoutHeader,
			LayoutSidebar
			// LayoutFooter
		},

		created() {
			this.loadSite();

			EventBus.$on('app-version-update', (targetRoute) => {
				this.$refs.versionUpdate.open(targetRoute);
			});
		},

		beforeDestroy() {
			EventBus.$off('app-version-update');
		},

		methods: {
			...mapActions('CmsSite', ['loadSite'])
		}
	};
</script>

<style lang="scss"></style>
