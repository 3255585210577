<template>
	<div>
		<v-container class="pt-8">
			<dynamic-form :form-schema="form('manager_deal').value" :form-data="formData" :submit-button="false" confirm-file-upload />
		</v-container>

		<v-container class="pt-8">
			<dynamic-form :form-schema="form('manager_deal_select').value" :form-data="formDataSelect" :submit-button="false" confirm-file-upload />
		</v-container>
	</div>
</template>

<script>
	import DynamicForm from '@/components/common/base/dynamic-form';
	import { mapActions, mapGetters } from 'vuex';
	export default {
		name: 'app-cms-deals',

		components: {
			DynamicForm
		},

		data() {
			return {
				formData: {},
				formDataSelect: {},
				loading: false
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form'])
		},

		created() {
			this.loadForm('manager_deal');
			this.loadForm('manager_deal_select');
		},

		methods: {
			...mapActions('CmsForm', ['loadForm'])
		}
	};
</script>
