<template>
	<div class="pa-8">
		<p class="caption mb-5">
			Notes: Email, company and submitted columns removed, details can be retrieved from new view details button to be more restful. Submitted column relates to old "bank_details" column in users table. Redeemed is
			new actioned column. Results should be all pending (not approved, declined or deleted) requests. Button actions are approve, decline and delete. Approve should add relevant points back on to user account.
		</p>

		<div class="d-flex">
			<validation-observer ref="observer" class="pa-0 col-6">
				<validation-provider v-slot="{ errors }" name="Search" rules="search">
					<v-text-field v-model="searchTerm" placeholder="Search" append-icon="mdi-magnify" dense solo light clear-icon="mdi-close-circle" clearable :error-messages="errors" @click:clear="searchTerm = ''" />
				</validation-provider>
			</validation-observer>
			<v-spacer />
			<v-pagination v-model="options.page" :length="pages" total-visible="7" :disabled="loading" />
		</div>

		<v-data-table
			:headers="headers"
			:items="items"
			class="elevation-1 mt-3"
			:options.sync="options"
			:server-items-length="total"
			:loading="loading"
			:footer-props="{ 'disable-items-per-page': loading, 'disable-pagination': loading, 'items-per-page-options': [5, 10, 15, 20, 25] }"
		>
			<template #[`item.user.name`]="{ item }">
				{{ item.user.name }}
				<v-btn small color="primary" class="d-block my-2" :loading="loadingUserDetails[item.id]" @click="!user[item.id] ? getUserDetails(item) : hideUserDetails(item.id)">
					{{ !user[item.id] ? 'View' : 'Hide' }} Details
				</v-btn>
				<div v-if="user[item.id]" class="d-flex flex-column">
					<span> Email: {{ user[item.id].userDetail.email }} </span>
					<span> Company: {{ user[item.id].organisation.name }} </span>
					<span>
						Bank details:
						<v-icon :color="user[item.id].data.bank?.details ? 'success' : 'error'">
							{{ user[item.id].data.bank?.details ? 'mdi-check-circle' : 'mdi-close-circle' }}
						</v-icon>
					</span>
				</div>
			</template>

			<template #item.created="{ item }">
				{{ item.created | moment('Do MMM YYYY, HH:mm') }}
			</template>
			<template #item.actioned="{ item }">
				{{ item.actioned | moment('Do MMM YYYY, HH:mm') }}
			</template>
			<template #item.expiry="{ item }">
				{{ item.expiry | moment('Do MMM YYYY, HH:mm') }}
			</template>

			<template #item.actions="{ item }">
				<div class="d-flex justify-end">
					<v-btn small color="success" :loading="approving[item.id]" @click="approveItem(item.id)">
						Approve
					</v-btn>
					<v-btn small color="warning" class="ml-2" :loading="declining[item.id]" @click="declineItem(item.id)">
						Decline
					</v-btn>
					<v-btn small color="error" class="ml-2" :loading="deleting[item.id]" @click="deleteItem(item.id)">
						Delete
					</v-btn>
				</div>
			</template>
		</v-data-table>

		<common-dialog-confirm ref="confirm" />
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import CommonDialogConfirm from '@/components/common/dialog/confirm';
	import { debounce } from 'lodash';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';

	export default {
		name: 'app-products',

		components: {
			CommonDialogConfirm,
			ValidationObserver,
			ValidationProvider
		},

		data: () => ({
			items: [],
			user: {},
			loading: false,
			loadingUserDetails: {},
			deleting: {},
			approving: {},
			declining: {},
			options: {},
			total: 0,
			pages: 0,
			headers: [
				{ text: 'Adviser', value: 'user.name' },
				{ text: 'Amount', value: 'amount', sortable: false },
				{ text: 'Type', value: 'data.type', sortable: false },
				{ text: 'Originator', value: 'data.originator', sortable: false },
				{ text: 'Method', value: 'debit.name', sortable: false },
				{ text: 'Created', value: 'created' },
				{ text: 'Redeemed', value: 'actioned', sortable: false },
				{ text: 'Expires', value: 'expiry', sortable: false },
				{ text: '', value: 'actions', sortable: false }
			],
			searchTerm: '',
			statusItems: ['Pending', 'Submitted', 'Received']
		}),

		watch: {
			options: {
				handler() {
					this.fetchRewardTransactionList();
				},
				deep: true
			},

			async searchTerm() {
				const valid = await this.$refs.observer.validate();
				if(!valid) return;
				this.debouncedFetchRewardTransactionList();
			}
		},

		methods: {
			...mapActions('AppRewardTransaction', ['getRewardTransactionList', 'modifyRewardTransaction', 'deleteRewardTransaction']),
			...mapActions('AccountUser', ['fetchUser']),

			async fetchRewardTransactionList() {
				if (this.loading) return;

				this.loading = true;
				const { sortBy, sortDesc, page, itemsPerPage } = this.options;

				const payload = {
					where: [{ credit_id: null }, '&&', { deleted: null }, '&&', { actioned: { c: 'IS NOT', v: null } }, '&&', { approved: null }, '&&', { rejected: null }],
					limit: itemsPerPage !== -1 && itemsPerPage,
					offset: itemsPerPage !== -1 ? (page - 1) * itemsPerPage : 0,
					order: sortBy.length > 0 ? {
						property: sortBy[0],
						direction: sortDesc[0] ? 'desc' : 'asc'
					} : undefined
				};

				if (this.searchTerm) {
					if (payload.where?.length) payload.where.push('&&');
					else payload.where = [];

					let conditions = [
						{ '"user".name': { c: 'ILIKE', v: `%${this.searchTerm}%` } }
					];
					payload.where = [...payload.where, ...conditions];
				}

				const { data } = await this.getRewardTransactionList(payload);

				this.items = data.data;
				this.total = data.total;
				this.pages = Math.ceil(this.total / this.options.itemsPerPage);
				this.loading = false;
			},

			/**
			 * @name debouncedFetchRewardTransactionList
			 * @description Load Reward Transactions with debounce
			 */
			debouncedFetchRewardTransactionList: debounce(function () {
				this.options.page = 1;
				this.fetchRewardTransactionList();
			}, 1000),

			approveItem(id) {
				this.$refs.confirm
					.open('Approve Request', 'Are you sure you wish to approve this request?')
					.then(async () => {
						this.$set(this.approving, id, true);
						await this.modifyRewardTransaction({ id, payload: { approved: true } });
						await this.fetchRewardTransactionList(id);
						this.$set(this.approving, id, false);
					})
					.catch(() => {});
			},

			async declineItem(id) {
				this.$refs.confirm
					.open('Decline Request', "Are you sure you wish to decline this request? Doing so will add the points back on to the adviser's account")
					.then(async () => {
						this.$set(this.declining, id, true);
						await this.modifyRewardTransaction({ id, payload: { rejected: true } });
						await this.fetchRewardTransactionList();
						this.$set(this.declining, id, false);
					})
					.catch(() => {});
			},

			async deleteItem(id) {
				this.$refs.confirm
					.open('Delete Request', 'Are you sure you wish to delete this request?')
					.then(async () => {
						this.$set(this.deleting, id, true);
						await this.deleteRewardTransaction(id);
						await this.fetchRewardTransactionList();
						this.$set(this.deleting, id, false);
					})
					.catch(() => {});
			},

			async getUserDetails(item) {
				this.$set(this.loadingUserDetails, item.id, true);
				const { data } = await this.fetchUser(item.user.id);
				this.$set(this.user, item.id, data);
				this.$set(this.loadingUserDetails, item.id, false);
			},

			hideUserDetails(id) {
				this.$delete(this.user, id);
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep ul.v-pagination {
		justify-content: end !important;
	}
</style>
