import { $http } from '@/utils';

/**
 * @namespace Service
 * @class ProductLoanToValue
 * @exports Service/Product
 * @description API Product Loan To Value Service
 * @date 2024/09/30
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name validate
	 * @description Send a request to process CSV file and validate
	 * @returns {Object} Promise a http response
	 */
	validate: (payload) => $http.post(`product/loan-to-value-update/validate`, payload),

	/**
	 * @name addSchedule
	 * @description Add a schedule to process CSV file and update database
	 * @returns {Object} Promise a http response
	 */
	addSchedule: (payload) => $http.post(`product/loan-to-value-update/schedule`, payload)
};
