import { Matter, MatterEntity, MatterTypeOrganisation, FactFind, Case } from '@/service';

/**
 * @class @name
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	state: {},

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	getters: {},

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	mutations: {},

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name fetchFactFindList
		 * @param {function} commit
		 * @param {Object} payload
		 */
		fetchFactFindList(context, payload) {
			return FactFind.list(payload);
		},

		/**
		 * @async @name fetchCaseList
		 * @param {function} commit
		 * @param {Object} payload
		 */
		fetchCaseList(context, payload) {
			return Case.list(payload);
		},

		/**
		 * @async @name modifyMatter
		 * @param {function} commit
		 * @param {Object} data
		 */
		modifyMatter(context, { id, payload }) {
			return Matter.modify(id, payload);
		},

		/**
		 * @async @name deleteMatter
		 * @param {function} commit
		 * @param {Object} data
		 */
		deleteMatter(context, id) {
			return Matter.delete(id);
		},

		/**
		 * @name addMatterEntity
		 * @param {Object} context
		 * @param {Object} payload
		 */
		addMatterEntity(context, { matterId, payload }) {
			return MatterEntity.add(matterId, payload);
		},

		/**
		 * @name addMatterTypeOrganisation
		 * @param {Object} context
		 * @param {Object} payload
		 */
		addMatterTypeOrganisation(context, { id, payload }) {
			return MatterTypeOrganisation.add(id, payload);
		},

		/**
		 * @name deleteMatterTypeOrganisation
		 * @param {Object} context
		 * @param {Object} payload
		 */
		deleteMatterTypeOrganisation(context, { id, organisationId }) {
			return MatterTypeOrganisation.delete(id, organisationId);
		}
	}
};
