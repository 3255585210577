import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Form
 * @exports Service/Cms/Form
 * @description API Form Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @static @name usingName
	 * @description Get Form using name
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	usingName: (name) => $http.get(`cms/form/using/name/${name}`),

	/**
	 * @static @name usingName
	 * @description Get Forms using selected names
	 * @param {Array} select
	 * @returns {Object} Promise a http response
	 */
	usingList: (select) => $http.post('cms/form/list', { select })
};
