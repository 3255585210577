<template>
	<div class="pa-8">
		<v-toolbar class="mb-6">
			<v-toolbar-title>User #{{ userId }}</v-toolbar-title>
			<v-spacer />
			<v-btn color="primary" dark to="/users">
				Back to users
			</v-btn>
		</v-toolbar>

		<v-container fluid>
			<v-row dense no-gutters>
				<v-col cols="12">
					<p class="caption mb-5">
						Notes: SecQuestions, Password, Credentials, Messages, Webinars are NOT being rebuilt as they are either accessible on the adviser takeover, or in main CRM. Academy and Club have been merged in to
						Membership
					</p>
					<v-list v-if="user" dark class="ma-0 pa-0">
						<v-list-item v-for="item in filteredItems" :key="item.path" :to="`${$route.path}/${item.path}`" :disabled="item.disabled" dense class="my-1 primary">
							<v-list-item-content>
								{{ item.name }}
							</v-list-item-content>
							<v-list-item-action class="my-0">
								<v-icon>
									{{ item.icon }}
								</v-icon>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';

	export default {
		name: 'app-users-details',

		data() {
			return {
				user: null,
				items: [
					{
						name: 'Details',
						path: 'details',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'Firm',
						path: 'firm',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'Membership',
						path: 'membership',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'Rewards',
						path: 'rewards',
						icon: 'mdi-star',
						disabled: false
					},
					{
						name: 'WriteRoute',
						path: 'write-route',
						icon: 'mdi-star',
						disabled: false
					}
				]
			};
		},

		computed: {
			userId() {
				return this.$route.params.id;
			},

			filteredItems() {
				const remove = [];
				if (this.user?.organisation.type === 'provider') remove.push('rewards', 'write-route', 'firm');
				return this.items.filter((i) => !remove.includes(i.path));
			}
		},

		created() {
			this.$route.meta.breadcrumb[2] = {
				text: `User #${this.userId}`,
				to: this.$route.path
			};

			this.fetchUser(this.userId).then(({ data }) => {
				this.user = data;
			});
			
		},

		methods: {
			...mapActions('AccountUser', ['getUserGroups', 'fetchUser'])
		}
	};
</script>
