/**
 * @namespace Router
 * @exports Router
 * @description Routes for system
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

import APIUserDetails from '@/components/app/admin/api-users/details';
import APIUsers from '@/components/app/admin/api-users';
import Admin from '@/components/app/admin';
import ApiHealth from '@/components/app/admin/api-health';
import ApiLogs from '@/components/app/admin/api-logs';
import CompletionUploadDetails from '@/components/app/admin/completion-uploads/details';
import CompletionUploads from '@/components/app/admin/completion-uploads';
import GroupDetails from '@/components/app/admin/groups/details';
import Groups from '@/components/app/admin/groups';
import MandatoryPaymentsRateUpdate from '@/components/app/admin/mandatory-payments-rate-update';
import NetworkDetails from '@/components/app/admin/networks/details';
import NetworkDetailsDetails from '@/components/app/admin/networks/details/details';
import NetworkDetailsUserDetails from '@/components/app/admin/networks/details/users/details';
import NetworkDetailsUsers from '@/components/app/admin/networks/details/users';
import Networks from '@/components/app/admin/networks';
import NotificationDetails from '@/components/app/admin/notifications/details';
import Notifications from '@/components/app/admin/notifications';
import PartnerEnvironment from '@/components/app/admin/partner-environment';
import LoanToValueUpdate from '@/components/app/admin/loan-to-value-update';
import RateUpdate from '@/components/app/admin/rate-update';
import RoleDetails from '@/components/app/admin/roles/details';
import Roles from '@/components/app/admin/roles';
import UserDetails from '@/components/app/admin/users/details';
import Users from '@/components/app/admin/users';
import UnclaimedRewards from '@/components/app/admin/unclaimed-rewards';

export default [
	{
		name: 'admin',
		path: '/admin',
		component: Admin,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.notifications',
		path: '/admin/notifications',
		component: Notifications,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Notifications' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.notifications.detail',
		path: '/admin/notification/:id?',
		component: NotificationDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Notifications', to: '/admin/notifications' }, { text: 'Notification Details' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.api-health',
		path: '/admin/api-health',
		component: ApiHealth,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'API Health' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.completion-uploads',
		path: '/admin/completion-uploads',
		component: CompletionUploads,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Completion Uploads' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.completion-upload',
		path: '/admin/completion-upload/:id',
		component: CompletionUploadDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Completion Uploads', to: '/admin/completion-uploads' }, { text: 'Completion Upload Details' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.users',
		path: '/admin/users',
		component: Users,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Users' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.users.detail',
		path: '/admin/user/:id?',
		component: UserDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Users', to: '/admin/users' }, { text: 'User Details' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.roles',
		path: '/admin/roles',
		component: Roles,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Roles' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.roles.detail',
		path: '/admin/role/:id?',
		component: RoleDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Roles', to: '/admin/roles' }, { text: 'Role Details' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.groups',
		path: '/admin/groups',
		component: Groups,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Groups' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.groups.detail',
		path: '/admin/group/:id?',
		component: GroupDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Groups', to: '/admin/groups' }, { text: 'Group Details' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.rate-update',
		path: '/admin/rate-update',
		component: RateUpdate,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Rate Update' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.mandatory-payments-rate-update',
		path: '/admin/mandatory-payments-rate-update',
		component: MandatoryPaymentsRateUpdate,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Mandatory Payments Rate Update' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.loan-to-value-update',
		path: '/admin/loan-to-value-update',
		component: LoanToValueUpdate,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Loan To Value Update' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.networks',
		path: '/admin/networks',
		component: Networks,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Networks' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'cms.providers',
		path: '/admin/network/:id',
		component: NetworkDetails,
		meta: {
			breadcrumb: [
				{ text: 'Dashboard', to: '/' },
				{ text: 'Admin', to: '/admin' },
				{ text: 'Networks', to: '/admin/networks' }
			],
			groups: ['administrator.system']
		}
	},
	{
		name: 'cms.providers',
		path: '/admin/network/:id/details',
		component: NetworkDetailsDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Networks', to: '/admin/networks' }, { text: 'Network Details' }, { text: 'Details' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'cms.providers',
		path: '/admin/network/:id/users',
		component: NetworkDetailsUsers,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Networks', to: '/admin/networks' }, { text: 'Network Details' }, { text: 'Users' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'cms.providers',
		path: '/admin/network/:id/user/:userId?',
		component: NetworkDetailsUserDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Networks', to: '/admin/networks' }, { text: 'Network Details' }, { text: 'Users' }, { text: 'User Details' }],
			groups: ['administrator.system']
		}
	},
	{
		name: 'admin.api-users',
		path: '/admin/api-users',
		component: APIUsers,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'API Users' }]
		}
	},
	{
		name: 'admin.api-users.detail',
		path: '/admin/api-user/:id?',
		component: APIUserDetails,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'API Users', to: '/admin/api-users' }, { text: 'API User Details' }]
		}
	},
	{
		name: 'admin.api-logs',
		path: '/admin/api-logs',
		component: ApiLogs,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Api Logs' }]
		}
	},
	{
		name: 'admin.partner-environment',
		path: '/admin/partner-environment',
		component: PartnerEnvironment,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Partner Environment' }]
		}
	},
	{
		name: 'admin.unclaimed-rewards',
		path: '/admin/unclaimed-rewards',
		component: UnclaimedRewards,
		meta: {
			breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Admin', to: '/admin' }, { text: 'Unclaimed Rewards' }],
			groups: ['administrator.system']
		}
	}
];
