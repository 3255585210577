import { $http } from '@/utils';

/**
 * @namespace Service
 * @class Client
 * @exports Service/Client
 * @description API Client Service
 * @date 2021/04/01
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @@name list
	 * @description Get all clients
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	list: (payload) => $http.post('client/list', payload),

	/**
	 * @@name get
	 * @description Get client details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	get: (id) => $http.get(`client/${id}`),

	/**
	 * @@name update
	 * @description Update all client details for ID
	 * @param {String} id
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	update: (id, payload) => $http.put(`client/${id}`, payload),

	/**
	 * @@name modify
	 * @description Update some client details for ID
	 * @param {String} id
	 * @param {Object} payload
	 */
	modify: (id, payload) => $http.patch(`client/${id}`, payload),

	/**
	 * @@name delete
	 * @description Hard delete client details for ID
	 * @param {String} id
	 * @returns {Object} Promise a http response
	 */
	delete: (id) => $http.delete(`client/${id}`),

	/**
	 * @@name add
	 * @description Add a new client resource
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	add: (payload) => $http.post(`client`, payload),

	/**
	 * @@name aggregatedList
	 * @description Get all clients with associated clients
	 * @param {Object} payload
	 * @returns {Object} Promise a http response
	 */
	aggregatedList: (payload) => $http.post('client/aggregated/list', payload)
};
