<template>
	<div>
		<common-dialog ref="logs" :max-width="1200">
			<template #header>
				API Logs
			</template>

			<template #body>
				<v-data-table v-if="logs.length" :headers="headers" :items="orderedLogs" hide-default-footer class="mt-6 elevation-2">
					<template #item.file="{ item }">
						<span>{{ item.file }}</span>
						<span class="d-block text-caption grey--text text--darken-1 mt-1">{{ item.method }}</span>
					</template>
					<template #item.lastModified="{ item }">
						{{ item.lastModified | moment('Do MMM YYYY, HH:mm:ss') }}
					</template>
					<template #item.actions="{ item }">
						<v-btn icon @click="openLog(item)">
							<v-icon :color="item.method ? 'success' : 'primary'">mdi-eye</v-icon>
						</v-btn>
					</template>
				</v-data-table>
				<v-skeleton-loader v-else type="list-item-three-line" class="pa-0 mt-5 mb-0" />
			</template>
		</common-dialog>

		<common-dialog ref="log" :max-width="1000">
			<template #header>
				Logs
			</template>

			<template #body>
				<v-expansion-panels v-if="log" v-model="panel" class="mt-5">
					<v-expansion-panel>
						<v-expansion-panel-header>
							Details
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<p v-if="method" class="text-caption mt-1 mb-0">
								<span class="font-weight-bold">Method:</span> {{ method }}
							</p>
							<p v-if="lastModified" class="text-caption mt-1 mb-0">
								<span class="font-weight-bold">Created:</span> {{ lastModified }}
							</p>
							<p v-if="status" class="text-caption mt-1 mb-0">
								<span class="font-weight-bold">Status:</span> {{ status }}
							</p>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header>
							Request
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<json-viewer :value="log.request" boxed copyable expanded />
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header>
							Response
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<json-viewer :value="log.response" boxed copyable expanded />
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<v-skeleton-loader v-else type="list-item-three-line" class="pa-0 mt-5 mb-0" />
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import CommonDialog from '@/components/common/dialog';
	import { orderBy } from 'lodash';
	import { mapActions } from 'vuex';
	import { ElementTools } from '@/utils';

	export default {
		name: 'app-requests-log-dialog',

		components: {
			CommonDialog
		},

		data: () => ({
			iterationId: null,
            headers: [
                { text: 'Log', align: 'start', value: 'file', sortable: false },
                { text: 'Created', align: 'center', value: 'lastModified', sortable: false },
                { text: 'Actions', align: 'center', value: 'actions', sortable: false }
            ],
			logs: [],
            panel: 0,
            log: null
		}),

        computed: {
			orderedLogs() {
				return orderBy(this.logs, 'lastModified', ['desc']);
			},

			method() {
				if( this.log == null ) return false;
				return this.log?.request?.url;
			},

			lastModified() {
				if( this.log == null ) return false;
				return this.log?.details?.lastModified;
			},

			status() {
				if( this.log == null ) return false;
				return this.log?.response?.status;
			}
        },

		methods: {
			...mapActions('AppMatterKfi', ['fetchLogs', 'fetchLog']),

			open(iterationId) {
				this.logs = [];
				this.log = null;
				this.$refs.logs.open();
				this.iterationId = iterationId;
				this.fetchLogs(this.iterationId)
					.then((resp) => this.logs = resp.data?.logs)
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'There are no logs available for this request'));
			},

            async openLog(item) {
                this.panel = 0;
                this.log = null;
                this.$refs.log.open();
                const { data } = await this.fetchLog({ id: this.iterationId, payload: { file: item.file } });
                this.log = { ...data.log, details: item };
				if (!item.method) {
					const index = this.logs.findIndex((i) => i.file === item.file);
					this.$set(this.logs, index, { ...this.logs[index], method: data.log?.request?.url });
				}
			}
		}
	};
</script>

<style scoped lang="scss">
    ::v-deep .jv-container {
        .jv-code.open {
            padding: 2rem;
        }
    }
</style>