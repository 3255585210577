import { AccountUser, AccountUserAccount, AccountUserAddress, AccountUserDepartment, AccountUserDetail, AccountUserIdentity } from '@/service';

/**
 * @class @name
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	state: {},

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	getters: {},

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	mutations: {},

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @async @name addUser
		 * @param {Object} context
		 * @param {Object} payload
		 */
		addUser(context, payload) {
			return AccountUser.add(payload);
		},

		/**
		 * @async @name addApiUser
		 * @param {Object} context
		 * @param {Object} payload
		 */
		addApiUser(context, payload) {
			return AccountUser.addApiUser(payload);
		},

		/**
		 * @async @name fetchUserList
		 * @param {Object} context
		 * @param {Object} payload
		 */
		fetchUserList(context, payload) {
			return AccountUser.list(payload);
		},

		/**
		 * @async @name fetchUser
		 * @param {Object} context
		 * @param {String} id
		 */
		fetchUser(context, id) {
			return AccountUser.get(id);
		},

		/**
		 * @async @name modifyUser
		 * @description used to modify user details
		 * @param {Object} context
		 * @param {Object} data
		 */
		modifyUser(context, { id, data }) {
			return AccountUser.modify(id, data);
		},

		/**
		 * @async @name modifyUserAccount
		 * @param {Object} context
		 */
		modifyUserAccount(context, { id, data }) {
			return AccountUserAccount.modify(id, data);
		},

		/**
		 * @name modifyUserAddress
		 * @param {Object} context
		 */
		modifyUserAddress(context, { id, data }) {
			return AccountUserAddress.modify(id, data);
		},

		/**
		 * @name modifyUserDetail
		 * @param {Object} context
		 */
		modifyUserDetail(context, { id, data }) {
			return AccountUserDetail.modify(id, data);
		},

		/**
		 * @name modifyUserIdentity
		 * @param {Object} context
		 */
		modifyUserIdentity(context, { identity, data }) {
			return AccountUserIdentity.modify(identity.id, data);
		},

		/**
		 * @name addUserDepartment
		 * @param {Object} context
		 * @param {Object} payload
		 */
		addUserDepartment(context, payload) {
			return AccountUserDepartment.add(payload);
		},

		/**
		 * @name modifyUserDepartment
		 * @param {Object} context
		 */
		modifyUserDepartment(context, { id, data }) {
			return AccountUserDepartment.modify(id, data);
		},

		/**
		 * @async @name deleteUser
		 * @param {Object} context
		 * @param {String} id
		 */
		deleteUser(context, { id, data }) {
			return AccountUser.delete(id, data);
		},

		/**
		 * @async @name addUserToRole
		 * @param {Object} context
		 * @param {Object} payload
		 */
		addUserToRole(context, { id, payload }) {
			return AccountUser.addUserToRole(id, payload);
		},

		/**
		 * @async @name addUserGroups
		 * @param {Object} context
		 * @param {Object} payload
		 */
		addUserGroups(context, { id, payload }) {
			return AccountUser.addUserGroups(id, payload);
		}
	}
};
